import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText, FormInputDropdown } from "../Form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import _, { map } from "underscore";

const { forwardRef, useState, useImperativeHandle } = React;
const AddEditProjectRoleMaster = (prop, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ ProjectRoleMaster: {} });
  useImperativeHandle(ref, () => ({
    openModal: async (id) => {
      setShow(true);
      if(id === 0){
        const parentRequest = await reportToFetch(id);
        setData({ProjectRoleMaster: {}, parent: parentRequest});
      }else {
        setShow(false);
        await fetchProject(id);
        setShow(true);
      }
    },
  }));

  const reportToFetch = async (id) => {
    const parentRequest = await WebService({
      dispatch,
      endPoint: `CommonUtility/static_project_roles?where=ProjectRoleId ne ${id || 0}`,
    }).then((c) =>
      [{}].concat(
        map(c ?? [], (m) => ({
          value: m.ProjectRoleId,
          text: m.DisplayDescription,
        }))
      )
    );
    return parentRequest;
  }

  const fetchProject = async(id) => {
    // const projectRoleRequest = WebService({
    //   endPoint: `CommonUtility/static_project_roles?where=ProjectRoleId eq ${id || 0}`,
    //   dispatch,
    // }).then((c) => (c.length > 0 ? c[0] : {}));
    
    const projectRoleRequest = await WebService({
      endPoint: `CommonUtility/Edit/static_project_roles?ProjectRoleId=${id || 0}`,
      dispatch,
  }).then((c) => (c.length > 0 ? c[0] : {}))

    // const parentRequest = await WebService({
    //   dispatch,
    //   endPoint: `CommonUtility/static_project_roles?where=ProjectRoleId ne ${id || 0}`,
    // }).then((c) =>
    //   [{}].concat(
    //     map(c ?? [], (m) => ({
    //       value: m.ProjectRoleId,
    //       text: m.DisplayDescription,
    //     }))
    //   )
    // );

    const parentRequest = await reportToFetch(id);

    // const parentRequest = await WebService({
    //   endPoint: `CommonUtility/Edit/static_project_roles?ProjectRoleId=${id || 0}`,
    //   dispatch,
    //     }).then((c) =>
    //     [{}].concat(
    //       map(c ?? [], (m) => ({
    //         value: m.ProjectRoleId,
    //         text: m.DisplayDescription,
    //       }))
    //     )
    //   );

    Promise.all([projectRoleRequest, parentRequest]).then(([projectRole, parent]) => {
      const data = {
        ProjectRoleMaster: projectRole,
        parent: parent,
      };
      setData(data);
});

  }
  const handleClose = () => {
    setShow(false)
    setData({ProjectRoleMaster : {}});
  };
  const requiredMessage = "This is a required field";
  const schema = yup
    .object()
    .shape({
      DisplayDescription: yup.string().trim().required(requiredMessage),
    })
    .required();
  const onSubmit = async (data) => {
    if (data.ProjectRoleId === undefined) {
      await WebService({
        endPoint: "CommonUtility/static_project_roles",
        body: data,
        dispatch,
      });
    } else {
      await WebService({
        endPoint: `CommonUtility/static_project_roles?ProjectRoleId=${data.ProjectRoleId}`,
        method: "PUT",
        body: data,
        dispatch,
      });
    }
    handleClose();
    prop.callBackEvent();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {(data.ProjectRoleMaster.ProjectRoleId || 0) === 0 ? "Add Project Designation" : "Edit Project Designation"}
        </Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={data.ProjectRoleMaster}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        <Modal.Body className="p-4">
          <div className="row">
            <div className="col-md-12">
              <FormInputText
                label="Project Designation"
                name="DisplayDescription"
                type="text"
                isRequired="true"
              />
            </div>
            <div className="col-md-12">
              <FormInputDropdown
                name="ParentId"
                ddOpt={data?.parent ?? []}
                label="Reports To"
              ></FormInputDropdown>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>          
          <Button  id="btnMenuMasterSubmit"  variant="outline-primary" type="submit">              {(data.ProjectRoleMaster.ProjectRoleId || 0) === 0 ? "Save Designation" : "Save Changes"}
 </Button>
  </Modal.Footer>  
       
      </Form>
    </Modal>
  );
};

export default forwardRef(AddEditProjectRoleMaster);
