import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText, FormInputDropdown } from "../Form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
import { map } from "underscore";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
const requiredMessage = StandardConst.requiredMessage;
const { forwardRef, useState, useImperativeHandle } = React;
const AddEditRoles = (prop, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ Roles: {} });

  const Roles = useSelector((s) => s.auth.AssignRole ?? {});

  useImperativeHandle(ref, () => ({
    openModal: async (id) => {
      setShow(true);
      if(id === 0){
        const data = {
          CloneRoles: await WebService({
            dispatch,
            endPoint: "Roles/Clone",
          }).then((c) =>
            [{}].concat(map(c, (m) => ({ value: m.RoleId, text: m.RoleName })))
          ),
        };
        setData(data);
      }else {
        setShow(false);
        const data = {
          // Roles: await WebService({
          //   endPoint: `CommonUtility/staticroles?where=RoleId eq ${id || 0}`,
          //   dispatch,
          // }).then((c) => (c.length > 0 ? c[0] : {})),
          Roles: await WebService({
            endPoint: `CommonUtility/Edit/staticroles?RoleId=${id || 0}`,
            dispatch,
            }).then((c) => (c.length > 0 ? c[0] : {})),
          // CloneRoles: await WebService({
          //   dispatch,
          //   endPoint: "Roles/Clone",
          // }).then((c) =>
          //   [{}].concat(map(c, (m) => ({ value: m.RoleId, text: m.RoleName })))
          // ),
        };
        setData(data);
        setShow(true);
      }
    },
  }));
  const handleClose = () => {
    setShow(false);
    setData({ Roles: {} });
  }
  const schema = yup
    .object()
    .shape({
      RoleName: yup.string().trim().required(requiredMessage),
    })
    .required();

  const onSubmit = async (data) => {
    if (data.RoleId === undefined) {
      await WebService({ dispatch, endPoint: "Roles", body: data });
    } else {
      await WebService({
        endPoint: `CommonUtility/staticroles?RoleId=${data.RoleId}`,
        method: "PUT",
        body: data,
        dispatch,
      });
    }

    handleClose();
    prop.callBackEvent();
  };

  const formBody = (
    <>
      <div className="row">
        <div className="col-md-12">
          <FormInputText
            label="Role Name"
            name="RoleName"
            type="text"
            isRequired="true"
          />
        </div>
        <div className="col-md-12">
        {Roles[0] === StandardConst.SystemRole.SuperAdmin && (
                <FormInputDropdown
                name="IsReserved"
                ddOpt={StandardConst.YesOrNoReserved}
                label="Reserved"
                value={data.Roles?.IsReserved}
            >
            </FormInputDropdown>
            )}
        </div>
      </div>
      {(data?.Roles?.RoleId ?? 0) === 0 && (
        <>
          <Row>
            <Col>
              <FormInputDropdown
                name="CloneRoleId"
                ddOpt={data.CloneRoles}
                label="Clone from"
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="px-2">
          {(data?.Roles?.RoleId || 0) === 0 ? "Add Role" : "Edit Role"}
        </Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={data.Roles}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        <Modal.Body className="p-4">
          {formBody}
        </Modal.Body>
        <Modal.Footer>          
          <Button id="btnRoleSubmit"  variant="outline-primary" type="submit"> {(data?.Roles?.RoleId || 0) === 0 ? "Submit" : "Save Changes"}</Button>
        </Modal.Footer>  
       
      </Form>
    </Modal>
  );
};
export default forwardRef(AddEditRoles);
