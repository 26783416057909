import React, { forwardRef, useImperativeHandle, useState } from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, FormInputDropdown, FormInputText } from '../Form';
import * as yup from "yup";
import { StandardConst } from '../../Services/StandardConst';
import { WebService } from '../../Services/WebService';
import { useDispatch, useSelector } from "react-redux"
import { map } from 'underscore';


const AddEditAccessLabel = (props, ref) => {

    const [show, setShow] = useState(false);
    const [data, setData] = useState({ AccessLevel: {} });
    const CompanyInfo = useSelector((s) => s.auth.CompanyInfo);
    const Roles = useSelector((s) => s.auth.AssignRole ?? {});
    const isSuperAdmin = Roles?.some(element => element === StandardConst.SystemRole.SuperAdmin);
    const dispatch = useDispatch();
    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            setShow(true);
            fetchAccesslevelTypes();
            if(Roles[0] === StandardConst.SystemRole.SuperAdmin) getAllCompaniesList();
            if (id === 0) {
                const data = {
                    AccessLevel: {},
                };
                setData(data);
            } else {
                setShow(false);
                await fetchEditValue(id);
                setShow(true);
            }
        },
    }));

    const fetchEditValue = async (id) => {
        const data = {
            AccessLevel: await WebService({
                endPoint: `CommonUtility/Edit/accesslevel?AccessLevelId=${id || 0}`,
                dispatch,
            }).then((c) => (c.length > 0 ? c[0] : {})),
        };
        setData(data);
    }

    const [AccessLevelTypeList, setAccessLevelTypeList] = useState([]);

    const fetchAccesslevelTypes = async () => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/accessleveltype`,
            dispatch,
        });
        const list = data.map((r) => {
            return { text: r.TypeName, value: r.AccessLevelTypeId, icon: r.Icon };
        });
        setAccessLevelTypeList(list);
    }

    const handleClose = () => {
        setShow(false)
        setData({AccessLevel : {}});
    };

    const requiredMessage = "This is a required field";
    const schema = yup
        .object()
        .shape({
            AccessLevelName: yup.string().trim().required(requiredMessage),
            Status: yup.string().trim().required(requiredMessage),
            AccessLevelTypeId: yup.string().trim().required(requiredMessage),
        })
        .required();

    const onSubmit = async (data) => {
        if (isSuperAdmin) {
            if (Number(data.CompanyId) === 0) {
                data.CompanyId = CompanyInfo.CompanyId;
            }
            else {
                data.CompanyId = Number(data.CompanyId);
            }
        }
        data.CompanyId = CompanyInfo.CompanyId;
        if (data.AccessLevelId === undefined) {
            await WebService({
                endPoint: "CommonUtility/accesslevel",
                body: data,
                dispatch,
            });
        } else {
            await WebService({
                endPoint: `CommonUtility/accesslevel?AccessLevelId=${data.AccessLevelId}`,
                method: "PUT",
                body: data,
                dispatch,
            });
        }
        handleClose();
        props.callBackEvent();
    }

    const [companyList, setCompanyList] = useState([]);

    const getAllCompaniesList = async () => {
        await WebService({
            endPoint: `CommonUtility/fetchAll/companyinformation`,
            dispatch,
        }).then((companyList) => {

        const companyListArray = map(companyList, (oneCompany) => ({
            value: oneCompany.CompanyId || "" || null || undefined,
            text: oneCompany.CompanyName,
        }));

        companyListArray.unshift({ value: 0, text: "All Companies" });
        setCompanyList(companyListArray);
    });
};
    
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {(data && data.AccessLevel && data.AccessLevel.AccessLevelId || 0) === 0
                        ? "Add Access Level"
                        : "Edit Access Level"
                    }
                </Modal.Title>
            </Modal.Header>
            <Form
                defaultValues={data.AccessLevel}
                onSubmit={onSubmit}
                validationSchema={schema}
            >
                <Modal.Body className="p-4">
                    <div className="row">
                        <div className="col-md-12">
                            <FormInputText
                                label="Access Level Name"
                                name="AccessLevelName"
                                type="text"
                                isRequired="true"
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputDropdown
                                name="Status"
                                ddOpt={StandardConst.StatusDropdown}
                                label="Status"
                                isRequired="true"
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputDropdown
                                name="AccessLevelTypeId"
                                ddOpt={AccessLevelTypeList}
                                label="Access Level Type"
                                isRequired="true"
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputText
                                label="Access Level Code"
                                name="Code"
                                type="text"
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputText
                                name="Description"
                                label="Description"
                                as="textarea"
                                rows="2"
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-12">
                            {Roles[0] === StandardConst.SystemRole.SuperAdmin && (
                                <FormInputDropdown
                                    name="CompanyId"
                                    ddOpt={companyList}
                                    label="Access"
                                >
                                </FormInputDropdown>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnAccessLevelSubmit" variant="outline-primary" type="submit">
                        {" "}
                        {(data && data.AccessLevel && data.AccessLevel.AccessLevelId || 0) === 0
                            ? "Submit"
                            : "Save Changes"}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default forwardRef(AddEditAccessLabel);