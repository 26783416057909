import React, { useEffect, useRef, useState } from 'react'
import ActionButton from '../../Services/ActionButton';
import { StandardConst } from '../../Services/StandardConst';
import { WebService } from '../../Services/WebService';
import { ActionPermission, PageInfo } from '../PageInfo';
import { useDispatch, useSelector} from "react-redux";
import { NoRecordTemplate } from '../../Services/TableComponent';
import StaticListComponent from '../../Services/StaticListComponent';
import SnackbarComponent from '../../Services/SnackbarComponent';
import DeleteConfirmAlert from "../../Services/AlertComponent";
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import Button from "react-bootstrap/esm/Button";
import AddEditAccessLevelType from './AddEditAccessLevelType';


const AccessLevelType = () => {

    const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    const [permission, SetPermission] = useState({});
    const [records, setRecords] = useState([]);
    PageInfo({ pageTitle: "Access Level Type" });
    useEffect(() => {
        SetPermission({
            ManageAdd: ActionPermission("Access Level Type - Add"),
            ManageEdit: ActionPermission("Access Level Type - Edit"),
            ManageDelete: ActionPermission("Access Level Type - Delete"),
        });
    }, [permissionList]);


    const fetchAccesslevelTypes = async () => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/accessleveltype`,
            dispatch,
        });
        setRecords(data);
        dispatch(ConfigurationflowStatusUpdate());
    }

    useEffect(() => {
        fetchAccesslevelTypes();
    }, []);


    const refSnackbar = useRef();
    const ref = useRef();
    const MasterPageName = "Access Level Type";
    const confirmMessage = MasterPageName + " Deleted successfully";
    const addEditModalRef = useRef();

    const AddButtonText = "Access Level Type";
    const dispatch = useDispatch();
    const onDelete = async (AccessLevelTypeId) => {
        await WebService({
            endPoint: `CommonUtility/accessleveltype?AccessLevelTypeId=${AccessLevelTypeId}`,
            method: "DELETE",
            dispatch,
        });
        refSnackbar.current.setOpenSnackBar();
        await fetchAccesslevelTypes();
    };

    const [bData,] = React.useState([
        {
            title: "Access Management",
            hrefLink: "#",
        },
        {
            title: "Access Level Type",
            hrefLink: "#",
        },
    ]);

    const columns = [
        {
            Text: "Type Name",
            Value: "TypeName"
        },
        {
            Text: "Icon",
            Value: "Icon"
        },
        {
            Text: "Status",
            Value: "Status",
            render: (dr) => (
                <>
                    {dr.Status === StandardConst.StatusDropdown[0].value ? StandardConst.StatusDropdown[0].text : StandardConst.StatusDropdown[1].text}
                </>
            )
        },
        {
            Text: "Action",
            cssClass: "text-center td-width-100",
            isVisiable: permission.ManageEdit || permission.ManageDelete,
            render: (dr) => (
                <>
                    <ActionButton
                        onClick={() => fnEdit(dr.AccessLevelTypeId)}
                        disabled={!permission.ManageEdit}
                        IconName="Edit"
                        IconTooltip={"Edit Access Level Type"}
                        id={`btnAccessLevelTypeEdit_${dr.AccessLevelTypeId}`}
                    />
                    <ActionButton
                        onClick={(e) =>
                            ref.current.confirmAlert(
                                "Delete", //Confirm button text
                                "Are You Sure", // Text if Alert
                                "Do you want to delete " + dr.TypeName, // Message of Alert
                                dr.AccessLevelTypeId  // Endpoint to hit for delete
                            )}
                        disabled={!permission.ManageDelete}
                        IconName="Delete"
                        IconTooltip={"Delete Access Level Type"}
                        id={`btnAccessLevelTypeDelete_${dr.AccessLevelTypeId}`}
                    />
                </>
            ),
        },
    ];

    const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);

    return (
        <>
            <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
            <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
            <StaticListComponent
                columns={columns}
                records={records}
                bData={bData}
                MasterPageName={MasterPageName}
                AddButtonText={AddButtonText}
                noRecordCss="p-0"
                noRecordFound={
                    <NoRecordTemplate
                        headerValue={StandardConst.AccessLevelTypeheaderValueNoResults}
                        subHeaderValue={StandardConst.AccessLevelTypesSubHeader}
                        imageUrl={StandardConst.imageNoRecordsFound}
                        actionButton={
                            <>
                                {permission.ManageAdd && (
                                    <Button variant="outline-primary" onClick={() => fnEdit(0)}>
                                        Add {AddButtonText}
                                    </Button>
                                )}
                            </>
                        }
                    />
                }
                onAddEvent={() => fnEdit()}
                IsAddButtonVisible={permission?.ManageAdd}
                isSearchRequired={true}
                allowSerialNo={true}
            ></StaticListComponent>
            <AddEditAccessLevelType
                callBackEvent={async () => await fetchAccesslevelTypes()}
                ref={addEditModalRef}
            ></AddEditAccessLevelType>
        </>
    )
}

export default AccessLevelType