import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormAutoCompleteDropdownCard, FormInputDropdown } from "../Form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { map } from "underscore";
import { useNavigate } from "react-router-dom";
import { ActionPermission } from "../PageInfo";
import { Alert } from "@mui/material";
import { StandardConst } from "../../Services/StandardConst";
const { forwardRef, useState, useImperativeHandle } = React;
const AddEditUserRole = (prop, ref) => {

  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
      ManageAdd: ActionPermission("Roles - Add"),
    });
  }, [permissionList]);

  var nevigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ UserRole: {} });
  const CompanyId = useSelector((s)=> s.auth.CompanyInfo.CompanyId);
  const [isRoleAlready, setIsRoleAlready] = useState(false);
  const assignedRole = useSelector((s) => s.auth.AssignRole);
  const [EmployeesList, setEmployeesList] = useState([]);

  useImperativeHandle(ref, () => ({
    openModal: async () => {
      setShow(true);
      setIsRoleAlready(false);
      let endPoint = ``;
      if(assignedRole?.length > 0){
        assignedRole.map((element)  => {
          if(element === StandardConst.SystemRole.SuperAdmin) {
            endPoint = `UserRole/fetchRoles?CompanyId=${CompanyId}`;
          }
          endPoint = `UserRole/fetchRoles?CompanyId=${CompanyId}&IsReserved=${0}`;
        });
      }
      const data = {
        roles: await WebService({
          endPoint: endPoint,
          dispatch,
          requiredLoader: false,
        }).then((c) => map(c, (m) => ({ value: m.RoleId, text: m.RoleName })))
      };
      setData(data);

      const empData = await WebService({
        dispatch,
        endPoint: `Visitor/fetchAllEmployees`,
        requiredLoader: false,
      });
      const list = empData?.filter((r) => r.FullName !== null)?.map((res) => {
        return {
            uniqueId: res.EmployeeId,
            heading: res.FullName, 
            description: res.Designation, 
            avatar: `${StandardConst.apiBaseUrl}/uploads/${res.ProfileImage}`
          }
      });
      setEmployeesList(list);
    },
  }));
  const handleClose = () => setShow(false); 
  
  const schema = yup
    .object()
    .shape({
      RoleId: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
      EmployeeId: yup
        .array()
        .of(
          yup.object().shape({
            heading: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
          })
        )
        .typeError(StandardConst.ValidationMessages.RequiredFieldMsg)
        .min(1, StandardConst.ValidationMessages.RequiredFieldMsg),
    })
    .required();
  const onSubmit = async (data) => {
    data.EmployeeId = data.EmployeeId[0].uniqueId;

    const isRoleAlreadyAssigned = await WebService({
      endPoint: `UserRole/isRoleAlreadyAssigned?RoleId=${data.RoleId}&EmployeeId=${data.EmployeeId}`,
      dispatch
    });
    if(isRoleAlreadyAssigned.length <= 0){
      await WebService({
        endPoint: "CommonUtility/userroles",
        body: data,
        dispatch,
      });
      handleClose();
      prop.callBackEvent();
    }else {
      setIsRoleAlready(true);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Employee Role</Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={data.UserRole}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        {
          isRoleAlready ? 
          <Alert severity="warning">
              This role is already assigned to the user
          </Alert>
          : 
          null
        }
        <Modal.Body className="p-4">
          <div className="row">
            <div className="col-md-12">
              <FormAutoCompleteDropdownCard
                name="EmployeeId"
                data={EmployeesList}
                label="Select Employee"
                optionText="heading"
                isRequired="true"
                placeholder="Choose a Employee"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <FormInputDropdown
                name="RoleId"
                label="Select Role"
                ddOpt={data.roles}
                isRequired="true"
              ></FormInputDropdown>
            </div>
          </div>
          <div className="row">
          {permission.ManageAdd && (
              <div className="col-md-12 text-end mt-1">
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={() => nevigate("/Roles")}
                  className="float-end"
                >
                  Add Role
                </Button>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="btnUserRoleSubmit"
            variant="outline-primary"
            type="submit"
          >
            {" "}
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default forwardRef(AddEditUserRole);
