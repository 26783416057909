import Button from "react-bootstrap/esm/Button";
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import * as htmlToImage from "html-to-image";
import { saveAs } from "file-saver";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { StandardConst } from "./StandardConst";
import { useSelector } from "react-redux";
const QRCodeGeneratorDownloadComponent = ({ data, showWodLogo, showLogo, FileName, EventLogo, Text }) => {
  Text ??= null;
  EventLogo ??= null;
  EventLogo = EventLogo === "" ? null : EventLogo;
  FileName ??= "QrCode";
  data ??= null;
  showLogo ??= false;
  const CompanyInfo = useSelector((s) => s.auth.CompanyInfo);
  const handleDownload = () => {
    const qrCodeNode = document.getElementById("qrcode");
    if (qrCodeNode) {
      // Convert the QR code element to an image
      htmlToImage
        .toPng(qrCodeNode)
        .then(function (dataUrl) {
          // Create a Blob from the data URL
          const blob = dataURLtoBlob(dataUrl);

          // Save the Blob as a file
          saveAs(blob, FileName + ".png");
        })
        .catch(function (error) {
          console.error("Error generating QR Code image:", error);
        });
    }
  };

  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const [logo, setLogo] = useState("");

  const getLogoData = async() => {
    if(EventLogo !== null || CompanyInfo?.Logo !== null){
      const logoData = await fetch(`${StandardConst.apiBaseUrl}/Visitor/getLogo/${EventLogo !== null ? EventLogo : CompanyInfo?.Logo}`);
      const LogoHTMLContent = await logoData.text();
      setLogo(LogoHTMLContent === "Logo File Not Found" ? "" : LogoHTMLContent);
    }
  }

  useEffect(() => {
    if(showLogo){
      getLogoData();
    }
  }, [showLogo]);
  return (
    <>
      <div className="d-flex flex-column justify-content-center">
        <div
          id="qrcode"
          className={`d-flex flex-column`}
          style={{
            padding: "10px",
            background: "#fff",
          }}
        >
          {(logo !== "")  && (
                <div className="p-2 d-flex justify-content-center">
                  <div style={{ width: "100px" }}>
                    <img src={logo} alt=""/>
                  </div>
                </div>
            )
          }

          <div className="d-flex justify-content-center p-2">
            <QRCode value={data} viewBox={`0 0 256 256`} size={250} />
          </div>
          {(Text !== null) && (
            <label className="justify-content-center text-center">{Text}</label>
          )}

          {
            showWodLogo && (
                <div className="p-2 d-flex justify-content-center">
                  <div style={{ width: "100px"}}>
                    <img src="WOD.png" alt=""/>
                  </div>
                </div>
            )
          }
        </div>

        <div className="d-flex justify-content-center">
          <Button
            onClick={handleDownload}
            className="mt-3 d-flex justify-content-center"
            variant="outline-primary"
            size="sm"
          >
            <CloudDownloadIcon color="inherit" sx={{ fontSize: 20 }} />
            &nbsp; Download QR
          </Button>
        </div>
      </div>
    </>
  );
};

export default QRCodeGeneratorDownloadComponent;
