import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import QrReader from 'react-qr-scanner';
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { WebService } from "../../Services/WebService";
import {
  Card, IconButton,
} from "@mui/material";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import { StandardConst } from "../../Services/StandardConst";
import { WSErrorAlert, WSInfoAlert, WSSuccessAlert } from "../../Services/WSAlert";
import { PageInfo } from "../PageInfo";
import { DateTime } from "luxon";
import Modal from "react-bootstrap/Modal";
import HtmlFileReader from "../../Services/HtmlFileReader";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CloseIcon from '@mui/icons-material/Close';
import { NoRecordTemplate } from "../../Services/TableComponent";
import ActionButton from "../../Services/ActionButton";
import { format } from "date-fns/esm";

const ValidateBadge = () => {
  const dispatch = useDispatch();
  const OpenBadgeRef = useRef();
  PageInfo({ pageTitle: "Validate Badge" });
  // "environment" dene per by default back camera open hota hai or front camera open krne ke liye "user" dena hota hai
  const [openFrontOrBackCamera , setOpenFrontOrBackCamera] = useState(StandardConst.Camera.BackCamera);
  
  const MasterPageName = "Validate Badge";
  const [bData, setBData] = React.useState([
    {
      title: "Home",
      hrefLink: "/",
      visible: false,
    },
    {
      title: "Validate Badge",
      hrefLink: "#",
    },
  ]);


  // This code is qr scanner
  const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const CompanyId = useSelector((s) => s.auth.CompanyInfo?.CompanyId ?? 0);
  const [key, setKey] = useState(0);

  const handleScan = (data) => {
    if (data) {
      setKey((prevKey) => prevKey + 1);
      FetchVisitorDetails(data.text);
    }
  }

  const handleError = (err) => {
    console.log(err);
  }

  // scannedQRData me qr code me data ayega - agar visitor hai to "V,{Guid}" or agar employee hai to "E,{Guid}"
  const FetchVisitorDetails = async (scannedQRData) => {
    let showTheErrorMsg = true;
    const arrQRCodeData = scannedQRData.split(',');

    if(scannedQRData !== "" && arrQRCodeData.length > 0) {
      const VisitorOrEmployee = arrQRCodeData[0];
      const Guid = arrQRCodeData[1];

      // this is for visitors badges -- Guid ki length 36 hoti hai to humne check kr liye ki agar 32 se jada hai to iska mtlab hai ki hamara hi qr code hai
      if(VisitorOrEmployee === StandardConst.UserType.Visitor && Guid.length > 32) {
        const visitorData = await WebService({
          dispatch,
          endPoint: `CommonUtility/Edit/visitor?Guid='${Guid}'`,
        });

        if(visitorData.length > 0) {
          var EndPoint = `Visitor/GetVisitorDetails/${CompanyId}/${visitorData[0].VisitorId}`;
          
          if(visitorData[0].EventId !== null){
            EndPoint += `?EventId=${visitorData[0].EventId}`;
          }

          await WebService({
            dispatch,
            endPoint: EndPoint,
          }).then(async (d) => {
            if(d.length > 0 && d[0].badge_templatesId !== null) {
              showTheErrorMsg = false;

              if(visitorData[0].EventId !== null){
                const todayDate = format(new Date(), "yyyy-MM-dd");
                const fromDate = format(new Date(d[0].EventFromDate), "yyyy-MM-dd");
                const toDate = format(new Date(d[0].EventToDate), "yyyy-MM-dd");
                const EventExpire = fromDate <= todayDate && todayDate <= toDate;
                if(!EventExpire){
                  WSErrorAlert("Invalid Badge", "Event Expired this badge is not a valid badge. Please contact reception desk.");
                  return;
                }
              }

              const visitorTypeSelectedTemplate = [{
                text: d[0].badge_template_name, value: d[0].badge_templatesId,
                file: d[0].html_file_path
              }];
      
              const nameString = d[0]?.VisitorName.toUpperCase();
              const lines = nameString.split(' ');
              const nameFormattedText = lines.length >= 5 ? lines.slice(0, 4).join(' ') : null;
      
      
              const companyString = d[0]?.VisitorCompany?.split(' ');
              const companyFormattedText = companyString?.length >= 5 ? companyString?.slice(0, 5).join(' ') : null;
      
              const paramData = {
                Name: nameFormattedText || d[0]?.VisitorName.toUpperCase(),
                Photo: d[0].ProfilePicture,
                VisitorType: d[0]?.TypeName,
                Company: companyFormattedText || d[0]?.VisitorCompany,
                Email: d[0]?.Email,
                Phone: d[0]?.ContactNo,
                Logo: CompanyInfo.Logo,
                VisitorId: d[0]?.VisitorId,
                CheckInTime: d[0]?.CheckIn
                  ? DateTime.fromISO(d[0]?.CheckIn, { zone: 'utc' }).toFormat('dd-MMM-yyyy hh:mm a')
                  : "Not CheckedIn",
                CheckOutTime: d[0]?.CheckOut
                  ? DateTime.fromISO(d[0]?.CheckOut, { zone: 'utc' }).toFormat('dd-MMM-yyyy hh:mm a')
                  : null,
                QRData:
                  "Name: " + d[0]?.VisitorName +
                  "Company: " + d[0]?.VisitorCompany +
                  "Email: " + d[0]?.Email +
                  "Phone: " + d[0]?.ContactNo
                ,
                VisitorGuid: d[0]?.Guid
              };

              await OpenBadgeRef.current.openModal(paramData, visitorTypeSelectedTemplate, StandardConst.UserType.Visitor);
            }
          });
        }

      }

      // this is for Employee Id Card -- Guid ki length 36 hoti hai to humne check kr liye ki agar 32 se jada hai to iska mtlab hai ki hamara hi qr code hai
      if(VisitorOrEmployee === StandardConst.UserType.Employee && Guid.length > 32) {
          const employeeData = await WebService({
            dispatch,
            endPoint: `CommonUtility/Edit/employees?Guid='${Guid}'`,
          });

          if(employeeData.length > 0) {
            const data = await WebService({
              endPoint: `EmployeeIdCard/FetchActiveIdCard/${employeeData[0].EmployeeId}`,
              dispatch,
            }).then(async (data) => {
              if(data?.IdCardDetails.length > 0 && data?.IdCardDetails[0].badge_templatesId !== null) {
                showTheErrorMsg = false;
                
                const Details = data?.IdCardDetails[0];
                const SelectedTemplate = [{text: Details.badge_template_name, value: Details.badge_templatesId}];
                const companyString = Details.CompanyName?.split(' ');
                const companyFormattedText = companyString?.length >= 5 ? companyString?.slice(0, 5).join(' ') : null;
                const nameString = Details?.FullName?.toUpperCase().split(' ');
                const nameFormattedText =nameString?.length >= 3 ? nameString?.slice(0, 3).join(' ') : null;
            
                const permanentAddressString = Details?.PermanentAddress?.toUpperCase()?.split(' ');
                const permanentAddressFormattedText =permanentAddressString?.length >= 3 ? permanentAddressString?.slice(0, 3).join(' ') : null;
            
                const communicationAddressString = Details?.Address?.toUpperCase()?.split(' ');
                const communicationAddressFormattedText =communicationAddressString?.length >= 6 ? communicationAddressString?.slice(0, 6).join(' ') : null;
                
                const paramData = {
                  CompanyName: companyFormattedText || Details.CompanyName,
                  Logo: CompanyInfo.Logo,
                  ProfileImage: Details?.ProfileImage,
                  FullName: nameFormattedText || Details.FullName.toUpperCase(),
                  EmployeeCode: Details?.EmployeeCode?.toUpperCase(),
                  PermanentAddress: permanentAddressFormattedText || Details.PermanentAddress,
                  Email: Details.EmployeeEmail,
                  Designation: Details.Designation,
                  Phone: Details.Phone,
                  Address: Details.Address,
                  CommunicationAddress: communicationAddressFormattedText || Details.Address,
                  CompanyPhone: Details.CompanyPhone,
                  EmployeeGuid: Details.Guid,
                  QRData: 
                      "Name: " + Details.FullName + 
                      "Company: " + Details.CompanyName + 
                      "Email: " + Details.Address + 
                      "Phone: " + Details.Phone + 
                      "Code: " + Details.EmployeeCode,
                }
                await OpenBadgeRef.current.openModal(paramData, SelectedTemplate, StandardConst.UserType.Employee);
              }
            });
          }
      }
    }

    if(showTheErrorMsg){
      WSErrorAlert("Invalid Badge", "This badge is not a valid badge. Please contact reception desk.");
    }

  };

  const toggleCamera = () => {
    if(openFrontOrBackCamera === StandardConst.Camera.BackCamera){
      setOpenFrontOrBackCamera(StandardConst.Camera.FrontCamera);
    }else{
      setOpenFrontOrBackCamera(StandardConst.Camera.BackCamera);
    }
  }


  return (
    <div className="base-container container-fluid ">
      <Box
        sx={{
          width: 1,
          height: 80,
        }}
      >
        <h3 className="ms-4 mt-2">{MasterPageName}</h3>
        <div className="ms-4">
          <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
        </div>
      </Box>
      <div className="p-3 primary-bg-color"></div>

      <div class="base-margin-x base-margin-2y mb-2 ">
        <div className="row mb-4 justify-content-center mt-2">
          <Card sx={{ maxWidth: 600, backgroundColor: "#fbfbfb" }}>
            <div className="text-center">
                <QrReader
                  key={key}
                  delay={300}
                  onError={handleError}
                  onScan={handleScan}
                  constraints={{
                    video: { facingMode: openFrontOrBackCamera }
                  }}
                  style={{ width: '100%' }}
                />
                <span>
                  Scan the QR CODE of the badge  
                  <ActionButton
                    onClick={() => toggleCamera()}
                    IconName="SwitchCameraLarge"
                    IconTooltip="Switch Camera"
                    id="SwitchCamera"
                  />
                  <ActionButton
                    IconName="FullScreenLarge"
                    IconTooltip="Full Screen"
                    id="FullScreen"
                  />
                  <ActionButton
                    IconName="FullScreenExitLarge"
                    IconTooltip="Exit Full Screen"
                    id="FullScreenExit"
                  />
                </span>
            </div>
          </Card>
          <ShowBadge ref={OpenBadgeRef} />
        </div>
      </div>
    </div>
  );
};

const ShowBadge = forwardRef((prop, ref) => {
  const [show, setShow] = useState(false);
  const [visitorTypeSelectedTemplate, setVisitorTypeSelectedTemplate] = useState([]);
  const [paramData, setParamData] = useState({});
  const [closeMsg, setCloseMsg] = useState(true);
  let timeoutId;

  const handleClose = () => {
    clearTimeout(timeoutId);
    setVisitorTypeSelectedTemplate([]);
    setParamData({});
    setShow(false);
  };
  useImperativeHandle(ref, () => ({
    openModal: async (paramData, visitorTypeSelectedTemplate, UserType) => {
      setCloseMsg(true);
      if(paramData !== null && visitorTypeSelectedTemplate !== null) {
        setVisitorTypeSelectedTemplate(visitorTypeSelectedTemplate);
        setParamData(paramData);
      }
      setShow(true);

      if(UserType === StandardConst.UserType.Employee){
        setCloseMsg(false);
      }
      timeoutId = setTimeout(() => {
        setCloseMsg(false);
      }, 5000);
    },
  }));

  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      className="container-fluid"
    >
      <Modal.Header closeButton className="border-0"></Modal.Header>
      <Modal.Body closeButton>
        <div className="position-relative w-100">
          {/* this is for Invalid visitor */}

          {(Object.keys(paramData).length == 0 && closeMsg) && (
              <div class="z-3 position-absolute bottom-0 right-0 alert w-100 alert-danger" role="alert" style={{zIndex: 999}}>
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-2 ">
                      <RemoveCircleIcon style={{ fontSize: "50px" }} />
                  </div>
                  <div className="col-10">
                    <span><b>{StandardConst.InvalidVisitor}</b></span>
                    {/* <br/>
                    {(paramData.CheckOutTime !== null) && (
                      <span>Checked Out at {paramData.CheckOutTime}</span>
                    )}
                    {(paramData.CheckInTime !== null && paramData.CheckOutTime === null) && (
                      <span>Checked In at {paramData.CheckInTime}</span>
                    )} */}
                  </div>
                  {/* <div className="col-2 text-center">
                    <CloseIcon onClick={() => msgClose()} />
                  </div> */}
                </div>
              </div>
          )}

          {/* this is for checked in or Checked Out */}
          {(Object.keys(paramData).length > 0 && paramData.CheckInTime !== null && closeMsg) && (
            <div class={`z-3 position-absolute bottom-0 right-0 alert w-100 ${(paramData.CheckOutTime !== null) ? 'alert-warning' : 'alert-info'}`} role="alert" style={{zIndex: 999}}>
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-2 ">
                  {paramData.CheckOutTime !== null ? (
                    <ErrorIcon style={{ fontSize: "50px" }} />
                  ) : (
                    <CheckCircleIcon style={{ fontSize: "50px" }} />
                  )}
                </div>
                <div className="col-10">
                  <span><b>{paramData.Name}</b></span><br/>
                  {(paramData.CheckOutTime !== null) && (
                    <span>Checked Out at {paramData.CheckOutTime}</span>
                  )}
                  {(paramData.CheckInTime !== null && paramData.CheckOutTime === null) && (
                    <span>Checked In at {paramData.CheckInTime}</span>
                  )}
                </div>
                {/* <div className="col-2 text-center">
                  <CloseIcon onClick={() => msgClose()} />
                </div> */}
              </div>
            </div>
          )}

          {(Object.keys(paramData).length == 0 ) ? (
            <NoRecordTemplate
                headerValue={StandardConst.InvalidVisitor}
                imageUrl={StandardConst.imageNoRecordsFound}
                // subHeaderValue={StandardConst.ManageEventSubHeaderRole}
            />
          ) : (
            <HtmlFileReader 
              jsonData={paramData} 
              ShowDropdown={0} 
              ShowPrintBadgeBtn={0} 
              ShowDownloadBadgeBtn = {0}
              SelectedTemplateId={visitorTypeSelectedTemplate}
              selfVisitorBadge={StandardConst.ShowOrHide.Hide}
              showDropDownList={StandardConst.BadgeType[0].text}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
});

export default ValidateBadge;
