import React, { useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormAutoCompleteDropdownCard, FormInputText } from "../Form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import format from "date-fns/format";
import SnackbarComponent from "../../Services/SnackbarComponent";
import HtmlFileReader from "../../Services/HtmlFileReader";
import { StandardConst } from "../../Services/StandardConst";
import { Alert } from "@mui/material";
import { WSSuccessAlert } from "../../Services/WSAlert";
const { forwardRef, useState, useImperativeHandle } = React;

const AddEmployeeIdCard = (prop, ref) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const [paramData, setParamData] = useState({
        CompanyName: "CompanyName",
        Logo: CompanyInfo.Logo,
        ProfileImage: "null",
        FullName: "FullName",
        EmployeeCode: "EmployeeCode",
        PermanentAddress: "PermanentAddress",
        Email: "EmployeeEmail",
        Designation: "Designation",
        Phone: "Phone",
        Address: "Address",
        CommunicationAddress: "OfficeAddress",
        CompanyPhone: "CompanyPhone",
        QRData: 
            "Name: " +
            "Company: " + 
            "Email: " + 
            "Phone: " +
            "Code: " ,
        EmployeeGuid: "Guid"
    });
    const [persons, setPersons] = useState([]);
    const refSnackbar = useRef();
    const [confirmMessage, setconfirmMessage] = useState([]);
    const [selectedFile, setSelectedFile] = useState("");
    const [showEditButton, setShowEditButton] = useState(false);
    const [showDisableAndAddNewButton, setShowDisableAndAddNewButton] = useState(false);

    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            const personlist = await fetchPersonList();
            if(id){
                setShowEditButton(true);
                await fetchEmployeeBadgeByEmployeeCardBadgeId(id, personlist);
            }
            setShow(true);
        },
    }));

    const handleClose = () => {
        setShow(false);
        setPersons([]);
        setFilter({});
        setShowDisableAndAddNewButton(false);
        setShowEditButton(false);
        setShowMsg(false);
    }
    
    const [filter, setFilter] = useState({
        FromDate: DateTime.local().toFormat("yyyy-MM-dd"),
        ToDate: DateTime.local().toFormat("yyyy-MM-dd"),
    });
    const requiredMessage = "This is a required field";
    const schema = yup
        .object()
        .shape({
            ExpireDate: yup
                .date()
                .label("Expire Date")
                .typeError(requiredMessage)
                .required(),
        })
        .required();


    const handleSelectFile = (Filename) => {
        setSelectedFile(Filename);
    }

    const fetchEmployeeBadgeIdCard = async() => {

    const badgeTemplates = await WebService({
        dispatch, 
        endPoint: `CommonUtility/fetchAll/badge_templates?CompanyId=${StandardConst.YesNo.Yes}&isReserved=${StandardConst.YesNo.Yes}`,
    });
    const badgeId = badgeTemplates.find((template) => {
        return template.badge_templatesId === Number(selectedFile);
    });
    return badgeId.badge_templatesId;
    }

    const [showMsg, setShowMsg] = useState(false);
    const onSubmit = async (data) => {
        // data.EmployeeId = data.EmployeeId[0].EmployeeId;
        data.EmployeeId = data.EmployeeId.uniqueId;
        const BadgeCardId = await fetchEmployeeBadgeIdCard();
        data.badge_templatesId = BadgeCardId;
        if ((data?.ExpiryDate || "") !== ""){
            data.ExpiryDate = format(new Date(data.ExpiryDate), "yyyy-MM-dd");
        }

        if(showEditButton && data.EmployeeCardBadgeId > 0){
            await WebService({
                endPoint: `CommonUtility/employeecardbadges?EmployeeCardBadgeId=${data.EmployeeCardBadgeId}`,
                body: data,
                method: "PUT",
                dispatch,
            }).then(res => {
                handleClose();
                WSSuccessAlert("Success", "Employee Id Card Successfully Updated");
            });
            await prop.callBackEvent();
        }else{
            data.IssuedDate = format(new Date(), "yyyy-MM-dd");
            data.StatusId = StandardConst.EmployeeIdCardStatus.Active;
            delete data.EmployeeCardBadgeId;
            if(!showEditButton && showDisableAndAddNewButton){
                await WebService({
                    endPoint: `CommonUtility/employeecardbadges?EmployeeId=${data.EmployeeId}&StatusId=${StandardConst.EmployeeIdCardStatus.Active}`,
                    body: {StatusId: StandardConst.EmployeeIdCardStatus.InActive},
                    method: "PUT",
                    dispatch,
                });
            }

            const res = await WebService({
                endPoint: "EmployeeIdCard/FetchCard",
                body: data,
                dispatch,
            });

            if (res === "Employee Id Card Already Created..!") {
                setconfirmMessage(res);
                setShowMsg(true);
            } else if (res === "Office location Data is Missing..!") {
                setconfirmMessage(res);
                setShowMsg(true);
            }else if (res === "Success"){
                handleClose();
                await prop.callBackEvent();
                WSSuccessAlert("Success", "Employee Id Card Successfully Generated");
            }
        }
    };

    const fetchPersonList = async () => {
        const res = await WebService({
            endPoint: `Visitor/fetchAllEmployees`,
            dispatch
        });
        fetchEmployeesDataForBadge(res[0]?.EmployeeId);
        const list = res?.filter((r) => r.FullName !== null)?.map((res) => {
            return {
                uniqueId: res.EmployeeId,
                heading: res.FullName,
                description: res.Designation,
                avatar: `${StandardConst.apiBaseUrl}/uploads/${res.ProfileImage}`
            }
        });
        setPersons(list);
        return list;
    };


    const fetchEmployeesDataForBadge = async (selectedEmployeeId) => {
        if(selectedEmployeeId !== null) {
            await WebService({
                endPoint: `User/getEmployeeDetail/${selectedEmployeeId}`,
                dispatch,
            }).then(async (data) => {
                if(data?.length > 0) {
                    const Details = data[0];
                    const companyString = Details.CompanyName?.split(' ');
                    const companyFormattedText = companyString?.length >= 5 ? companyString?.slice(0, 5).join(' ') : null;
                    const nameString = Details?.FullName?.toUpperCase().split(' ');
                    const nameFormattedText =nameString?.length >= 3 ? nameString?.slice(0, 3).join(' ') : null;
                
                    const permanentAddressString = Details?.PermanentAddress?.toUpperCase()?.split(' ');
                    const permanentAddressFormattedText =permanentAddressString?.length >= 3 ? permanentAddressString?.slice(0, 3).join(' ') : null;
                
                    const communicationAddressString = Details?.Address?.toUpperCase()?.split(' ');
                    const communicationAddressFormattedText =communicationAddressString?.length >= 6 ? communicationAddressString?.slice(0, 6).join(' ') : null;
                    
                    const paramData = {
                        CompanyName: companyFormattedText || Details.CompanyName,
                        Logo: CompanyInfo.Logo,
                        ProfileImage: Details?.ProfileImage,
                        FullName: nameFormattedText || Details.FullName.toUpperCase(),
                        EmployeeCode: Details?.EmployeeCode?.toUpperCase(),
                        PermanentAddress: permanentAddressFormattedText || Details.PermanentAddress,
                        Email: Details.EmployeeEmail,
                        Designation: Details.Designation,
                        Phone: Details.Phone,
                        Address: Details.Address,
                        CommunicationAddress: communicationAddressFormattedText || Details.Address,
                        CompanyPhone: Details.CompanyPhone,
                        EmployeeGuid: Details.Guid,
                        QRData: 
                            "Name: " + Details.FullName + 
                            "Company: " + Details.CompanyName + 
                            "Email: " + Details.Address + 
                            "Phone: " + Details.Phone + 
                            "Code: " + Details.EmployeeCode,
                    }
                    setParamData(paramData);
                }
            });
        }
    }

    const fetchEmployeeBadgeByEmployeeCardBadgeId = async (EmployeeCardBadgeId, personList) => {
        await WebService({
            dispatch, 
            endPoint: `CommonUtility/Edit/employeecardbadges?EmployeeCardBadgeId=${EmployeeCardBadgeId}`,
        }).then((rec) => {
            if(rec.length > 0) {
                const employee = personList.filter(item => item.uniqueId === rec[0].EmployeeId);
                rec[0].EmployeeId = employee[0];
                setFilter(rec[0]);
            }
        });
    };

    const fetchEmployeecardbadges = async (EmployeeId, selectedEmployeeObj) => {
        selectedEmployeeObj ??= null;
        if(selectedEmployeeObj !== null){
            setShowDisableAndAddNewButton(true);
        }
        setShow(false);
        const getEmployeeCardBadge = await WebService({
            dispatch, 
            endPoint: `CommonUtility/Edit/employeecardbadges?EmployeeId=${EmployeeId}&StatusId=${StandardConst.EmployeeIdCardStatus.Active}`,
        });
        if(getEmployeeCardBadge.length > 0) {
            if(selectedEmployeeObj !== null){
                getEmployeeCardBadge[0].EmployeeId = selectedEmployeeObj[0];
            }else{
                const filterEmployee = persons.filter(item => item.uniqueId === getEmployeeCardBadge[0].EmployeeId);
                getEmployeeCardBadge[0].EmployeeId = filterEmployee[0];
            }

            setFilter(getEmployeeCardBadge[0]);
        }else{
            setShowDisableAndAddNewButton(false);
            setFilter((previous) => {
                return {
                    ...previous,
                    EmployeeId : (selectedEmployeeObj !== null) ? selectedEmployeeObj[0] : "",
                    ExpiryDate: "",
                    badge_templatesId: "",
                    IssuedDate: ""
                };
            });
        }
        setShow(true);
    }

    const handleExistingEmployeeCard = async (selectedEmployeeObj) => {
        await fetchEmployeesDataForBadge(selectedEmployeeObj[0].uniqueId);
        fetchEmployeecardbadges(selectedEmployeeObj[0].uniqueId, selectedEmployeeObj);
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {(showEditButton) ? (
                        "Edit Employee Id Card"
                    ) : (
                        "Create Employee Id Card"
                    )}
                </Modal.Title>
            </Modal.Header>
            <SnackbarComponent
                ref={refSnackbar}
                confirmMessage={confirmMessage}
            />
            <Form
                defaultValues={filter}
                onSubmit={onSubmit}
                // validationSchema={schema}
            >
            {
            showMsg ? 
            <Alert severity="warning">
                {confirmMessage}
            </Alert>
            : 
            null
            }
                <Modal.Body className="p-4">
                    <div className="row">
                        <div className="col-md-12 mb-1">
                            <FormAutoCompleteDropdownCard
                                name="EmployeeId"
                                data={persons}
                                label="Employee"
                                optionText="heading"
                                placeholder="Choose a Employee"
                                setValue={(val) => handleExistingEmployeeCard(val)}
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputText
                                label="Expiry Date"
                                name="ExpiryDate"
                                type="date"
                                min={new Date().toISOString().split("T")[0]}
                                max="2999-12-31"
                                value={filter?.ExpiryDate}
                            />
                        </div>
                        <div className="col-md-12" style={{position: "relative", bottom: "20px"}}>
                        <span style={{color: "#5a5a5a", position: "relative", top: "25px", fontSize: "14px"}}>
                            Employee Badge Template{" "}
                            <div className="text-danger" style={{    position: "relative", top: "-19px", left: "143px"}}>＊</div>
                        </span>
                        <div>
                            <HtmlFileReader 
                                jsonData={paramData} 
                                defaultTemplate={{ text: "", value: ""}}
                                ShowDropdown={StandardConst.YesNo.Yes}
                                selectPosition={true}
                                SelectedTemplateId={[{ text: "", value: filter?.badge_templatesId ?? ""}]}
                                selectFile={handleSelectFile}
                                showDropDownList={StandardConst.BadgeType[1].text}
                            />
                        </div>  
            </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" type="submit" >
                        {(showEditButton) ? (
                            "Update"
                        ) : (
                            (showDisableAndAddNewButton) ? (
                                "Disable And Add New Card"
                            ) : (
                                "Create ID Card"
                            )
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default forwardRef(AddEmployeeIdCard);
