import React, { useState, useRef, useEffect } from 'react'
import { Container, Box, Stack, IconButton } from "@mui/material";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import AppbarComponent from '../../Services/AppbarComponent';
import SnackbarComponent from '../../Services/SnackbarComponent';
import { PageInfo } from '../PageInfo';
import { useLocation } from "react-router-dom";
import { WebService } from '../../Services/WebService';
import { Form } from '../Form';
import { useSelector, useDispatch } from "react-redux";
import { StandardConst } from '../../Services/StandardConst';
import Button from "react-bootstrap/Button";
import NotesIcon from '@mui/icons-material/Notes';
import ReplyIcon from '@mui/icons-material/Reply';
import SaveIcon from '@mui/icons-material/Save';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CloseIcon from '@mui/icons-material/Close';
import TableComponent, { NoRecordTemplate } from '../../Services/TableComponent';
import Avatar from '@mui/material/Avatar';
import { DateTime } from "luxon";
import ActionButton from '../../Services/ActionButton';
import "./ViewTicket.css"
import ShortcutIcon from '@mui/icons-material/Shortcut';
import RichTextBox from '../../Services/RichTextBox';

const ViewTicket = () => {

    const MasterPageName = "Manage Ticket";
    PageInfo({ pageTitle: "View Ticket" });
    const { state } = useLocation();
    const refSnackbar = useRef();
    const dispatch = useDispatch();
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const [ticketData, setTicketData] = useState({ Tickets: {} });
    const [showReplySubmitButton, setShowReplySubmitButton] = useState(false);
    const [showAddNoteSubmitButton, setShowAddNoteSubmitButton] = useState(false);
    const EmployeeId = useSelector((s) => s.auth.LoggedUser ?? 0);
    const UserId = useSelector((s) => s.auth.LoggedCustomer ?? 0);
    const [ticketResponse, setTicketResponse] = useState([]);
    const [EditPersonDetails, setEditPersonDetails] = useState({});

    const [bData] = React.useState([
        {
            title: "Manage Ticket",
            hrefLink: "#",
        },
        {
            title: "View Ticket",
            hrefLink: "#",
        },
    ]);
    const [searchContent, setSearchContent] = useState("");

    useEffect(() => {
        if (state.TicketId) {
            fetchTicketData();
        }
    }, []);


    const fetchTicketData = async () => {
        const data = {
            Tickets: await WebService({
                endPoint: `CommonUtility/Edit/tickets?TicketId=${state.TicketId || 0}`,
                dispatch,
            }).then((c) => (c.length > 0 ? c[0] : {}))
        }
        setTicketData(data);
    }

    const [value, setValue] = useState('');

    const [OpenRichTextBox, setOpenRichTextBox] = useState({ AddRichTextBox: false, EditRichTextBox: false });

    const handleSubmitDataRichTextBox = async (type) => {
        const data = {};
        data.CompanyId = companyInfo.CompanyId;
        data.TicketId = state.TicketId;
        if (value) data.Description = value;
        if (type === StandardConst.TicketResponseTypeString.AddNote) {
            data.ResponseType = StandardConst.TicketRespnseType.AddNote;
        }
        if (type === StandardConst.TicketResponseTypeString.Reply) {
            data.ResponseType = StandardConst.TicketRespnseType.Reply;
        }
        if (EmployeeId) {
            data.UserId = EmployeeId;
            data.UserType = StandardConst.TicketUserType.Employee;
            const details = await WebService({
                endPoint: `CommonUtility/Edit/employees?EmployeeId=${EmployeeId || 0}`,
                dispatch,
            }).then((c) => c.length > 0 ? c[0] : {});
            data.UserName = details.FullName
        }
        if (UserId) {
            data.UserId = UserId;
            data.UserType = StandardConst.TicketUserType.User;
            const userDetails = await WebService({
                endPoint: `CommonUtility/Edit/users?UserId=${UserId || 0}`,
                dispatch,
            }).then((c) => c.length > 0 ? c[0] : {});
            data.UserName = userDetails.FullName;
        }
        if (EditPersonDetails && EditPersonDetails.TicketResponseId) {
            await WebService({
                endPoint: `CommonUtility/ticketresponse?TicketResponseId=${EditPersonDetails.TicketResponseId}`,
                method: "PUT",
                body: data,
                dispatch,
            });
        } else {
            await WebService({
                endPoint: "CommonUtility/ticketresponse",
                body: data,
                dispatch,
            });
        }
        fetchAllTicketReponses();
        if (type === StandardConst.TicketResponseTypeString.AddNote) {
            setShowAddNoteSubmitButton(false);
        }
        if (type === StandardConst.TicketResponseTypeString.Reply) {
            setShowReplySubmitButton(false);
        }
        setOpenRichTextBox({ AddRichTextBox: false, EditRichTextBox: false });
    }

    const handleCloseRichTextBox = (type) => {
        setValue("");
        setEditPersonDetails({});
        setOpenRichTextBox({ AddRichTextBox: false, EditRichTextBox: false });
        setShowAddNoteSubmitButton(false);
        setShowReplySubmitButton(false);
    }

    const handleOpenRichTextBox = (type, mode) => {
        setOpenRichTextBox(mode);
        if (type === StandardConst.TicketResponseTypeString.AddNote) {
            setShowAddNoteSubmitButton(true)
            setShowReplySubmitButton(false);
        }
        if (type === StandardConst.TicketResponseTypeString.Reply) {
            setShowReplySubmitButton(true);
            setShowAddNoteSubmitButton(false);
        }
        setValue('');
    }

    const fetchAllTicketReponses = async () => {
        const data = await WebService({
            endPoint: `CommonUtility/Edit/ticketresponse?CompanyId=${companyInfo.CompanyId}&TicketId=${state.TicketId}`,
            dispatch,
        });
        const modifiedData = data.map((res) => {
            const pastDateTime = DateTime.fromISO(res.DateTime);
            // Current date and time
            const currentDateTime = DateTime.now();
            const elapsed = currentDateTime.diff(pastDateTime, ['hours', 'minutes', 'seconds']).toObject();
            res.elapsed = elapsed
            return res;
        });
        setTicketResponse(modifiedData);
    }

    useEffect(() => {
        fetchAllTicketReponses();
    }, [companyInfo.CompanyId]);

    const fnDelete = async (id) => {
        await WebService({
            endPoint: `CommonUtility/ticketresponse?TicketResponseId=${id}`,
            method: "DELETE",
            dispatch,
        });
        fetchAllTicketReponses();
    }


    const fnEdit = async (id) => {
        setEditPersonDetails({});
        const data = await WebService({
            endPoint: `CommonUtility/Edit/ticketresponse?TicketResponseId=${id || 0}`,
            dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}));
        setEditPersonDetails(data);
        if (data.ResponseType === StandardConst.TicketRespnseType.AddNote) {
            handleOpenRichTextBox(StandardConst.TicketResponseTypeString.AddNote, { EditRichTextBox: true });
            setValue(data.Description);
        }
        if (data.ResponseType === StandardConst.TicketRespnseType.Reply) {
            handleOpenRichTextBox(StandardConst.TicketResponseTypeString.Reply, { EditRichTextBox: true });
            setValue(data.Description);
        }
    }


    const columns = [
        {
            Text: "Replies",
            Value: "Description",
            style: { backgroundColor: "white" },
            render: (dr) => {
                return (
                    <>
                        {
                            OpenRichTextBox.EditRichTextBox && EditPersonDetails && EditPersonDetails.TicketResponseId === dr.TicketResponseId ?
                                <RichTextBoxComponent
                                    value={value}
                                    setValue={setValue}
                                    handleSubmitDataRichTextBox={handleSubmitDataRichTextBox}
                                    handleCloseRichTextBox={handleCloseRichTextBox}
                                    showAddNoteSubmitButton={showAddNoteSubmitButton}
                                    showReplySubmitButton={showReplySubmitButton}
                                />
                                :
                                <div
                                    className="card m-0 border-none shadow-none"
                                >
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span>
                                            <div className="user-info d-flex align-items-center">
                                                <Avatar variant="square">
                                                    {dr.UserName.charAt(0)}
                                                </Avatar>
                                                <span className="ms-2 text-primary">{dr.UserName}</span>
                                            </div>
                                            <span className="text-muted ms-5">{dr.elapsed.hours} hour ago ({DateTime.fromISO(dr.DateTime).toFormat("dd-MM-yyyy hh:mm:ss")})</span>
                                        </span>
                                        <div className='d-flex justify-content-end'>
                                            <div>
                                                <ActionButton
                                                    onClick={() => fnEdit(dr.TicketResponseId)}
                                                    IconName="Edit"
                                                    id={`btnEdit_${dr.TicketResponseId}`}
                                                />
                                                <ActionButton
                                                    onClick={() => fnDelete(dr.TicketResponseId)}
                                                    IconName="Delete"
                                                    id={`btnDelete_${dr.TicketResponseId}`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mt-2 ms-5 text-black mb-0" dangerouslySetInnerHTML={{ __html: dr.Description }}></p>
                                </div>
                        }
                    </>
                )
            }
        }
    ]

    return (
        <>
            <SnackbarComponent ref={refSnackbar} confirmMessage={""} />
            <Container fluid className="base-container">
                <Box
                    sx={{
                        width: 1,
                    }}
                >
                    <h5 className="ms-4 mt-2">{MasterPageName}</h5>
                    <div className="ms-4">
                        <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
                    </div>
                </Box>
                <AppbarComponent
                    title={MasterPageName}
                    isSearchRequired={false}
                    isAddButtonRequired={true}
                    Exception={true}
                    setSearchContent={(v) => setSearchContent(v ?? "")}
                    searchContent={searchContent}
                />
                <Form>
                    <div className="row p-0 mb-2 d-flex flex-row" style={{ marginLeft: "10px" }}>
                        <div className="col-md-8" style={{ fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif' }}>
                            <h1>
                                {ticketData.Tickets.Title}
                            </h1>
                            <p>
                                {ticketData.Tickets.Description}
                            </p>
                            <div className="action-buttons">
                                <Button id="ReplySubmit" variant="outline-primary" onClick={() => handleOpenRichTextBox(StandardConst.TicketResponseTypeString.Reply, { AddRichTextBox: true })}>
                                    <ReplyIcon />
                                    Reply
                                </Button>
                                <Button id="addnoteSubmit" variant="outline-primary" className='m-2' onClick={() => handleOpenRichTextBox(StandardConst.TicketResponseTypeString.AddNote, { AddRichTextBox: true })}>
                                    <NotesIcon />
                                    Add note
                                </Button>
                                <Button id="forwardSubmit" variant="outline-primary">
                                    <ShortcutIcon />
                                    Forward
                                </Button>
                            </div>
                            {
                                OpenRichTextBox.AddRichTextBox && (
                                    <RichTextBoxComponent
                                        value={value}
                                        setValue={setValue}
                                        handleSubmitDataRichTextBox={handleSubmitDataRichTextBox}
                                        handleCloseRichTextBox={handleCloseRichTextBox}
                                        showAddNoteSubmitButton={showAddNoteSubmitButton}
                                        showReplySubmitButton={showReplySubmitButton}
                                    />
                                )
                            }
                        </div>
                        <div className="document-table">
                            <TableComponent
                                columns={columns}
                                data={ticketResponse}
                                noRecordCss="p-0"
                                noRecordFound={
                                    <NoRecordTemplate
                                        headerValue={StandardConst.TicketReplyheaderValueNoResults}
                                        subHeaderValue={StandardConst.TicektReplySubHeaderRole}
                                        imageUrl={StandardConst.imageNoRecordsFound}
                                    />
                                }
                                isSearchRequired={true}
                                allowSerialNo={false}
                                isExcelExportButtonVisible={false}
                            />
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-1"></div>
                    </div>
                </Form>
            </Container>
        </>
    )
}

const RichTextBoxComponent = ({ value, setValue, handleSubmitDataRichTextBox, handleCloseRichTextBox, showAddNoteSubmitButton, showReplySubmitButton }) => {

    return (
        <>
            <div className="add-note-rich-text-box">

                <RichTextBox
                    value={value}
                    setValue={setValue}
                />
                <div className="icon-buttons d-flex justify-content-end m-1">
                    <span className='me-3'>
                        <CloseIcon sx={{ color: 'grey', cursor: "pointer" }} onClick={() => handleCloseRichTextBox()} /> Cancel
                    </span>
                    {
                        showAddNoteSubmitButton && (
                            <span>
                                <SaveIcon color="primary" sx={{ cursor: "pointer" }} onClick={() => handleSubmitDataRichTextBox(StandardConst.TicketResponseTypeString.AddNote)} /> Save
                            </span>
                        )
                    }
                    {
                        showReplySubmitButton && (
                            <span>
                                <SaveIcon color="primary" sx={{ cursor: "pointer" }} onClick={() => handleSubmitDataRichTextBox(StandardConst.TicketResponseTypeString.Reply)} /> Save
                            </span>
                        )
                    }
                </div>
            </div>
        </>
    )
}


export default ViewTicket