import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText, FormInputDropdown, FormAutoCompleteDropdownCard } from "../Form";
import * as yup from "yup";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { omit } from "underscore";
import { StandardConst } from "../../Services/StandardConst";
import { DateTime } from "luxon";
import { ActionPermission } from "../PageInfo";
import { useNavigate } from "react-router-dom";
import { WSSuccessAlert } from "../../Services/WSAlert";
const { forwardRef, useState, useImperativeHandle } = React;

const AddEditleaveBalance = (prop, ref) => {
  const [permission] = useState({
    ManageAdd: ActionPermission("Leave Type - Add"),
  });
  var nevigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ leaveBalance: {} });
  const [formdate, setFormtDate] = useState();
  const [todate, setToDate] = useState();
  const [selectedleave, setleavetypeId] = useState();

  useImperativeHandle(ref, () => ({
    openModal: async (id) => {
      setFormtDate(null);
      setToDate(null);
      setShow(true);
      if(id === 0){
        await fetchData(id);
      }else {
        setShow(false);
        await fetchData(id);
        setShow(true);
      }
    },
  }));

  const fetchData = async(id) => {
    const data = await WebService({
      endPoint: `leaveBalance/Fetch/${id || 0}`,
      dispatch,
    });

    if ((data?.leaveBalance?.ValidFormDate || "") !== "") {
      data.leaveBalance.ValidFormDate = format(
        new Date(data.leaveBalance.ValidFormDate),
        "yyyy-MM-dd"
      );
      setFormtDate(data.leaveBalance.ValidFormDate);
    }
    if ((data?.leaveBalance?.ValidToDate || "") !== "") {
      data.leaveBalance.ValidToDate = format(
        new Date(data.leaveBalance.ValidToDate),
        "yyyy-MM-dd"
      );
      setToDate(data.leaveBalance.ValidToDate);
    }

    data.leaveType = data.leaveType.map((v) => {
      return {
        value: v.LeaveId,
        text: v.LeaveName,
      };
    });

    const employeeData = await WebService({
      endPoint: `Visitor/fetchAllEmployees`,
      dispatch,
    });

    data.Employee = employeeData?.filter((r) => r.FullName !== null)?.map((res) => {
      return {
          EmployeeId: res.EmployeeId,
          heading: res.FullName, 
          description: res.Designation, 
          avatar: `${StandardConst.apiBaseUrl}/uploads/${res.ProfileImage}`
        }
    });

    data.leaveBalance ??= {};
    data.leaveBalance.LeaveTypeId = data.leaveBalance.leavetypeId;
    data.leaveBalance = omit(data.leaveBalance, "leavetypeId");

    data.leaveBalance.EmployeeId = [data.Employee?.find(item => item?.EmployeeId === data?.leaveBalance?.EmployeeId)];

    setData(data);
  }

  const handleClose = () => {
    setShow(false);
    setData({ leaveBalance: {} });
  };

  const schema = yup
    .object()
    .shape({
      NoOfLeaveDebited: yup
        .number()
        .typeError(StandardConst.requiredMessage)
        // .integer()
        .positive()
        .label("No. of Leaves")
        .required(StandardConst.requiredMessage)
        .when(["Consumed"], (consumeLeaves, passSchema) =>
          (consumeLeaves ?? 0) === 0
            ? passSchema.min(1)
            : passSchema.min(consumeLeaves)
        ),
      ValidFormDate: yup
        .date()
        .label("Leave Month")
        .required(StandardConst.requiredMessage),
      ValidToDate: yup
        .date()
        .label("Expiry Date")
        .required(StandardConst.requiredMessage),
      EmployeeId: yup
        .array()
        .of(
          yup.object().shape({
            heading: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
          })
        )
        .typeError(StandardConst.ValidationMessages.RequiredFieldMsg)
        .min(1, StandardConst.ValidationMessages.RequiredFieldMsg),
    })
    .required();

  const onSubmit = async (data) => {
    data.ValidFormDate = DateTime.fromJSDate(data.ValidFormDate).toSQLDate();
    data.ValidToDate = todate;
    data.EmployeeId = data.EmployeeId[0].EmployeeId;
    const responsedata = await WebService({
      endPoint: "leaveBalance/Submit",
      body: data,
      dispatch,
    });
    WSSuccessAlert("Success", responsedata);
    handleClose();
    prop.callBackEvent();
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      onHide={handleClose}
      centered
      className="container-fluid"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {(data.leaveBalance.leaveTransactionsId || 0) === 0 ? "Add" : "Edit"}{" "}
          Leave Allocation
        </Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={data.leaveBalance}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        <Modal.Body className="p-4">
          <div className="row">
            <div className="col-md-3" style={{ color: '#5a5a5a',fontFamily: 'sans-serif',fontSize: '14px'}}>Employee</div>
            <div className="col-md-9">
              <FormAutoCompleteDropdownCard
                    name="EmployeeId"
                    data={data.Employee}
                    optionText="heading"
                    value={data.leaveBalance.EmployeeId !== undefined ? data.leaveBalance.EmployeeId[0] : ''}
                    isRequired="true"
                    placeholder="Choose a Employee"
                    disabled={(data.leaveBalance?.Consumed ?? 0) > 0}
                  />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-3" style={{ color: '#5a5a5a',fontFamily: 'sans-serif',fontSize: '14px'}}>Month of Leave</div>
            <div className="col-md-9">
              <FormInputText
                name="ValidFormDate"
                setValue={setFormtDate}
                type="date"
                min={new Date().toISOString().split("T")[0]}
                max={todate}
                showMonthYearPicker
                dateFormat="MMMM yyyy"
                isRequired="true"
                disabled={(data.leaveBalance?.Consumed ?? 0) > 0}
              />
            </div>
          </div>
          <div className="row mt-1">
            <div className="col-md-3" style={{ color: '#5a5a5a',fontFamily: 'sans-serif',fontSize: '14px'}}>Leave Type</div>
            <div className="col-md-9">
              <div className="row">
                <div
                  className={`${
                    permission.ManageAdd == true ? "col-md-9" : "col-md-12"
                  }`}
                >
                  <FormInputDropdown
                    name="LeaveTypeId"
                    ddOpt={data.leaveType}
                    setValue={(val) => setleavetypeId(val)}
                    isRequired="true"
                    disabled={(data.leaveBalance?.Consumed ?? 0) > 0}
                  ></FormInputDropdown>
                </div>
                {permission.ManageAdd && (
                  <div className="col-md-3 d-flex justify-content-end align-items-center">
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => nevigate("/LeaveType")}
                      disabled={(data.leaveBalance?.Consumed ?? 0) > 0}
                    >
                      Add Leave Type
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-3" style={{ color: '#5a5a5a',fontFamily: 'sans-serif',fontSize: '14px'}}>No. of Leaves</div>
            <div className="col-md-9">
              <FormInputText
                name="NoOfLeaveDebited"
                type="number"
                isRequired="true"
              />
            </div>
          </div>

          <div className="row mt-1">
            <div className="col-md-3" style={{ color: '#5a5a5a',fontFamily: 'sans-serif',fontSize: '14px'}}>Expiry (End month)</div>
            <div className="col-md-9">
              <FormInputText
                name="ValidToDate"
                setValue={(val) => {setToDate(
                    new Date(new Date(val).getFullYear(), new Date(val).getMonth() + 1, 1)
                  )}}
                type="date"
                min={
                  formdate ? new Date(formdate).toISOString().split("T")[0] : ""
                }
                showMonthYearPicker
                dateFormat="MMMM yyyy"
                isRequired="true"
                disabled={
                  (data.leaveBalance?.Consumed ?? 0) > 0 || formdate == null
                }
              />
            </div>
          </div>

          <div className="row mt-1">
            <div className="col-md-3" style={{ color: '#5a5a5a',fontFamily: 'sans-serif',fontSize: '14px'}}>Leave Description </div>
            <div className="col-md-9">
              <FormInputText name="Notes" as="textarea" />
            </div>
          </div>
          <FormInputText
            name="NoOfLeaveCredited"
            value="0"
            type="hidden"
          ></FormInputText>
          <FormInputText
            name="TransactionsType"
            value="Dr"
            type="hidden"
          ></FormInputText>
          <FormInputText
            name="TransactionByUserId"
            value={data.EmployeeID}
            type="hidden"
          ></FormInputText>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            id="btnleaveBalanceSubmit"
            type="submit"
          >
            {(data.leaveBalance.leaveTransactionsId || 0) === 0
              ? "Submit"
              : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default forwardRef(AddEditleaveBalance);
