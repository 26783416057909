import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText, FormInputDropdown } from "../Form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import _, { map, sortBy } from "underscore";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { StandardConst } from "../../Services/StandardConst";

const { forwardRef, useState, useImperativeHandle } = React;
const AddEditTask = (prop, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const [typeOptions, setTypeOptions] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const companyInfo = useSelector(s => s.auth.CompanyInfo ?? {});

  useImperativeHandle(ref, () => ({
    openModal: async (id, projectId) => {
      setShow(true);
      if(id === 0){
        await fetchData(id, projectId);
      }else {
        setShow(false);
        await fetchData(id, projectId);
        setShow(true);
      }
    },
  }));

  const fetchData = async (id, projectId)=> {
    await WebService({
      dispatch,
      endPoint: `CommonUtility/Edit/projectmaster?CompanyId=${companyInfo.CompanyId}`,
    }).then((r) => {
      const list = r.map((res) => {
        return { value: res.ProjectId, text: res.ProjectName }
      });
      setProjectList(list);
    }
    );
    await WebService({
      dispatch,
      endPoint: "CommonUtility/Edit/tasktype",
      requiredLoader: false,
    }).then((c) => {
      const list = c.map((res) => {
        return { value: res.TaskTypeId, text: res.DisplayDescription}
      });
      setTypeOptions(list);
    }
    );

    await WebService({
      endPoint: `CommonUtility/Edit/project_tasks?TaskCategoryId=${id || 0}`,
      dispatch,
      }).then((c) =>
        setData(c.length > 0 ? c[0] : { ProjectId: projectId ?? null })
      );
  }
  const handleClose = () => {
    setShow(false);
    setData({});
    setTypeOptions([]);
    setProjectList([]);
  }

  const schema = yup
    .object()
    .shape({
      TaskCategoryName: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
      TaskTypeId: yup
        .number()
        .typeError(StandardConst.ValidationMessages.RequiredFieldMsg)
        .required(StandardConst.ValidationMessages.RequiredFieldMsg),
    })
    .required();
    
  const onSubmit = async (data) => {
    var endPoint = "CommonUtility/project_tasks";
    var method = "POST";
    if ((data.TaskCategoryId ?? 0) > 0) {
      endPoint += `?TaskCategoryId=${data.TaskCategoryId}`;
      method = "PUT";
    }
    await WebService({
      dispatch,
      endPoint,
      method,
      body: _.omit(data, "TaskCategoryId"),
    });
    handleClose();
    prop.callBackEvent();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {(data.TaskCategoryId || 0) === 0
            ? "Add Project Task"
            : "Edit Project Task"}
        </Modal.Title>
      </Modal.Header>
      <Form defaultValues={data} onSubmit={onSubmit} validationSchema={schema}>
        <Modal.Body className="p-4">
          <Row>
            <Col md={12}>
              <FormInputText
                label="Task Name"
                name="TaskCategoryName"
                type="text"
                className="form-control"
                isRequired="true"
              />
            </Col>
            <Col md={12}>
              <FormInputDropdown
                label="Project"
                name="ProjectId"
                className="form-control"
                ddOpt={projectList}
                isRequired="true"
              />
            </Col>
            <Col md={12}>
              <FormInputDropdown
                label="Type"
                name="TaskTypeId"
                className="form-control"
                ddOpt={typeOptions}
                isRequired="true"
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button id="btnTaskSubmit" variant="outline-primary" type="submit">
            {" "}
            {(data.TaskCategoryId || 0) === 0 ? "Submit" : "Save Changes"}{" "}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default forwardRef(AddEditTask);
