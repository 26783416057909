import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText } from "../Form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";

const apiUrl = "Customer";
const { forwardRef, useState, useImperativeHandle } = React;
const AddEditCustomer = (prop, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ Customer: {} });
  useImperativeHandle(ref, () => ({
    openModal: async (CustomerId) => {
      CustomerId ??= 0;
      setShow(true);
      if(CustomerId){
        setShow(false);
        const data = {
          Customer: await WebService({
            endPoint: `${apiUrl}/Fetch/${CustomerId}`,
            dispatch,
          }),
        };
        setData(data);
        setShow(true);
      }
    },
  }));
  const handleClose = () => {
    setShow(false);
    setData({ Customer: {} });
  }

  const requiredMessage = StandardConst.requiredMessage;

  const schema = yup
    .object()
    .shape({
      Name: yup.string().trim().required(requiredMessage),
      PersonName: yup.string().trim().required(requiredMessage),

      Mobile: yup.string()
        .required(requiredMessage)
        .matches(StandardConst.PhoneNumberValidateRegex, StandardConst.ValidationMessages.PhoneNoIsNotValid),
        
      Address: yup.string().trim().notRequired(),
    })
    .required();
  const onSubmit = async (data) => {
    await WebService({
      endPoint: `${apiUrl}/Submit`,
      body: data,
      dispatch,
    });

    handleClose();
    prop.callBackEvent();
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {(data.Customer.CustomerId || 0) === 0
            ? "Add Customer"
            : "Edit Customer"}
        </Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={data.Customer}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        <Modal.Body className="p-4">
          <div className="row">
            <div className="col-md-12">
              <FormInputText
                label="Company Name"
                name="Name"
                type="text"
                isRequired="true"
              />
            </div>
            <div className="col-md-12">
              <FormInputText
                label="Primary Contact Person"
                name="PersonName"
                type="text"
                isRequired="true"
              />
            </div>
            <div className="col-md-12">
              <FormInputText
                label="Primary Contact Phone"
                name="Mobile"
                type="text"
                isRequired="true"
              />
            </div>

            <div className="col-md-12">
              <FormInputText
                name="Address"
                label="Address"
                as="textarea"
                rows="2"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="btnCustomerSubmit"
            variant="outline-primary"
            type="submit"
          >
            {" "}
            {(data.Customer.CustomerId || 0) === 0 ? "Save Customer" : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default forwardRef(AddEditCustomer);
