import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { WebService } from "../../Services/WebService";
import { ActionPermission, PageInfo } from "../PageInfo";
import { useDispatch, useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import { Tab, Tabs, Box, AppBar, Alert, ToggleButtonGroup, ToggleButton, Autocomplete, TextField, Grid, Chip, CardHeader, IconButton } from "@mui/material";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import Topbar from "../../Services/TopbarComponent";
import { StandardConst } from '../../Services/StandardConst';
import { Form, FormAutoCompleteDropdownCard, FormCheckRadioInput, FormInputDropdown, FormInputFile, FormInputText, InputDropdown, InputText } from '../Form';
import Button from "react-bootstrap/Button";
import TableComponent, { NoRecordTemplate } from '../../Services/TableComponent';
import DeleteConfirmAlert from "../../Services/AlertComponent";
import ActionButton from '../../Services/ActionButton';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import DescriptionIcon from '@mui/icons-material/Description';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import { chain, chunk, map, range } from "underscore";
import QRCodeGeneratorDownloadComponent from '../../Services/QRCodeGeneratorDownloadComponent';
import { WSErrorAlert, WSSuccessAlert } from '../../Services/WSAlert';
import BootstrapModal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import * as yup from "yup";
import { addDays, endOfDay } from 'date-fns';
import CurrencyInput from 'react-currency-input-field';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PersonIcon from '@mui/icons-material/Person';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { formatDate, format } from 'date-fns';
import JobViewModal from './JobViewModal';
import { CurrencyFormat } from '../../Services/DisplayCurrencyFormat';


const JobListing = () => {
    PageInfo({ pageTitle: "Job List" });

    const refJobViewModal = useRef();
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const Roles = useSelector((s) => s.auth.AssignRole ?? {});
    const EmployeeId = useSelector((s) => s.auth.LoggedUser ?? {});
    const UserId = useSelector((s) => s.auth.LoggedCustomer ?? {});

    // ye original data ko store krti hai
    const [jobsData, setJobsData] = useState([]);
    // ye state filter state hai jo ki JobApplicant ke time me filter se or first time data get krne ki liye set hoti hai
    const [filteredJobData, setFilteredJobData] = useState([]);
    const [jobApplicantId, setJobApplicantId] = useState(null);
    const [value, setValue] = useState(0);
    const [isJobApplicantState, setIsJobApplicantState] = useState(false);


    const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    const [permission, SetPermission] = useState({});
    const [records, setRecords] = useState([]);
    const [appliedJobList, setAppliedJobList] = useState([]);

    const onFilterChanged = (selectedFilter) => {
        if(selectedFilter === 'Applied'){
            const filteredData = jobsData.filter(item => appliedJobList.some((job) => job === item.JobId));
            setFilteredJobData(filteredData);
        }else if(selectedFilter === 'All'){
            setFilteredJobData(jobsData);
        }
    };
    useEffect(() => {
        SetPermission({
            ManageAdd:  ActionPermission("Manage Job Posting - Add"),
            ManageEdit: ActionPermission("Manage Job Posting - Edit"),
            ManageDelete: ActionPermission("Manage Job Posting - Delete"),
        });
    }, [permissionList]);

    const getAppliedJobList = async (jobApplicantId) => {
        await WebService({
            endPoint: `CommonUtility/Edit/jobapplications?JobApplicantId=${jobApplicantId}`,
        }).then((res) => setAppliedJobList(res.map(item => (item.JobId))))
    }

    useEffect(() => {
        if(jobApplicantId !== null) {
            getAppliedJobList(jobApplicantId);
        }
    },[jobApplicantId]);


    const [bData] = useState([
        {
            title: "Recruitment",
            hrefLink: "#",
        },
        {
            title: "Jobs",
            hrefLink: "#",
        },
    ]);

    const fetchAllJobPosting = async (SearchData) => {
        SearchData ??= null;
        let EndPoint = `Recruitment/fetchJobPostingData`;
        if(SearchData !== null) {
            EndPoint += `?TitleOrCompanySearch=${SearchData.TitleOrCompanySearch ?? ''}&CityOrStateSearch=${SearchData.CityOrStateSearch ?? ''}`;
        }
        
        await WebService({
            endPoint: EndPoint,
            method: "GET",
            dispatch
        }).then(response => {setJobsData(response);setFilteredJobData(response);});
    }

    const fetchLoggedInUserJobPosting = async (SearchData) => {
        SearchData ??= null;

        let EndPoint = "Recruitment/fetchLoggedInJobPostingData";
        if(SearchData !== null) {
            EndPoint += `?TitleOrCompanySearch=${SearchData.TitleOrCompanySearch ?? ''}&CityOrStateSearch=${SearchData.CityOrStateSearch ?? ''}&SearchCustomerId=${SearchData?.CustomerId !== null ? SearchData?.CustomerId[0]?.uniqueId ?? '' : ''}`;
        }
        await WebService({
            endPoint: EndPoint,
            method: "GET",
            dispatch
        }).then(response => {setJobsData(response);setFilteredJobData(response);});
    }

    const getJobApplicantIdByUserId = async (userId) => {
        await WebService({
            endPoint: `CommonUtility/Edit/jobapplicants?UserId=${userId}`,
        }).then(res => res.length > 0 ? setJobApplicantId(res[0].JobApplicantId) : setJobApplicantId(null));
    };

    const [customerList, setCustomerList] = useState([]);
    const FetchCustomerList = async() => {
        const data = await WebService({
          dispatch,
          endPoint: `CommonUtility/customerdetails`
        });
        const list = data?.filter((r) => r.FullName !== null)?.map((res) => {
          return {
              uniqueId: res.CustomerId,
              heading: res.Name, 
              description: '', 
              avatar: ''
            //   avatar: `${StandardConst.apiBaseUrl}/uploads/${res.ProfileImage}`
            }
        });
        setCustomerList(list);
      }

    useEffect(() => {
        if(Roles.length > 0){
            FetchCustomerList();
            fetchLoggedInUserJobPosting();
            const isJobApplicant = Roles.some((role) => role === StandardConst.SystemRole.JobApplicant);
            setIsJobApplicantState(isJobApplicant);
            if(isJobApplicant && UserId){
                getJobApplicantIdByUserId(UserId);
            }
        }else{
            fetchAllJobPosting(null);
        }
    }, [Roles]);

    const setNotificationAndMail = async (JobId, jobApplicantId) => {
        const fetchJobData = await WebService({
            endPoint: `CommonUtility/Edit/jobs?jobId=${JobId}`,
            method: 'GET',
            dispatch
        }).then(res => res.length > 0 ? res[0] : null);

        const JobApplicantName = await WebService({
            endPoint: `Recruitment/fetchJobApplicantDetails?JobApplicantId=${jobApplicantId}`,
            method: 'GET',
            dispatch
        }).then(res => res.length > 0 ? res[0].FullName : null);

        // Here we send notification
        // if(fetchJobData !== null && fetchJobData.EmployeeId !== null && JobApplicantName !== null){
        //     await WebService({
        //         dispatch,
        //         endPoint: "Notification/sendSystemNotification",
        //         body: {
        //           Message: `${format(new Date(), 'dd-MM-yyyy')} - ${JobApplicantName} applied for ${fetchJobData.Title}`,
        //           SendTo: [fetchJobData.EmployeeId],
        //           Expire: addDays(new Date(), 30),
        //         },
        //     });
        // }

        // Here we send email
        if(fetchJobData !== null && fetchJobData.EmployeeId !== null && JobApplicantName !== null){
            await WebService({
                dispatch,
                endPoint: "Recruitment/SendMailForRecruiter",
                method: "POST",
                body: {
                    ApplicationDate: format(new Date(), 'dd-MM-yyyy'),
                    ApplicantName: JobApplicantName,
                    JobTitle : fetchJobData.Title,
                    EmployeeId: fetchJobData.EmployeeId
                }
            });
        }
    }

    const jobApply = async(JobId) => {
        if(jobApplicantId !== null){
            const data = {
                JobId : JobId,
                JobApplicantId : jobApplicantId,
                StatusId : StandardConst.Status.Applied,
                ApplicationDate : format(new Date(), 'yyyy-MM-dd HH:mm:ss')
            }

            await WebService({
                endPoint : `Recruitment/insertJobApplication`,
                body : data,
                method : 'POST',
                dispatch
            }).then((res) => {
                getAppliedJobList(jobApplicantId);
                setNotificationAndMail(JobId, jobApplicantId);
                WSSuccessAlert("Successfully Appplied");
            });
        }else{
            navigate("/NonCompanyUserLogin");
        }
    }
      
    const onDelete = async (JobId) => {
        await WebService({
            endPoint : `CommonUtility/jobs?JobId=${JobId}`,
            method : 'DELETE',
            dispatch
        }).then((res) => {
            fetchLoggedInUserJobPosting();
            WSSuccessAlert("Success", "Successfully Deleted")
        });
    };

    const onSearchSubmit = async (data) => {
        if(Roles.length > 0){
            fetchLoggedInUserJobPosting(data);
        }else{
            fetchAllJobPosting(data);
        }
    };

    const fnViewModal = (JobId, isApplied) => refJobViewModal.current.openModal(JobId, isApplied);

    const renderSalary = (data) => {
        switch (data.SalaryRange) {
            case "Exact Salary":
                return <CurrencyFormat value={data.ExactSalary} CurrencyCode={data.CurrencyCode} LocaleCode={data.LocaleCode} /> ;
            
            case "Starting Salary":
                return <CurrencyFormat value={data.MinimumSalary} CurrencyCode={data.CurrencyCode} LocaleCode={data.LocaleCode} />;

            case "Maximum Salary":
                return <CurrencyFormat value={data.MaximumSalary} CurrencyCode={data.CurrencyCode} LocaleCode={data.LocaleCode} />;

            case "Range":
                return (
                    <span>
                      <CurrencyFormat value={data.MinimumSalary} CurrencyCode={data.CurrencyCode} LocaleCode={data.LocaleCode} /> 
                      {' to '} 
                      <CurrencyFormat value={data.MaximumSalary} CurrencyCode={data.CurrencyCode} LocaleCode={data.LocaleCode} />
                    </span>
                  );
        }
      };

      const trimDescription = (str, length) => {
        length ??= 500;
        if (str.length <= length) {
          return str;
        }
        return str.substring(0, length) + '...';
      };
      

    return (
        <>
            <JobViewModal 
                ref={refJobViewModal}
                ApplyJob={jobApply}
            >
            </JobViewModal>
            {/* common carousel image for all tabs */}
            <Container
                fluid
                className="base-container"
                style={{minHeight: '800px'}}
            >
                {(Roles.length > 0) && (
                    <Topbar bData={bData} HeadingText={isJobApplicantState ? "All Jobs" : "My Jobs"}></Topbar>
                )}
                <Form
                    // defaultValues={{ Token: token }}
                    onSubmit={onSearchSubmit}
                    // validationSchema={schema}
                >
                    <div className="mx-4 py-2 d-flex justify-content-start text-align-center">
                        <div className="border card pt-2 pb-2 rounded m-0" style={{width: "740px"}}>
                            <div className="row m-0 d-flex justify-content-center text-align-center">
                                <div className="col-md-3">
                                    <FormInputText
                                        name="TitleOrCompanySearch"
                                        placeholder="Job Title or Company"
                                        type="text"
                                        isRequired="true"
                                        className="border-0 form-control"
                                    />
                                </div>
                                <div className="col-md-3">
                                    <FormInputText
                                        name="CityOrStateSearch"
                                        placeholder="City, State"
                                        type="text"
                                        isRequired="true"
                                        className="border-0"
                                    />
                                </div>
                                <div className="col-md-3">
                                    <FormAutoCompleteDropdownCard
                                        name="CustomerId"
                                        data={chain(customerList ?? [])}
                                        optionText="heading"
                                        isRequired="true"
                                        placeholder="Customer Name"
                                    />
                                </div>
                                <div className="col-md-3 d-flex justify-content-center text-align-center">
                                    <Button type="submit">
                                        Find Jobs
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
                {(permission.ManageAdd) && (
                    <div className="container bg-primary px-4 py-1 d-flex justify-content-end">
                        <Button onClick={() => navigate("/AddEditJobPosting")}>Add Job Post</Button>
                    </div>
                )}

                {(isJobApplicantState) && (
                    <div className="container px-4">
                        <div className="row">
                            <div className="col-md-10"></div>
                            <div className="col-md-2 d-flex justify-content-end">
                                <InputDropdown
                                    name="filter"
                                    ddOpt={[{text: "All", value: "All"}, {text: "Applied", value: "Applied"}]}
                                    // className="border-0 form-control"
                                    // label="Job"
                                    setValue={(val) => onFilterChanged(val)}
                                />
                            </div>
                        </div>
                    </div>
                )}

                <div className='m-4'>
                    <Box sx={{ flexGrow: 1 }}>
                        {(filteredJobData.length > 0) ? (
                            <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
                                {filteredJobData.map((job, index) => (
                                    <Grid item xs={12} sm={4} md={3} key={index}>
                                        <Card sx={{ maxWidth: 345 }}>
                                            <CardHeader
                                                action={
                                                <IconButton aria-label="settings">
                                                    <MoreVertIcon />
                                                </IconButton>
                                                }
                                                title={job.Title}
                                                subheader={job.Location}
                                                className="pb-0"
                                            />
                                            <CardContent className="pt-2 pb-3">
                                                {job.JobTypes?.split(',').map((jobType) => (
                                                    <>
                                                        <Chip 
                                                            label={jobType}
                                                            // icon={<FavoriteIcon sx={{color: "green", floatRight: "1px"}}/>}
                                                            sx={{ color: "green", padding: "0px", borderRadius: "5px", fontWeight: "bold", backgroundColor: "rgba(0,125,0,0.1)", marginBottom: "8px"}}
                                                            size="small"
                                                        />
                                                        &nbsp;
                                                    </>
                                                ))}

                                                <Typography variant="body2" color="text.secondary">
                                                    {renderSalary(job)}
                                                </Typography>
                                                <br />
                                                <Typography variant="body2" color="text.secondary">

                                                    <div dangerouslySetInnerHTML={{ __html:  (job.Description !== null && job.Description !== undefined) ? trimDescription(job.Description) : ''}} />
                                                </Typography>
                                                
                                                {job?.Skills?.split(',').map((skill) => (
                                                    <>
                                                        <Chip 
                                                            label={skill}
                                                            sx={{ color: "green", padding: "0px", borderRadius: "5px", fontWeight: "bold", backgroundColor: "rgba(0,125,0,0.1)", marginBottom: "8px"}}
                                                            size="small"
                                                        />
                                                        &nbsp;
                                                    </>
                                                ))}
                                                <br />
                                                <Typography className='mt-2' variant="body2" color="text.secondary">
                                                    <div className="d-flex" style={{maxHeight: "18px"}}>
                                                        <span>Posted : </span>&nbsp; <CalculatePostedDate dateTime={job.PostedDate} />
                                                    </div>
                                                </Typography>

                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <ActionButton
                                                            onClick={() => fnViewModal(job.JobId, appliedJobList.some(item => item === job.JobId))}
                                                            IconName="View"
                                                            id="btnIdJobView"
                                                        />

                                                        {permission.ManageEdit && (
                                                            <ActionButton
                                                                onClick={() => navigate("/AddEditJobPosting", { state: { JobId: job.JobId }})}
                                                                IconName="Edit"
                                                                id="btnEditFuel"
                                                            />
                                                        )}

                                                        {permission.ManageDelete && (
                                                            <ActionButton
                                                                onClick={() => onDelete(job.JobId)}
                                                                IconName="Delete"
                                                                id="btnDeleteFuel"
                                                            />
                                                        )}
                                                    </div>

                                                    <div>
                                                        {Roles?.some(item => item === StandardConst.SystemRole.JobApplicant) || Roles.length === 0 ? (
                                                            appliedJobList.some(item => item === job.JobId) ? (
                                                                <Chip 
                                                                    label="Applied"
                                                                    sx={{ color: "white", padding: "0px" }}
                                                                    color="success"
                                                                    size="small"
                                                                />
                                                            ) : (
                                                                <Button size="small" onClick={() => jobApply(job.JobId)}>Apply</Button>
                                                            )
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <NoRecordTemplate
                                headerValue={"No Jobs Posted"}
                                subHeaderValue={"There are no jobs opening"}
                                imageUrl={StandardConst.imageNoRecordsFound}
                            />
                        )}
                    </Box>
                </div>
            </Container>
        </>
    );
}

// this is for fetch job posting time
export const CalculatePostedDate = ({ dateTime }) => {
    const [difference, setDifference] = useState('');
  
    useEffect(() => {
      const interval = setInterval(() => {
        const now = new Date();
        const targetDate = new Date(dateTime);
  
        const diffInMs = now - targetDate;
        const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        
        let differenceString = '';
        if (days > 0) {
          differenceString = `${days} day${days > 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
          differenceString = `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else {
          differenceString = 'Just now';
        }
  
        setDifference(differenceString);
      }, 1000);
  
      return () => clearInterval(interval);
    }, [dateTime]);
  
    return (
      <div>
        <p>{difference}</p>
      </div>
    );
}

export default JobListing;