import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import { StandardConst } from "./StandardConst";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Alert, AlertTitle } from "@mui/material";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import { ActionPermission } from "../Component/PageInfo";
import { dateFormatFunction }from "./StandardFunctions.jsx";


const HtmlFileReaderForInvoice = ({ jsonData, defaultSelectedTemplate, ShowDownloadBadgeBtn, ShowPrintBadgeBtn, Message, MessageAlertTitle }) => {
  ShowPrintBadgeBtn ??= 0;
  Message ??= "";
  MessageAlertTitle ??= "";
  ShowDownloadBadgeBtn ??= 0;
  const [htmlContent, setHtmlContent] = useState("");
  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
        InvoiceGenerate: ActionPermission("Invoice - Generate"),
        InvoiceEdit: ActionPermission("Invoice - Edit"),
        InvoiceView: ActionPermission("Invoice - View"),
        InvoiceDelete: ActionPermission("Invoice - Delete"),
        InvoicePrint: ActionPermission("Invoice - Print"),
        InvoiceDownload: ActionPermission("Invoice - Download"),
        InvoiceMail: ActionPermission("Invoice - Mail"),
        InvoicePublish: ActionPermission("Invoice - Download"),
    });
  }, [permissionList]);

  let downloadfileName = "";

  const formatCurrency = (currencyCode, localeCode, value) => {
     return new Intl.NumberFormat(localeCode, {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(Number(value));
  };

  const replaceValues = (content) => {
      let modifiedContent = content;
      const currencyCode = jsonData?.SettingDetails?.CurrencyCode;
      const localeCode = jsonData?.SettingDetails?.LocaleCode;
      Object.keys(jsonData).forEach(key => {
        // this is to replace Template Setting information.
        if (key === 'SettingDetails') {
          downloadfileName = jsonData[key].InvoiceNo;
          Object.keys(jsonData[key]).forEach(keyName => {
            modifiedContent = modifiedContent.replace(
              new RegExp(`{{${keyName}}}`, "g"),
              (((keyName == "InvoiceDate" || keyName == "InvoiceDueDate") ? dateFormatFunction(jsonData[key][keyName]) : (keyName === "TaxAmount" || keyName === "NetAmount" || keyName === "TotalAmount") ? formatCurrency(currencyCode, localeCode, jsonData[key][keyName]) : jsonData[key][keyName]))??""
            );
          })
        }

        // this is to replace Company information.
        if (key === StandardConst.InvoiceSectionNames.Company) {
          Object.keys(jsonData[key]).forEach(keyName => {
            jsonData[key].map((cValue) => {
              modifiedContent = modifiedContent.replace(
                new RegExp(`{{${cValue.FieldName}}}`, "g"),
                ((cValue.FieldType === StandardConst.dataTypes.Currency) ? formatCurrency(currencyCode, localeCode, cValue.Value) : cValue.Value)??""
              );
            })
          })
        }

        if (key === StandardConst.InvoiceSectionNames.Bottom) {
          Object.keys(jsonData[key]).forEach(keyName => {
            jsonData[key].map((cValue) => {
              modifiedContent = modifiedContent.replace(
                new RegExp(`{{${cValue.FieldName}}}`, "g"),
                ((cValue.FieldType === StandardConst.dataTypes.Currency) ? formatCurrency(currencyCode, localeCode, cValue.Value) : cValue.Value)??""
              );
            })
          })
        }

        // this is to replace Customer information.
        if (key === StandardConst.InvoiceSectionNames.Customer) {
          Object.keys(jsonData[key]).forEach(keyName => {
            jsonData[key].map((cuValue) => {
              modifiedContent = modifiedContent.replace(
                new RegExp(`{{${cuValue.FieldName}}}`, "g"),
                ((cuValue.FieldType === StandardConst.dataTypes.Currency) ? formatCurrency(currencyCode, localeCode, cuValue.Value) : cuValue.Value)??""
              );
            })
          })
        }

        // this is replace for Details information.
        if (key === StandardConst.InvoiceSectionNames.Details) {
            //Here we get columns count
            var columnCount = jsonData[key][0]?.InvoiceDetailColumns.length;

            // Here we fill only head on the table.
            for (var i = 0; i < columnCount; i++) {
              modifiedContent = modifiedContent.replace(new RegExp(`{{${jsonData[key][0]?.InvoiceDetailColumns[i].FieldName}${StandardConst.DetailsHeadingSuffix.Heading}}}`, "g"), jsonData[key][0]?.InvoiceDetailColumns[i].Name??"");
            }
              
            // Create a DOMParser
            const parser = new DOMParser();
            // Parse the HTML content
            const doc = parser.parseFromString(modifiedContent, 'text/html');

            const BodyHeading = doc.getElementById("InvoiceDetailRows");
            
            var replaceBody = ``;
            Object.keys(jsonData[key]).forEach(index => {

              var OneDataRow = jsonData[key][index];
              
              if (BodyHeading) {
                var trElement = BodyHeading.outerHTML;
                for (var j = 0; j < columnCount; j++) {
                  trElement = trElement.replace(new RegExp(`{{${OneDataRow?.InvoiceDetailColumns[j].FieldName}}}`, "g"), ((OneDataRow.InvoiceDetailColumns[j]?.FieldType === StandardConst.dataTypes.Currency) ? formatCurrency(currencyCode, localeCode, OneDataRow.InvoiceDetailColumns[j]?.Value) : OneDataRow.InvoiceDetailColumns[j]?.Value) ??"");
                }
                replaceBody += trElement;
              }
            });

            if (BodyHeading) {
              BodyHeading.outerHTML = replaceBody;
              modifiedContent = doc.documentElement.outerHTML;
            }
        }
      });

      return modifiedContent;
  };


  const handleDownloadPdf = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    doc.html(replaceValues(htmlContent), {
      x: 0,
      y: 0,
      async callback(doc) {
        await doc.save(downloadfileName);
      },
      html2canvas: { scale: 0.4 }
    });
  };

  const handlePrint = () => {
    const modifiedContent = replaceValues(htmlContent);
    const printWindow = window.open("", "_blank");
    printWindow.document.write(modifiedContent);
    printWindow.document.close();
    const openPrint = () => {
        printWindow.print();
    };
    printWindow.addEventListener('load', openPrint);
  };

  const handleReadFile = async (fileName) => {
    try {
      const badgeHTMLResponse = await fetch(`${StandardConst.apiBaseUrl}/Invoice/getInvoiceTemplateContents/${fileName}`);
      const badgeHTMLContent = await badgeHTMLResponse.text();
      setHtmlContent(badgeHTMLContent);
    } catch (error) {
      console.error("Error fetching HTML file:", error);
    }
  };

  useEffect(() => {
    handleReadFile(defaultSelectedTemplate);
  });

const htmlCode = `<div className="mt-2 mb-2">
                    <div dangerouslySetInnerHTML= __html:${ replaceValues(htmlContent)} 
                  </div>`;
  return (
    <>
      <div className="d-flex flex-column">
        {(Message != "") && (
          <Alert
            severity="success"
            icon={<CheckCircleIcon fontSize="inherit" />}
          >
            {(MessageAlertTitle != "") && (
              <AlertTitle>{MessageAlertTitle}</AlertTitle>
            )}
            <span>
              <strong>
                {Message}
              </strong>
            </span>
          </Alert>
        )}

        <iframe srcDoc={htmlCode} style={{width: '100%', height: '800px'}}></iframe>

        <div className="row p-3">
          {(ShowPrintBadgeBtn == 1 && permission?.InvoicePrint) && (
            <div className="col-md-6 col-6">
              <Button
                onClick={() => handlePrint()}
                variant="outline-primary"
                size="sm"
              >
                Print Invoice
              </Button>
            </div>
          )}

          {(ShowDownloadBadgeBtn == 1 && permission?.InvoiceDownload) && (
            <div className="col-md-6 col-6">
              <Button
                onClick={handleDownloadPdf}
                variant="outline-primary"
                className="float-end"
                size="sm"
              >
                <CloudDownloadIcon color="inherit" sx={{ fontSize: 20 }} />
                &nbsp; Download Invoice
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HtmlFileReaderForInvoice;
