import React, { forwardRef, useImperativeHandle, useState } from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, FormInputDropdown, FormInputText } from '../Form';
import * as yup from "yup";
import { StandardConst } from '../../Services/StandardConst';
import { WebService } from '../../Services/WebService';
import { useDispatch, useSelector } from "react-redux";
import _, { map } from 'underscore';


const AddEditAccessGroup = (props, ref) => {

    const [show, setShow] = useState(false);
    const [data, setData] = useState({ AccessGroup: {} });
    const CompanyInfo = useSelector((s) => s.auth.CompanyInfo);
    const Roles = useSelector((s) => s.auth.AssignRole ?? {});
    const dispatch = useDispatch();
    const isSuperAdmin = Roles?.some(element => element === StandardConst.SystemRole.SuperAdmin);
    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            setShow(true);
            fetchParentAccessGroupList();
            if(Roles[0] === StandardConst.SystemRole.SuperAdmin) getAllCompaniesList();
            if (id === 0) {
                const data = {
                    AccessGroup: {},
                };
                setData(data);
            } else {
                setShow(false);
                await fetchEditValue(id);
                setShow(true);
            }
        },
    }));

    const fetchEditValue = async (id) => {
        const data = {
            AccessGroup: await WebService({
                endPoint: `CommonUtility/Edit/accessgroup?AccessGroupId=${id || 0}`,
                dispatch,
            }).then((c) => (c.length > 0 ? c[0] : {})),
        };
        setData(data);
    }

    const handleClose = () => {
        setShow(false);
        setData({ AccessGroup: {} });
    }

    const requiredMessage = "This is a required field";
    const schema = yup
        .object()
        .shape({
            AccessGroupName: yup.string().trim().required(requiredMessage),
            Status: yup.string().trim().required(requiredMessage),
        })
        .required();

    const onSubmit = async (data) => {
        if (isSuperAdmin) {
            if (Number(data.CompanyId) === 0) {
                data.CompanyId = CompanyInfo.CompanyId;
            }
            else {
                data.CompanyId = Number(data.CompanyId);
            }
        }
        data.CompanyId = CompanyInfo.CompanyId;
        if (data.AccessGroupId === undefined) {
            await WebService({
                endPoint: "CommonUtility/accessgroup",
                body: data,
                dispatch,
            });
        } else {
            await WebService({
                endPoint: `CommonUtility/accessgroup?AccessGroupId=${data.AccessGroupId}`,
                method: "PUT",
                body: data,
                dispatch,
            });
        }
        handleClose();
        props.callBackEvent();
    }

    const [ParentAccessGroupList, setParentAccessGroupList] = useState([]);

    const fetchParentAccessGroupList = async () => {

        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/accessgroup?CompanyId=${StandardConst.YesNo.Yes}`,
            dispatch,
        });

        const parentOptions = [{ value: null, text: "" }].concat(
            _.map(data, (m) => {
                return {
                    value: m.AccessGroupId,
                    text: m.AccessGroupName,
                };
            })
        );
        setParentAccessGroupList(parentOptions);
    }

    const [companyList, setCompanyList] = useState([]);

    const getAllCompaniesList = async () => {
        await WebService({
            endPoint: `CommonUtility/fetchAll/companyinformation`,
            dispatch,
        }).then((companyList) => {

            const companyListArray = map(companyList, (oneCompany) => ({
                value: oneCompany.CompanyId || "" || null || undefined,
                text: oneCompany.CompanyName,
            }));

            companyListArray.unshift({ value: 0, text: "All Companies" });
            setCompanyList(companyListArray);
        });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {(data && data.AccessGroup && data.AccessGroup.AccessGroupId || 0) === 0
                        ? "Add Access Group"
                        : "Edit Access Group"
                    }
                </Modal.Title>
            </Modal.Header>
            <Form
                defaultValues={data.AccessGroup}
                onSubmit={onSubmit}
                validationSchema={schema}
            >
                <Modal.Body className="p-4">
                    <div className="row">
                        <div className="col-md-12">
                            <FormInputText
                                label="Access Group Name"
                                name="AccessGroupName"
                                type="text"
                                isRequired="true"
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputDropdown
                                name="Status"
                                ddOpt={StandardConst.StatusDropdown}
                                label="Status"
                                isRequired="true"
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputDropdown
                                name="ParentGroupId"
                                ddOpt={ParentAccessGroupList}
                                label="Parent Group"
                            ></FormInputDropdown>
                        </div>
                        <div className="col-md-12">
                            {Roles[0] === StandardConst.SystemRole.SuperAdmin && (
                                <FormInputDropdown
                                    name="CompanyId"
                                    ddOpt={companyList}
                                    label="Access"
                                >
                                </FormInputDropdown>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnAccessGroupSubmit" variant="outline-primary" type="submit">
                        {" "}
                        {(data && data.AccessGroup && data.AccessGroup.AccessGroupId || 0) === 0
                            ? "Submit"
                            : "Save Changes"}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default forwardRef(AddEditAccessGroup);