/* #region Import */
import React, { memo, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, FormInputText, FormInputFile, FormInputDropdown } from "../Form";
import "../Company/CompanyRegistration.css";
import Button from "react-bootstrap/Button";
import "../../asset/ReactStyle.scss";
import _, { findWhere, omit } from "underscore";
import { WebService } from "../../Services/WebService";
import { StandardConst } from "../../Services/StandardConst";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Alert, Snackbar } from "@mui/material";
import { DateTime } from "luxon";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { generateQueryStringByObject } from "../../Services/UtilityService";

const requiredMessage = StandardConst.requiredMessage;
const numberError = StandardConst.numberError;
const mobileMessage = StandardConst.mobileMessage;

/* #endregion */

const CompanyRegistration = () => {
  const dispatch = useDispatch();

  const { useState } = React;
  const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const [data] = useState({ File: null });

  const MAX_FILE_SIZE = 1024 * 1024 * 2; //2MB
  const validFileExtensions = {
    image: ["jpg", "gif", "png", "jpeg", "svg", "webp"],
  };
  const isValidFileType = (fileName, fileType) => {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  };

  const schema = yup
    .object()
    .shape({
      File: yup
        .mixed()
        .required(requiredMessage)
        .test("is-valid-type", "Not a valid image type", (value) =>
          isValidFileType(value && value.name.toLowerCase(), "image")
        )
        .test(
          "is-valid-size",
          "Max allowed size is 2MB",
          (value) => value && value.size <= MAX_FILE_SIZE
        ),
      CompanyName: yup.string().trim().required(requiredMessage),
      CountryId: yup.string().trim().required(requiredMessage),
      FullName: yup.string().trim().required(requiredMessage),
      EmployeeSize: yup.string().trim().required(requiredMessage),
      Email: yup
        .string()
        .email()
        .required(requiredMessage)
        .matches(
          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
          "Please enter a valid email"
        ),

      Password: yup.string().trim().required(requiredMessage).min(8).max(16),
      ConfirmPassword: yup
        .string()
        .trim()
        .required(requiredMessage)
        .test("passwords-match", "Passwords must match", function (value) {
          return this.parent.Password === value;
        }),

      Phone: yup.string()
        .required(requiredMessage)
        .matches(StandardConst.PhoneNumberValidateRegex, StandardConst.ValidationMessages.PhoneNoIsNotValid),

    })
    .required();
  const EmployeeSize = [
    { value: "1-50", text: "1-50" },
    { value: "50-100", text: "50-100" },
    { value: "101-200", text: "101-200" },
    { value: "201-500", text: "201-500" },
    { value: "501-1000", text: "501-1000" },
    { value: "1000+", text: "1000+" },
  ];

  var nevigate = useNavigate();
  var origin = window.location.origin.toString();
  const onSubmit = async (data) => {
    data.Logo = await WebService({
      endPoint: "upload/File",
      body: data.File,
      dispatch,
      isFile: true,
    }).then((res) => res.filename);
    data.Subscription = await DefaultPlanSubscription(parseInt(data.CountryId));
    const responsedata = await WebService({
      endPoint: "registration/Company",
      body: omit(data, ["File"]),
      dispatch,
    });

    setRegistrationData({
      CompanyName: data.CompanyName,
      LoginId: data.Email,
      Password: data.Password,
      domainUrl: `${origin}/#/Login?company=${responsedata.Code}`,
    });
    handleShowModal();
  };
  const DefaultPlanSubscription = (CountryId = null) => {
    CountryId ??= 0;
    return WebService({
      dispatch,
      endPoint: `Subscription/PlanHeaders?where=IsDefault eq ${StandardConst.YesNo.Yes} and (CountryId eq ${CountryId} or CountryId is null)`,
    })
      .then((r) => (r.length > 0 ? r[0] : {}))
      .then((r) =>
        WebService({
          dispatch,
          endPoint: `Subscription/Plan/${r.SubscriptionId}`,
        })
      )
      .then((r) => {
        const subcriptionDetail = findWhere(r.Details, {
          IsDefault: StandardConst.YesNo.Yes,
        });
        return {
          SubscriptionId: r.SubscriptionId,
          SubscriptionName: r.Name,
          SubscriptionDuration: subcriptionDetail.Duration,
          SubscriptionTypeId: r.SubscriptionTypeId,
          SubscriptionDetailId: subcriptionDetail.SubscriptionDetailId,
          UserLimitFrom: subcriptionDetail.UserLimitFrom,
          UserLimitTo: subcriptionDetail.UserLimitTo,
          IsActive: StandardConst.YesNo.Yes,
          TimeZone: DateTime.now().zone.name,
          Month:
            StandardConst.subscriptionPeriod[subcriptionDetail.Duration ?? ""]
              ?.Month ?? 0,
        };
      });
  };
  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob(
      [
        `Details for the Organization : ${registrationData.CompanyName}\nLogin ID: ${registrationData.LoginId} \nPassword: ${registrationData.Password}\nLoginURL: ${registrationData.domainUrl}
        
        \nTo setup WiseOfficeDay as your private Company HRMS system, please follow the instructions below:\n
        1. Open company profile on WiseOfficeDay using the Company Profile menu item and add your website domain.\n
        2. Now, go your company website hosting provide. Login into your account. Please create a subdomain in your website with following format\n
               employee.[Yourwebsite].com
          3. Please add a A record in the DNS configuration for your newly created sub-domain employee.[Yourwebsite].com\n
           DNS Setting\n
           A Record\n
           Host name:  employee.[Yourdomain].com\n
           IP Address: 69.49.234.185\n
        Wait for 1-2 hours. You and your employees will be able to login using employee.[Yourwebsite].com`,
      ],
      {
        type: "text/plain;charset=utf-8",
      }
    );
    element.href = URL.createObjectURL(file);
    element.download = "OrganizationDetails.txt";
    document.body.appendChild(element);
    element.click();
  };
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const [showModal, setShowModal] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const [registrationData, setRegistrationData] = useState({
    CompanyName: null,
    LoginId: null,
    Password: null,
    domainUrl: null,
  });
  const copyDomain = () => {
    setOpen(true);
    navigator.clipboard.writeText(registrationData.domainUrl);
  };
  const copyLoginDetails = () => {
    setOpen(true);
    navigator.clipboard.writeText(
      `Login ID: ${registrationData.LoginId} Password: ${registrationData.Password}`
    );
  };
  const [open, setOpen] = React.useState(false);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    // var p1 = WebService({
    //   dispatch,
    //   endPoint: "CompanyProfile/FetchCountryWithoutAuth",
    // }).then((r) => {
    //   setCountries(r);
    // });
    setCountries(StandardConst.Countries);
    // var p2 = frmSignupRef.current.fnReset((dt) => ({
    //   ...dt,
    //   EmployeeSize: "",
    //   CountryId: "",
    // }));
    // Promise.all([p1, p2]);
    Promise.all([urlRedirectToLogin()]);
  }, []);
  const urlRedirectToLogin = async () => {
    if (!(window.location.origin.indexOf("wiseofficeday.com") >= 0)) {
      let domain = window.location.href
        .toString()
        .toLowerCase()
        .replaceAll("https://", "")
        .replaceAll("http://", "")
        .replaceAll("index.html", "")
        .split("/#/")[0];
      if (domain.lastIndexOf("/") === domain.length - 1)
        domain = domain.substring(0, domain.length - 1);
      await WebService({
        dispatch,
        endPoint: `CompanyProfile?${generateQueryStringByObject({
          where: `LOWER(ifnull(DomainName,'')) eq '${domain}'`,
          select: "Code",
        })}`,
      }).then((r) => {
        var code = "";
        if (r.length > 0) r = r[0];
        code = r?.Code ?? "";
        if (code !== "") window.location.assign(`/#/Login?company=${code}`);
      });
    }
  };
  const frmSignupRef = useRef();
  const registrationForm = (
    <>
      <Form
        onSubmit={onSubmit}
        defaultValues={data}
        validationSchema={schema}
        ref={frmSignupRef}
      >
        <div className="form-group za-user-container">
          {" "}
          <FormInputText
            name="FullName"
            type="text"
            className="form-control"
            placeholder="Name"
            autoFocus={true}
          />
        </div>
        <div className="form-group za-name-container">
          <FormInputText
            name="CompanyName"
            type="text"
            isRequired="true"
            placeholder="Company Name"
            className="form-control"
          />
        </div>
        <div className="form-group za-email-container">
          <FormInputText
            name="Email"
            type="email"
            isRequired="true"
            placeholder="Email"
            className="form-control"
          />
        </div>
        <div className="form-group za-contact-number-container">
          <FormInputText
            name="Phone"
            type="text"
            isRequired="true"
            className="form-control"
            placeholder="Contact Number"
          />
        </div>
        <div className="form-group za-password-container">
          <FormInputText
            name="Password"
            type="password"
            isRequired="true"
            placeholder="Choose Password"
            className="form-control"
          />
        </div>
        <div className="form-group za-password-container">
          <FormInputText
            name="ConfirmPassword"
            type="password"
            placeholder="Confirm Password"
            isRequired="true"
            className="form-control"
          />
        </div>
        <div className="form-group za-website-container">
          <FormInputText
            name="DomainName"
            type="text"
            isRequired="true"
            placeholder="Company Domain"
            className="form-control"
          />
        </div>
        <div className="form-group za-employees-container">
          <FormInputDropdown
            name="EmployeeSize"
            ddOpt={EmployeeSize}
            isRequired="true"
            className="form-control"
            // style={{ color: "gray", fontWeight: "200" }}
          ></FormInputDropdown>
        </div>

        <div className="form-group za-country">
          <span className="select-dropdown"></span>
          <FormInputDropdown
            name="CountryId"
            ddOpt={countries.map((m) => ({
              value: m.CountryId,
              text: m.Country,
            }))}
            isRequired="true"
            // style={{ color: "gray", fontWeight: "200" }}
          ></FormInputDropdown>
        </div>
        <div className={`row input-group customerFileBtn`}>
          <div
            className="col-md-4 col-5"
            onClick={() => {
              document.getElementById("control_File").click();
            }}
            id="uploadLogoBtn"
          >
            <label
              className="input-group-text CR_input-padding registration_fileupload"
              htmlFor="inputGroupFile"
              id="upload_logo_btn"
            >
              Upload Logo
            </label>
          </div>
          <div className="col-md-8 col-7">
            <FormInputFile
              type="file"
              className="form-control FormInputFile registration_fileupload"
              name="File"
              isRequired="true"
              style={{ padding: "7px" }}
              id="upload_btn_file"
            />
          </div>
        </div>

        <div className="form-group pt-2">
          <Button
            id="btnCompanySubmit"
            type="submit"
            className="zp-form-submit btn-signup"
          >
            SIGN UP NOW
          </Button>
        </div>

        {/* <div className="form-group za-dc-container">
          <ReCAPTCHA sitekey="6Ld8nfkmAAAAAFnJ8rKqw2zv4BBWTCkhz1baHunP" />
        </div> */}
        <div className="form-group za-dc-container">
          <span id="data-center-name">
            By submitting my personal data, I consent to WiseOfficeDay for
            collecting, processing and storing my information in accordance with
            the{" "}
            <a
              href="https://wiseofficeday.com/privacy-policy.php"
              target="_blank"
              className="font-bold text-primary"
            >
              WiseOfficeDay Privacy Policy.
            </a>{" "}
          </span>
        </div>

        {/* <div className="alternate-signup">
          <p>
            Already have an account?{" "}
            <Link
              className="font-bold text-primary"
              to={`/Login?company=${(CompanyInfo ?? {}).Code}`}
              id="btnRegister"
            >
              Sign in
            </Link>{" "}
          </p>
        </div> */}
      </Form>
    </>
  );

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity="info"
          sx={{ width: "100%" }}
        >
          Copied!!!
        </Alert>
      </Snackbar>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="text-success">
              {" "}
              <i className="fa fa-check-circle" aria-hidden="true"></i> Registration
              Successful!!
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-bg-light p-0">
          <div className="alert alert-primary all-border-radius" role="alert">
            <strong className="p-0 m-0">
              Welcome&nbsp;
              {registrationData.CompanyName},<br></br>{" "}
            </strong>
            <small>
              Your organization registration has been successfully completed.
              Please follow given instructions!
            </small>
          </div>
          <div className="d-none d-lg-block p-4">
            <div className="input-group mb-3">
              <span className="input-group-text ">Login ID & Password</span>
              <input
                type="text"
                value={registrationData.LoginId}
                aria-label="First name"
                className="form-control text-control"
              />
              <input
                type={passwordShown ? "text" : "password"}
                value={registrationData.Password}
                aria-label="Last name"
                className="form-control text-control"
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={togglePassword}
                id="eye_splash_btn"
              >
                <i
                  className={`${passwordShown ? "fa fa-eye-slash" : "fa fa-eye"}`}
                  aria-hidden="true"
                ></i>
              </button>
              <button
                className="btn btn-outline-secondary right-border-radius"
                type="button"
                onClick={copyLoginDetails}
                id="clipboard_cpy_btn"
              >
                <i className="fa fa-clipboard" aria-hidden="true"></i>
              </button>
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text">
                Setup Domain URL &nbsp;&nbsp;
              </span>
              <input
                type="text"
                value={registrationData.domainUrl}
                className="form-control text-control"
                aria-label="Text input with checkbox"
              />
              <button
                onClick={copyDomain}
                className="btn btn-outline-secondary right-border-radius"
                type="button"
                id="clipboard_btn"
              >
                <i className="fa fa-clipboard" aria-hidden="true"></i> Copy
              </button>
            </div>
          </div>
          {/* Mobile View Start */}
          <div className="d-md-none d-lg-none d-sm-block p-2">
            <div className="input-group mb-3">
              <span className="input-group-text ">Login ID &nbsp;&nbsp;</span>
              <input
                type="text"
                value={registrationData.LoginId}
                aria-label="First name"
                className="form-control text-control"
              />

              <button
                className="btn btn-outline-secondary right-border-radius"
                type="button"
                onClick={copyLoginDetails}
                id="clipboard_copy_btn"
              >
                <i className="fa fa-clipboard" aria-hidden="true"></i>
              </button>
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text ">Password&nbsp;</span>
              <input
                type={passwordShown ? "text" : "password"}
                value={registrationData.Password}
                aria-label="Last name"
                className="form-control text-control"
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={togglePassword}
                id="eye_btn"
              >
                <i
                  className={`${passwordShown ? "fa fa-eye-slash" : "fa fa-eye"}`}
                  aria-hidden="true"
                ></i>
              </button>
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text">Login URL</span>
              <input
                type="text"
                value={registrationData.domainUrl}
                className="form-control text-control"
                aria-label="Text input with checkbox"
              />
              <button
                onClick={copyDomain}
                className="btn btn-outline-secondary right-border-radius"
                type="button"
                id="copy_btn"
              >
                <i className="fa fa-clipboard" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          {/* Mobile View End */}
        </Modal.Body>
        <Modal.Footer>
          <div className="row col-12">
            <div className="col-3">
              <button
                className="btn btn-outline-success"
                type="button"
                onClick={downloadTxtFile}
                id="download_btn"
              >
                <i className="fa fa-download" aria-hidden="true"></i> Download
              </button>
            </div>
            <div className="col-9 text-end">
              <Button
                variant="outline-danger"
                onClick={handleCloseModal}
                className="mx-2"
                id="close_btn"
              >
                Close
              </Button>
              <Button
                variant="outline-primary"
                onClick={() => {
                  nevigate(
                    registrationData.domainUrl.replaceAll(`${origin}/#`, "")
                  );
                }}
                id="go_to_login_btn"
              >
                Go to Login{" "}
                <i className="fa fa-angle-double-right" aria-hidden="true"></i>
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <div className="justify-content-center">
        {/* <div className="ibox-content m-b-sm ">
          <div className="row">
            <div className="col-md-2">
              <img src={`${StandardConst.apiBaseUrl}/uploads/${(CompanyInfo ?? {}).Logo ?? ""}`}
                alt="logo"
                className="img-size rounded mx-auto d-block"
              />
            </div>

            <div className="col-md-9 offset-md-1">
              <h2 className="">Human Resource Management System</h2>
              <div className=" forum-sub-title">
                Register your business to manage your Company operations.
              </div>
            </div>
          </div>
        </div> */}
        <section className="sales__logo">
          <div className="container">
            <div className="row">
              <div className="col-md-6 d-flex">
                <a href="https://wiseofficeday.com/" className="pr-20">
                  <LazyLoadImage
                    src="WOD.png"
                    alt="Logo"
                    className="header__logo"
                    title="WiseOfficeDay Logo"
                  />{" "}
                </a>
                <div className="d-flex pl-20 awarded-medal">
                  <div className="pl-10 text">
                    <span>Recruitment, Visitors, HR, Payroll, Timesheet, Invoice, Project, Performance </span>
                    <br />
                    and More... Covers everything that your business needs
                    <br />
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-end">
                <div className=" d-flex flex-column ">
                  <div>
                    <a href="tel:+918217643532" className="Instagram">
                      <i className="fa fa-phone text-primary"></i>
                      <strong> +91 8217 643 532</strong>
                    </a>
                    <a
                      href="https://api.whatsapp.com/send?phone=+918217643532&text=Hello"
                      className="whatsapp"
                    >
                      <i className="ms-2 fa fa-whatsapp text-success"></i> Whatsapp{" "}
                    </a>
                  </div>
                  <div>
                    <a href="mailto:sales@wiseofficeday.com">
                      <i className="fa fa-envelope text-primary"></i>{" "}
                      sales@wiseofficeday.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="landing__header slideshow">
          <div className="container">
            <div className="row d-flex justify-content-between">
              <div className="col-md-5 col-lg-5 col-xl-6 text-md-left order-md-1 order-2 ">
                <h1 style={{ color: "#F68B19" }} className="text-center">
                  End to End Intelligent Business Management Suite
                </h1>
                <p className="pt-3 text-white">
                  Run your organization the way you want with the freedom and
                  flexibility to create unlimited customized workflows for your
                  unique processes.
                </p>
                <ul className="check__list pt-3 pb-3 NoBullets ">
                  <li>End to End Business Management solution</li>

                  <li>
                    Learn about the <strong>WiseOfficeDay</strong> platform from
                    a product expert.
                  </li>
                  <li>15+ Integrated Modules</li>
                  <li>Discuss your specific HR goals and pain points</li>
                  <li>Explore how our tools can help you succeed.</li>
                  <li>Focus on Business Success</li>
                </ul>
                <div className="row  pb-3">
                  <div className="col-md-4 col-4 text-center">
                    <h3 className="text-warning">100+</h3>
                    <p className="text-white">App Features</p>
                  </div>
                  <div className="col-md-4 col-4 text-center">
                    <h3 className="text-warning">2,500+</h3>
                    <p className="text-white">Satisfied Users</p>
                  </div>
                  <div className="col-md-4 col-4 text-center">
                    <h3 className="text-warning">21,900+</h3>
                    <p className="text-white">Free Hours</p>
                  </div>
                </div>
                <div className="landing__stars">
                  <div className="row">
                    <div className="col-md-4 col-4 text-center">
                      <p className="text-white">
                        <span>4.7</span>/5
                      </p>
                      <LazyLoadImage
                        src="4.7.png"
                        alt="Star47"
                        title="star47"
                      />
                      <p className="text-white">Top Rated</p>
                    </div>
                    <div className="col-md-4 col-4 text-center">
                      <p className="text-white">
                        <span>4.5</span>/5
                      </p>
                      <LazyLoadImage
                        src="../4.7.png"
                        alt="Star45"
                        title="star45"
                      />
                      <p className="text-white">Top Rated</p>
                    </div>
                    <div className="col-md-4 col-4 text-center">
                      <p className="text-white">
                        <span>4.3</span>/5
                      </p>
                      <LazyLoadImage
                        src="../4.7.png"
                        alt="Star43"
                        title="star43"
                      />
                      <p className="text-white">System Suggest</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 order-1 order-md-2 ">
                <div className="zp-signup-form-container">
                  <div className="zp-signup-form">
                    <div className="zp-signup-form-heading">
                      <h2 className="zp-form-heading">Let's get Started</h2>
                      <p>Explore all the features with a 365-days free trial</p>
                    </div>
                    <div className="form ZP-form">{registrationForm}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="landing__suite d-none">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2>An end to end WiseOfficeDay Software</h2>
                <p>
                  WiseOfficeDay is a platform that connects everyone in an
                  organization under one head.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="landing__software blue software__mobile d-none">
          <div className="container">
            <div className="row align-items-center software__mobile--first">
              <div className="col-md-7 order-2 order-md-1 text-center">
                <LazyLoadImage
                  src="https://hrone.cloud/core/views/f34b8573e2/inc/assets/images/landing/mobile-simplify.jpg"
                  alt="Hrone Mobile App"
                  className="software__mobile--convenience"
                  title="mobile simplify"
                />
              </div>
              <div className="col-md-5 order-1 order-md-2 text-center text-md-left">
                <h2>
                  Manage all your HR operations with one integrated platform.
                </h2>
                <p>
                  Give your HR &amp; Employees the freedom to use HR services on
                  the go and undertake more than 100+ HR actions without logging
                  in the desktop software!
                </p>
              </div>
            </div>
          </div>
        </div> */}

        {/* Features of WiseOfficeDay - commented by shubham*/}
        {/* <div
          className="text-center  s-pt-50 s-pb-50 mt-4 pt-4 mb-4"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="container">
            <h2 className="mb-4">
              Features of{" "}
              <strong className="color-main5 bolder">WiseOfficeDay</strong>
            </h2>
            <h5 className="special-heading color-dark"></h5>
            <div className="divider-50 d-none d-xl-block"></div>
            <div className="row">
              <div
                className="col-lg-3 offset-lg-2 text-center  animate"
                data-animation="expandOpen"
                data-delay="150"
              >
                <div className=" icon-styled fs-56">
                  <LazyLoadImage
                    src="https://wisesoftwaresolutions.com/images/persondetails.png"
                    className="img-fluid"
                    alt="SECURITY"
                  />
                </div>
                <h6 className="mb-2 highlight text-capitalize color-dark bolder">
                  Salary Process Management
                </h6>
                <p>
                  Adaptable system for managing all aspects of salary processing
                </p>
              </div>
              <div
                className="col-lg-3 text-center  animate"
                data-animation="expandOpen"
                data-delay="150"
              >
                <div className=" icon-styled fs-56">
                  <LazyLoadImage
                    src="https://wisesoftwaresolutions.com/images/wiseoneview01.png"
                    className="img-fluid"
                    alt="SECURITY"
                  />
                </div>
                <h6 className="mb-2 highlight text-capitalize color-dark bolder">
                  Time & Attendance Management
                </h6>
                <p>
                  {" "}
                  Real-time task details to improve the efficiency of payroll
                  processing.
                </p>
              </div>
              <div
                className="col-lg-3 text-center  animate"
                data-animation="expandOpen"
                data-delay="150"
              >
                <div className=" icon-styled fs-56">
                  <LazyLoadImage
                    src="https://wisesoftwaresolutions.com/images/wiseoneview01.png"
                    className="img-fluid"
                    alt="SECURITY"
                  />
                </div>
                <h6 className="mb-2 highlight text-capitalize color-dark bolder">
                  Leave Management
                </h6>
                <p>
                  Complete management of leave details with ready to read
                  reports.
                </p>
              </div>
            </div>
            <div className="divider-60 d-none d-xl-block"></div>
            <div className="row ">
              <div
                className="col-lg-3  text-center  animate"
                data-animation="expandOpen"
                data-delay="150"
              >
                <div className=" icon-styled fs-56">
                  <LazyLoadImage
                    src="https://wisesoftwaresolutions.com/images/wiseoneview02.png"
                    className="img-fluid"
                    alt="SECURITY"
                  />
                </div>
                <h6 className="mb-2 highlight text-capitalize color-dark bolder">
                  Reporting and Analysis{" "}
                </h6>
                <p>
                  Generate valuable reports on various payroll to employee pay
                  process.
                </p>
              </div>
              <div
                className="col-lg-3 text-center  animate"
                data-animation="expandOpen"
                data-delay="150"
              >
                <div className=" icon-styled fs-56">
                  <LazyLoadImage
                    src="https://wisesoftwaresolutions.com/images/file-transfer.png"
                    className="img-fluid"
                    alt="SECURITY"
                  />
                </div>
                <h6 className="mb-2 highlight text-capitalize color-dark bolder">
                  Employee Self Service
                </h6>
                <p>
                  Facilitating an easier process by involving employees in the
                  self-service portal
                </p>
              </div>
              <div
                className="col-lg-3 text-center  animate"
                data-animation="expandOpen"
                data-delay="150"
              >
                <div className=" icon-styled fs-56">
                  <LazyLoadImage
                    src="https://wisesoftwaresolutions.com/images/persondetails.png"
                    className="img-fluid"
                    alt="SECURITY"
                  />
                </div>
                <h6 className="mb-2 highlight text-capitalize color-dark bolder">
                  Statutory Compliance
                </h6>
                <p>
                  Stay compliant with all payroll laws for seamless working on
                  different statutory compliance
                </p>
              </div>

              <div
                className="col-lg-3 text-center  animate"
                data-animation="expandOpen"
                data-delay="150"
              >
                <div className=" icon-styled fs-56">
                  <LazyLoadImage
                    src="https://wisesoftwaresolutions.com/images/wiseoneview02.png"
                    className="img-fluid"
                    alt="SECURITY"
                  />
                </div>
                <h6 className="mb-2 highlight text-capitalize color-dark bolder">
                  Performance Tracking
                </h6>
                <p>
                  The system should enable managers to track employee
                  performance, set goals, conduct performance reviews, and
                  provide feedback.
                </p>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="landing__software blue software__mobile">
          <div className="container-fluid">
            <div className="row align-items-center software__mobile--first">
              <div className="col-md-7 order-2 order-md-1 text-center">
                <LazyLoadImage
                  src="../dashboard.png"
                  alt=" Mobile App"
                  className="software__mobile--convenience"
                  title="mobile simplify"
                />
              </div>
              <div className="col-md-5 order-1 order-md-2 text-center text-md-left">
                <h2>
                  Manage all your HR operations with one integrated platform.
                </h2>
                <p>
                  Give your HR &amp; Employees the freedom to use HR services on
                  the go and undertake more than 100+ HR actions without logging
                  in the desktop software!
                </p>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="landing__software blue software__mobile">
          <div className="container-fluid">
            <div className="row align-items-center software__mobile--first">
              <div className="col-md-5  text-center text-md-right">
                <h2>Time and attendance tracking made easy. </h2>
                <p>
                  With our time and attendance software, employees time in and
                  out right on the WiseOfficeDay home screen or on their phones,
                  edit their hours, and track the time they’ve worked on
                  specific projects or tasks.
                </p>
              </div>
              <div className="col-md-7 text-center">
                <LazyLoadImage
                  src="../timesheet.png"
                  alt=" Mobile App"
                  className="software__mobile--convenience"
                  title="mobile simplify"
                />
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="landing__software blue software__mobile">
          <div className="container-fluid">
            <div className="row align-items-center software__mobile--first">
              <div className="col-md-7 order-2 order-md-1 text-center">
                <LazyLoadImage
                  src="../approvetimesheet.png"
                  alt=" Mobile App"
                  className="software__mobile--convenience"
                  title="mobile simplify"
                />
              </div>
              <div className="col-md-5 order-1 order-md-2 text-center text-md-left">
                <h2>Approve employee time with one click.</h2>
                <p>
                  Time and attendance monitoring just got a whole lot easier.
                  Managers don’t have to send countless emails or wait for
                  employees to hand in their timecards—the approval workflow
                  handles all of it, with timesheets attached for easy review
                  and one-click approval.
                </p>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="landing__software blue software__mobile">
          <div className="container-fluid">
            <div className="row align-items-center software__mobile--first">
              <div className="col-md-5  text-center text-md-right">
                <h2>Manage Leave summary with one integrated platform.</h2>
                <p>
                  Give your HR &amp; Employees the freedom to use HR services on
                  the go and undertake more than 100+ HR actions without logging
                  in the desktop software!
                </p>
              </div>
              <div className="col-md-7 text-center">
                <LazyLoadImage
                  src="../leavesummry.png"
                  alt="Mobile App"
                  className="software__mobile--convenience"
                  title="mobile simplify"
                />
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="landing__suite">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2>Why WiseOfficeDay?</h2>
                <p>
                  <b>WiseOfficeDay</b> is technology designed to streamline core
                  HR services and improve workforce productivity. It
                  accomplishes these goals largely by automating labor-intensive
                  administrative tasks and using analytics to drive business
                  decisions.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="landing__bottom">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-4 text-center text-md-left">
                <h4>Intelligent</h4>
                <p>
                  Assign, track, execute tasks from the inbox and search data
                  with a google-like search engine.
                </p>
              </div>
              <div className="col-md-4 text-center text-md-left">
                <h4>Experiential</h4>
                <p>
                  Automate over 100+ HR tasks and amplify human
                  interactions.
                </p>
              </div>
              <div className="col-md-4 text-center text-md-left">
                <h4>Insights</h4>
                <p>
                  Actionable insights that help Line Managers, HR Professional
                  &amp; CXO achieve more.
                </p>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="landing__footer d-none">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12  text-md-left">
                <h4 className="pb-2">About the company</h4>
                <p>
                  WiseOfficeDay is the product of Uneecops Workplace Solutions
                  Private Limited which is a subsidiary of Uneecops Group, a 450
                  Cr. Conglomerate established in 1995. Uneecops is a technology
                  solution provider associated with some of the world’s leading
                  OEMs &amp; technology companies as their most trusted partners
                  for business in India. The company is at the forefront of
                  developing cutting edge IT products that helps growing
                  companies adapt &amp; use technology more efficiently.
                </p>
                <h4 className="pb-2 pt-2">Location</h4>
                <p>
                  WiseOfficeDay Home | Contact Us | Terms of Service | Privacy
                  Policy | Cookie Policy
                </p>
              </div>
            </div>
          </div>
        </div> */}
        {/* <footer className="footer-section pb-5">
          <div className="container">
            <div className="footer-content pt-5 pb-1">
              <div className="row  footer-cta">
                <div className="col-xl-4 col-lg-4 mb-50">
                  <div className="footer-widget">
                    <div className="footer-logo">
                      <a>
                        <img src="WOD.png" className="img-fluid" alt="logo" />
                      </a>
                    </div>
                    <div className="footer-text">
                      <p className="text-justify">
                        WiseOfficeDay HRMS SaaS based platform is designed to
                        streamline core HR services and improve workforce
                        efficiency.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-4 mb-30">
                  <div className="footer-widget">
                    <div className="footer-widget-heading">
                      <h3 className="text-black">Support</h3>
                    </div>
                    <ul>
                      <li>
                        <a href="https://wiseofficeday.com/contact.php">Help</a>
                      </li>
                      <li>
                        <a href="https://wiseofficeday.com/contact.php">
                          Contact HR
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-4 col-md-4 mb-30">
                  <div className="footer-widget">
                    <div className="footer-widget-heading">
                      <h3 className="text-black">Mail Us</h3>
                    </div>
                    <ul>
                      <li>
                        <a>sales@wiseofficeday.com</a>
                      </li>
                      <li>
                        <a>support@wiseofficeday.com</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row pt-5">
                <div className="col-xl-3 col-lg-3 col-md-6 mb-30">
                  <div className="footer-widget">
                    <div className="footer-widget-heading">
                      <h3>Useful Links</h3>
                    </div>
                    <ul>
                      <li>
                        <a
                          href="https://wiseofficeday.com/Leave_Management.php"
                          target="_blank"
                        >
                          Leave management
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://wiseofficeday.com/TimeSheet_Management.php"
                          target="_blank"
                        >
                          Time sheet management
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 mb-30">
                  <div className="footer-widget">
                    <div className="footer-widget-heading">
                      <h3>&nbsp;</h3>
                    </div>
                    <ul>
                      <li>
                        <a
                          href="https://wiseofficeday.com/Payroll_Management.php"
                          target="_blank"
                        >
                          Payroll management
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://wiseofficeday.com/Performance_Management.php"
                          target="_blank"
                        >
                          Performance management
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 mb-30">
                  <div className="footer-widget">
                    <div className="footer-widget-heading">
                      <h3>&nbsp;</h3>
                    </div>
                    <ul>
                      <li>
                        <a
                          href="https://wiseofficeday.com/Security_Management.php"
                          target="_blank"
                        >
                          Security management
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://wiseofficeday.com/Company_Information_Management.php"
                          target="_blank"
                        >
                          Company Management
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 mb-30">
                  <div className="footer-widget">
                    <div className="footer-widget-heading">
                      <h3>&nbsp;</h3>
                    </div>
                    <ul>
                      <li>
                        <a href="Employee_Management.php">
                          Employee Management
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer> */}
        {/* <div className="site-footer landing__copyright">
          <div className="site-info">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-12 col-md-12 text-center text-md-left">
                  <p>
                    Copyright © 2023. Wise Software Solutions Pvt. Ltd. All
                    Rights Reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default memo(CompanyRegistration);
