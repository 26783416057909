import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText } from "../Form";
import * as yup from "yup";
import { useDispatch } from "react-redux";

const { forwardRef, useState, useImperativeHandle } = React;
const AddEditJobFunction = (prop, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ JobFunction: {} });
  useImperativeHandle(ref, () => ({
    openModal: async (id) => {
      setShow(true);
      if(id === 0){
        setData({ JobFunction: {}})
      }else {
        setShow(false);
        const apiData = await WebService({
          // endPoint: `JobFunction/Fetch/${id || 0}`,
          endPoint: `CommonUtility/Edit/staticjobfunctions?JobFunctionId=${id || 0}`,
          dispatch,
        });
        data.JobFunction = apiData[0]
        setData(data);
        setShow(true);
      }
    },
  }));
  const handleClose = () => setShow(false);
  const requiredMessage = "This is a required field";
  const schema = yup
    .object()
    .shape({
      JobFunction: yup.string().trim().required(requiredMessage),
    })
    .required();
  const onSubmit = async (data) => {
    if (data.JobFunctionId === undefined) {
      await WebService({
        endPoint: "JobFunction/Create",
        body: data,
        dispatch,
      });
    } else {
      await WebService({
        endPoint: `JobFunction/Update/${data.JobFunctionId}`,
        method: "PUT",
        body: data,
        dispatch,
      });
    }
    handleClose();
    prop.callBackEvent();
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {(data?.JobFunction?.JobFunctionId || 0) === 0
            ? "Add Job Function"
            : "Edit Job Function"}
        </Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={data.JobFunction}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        <div>
          <Modal.Body className="p-4">
            <div className="row">
              <div className="col-md-12">
                <FormInputText
                  label="Job Function"
                  name="JobFunction"
                  type="text"
                  isRequired="true"
                />
              </div>
              <div className="col-md-12">
                <FormInputText
                  name="JobDescription"
                  label="Description"
                  as="textarea"
                  rows="2"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>          
          <Button  id="btnJobFunctionSubmit" variant="outline-primary" type="submit">{(data?.JobFunction?.JobFunctionId || 0) === 0
                ? "Submit"
                : "Save Changes"} </Button>
         </Modal.Footer>  
        </div>              
      </Form>
    </Modal>
  );
};

export default forwardRef(AddEditJobFunction);
