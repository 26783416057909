import React, { useCallback, useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import ReactDOMServer from "react-dom/server";
import ReactDOM from "react-dom";
import Button from "react-bootstrap/esm/Button";
import { InputDropdown } from "../Component/Form";
import { StandardConst } from "./StandardConst";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Alert, AlertTitle } from "@mui/material";
import * as htmlToImage from "html-to-image";
import { saveAs } from "file-saver";
import { WebService } from "./WebService";
import { useDispatch } from "react-redux";
import jsPDF from "jspdf";
import { Canvg } from "canvg";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
const HtmlFileReader = ({ jsonData, defaultTemplate, ShowDropdown, ShowPrintBadgeBtn, Message, MessageAlertTitle, ShowDownloadBadgeBtn, selectPosition, selectFile, SelectedTemplateId, selfVisitorBadge, showDropDownList, companyId, fullImagePathGiven }) => {
  defaultTemplate ??= "sample.html";
  fullImagePathGiven ??= false;
  SelectedTemplateId ??= "";
  ShowDropdown ??= 0;
  ShowPrintBadgeBtn ??= 0;
  Message ??= "";
  MessageAlertTitle ??= "";
  ShowDownloadBadgeBtn ??= 0;
  const [htmlContent, setHtmlContent] = useState("");
  // This State have all badge templates data and also have login company badge template data both
  const [badgeTemplatesFullData, setBadgeTemplatesFullData] = useState([]);
  // This State have all data of selected Template (selected template data)
  const [selectedBadgeTemplateDataState, setSelectedBadgeTemplateDataState] = useState({});

  // This State for Badge template dropdown, state have all data form of object which object have two data
  const [badgeTemplatesList, setBadgeTemplatesList] = useState([]);
  const [selectedBadgeTemplateId, setSelectedBadgeTemplateId] = useState(badgeTemplatesList[0]);

  const [qrData, setQRData] = useState({});
  const qrCodeRef = useRef(null);
  const htmlContainerRef = useRef(null);
  const handleChangeFile = (event) => {
    setSelectedBadgeTemplateId(event.target.value);
  };

  const [modifiedContent, setModifiedContent] = useState(null);

  const fetchAndReplace = useCallback(async () => {
    try {
      // replace values ko function me use isliye kiya hai kyoki ye ik promise object return krta hai 
      const content = await replaceValues(htmlContent);
      setModifiedContent(content);
    } catch (error) {
      console.error('Error:', error);
    }
  }, [htmlContent]); 

  const replaceValues = async(content) => {
    let modifiedContent = content;
    const keys = Object.keys(jsonData);
    const promises = keys.map(async (key) => {
      if(key === "Logo"){
        const companyLogoName = jsonData[key];
        const logoData = await fetch(`${StandardConst.apiBaseUrl}/Visitor/getLogo/${companyLogoName}`);
        const badgeHTMLContent = await logoData.text();
        modifiedContent = modifiedContent.replace(/{{Logo}}/g, badgeHTMLContent);
      }
      if (key === "Photo") {
        const visitorPhotoName = jsonData[key];
        if(fullImagePathGiven){
          modifiedContent = modifiedContent.replace(/{{Photo}}/g, visitorPhotoName);
        }else{
          const fileData = await fetch(`${StandardConst.apiBaseUrl}/Visitor/getImage/${visitorPhotoName}`);
          const badgeHTMLContent = await fileData.text();
          modifiedContent = modifiedContent.replace(/{{Photo}}/g, badgeHTMLContent);
        }
      } else if(key === "ProfileImage"){
        const ProfileImageName = jsonData[key];
        if(fullImagePathGiven){
          modifiedContent = modifiedContent.replace(/{{ProfileImage}}/g, ProfileImageName);
        }else{
          const fileData = await fetch(`${StandardConst.apiBaseUrl}/Visitor/getProfileImage/${ProfileImageName}`);
          const badgeHTMLContent = await fileData.text();
          modifiedContent = modifiedContent.replace(/{{ProfileImage}}/g, badgeHTMLContent);
        }
      } else {
        const regex = new RegExp(`{{${key}}}`, "g");
        modifiedContent = modifiedContent.replace(regex, jsonData[key] ?? "--");
      }
    });
  
    // yha pr hmne wait kiya hai ki jb tk promise variable ka saara data na aa jaaye tb tk wait krna hai or khass kr ye Photo key ke liye kiya gya hai kyoki photo replace me time lg rha tha or photo replce wait nhi kr rha tha 
    await Promise.all(promises);
    return modifiedContent;
  };

  if(selectFile)selectFile(selectedBadgeTemplateId);

  const FileName = "Visitor_Badge";

  const handleDownloadPdf = () => {

    const doc = new jsPDF(selectedBadgeTemplateDataState.BadgeTemplateOrientation, 'mm', [selectedBadgeTemplateDataState.BadgeTemplateHeight, selectedBadgeTemplateDataState.BadgeTemplateWidth]);

    const marginLeft = 10;
    const marginTop = 5;
    const contentWidth = doc.internal.pageSize.width - 2 * marginLeft;

    const startX = marginLeft + (contentWidth - contentWidth * 0.262) / 2;

    const docHtml = doc.html(modifiedContent, {
    // const docHtml = doc.html(replaceValues(htmlContent), {
      // x: showDropDownList === StandardConst.BadgeType[1].text ? startX : 0,
      // y: showDropDownList === StandardConst.BadgeType[1].text ? marginTop : 0,

      x: 0,
      y: 0,
      async callback(doc) {
        await doc.save("visitor_badge");
      },
      html2canvas: { scale: 0.262 },
    });
  };

  const handleDownload = () => {
      const visitorBadgeHtml = document.getElementById("VisitorBadge");

      htmlToImage
        .toPng(visitorBadgeHtml)
        .then(function (dataUrl) {
          // Create a Blob from the data URL
          const blob = dataURLtoBlob(dataUrl);
          // Save the Blob as a file
          saveAs(blob, FileName + ".png");
        })
        .catch(function (error) {
          console.error("Error generating QR Code image:", error);
        });
  };

  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const handlePrint = () => {
    // const modifiedContent = replaceValues(htmlContent);
    const printWindow = window.open("", "_blank");
    printWindow.document.write(modifiedContent);
    // printWindow.document.write(modifiedContent);
    printWindow.document.close();

    const setPrintSettings = () => {
      // Add print styles using matchMedia
      const style = document.createElement('style');
      style.textContent = `
          @media print {
              /* Add your print styles here */
              /* Example: */
              * {
                  -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
                  color-adjust: exact !important;                 /* Firefox 48 – 96 */
                  print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
                  margin: 0 !important;
              }
              
              @page {
                margin-top: 5cm;
                margin-bottom: 5cm;
              }
            
              @media print {
                  body {
                    margin-top: 50mm; margin-bottom: 50mm; 
                      margin-left: 0mm; 
                      margin-right: 0mm;
                  }
              }
          }
      `;
      printWindow.document.head.appendChild(style);
  };
    const openPrint = () => {
        setPrintSettings();
        
        printWindow.print();
    };
    printWindow.addEventListener('load', openPrint);
  };
  const handleReadFile = async (fileId) => {
    try {
      const selectedBadgeTemplateData = badgeTemplatesFullData?.find(item => item?.badge_templatesId == fileId);
        // Here we set the state for get Selected Badge template data
        setSelectedBadgeTemplateDataState(selectedBadgeTemplateData);
        // Get selected file name here 
        const file = selectedBadgeTemplateData?.html_file_path;
        if(file){
          const badgeHTMLResponse = await fetch(`${StandardConst.apiBaseUrl}/Visitor/getBadgeTemplateHTMLContents/${file}`);
          const badgeHTMLContent = await badgeHTMLResponse.text();
          const QRCodeContainer = document.createElement("div");
          var QRData = "";
          if(jsonData.VisitorGuid !== undefined){
            QRData = StandardConst.UserType.Visitor + "," + jsonData.VisitorGuid;
          }else if(jsonData.EmployeeGuid !== undefined){
            QRData = StandardConst.UserType.Employee + "," + jsonData.EmployeeGuid;
          }
          ReactDOM.render(<QRCode value={QRData} size={50} />, QRCodeContainer);

          var svgCode = QRCodeContainer.innerHTML;
        
          svgCode = svgCode.replace(/\r?\n|\r/g, '').trim();

          var canvas = document.createElement('canvas');
          var context = canvas.getContext('2d');

          context.clearRect(0, 0, 100, 100);
          const canvasFromSvg = await Canvg.from(
            context,
            svgCode
          );

          canvasFromSvg.start();

          window.onbeforeunload = () => {
            canvasFromSvg.stop();
          };

          var QRImgURI = canvas.toDataURL('image/png');
        
          var QrCodeImg = "<img src='"+QRImgURI+"'>";

          setHtmlContent(badgeHTMLContent.replace(/{{QrCode}}/g, QrCodeImg));
        }
    } catch (error) {
      console.error("Error fetching HTML file:", error);
    }
  };

  const dispatch = useDispatch();

  const badgeTemplates = async() => {
    let badgeTemplateData;
    if(companyId){
      badgeTemplateData = await WebService({
        dispatch, 
        endPoint: 
        `CommonUtility/selfCheckIn/fetchAll/badge_templates?CompanyId=${companyId}&isReserved=${StandardConst.YesNo.Yes}`,
      })
    }else {
      badgeTemplateData = await WebService({
        dispatch, 
        endPoint: 
        `CommonUtility/fetchAll/badge_templates?CompanyId=${StandardConst.YesNo.Yes}&isReserved=${StandardConst.YesNo.Yes}`,
      })
    }

    // This state have all badge templates data which is have this company or reserved badge templates for all company
    setBadgeTemplatesFullData(badgeTemplateData);


    let filterList = [];

    if(showDropDownList === StandardConst.BadgeType[1].text){
      filterList = badgeTemplateData.filter((item) => item.badge_type === StandardConst.BadgeType[1].value);
    }else if (showDropDownList === StandardConst.BadgeType[0].text){
      filterList = badgeTemplateData.filter((item) => item.badge_type === StandardConst.BadgeType[0].value);
    }else if (showDropDownList === StandardConst.BadgeType[2].text){
      filterList = badgeTemplateData.filter((item) => item.badge_type === StandardConst.BadgeType[2].value);
    }
    else {
      filterList = badgeTemplateData
    }

    let badgeTemplateDropdownList = filterList.map((r) => {
      return { text: r.badge_template_name, value: r.badge_templatesId };
    });

    // Add the first blank row
    badgeTemplateDropdownList.unshift({ text: '', value: null });

    setBadgeTemplatesList(badgeTemplateDropdownList);
    if(selectFile === undefined && selfVisitorBadge === StandardConst.ShowOrHide.Show || ShowDropdown === 0) {
      setSelectedBadgeTemplateId(defaultTemplate.value);
    }
    // else if(selectFile === undefined && selfVisitorBadge === StandardConst.ShowOrHide.Hide){
    //   setBadgeTemplatesList(defaultTemplate);
    // }
    // Combine SelectedTemplateId and list
    if(SelectedTemplateId && SelectedTemplateId.length > 0){
      // const combinedList = [...SelectedTemplateId, ...badgeTemplateDropdownList];

      // // Create a Set to store unique text values
      // const uniqueTexts = new Set();
      
      // // Filter the combined list to keep unique items based on the "text" property
      // const uniqueList = combinedList.filter((item) => {
      //   if (!uniqueTexts.has(item.text)) {
      //     uniqueTexts.add(item.text);
      //     return true;
      //   }
      //   return false;
      // });

        // Now, setBadgeTemplatesList to contain the unique list
        // const ReservedOrFilteredList = [...uniqueList, ...Reserved]
        // setBadgeTemplatesList(uniqueList);
        setSelectedBadgeTemplateId(SelectedTemplateId[0].value);
      }

  }

  React.useEffect(() => {
    handleReadFile(selectedBadgeTemplateId);
    // badgeTemplates();
  }, [selectedBadgeTemplateId]);

  useEffect(()=> {
    fetchAndReplace();
  }, [fetchAndReplace]);

  useEffect(() => {
    badgeTemplates();
  }, []);
  
  let customStyles = {};

if (selectPosition) {
  customStyles.position = "relative";
  customStyles.right = "-186px";
  customStyles.top = "-24px";
  customStyles.width = "287px";
}

  return (
    <>
      <div className="d-flex flex-column">
        {(ShowDropdown == 1) && (
          <div className="row">
            <div className="col-12 col-md-8" style={customStyles}>
              <InputDropdown
                value={SelectedTemplateId[0]?.value}
                setValue={(v) => setSelectedBadgeTemplateId(v)}
                ddOpt={badgeTemplatesList}
              ></InputDropdown>
            </div>
          </div>
        )}

          {(Message != "") && (
            <Alert
              severity="success"
              icon={<CheckCircleIcon fontSize="inherit" />}
            >
              {(MessageAlertTitle != "") && (
                <AlertTitle>{MessageAlertTitle}</AlertTitle>
              )}
              <span>
                <strong>
                  {Message}
                </strong>
              </span>
            </Alert>
          )}

        <div className="mt-2 mb-2">
          {/* <div dangerouslySetInnerHTML={{ __html: replaceValues(htmlContent) }} /> */}
          <div dangerouslySetInnerHTML={{ __html:  modifiedContent}} /> 
        </div>

        <div className="row">
          {(ShowPrintBadgeBtn == 1) && (
            <div className="col-4 col-md-3">
              <Button
                onClick={() => handlePrint()}
                variant="outline-primary"
                // className="float-end"
                size="sm"
                className="text-nowrap"
              >
                <LocalPrintshopIcon fontSize="small" />
              &nbsp; Print Badge
              </Button>
            </div>
          )}

          {(ShowDownloadBadgeBtn == 1) && (
            <div className="col-8 col-md-9">
              <Button
                onClick={handleDownloadPdf}
                variant="outline-primary"
                size="sm"
              >
                <CloudDownloadIcon color="inherit" sx={{ fontSize: 20 }} />
                &nbsp; Download Badge
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HtmlFileReader;
