import * as yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import {
    Form,
    FormInputText,
} from "../Form";
import { WebService } from "../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/esm/Button";
import { Container } from "@mui/material";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { PageInfo } from "../PageInfo";
import { ReactangleShapeOfUploadPicture } from "../../Services/ImageUploader";
import { Alert, AlertTitle } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { login } from "../../Services/authSlice";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { StandardConst } from "../../Services/StandardConst";


const QuickCompanyRegistration = () => {

    const frmRef = useRef();
    const [imageName, setImageName] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    PageInfo({ pageTitle: "Quick Company Registration" });
    const numberError = StandardConst.numberError;
    const mobileMessage = StandardConst.mobileMessage;
    const schema = yup
        .object()
        .shape({
            CompanyName: yup
                .string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            Password: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg).min(8).max(16),
            Email: yup
                .string()
                .email()
                .required(StandardConst.ValidationMessages.RequiredFieldMsg)
                .matches(
                    /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    StandardConst.ValidationMessages.InvalidEmailMsg
                ),

            Phone: yup.string()
                .required(StandardConst.ValidationMessages.RequiredFieldMsg)
                .matches(StandardConst.PhoneNumberValidateRegex, StandardConst.ValidationMessages.PhoneNoIsNotValid),
        })
        .required();
    const [showAlertMsg, setShowAlertMsg] = useState(false);

    useEffect(() => {
        cookies.remove("company");
    }, [])

    const onSubmit = async(data) => {
        data.Logo = imageName;
        data.Subscription = 1; // this is for free now of subscription plan

        const response = await WebService({
            endPoint: `registration/checkIfCompanyAlreadyExist`,
            body: data,
            dispatch,
        });
        if(response.Msg === "This email address already registered."){
            setIsSubmitted(false);
            setShowAlertMsg(false);

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: "btn btn-outline-success m-3",
                    cancelButton: "btn btn-outline-danger"
                },
                buttonsStyling: false
            });
            swalWithBootstrapButtons.fire({
                title: "This email address already registered.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Login",
                cancelButtonText: "Create a new company",
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(`/Login?company=${response.data[0].Code}`)
                } else if (
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    navigate("/QuickCompanyRegistration");
                }
            });

        }else if( response.Msg === "You can create"){
            const responsedata = await WebService({
                endPoint: "registration/Company",
                body: data,
                dispatch,
            });
            const LoginData = data;
            LoginData.CompanyId = responsedata.CompanyId;
            if (LoginData.hasOwnProperty('CompanyName')) {
                delete LoginData.CompanyName;
            }
            if(LoginData.hasOwnProperty("Logo")){
                delete LoginData.Logo;
            }
            if(LoginData.hasOwnProperty("Phone")){
                delete LoginData.Phone;
            }
            if(LoginData.hasOwnProperty("Subscription")){
                delete LoginData.Subscription;
            }
            WebService({
            endPoint: "LogIn/Validate",
            method: "POST",
            body: LoginData,
            dispatch,
            }).then((res) => {
                dispatch(login(res));
                fetchAndSetCompanyInfo(responsedata);
            });
            setIsSubmitted(!isSubmitted);
            setShowAlertMsg(true);
        }
    }
    const cookies = new Cookies();
    // const [searchParams] = useSearchParams();
    const fetchAndSetCompanyInfo = (responsedata) => {
        const domain=window.location.href.toString().toLowerCase().replaceAll("https://","").replaceAll("http://","").replaceAll("index.html","").split("/#/")[0];
        let companyCode=responsedata.Code;
        // let companyCode=searchParams.get("company")??"";
        if(companyCode==="undefined") companyCode="";
        const endPoint = `CompanyProfile?select=companyinformation.*,staticcurrency.Symbol
        &expand=staticcurrency&where=${
            companyCode != ""
                ? `Code eq '${companyCode}'`
                : `LOWER(ifnull(DomainName,'')) eq '${domain}'`
            }`;
            WebService({
            endPoint,
            }).then((c) => {
            if (c.length > 0) {
                cookies.set("company", JSON.stringify(c[0]), { path: "/" });
            } 
            else {
                navigate("/");
            }
            });
        };

    const handleNavigateToEventPage = () => {
        navigate("/ManageEvents", { state: { openModal: "openModal" } });
    }

    return (
        <section className="loginMobile_view p-2 p-md-3">
            <div className="base-container d-flex align-items-center justify-content-center">
                <Container fluid>
                    <Row>
                        <div style={{fontWeight: "500", fontSize: "23.64px"}}>
                            <span><br />Company Registration  <br /></span>
                        </div>
                        
                        <hr className="mt-3"/>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="px-1 mt-4 p-2">
                                <div className="mt-xs-2">
                                    <Alert
                                        severity="info"
                                        icon={<AccountCircleIcon fontSize="inherit" />}
                                    >
                                        <AlertTitle>Register your company here</AlertTitle>
                                                Upload Your Logo
                                                <strong> — Click on upload</strong>
                                    </Alert>
                                </div>
                            </div>
                            <Form
                                defaultValues={{}}
                                onSubmit={onSubmit}
                                ref={frmRef}
                                validationSchema={schema}
                            >
                                <Row>
                                    <Col className="col-md-12 col-sm-12 d-flex justify-content-center pb-2">
                                        <ReactangleShapeOfUploadPicture
                                            UploadedFileName={setImageName}
                                            // onSubmittedClicked={isSubmitted} // for image reset
                                            endPointUrl={"upload/File"}
                                        >
                                        </ReactangleShapeOfUploadPicture>
                                    </Col>
                                    <Col md={12} sm={12}>
                                        <FormInputText
                                            label="Company Name"
                                            type="text"
                                            name="CompanyName"
                                            isRequired="true"
                                            placeholder="Company Name"
                                        />
                                    </Col>
                                    <Col md={12} sm={12}>
                                        <FormInputText
                                            label="Email"
                                            name="Email"
                                            type="email"
                                            isRequired="true"
                                            placeholder="Email"
                                            className="form-control"
                                        />
                                    </Col>
                                    <Col md={12} sm={12}>
                                        <FormInputText
                                            name="Password"
                                            type="password"
                                            label="Password"
                                            isRequired="true"
                                            placeholder="Choose Password"
                                            className="form-control"
                                        />
                                    </Col>
                                    <Col md={12} sm={12}>
                                        <FormInputText
                                            label="Contact No"
                                            name="Phone"
                                            type="text"
                                            isRequired="true"
                                            placeholder="Contact Number"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2 mb-5">
                                    <Col className="d-flex justify-content-end">
                                        <Button
                                            className="mx-2"
                                            type="reset"
                                            onClick={() => frmRef.current.fnReset()}
                                            variant="outline-danger"
                                        >
                                            Reset
                                        </Button>
                                        <Button
                                            type="Submit"
                                            variant="outline-primary">
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                                {
                                    showAlertMsg && (
                                        <>
                                            <Row style={{ marginTop: "-30px" }}>
                                                <Col className="d-flex justify-content-start">
                                                    <Alert severity="success" className="form-control">
                                                        Your company successfully registered.
                                                    </Alert>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2 mb-4">
                                                <Col className="d-flex justify-content-end mt-3">
                                                    <Button
                                                        variant="outline-primary"
                                                        onClick={handleNavigateToEventPage}
                                                    >
                                                        Add Event
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </>
                                    )
                                }
                            </Form>
                        </Col>
                        <Col md={6} className="d-none d-sm-block">
                            <div className="card1">
                                <div className="row px-3 justify-content-center mt-5 mb-5">
                                    <img
                                        src="../vistorSelfCheckin.png"
                                        className="bg-image"
                                        alt="loading..."
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>

    )
}

export default QuickCompanyRegistration