import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Container, IconButton, TableContainer, Tooltip } from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import TableComponent from "../../Services/TableComponent";
import { WebService } from "../../Services/WebService";
import { useDispatch } from "react-redux";
import { DateTime } from "luxon";
import { extend, map } from "underscore";
import { generateQueryStringByObject } from "../../Services/UtilityService";
import { StandardConst } from "../../Services/StandardConst";
import CheckIcon from "@mui/icons-material/Check";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SquareIcon from "@mui/icons-material/Square";
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, FormInputText } from "../Form";
import * as yup from "yup";
import { WSSuccessAlert } from "../../Services/WSAlert";

const TeamEntryDetails = forwardRef(({StatusId, callBackFunction}, ref) => {
  StatusId ??= 0;
  var dispatch = useDispatch();
  const RejectRemarksModelRef = useRef();
  //#region Parent Data Id store
  const [parentParameter, setParentParameter] = useState({ render: false });
  useImperativeHandle(ref, () => ({
    fetchByParent: (parameter) =>
      setParentParameter(extend(parameter, { render: true })),
  }));
  //#endregion
  //#region Fetch Entry Details
  const [records, setRecords] = useState([]);
  useEffect(() => {
    if (parentParameter.render == true) {
      setParentParameter((p) => ({ ...p, render: false }));
      const body = {
        Date: DateTime.fromISO(parentParameter.Date).toSQLDate(),
        EmployeeId: parentParameter.EmployeeId,
        ProjectId: parentParameter.ProjectId,
        StatusId
      };
      const endPoint = `Report/TimeSheet/Team/Entry?${generateQueryStringByObject(
        body
      )}`;
      const promissItem1 = WebService({ dispatch, endPoint }).then((r) => {
        setRecords(r);
      });
      Promise.all([promissItem1]);
    }
  }, [parentParameter.render]);
  //#endregion
  const columns = [
    { Value: "Details", Text: "Timesheet" },
    { Value: "TaskCategoryName", Text: "Task Type" },
    { Value: "TimeInHour", Text: "Hour(s)" },
    {
      Text: "Biling",
      cssClass: "text-left",
      style: { width: "150px" },
      render: (dr) => (
        <>
          <SquareIcon
            sx={{
              color: `${
                dr.CoreCode == "Billable"
                  ? StandardConst.wsBillableColor
                  : StandardConst.wsNonBillableColor
              }`,
              fontSize: 10,
            }}
          />
          <span>
            {" "}
            {dr.CoreCode == "Billable" ? "Billable" : "Non-Billable"}
          </span>
        </>
      ),
    },
    {
      Text: "Status",
      style: { padding: "5px", width: "70px" },
      cssClass: "text-center",
      isVisiable: StatusId===0,
      render: (dr) => 
        <div>
          {dr.StatusId == StandardConst.Status.Pending ? (
            <span className="badge rounded-pill text-bg-warning">Pending</span>
          ) : dr.StatusId == StandardConst.Status.Approved ? (
            <span className="badge rounded-pill text-bg-success">Approved</span>
          ) : dr.StatusId == StandardConst.Status.Reject ? (
            <span className="badge rounded-pill text-bg-danger">Rejected</span>
          ) : null}
        </div>
    },
    {
      Text: "Action",
      style: { padding: "5px", width: "70px" },
      cssClass: "text-center",
      isVisiable: StatusId===0,
      render: (dr) => <>{approvalStatus(dr)}</>,
    },
  ];

  const handleUpdateStatus = async (data) => {
    const newData = {
      StatusId: StandardConst.Status.Approved
    }
    await WebService({
      endPoint: `CommonUtility/timesheet?TimeSheetId=${data.TimeSheetId}`,
      method: "PUT",
      body: newData,
      dispatch,
    });
    WSSuccessAlert("Success", "Successfully Approved");
    callBackFunction();
  }

  const fnRejectRemarks = async (dr) => await RejectRemarksModelRef.current.openModal(dr)

  const approvalStatus = (dr) => (
    <>
      <div className="d-flex">
        <Tooltip title="Reject">
          <i className="fa fa-ban text-danger" onClick={() => fnRejectRemarks(dr)} style={{fontSize: "20px"}}></i>
        </Tooltip> &nbsp;
        <Tooltip title="Approve">
          <i className="fa fa-check-circle text-primary" onClick={() => handleUpdateStatus(dr)} style={{fontSize: "20px"}}></i>
        </Tooltip>
      </div>
    </>
  );
  return (
    <Container>
      <TableContainer>
        <TableComponent
          tableCss="bg-white p-0 mt-2 mb-2 m-0"
          data={records}
          columns={columns}
          pageSize={0}
          headerCss="ws-bg-grey-light-2"
        ></TableComponent>
      </TableContainer>
      <RejectRemarksModel ref={RejectRemarksModelRef} CallBackFunction={callBackFunction}/>
    </Container>
  );
});


const RejectRemarksModel = forwardRef(({CallBackFunction}, ref) => {

  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    setShow(false);
    setData({ Remarks: {} });
  }

  const [data, setData] = useState({Remarks: {} });

  useImperativeHandle(ref, () => ({
    openModal: async (data) => {
      setShow(true);
      setData({Remarks: { TimeSheetId: data.TimeSheetId}})
    },
  }));

  const onSubmit = async (data) => {
    const newData = {
      StatusId: StandardConst.Status.Reject,
      Remark: data.Remark
    }
    await WebService({
      endPoint: `CommonUtility/timesheet?TimeSheetId=${data.TimeSheetId}`,
      method: "PUT",
      body: newData,
      dispatch,
    });
    WSSuccessAlert("Success", "Successfully Rejected");
    handleClose();
    CallBackFunction();
  }

  const schema = yup.object().shape({
    Remark: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg)
  })

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Add Remarks
          </Modal.Title>
        </Modal.Header>
        <Form
        defaultValues={data.Remarks}
        onSubmit={onSubmit}
        validationSchema={schema}
        >
          <Modal.Body className="p-4">
            <div className="row">
              <div className="col-md-12">
                <FormInputText
                  name="Remark"
                  label="Remark"
                  as="textarea"
                  rows="2"
                  className="form-control"
                  isRequired="true"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="btnDepartmentSubmit"
              variant="outline-primary"
              type="submit"
            >
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
});

export default TeamEntryDetails;
