import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import { InputDatePicker, InputDropdown } from "../../Form";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { WebService } from "../../../Services/WebService";
import { useDispatch } from "react-redux";
import { DateTime } from "luxon";
import _, { extend, findWhere, groupBy, map, omit, reduce } from "underscore";
import TableComponent from "../../../Services/TableComponent";
import { PageInfo } from "../../PageInfo";
import TopbarComponent from "../../../Services/TopbarComponent";
import { NoRecordTemplate } from "../../../Services/TableComponent";
import { StandardConst } from "../../../Services/StandardConst";
import DisplayNumberFormatComponent, {
  DisNumFormatWoCurrency,
} from "../../../Services/DisplayNumberFormatComponent";
import WSCircularProgress from "../../../Services/WSCircularProgress";
import SquareIcon from "@mui/icons-material/Square";
import { Box, Button, ButtonGroup, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import WSLinearProgressColor from "../../../Services/WSLinearProgressColor";
import wsTheme from "../../../Services/WsMuiTheme";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import WSLinearProgressWithCap from "../../../Services/WSLinearProgressWithCap";
import WSLinearProgressTriColor from "../../../Services/WSLinearProgressTriColor";
const SalarySummary = () => {
  PageInfo({ pageTitle: "Salary Summary" });
  var dispatch = useDispatch();
  const roundOf = (num, position) => {
    return (
      Math.round((num + Number.EPSILON) * Math.pow(10, position)) /
      Math.pow(10, 2)
    );
  };
  const [filter, setFilter] = useState({
    FromDate: DateTime.local().toFormat("yyyy-MM-dd"),
    ToDate: DateTime.local().toFormat("yyyy-MM-dd"),
    team: 0,
  });
  const [teams, setTeams] = useState([]);
  const [projects, setProjects] = useState([]);
  const [reports, setReports] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const afterRender = useRef(false);
  useEffect(() => {
    if (!afterRender.current) {
      //Promise.all([fetchDesignation(), fetchProject()]);
      Promise.all([fetchReport()]);
    }
    afterRender.current = true;
  }, []);
  const fetchDesignation = async () => {
    const v1 = await WebService({
      dispatch,
      endPoint:
        "CommonUtility/static_project_roles?orderBy=DisplayDescription&select=DisplayDescription,ProjectRoleId",
    });
    if (v1.length > 0)
      setTeams(
        [{ value: 0, text: "Everyone" }].concat(
          map(v1, (m) => ({
            value: m.ProjectRoleId,
            text: m.DisplayDescription,
          }))
        )
      );
  };
  const fetchProject = async () => {
    const v1 = await WebService({
      dispatch,
      endPoint:
        "CommonUtility/projectmaster?orderBy=ProjectName&select=ProjectName,ProjectId,Symbol&expand=staticcurrency",
    });
    if (v1.length > 0) {
      setProjects(
        map(v1, (m) => ({
          value: m.ProjectId,
          text: m.ProjectName,
          Symbol: m.Symbol,
        }))
      );
      setFilter((filter) => ({ ...filter, project: v1[0].ProjectId }));
    }
  };
  const fetchReport = async (search = filter) => {
    // const team =
    //   search.team != 0
    //     ? `'${search.team}'`
    //     : teams
    //         .filter((f) => f.value != 0)
    //         .reduce((m, v) => `${m},'${v.value}'`, "")
    //         .substring(1);
    // if (teams.length > 0) {
    //   const endPoint = `Report/Timesheet/Team?startDate=${search.FromDate}&endDate=${filter.ToDate}&projectId=${filter.project}&roles=(${team})`;
    //   var results = await WebService({ dispatch, endPoint });

    const loggedEmpId=await WebService({dispatch,endPoint:"User/LoggedUser"}).then(r=>r.EmployeeId);
    const empDetails = WebService({
      dispatch,
      endPoint: `CommonUtility/employees?where=EmployeeId eq ${loggedEmpId}`,
    }).then((r) => {
      r=r.length>0?r[0]:{};
      setEmployeeDetails(r);
    });
    const reportSummary=WebService({
      dispatch,
      endPoint:`Payslip/FethWithCondition?where=EmployeeId eq ${loggedEmpId}`
    }).then(r=>{
      setReports(r.map(m=>({
        GrossSalary: parseFloat(m.GrossSalary),
        Deduction: parseFloat(m.TotalDeductions),
        TakeHome: parseFloat(m.NetSalary),
        Month: DateTime.fromISO(m.FromDate).setLocale().toFormat('MMM-yyyy'),
        PaySlipDate: DateTime.fromISO(m.CreatedAt).setLocale().toFormat("dd/MM/yyyy")
      })));
    });
    await Promise.all([empDetails, reportSummary]);
  };

  const [LeaveType, setLeaveType] = useState([
    { LeaveType: "Casual" },
    { LeaveType: "Unpaid" },
    { LeaveType: "Sick" },
    { LeaveType: "Leave x" },
    { LeaveType: "Leave Y" },
  ]);
  const [BarColors, setBarColors] = useState([
    {
      Item: 0,
      PrimaryColor: "#0AA2C0",
      SecondaryColor: "#9EEAF9",
    },
    {
      Item: 1,
      PrimaryColor: "#D63384",
      SecondaryColor: "#EFADCE",
    },
    {
      Item: 2,
      PrimaryColor: "#3D8BFD",
      SecondaryColor: "#9EC5FE",
    },
    {
      Item: 3,
      PrimaryColor: "#FD7E14",
      SecondaryColor: "#FECBA1",
    },
    {
      Item: 4,
      PrimaryColor: "#4DD4AC",
      SecondaryColor: "#A6E9D5",
    },
  ]);
  useEffect(() => {
    if (
      (filter.FromDate ?? "").toString().length > 0 &&
      (filter.ToDate ?? "").toString().length > 0 &&
      teams.length > 0 &&
      (filter.project ?? 0) != 0
    ) {
      fetchReport();
    }
  }, [filter, teams]);
  const [budgetVisiable, setBudgetVisiable] = useState(false);
  useEffect(() => {
    if ((filter?.project ?? 0) !== 0) {
      var p1 = WebService({
        dispatch,
        endPoint: `Report/Timesheet/Team/BudgetVisiable?ProjectId=${filter.project}`,
      }).then((r) => {
        setBudgetVisiable(r);
      });
      Promise.all([p1]);
    }
  }, [filter.project]);
  //#region Time Frame
  const [timeFrame, setTimeFrame] = useState(StandardConst.timeFrame.Week);
  const setDateByTimeFrame = (fromDate) => {
    switch (timeFrame) {
      //#region Week
      case StandardConst.timeFrame.Week: {
        setFilter((filter) => ({
          ...filter,
          FromDate: fromDate.toSQLDate(),
          ToDate: fromDate.plus({ day: 6 }).toSQLDate(),
        }));
        break;
      }
      //#endregion
      //#region Semimonth
      case StandardConst.timeFrame.Semimonth: {
        setFilter((filter) => ({
          ...filter,
          FromDate: fromDate.toSQLDate(),
          ToDate: (fromDate.day > 15
            ? fromDate.endOf("month")
            : fromDate.plus({ day: 14 })
          ).toSQLDate(),
        }));
        break;
      }
      //#endregion
      //#region Month
      case StandardConst.timeFrame.Month: {
        setFilter((filter) => ({
          ...filter,
          FromDate: fromDate.toSQLDate(),
          ToDate: fromDate.endOf("month").toSQLDate(),
        }));
        break;
      }
      //#endregion
      //#region Quarter
      case StandardConst.timeFrame.Quarter: {
        setFilter((filter) => ({
          ...filter,
          FromDate: fromDate.toSQLDate(),
          ToDate: fromDate.endOf("quarter").toSQLDate(),
        }));
        break;
      }
      //#endregion
      //#region Year
      case StandardConst.timeFrame.Year: {
        setFilter((filter) => ({
          ...filter,
          FromDate: fromDate.toSQLDate(),
          ToDate: fromDate.endOf("year").toSQLDate(),
        }));
        break;
      }
      //#endregion
    }
  };
  useEffect(() => {
    switch (timeFrame) {
      //#region Week
      case StandardConst.timeFrame.Week: {
        const fromDate = DateTime.now().minus({
          day: DateTime.now().weekday - 1,
        });
        setDateByTimeFrame(fromDate);
        break;
      }
      //#endregion
      //#region Semimonth
      case StandardConst.timeFrame.Semimonth: {
        const currentDay = DateTime.now().day;
        let fromDate = DateTime.now().startOf("month");
        if (currentDay > 15) fromDate = fromDate.plus({ day: 15 });
        setDateByTimeFrame(fromDate);
        break;
      }
      //#endregion
      //#region Month
      case StandardConst.timeFrame.Month: {
        setDateByTimeFrame(DateTime.now().startOf("month"));
        break;
      }
      //#endregion
      //#region Quarter
      case StandardConst.timeFrame.Quarter: {
        setDateByTimeFrame(DateTime.local().startOf("quarter"));
        break;
      }
      //#endregion
      //#region Year
      case StandardConst.timeFrame.Year: {
        setDateByTimeFrame(DateTime.now().startOf("year"));
        break;
      }
      //#endregion
    }
  }, [timeFrame]);
  const fnPrevious = () => {
    switch (timeFrame) {
      //#region Week
      case StandardConst.timeFrame.Week: {
        const fromDate = DateTime.fromSQL(filter.FromDate).minus({ day: 7 });
        setDateByTimeFrame(fromDate);
        break;
      }
      //#endregion
      //#region Semimonth
      case StandardConst.timeFrame.Semimonth: {
        let fromDate = DateTime.fromSQL(filter.FromDate);
        fromDate =
          fromDate.day > 15
            ? fromDate.startOf("month")
            : fromDate.minus({ month: 1 }).plus({ day: 15 });
        setDateByTimeFrame(fromDate);
        break;
      }
      //#endregion
      //#region Month
      case StandardConst.timeFrame.Month: {
        setDateByTimeFrame(
          DateTime.fromSQL(filter.FromDate).minus({ month: 1 })
        );
        break;
      }
      //#endregion
      //#region Quarter
      case StandardConst.timeFrame.Quarter: {
        setDateByTimeFrame(
          DateTime.fromSQL(filter.FromDate).minus({ month: 3 })
        );
        break;
      }
      //#endregion
      //#region Year
      case StandardConst.timeFrame.Year: {
        setDateByTimeFrame(
          DateTime.fromSQL(filter.FromDate).minus({ year: 1 })
        );
        break;
      }
      //#endregion
    }
  };
  const fnNext = () => {
    switch (timeFrame) {
      //#region Week
      case StandardConst.timeFrame.Week: {
        const fromDate = DateTime.fromSQL(filter.FromDate).plus({ day: 7 });
        setDateByTimeFrame(fromDate);
        break;
      }
      //#endregion
      //#region Semimonth
      case StandardConst.timeFrame.Semimonth: {
        let fromDate = DateTime.fromSQL(filter.FromDate);
        fromDate =
          fromDate.day > 15
            ? fromDate.plus({ month: 1 }).startOf("month")
            : fromDate.plus({ day: 15 });
        setDateByTimeFrame(fromDate);
        break;
      }
      //#endregion
      //#region Month
      case StandardConst.timeFrame.Month: {
        setDateByTimeFrame(
          DateTime.fromSQL(filter.FromDate).plus({ month: 1 })
        );
        break;
      }
      //#endregion
      //#region Quarter
      case StandardConst.timeFrame.Quarter: {
        setDateByTimeFrame(
          DateTime.fromSQL(filter.FromDate).plus({ month: 3 })
        );
        break;
      }
      //#endregion
      //#region Year
      case StandardConst.timeFrame.Year: {
        setDateByTimeFrame(DateTime.fromSQL(filter.FromDate).plus({ year: 1 }));
        break;
      }
      //#endregion
    }
  };
  //#endregion
  var filterableViewComponent = (
    <>
      <Row className="mt-3 mx-4">
        <Col className="d-flex flex-row align-items-center p-0" md={9}>
          <ButtonGroup
            size="small"
            variant="outlined"
            aria-label="small button group"
          >
            <Button
              className={
                timeFrame === StandardConst.timeFrame.Custom ? "hide" : ""
              }
              onClick={fnPrevious}
            >
              <ArrowBackIcon />
            </Button>
            <Button
              className={
                timeFrame === StandardConst.timeFrame.Custom ? "hide" : ""
              }
              onClick={fnNext}
            >
              <ArrowForwardIcon />
            </Button>
          </ButtonGroup>
          <span className="mx-2 h4">
            <strong>
              This&nbsp;
              {`${timeFrame}: `}
            </strong>
            {`${DateTime.fromSQL(filter.FromDate).toFormat(
              "dd MMM"
            )} - ${DateTime.fromSQL(filter.ToDate).toFormat("dd MMM yyyy")}`}
          </span>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between mx-4 pt-2">
        <Col md={8}>
          <Row>
            <Col md={2} className="ps-0">
              <InputDropdown
                setValue={setTimeFrame}
                ddOpt={map(StandardConst.timeFrame, (text, _) => ({
                  value: text,
                  text,
                }))}
              />
            </Col>
            <Col
              md={2}
              className={
                timeFrame === StandardConst.timeFrame.Custom ? "p-0 " : "d-none"
              }
            >
              <InputDatePicker
                labelCss="text-center"
                value={filter.FromDate}
                setValue={(v) => setFilter({ ...filter, FromDate: v })}
                max={(filter.ToDate ?? "") == "" ? undefined : filter.ToDate}
              />
            </Col>
            <Col
              md={2}
              className={
                timeFrame === StandardConst.timeFrame.Custom ? "" : "d-none"
              }
            >
              <InputDatePicker
                labelCss="text-center"
                value={filter.ToDate}
                setValue={(v) => setFilter({ ...filter, ToDate: v })}
                min={
                  (filter.FromDate ?? "") == "" ? undefined : filter.FromDate
                }
              />
            </Col>
          </Row>
        </Col>
        <Col md={4} className="p-0">
          <Row>
            <Col
              md={6}
              className={
                timeFrame === StandardConst.timeFrame.Custom ? "p-0" : "p-0"
              }
            >
              <InputDropdown
                labelCss="text-center"
                className="form-control"
                ddOpt={teams || []}
                value={filter.team}
                setValue={(v) => setFilter({ ...filter, team: v })}
              />
            </Col>
            <Col md={6}>
              <InputDropdown
                labelCss="text-center"
                className="form-control"
                ddOpt={projects || []}
                value={filter.project}
                setValue={(v) => setFilter({ ...filter, project: parseInt(v) })}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
  const totalTakHome=()=>reports.reduce((m,v)=>(m+v.TakeHome),0);
  const totalDeduction=()=>reports.reduce((m,v)=>m+v.Deduction,0);
  const totalGrossSalary=()=>reports.reduce((m,v)=>m+v.GrossSalary,0);
  var reportSummaryComponent = (
    <>
      {/* <hr className="mx-4 p-0 my-3"></hr> */}
      <Row className="px-4 m-0 mt-3">
        <Col md={4} className="d-flex align-items-center">
          <div>
            <div className="d-flex flex-rows align-items-center">
              <div>
                <img
                  src={
                    (employeeDetails?.ProfileImage??"") != ""
                      ? `${StandardConst.apiBaseUrl}/uploads/${employeeDetails.ProfileImage}`
                      : `${StandardConst.apiBaseUrl}/uploads/user_placeholder.jpg`
                  }
                  alt=""
                  class="avatar-md rounded-circle img-thumbnail mt-1"
                />
              </div>
              <div class="flex-1 ms-2">
                <h4 class="mb-1">{employeeDetails?.FullName}</h4>
                <span>
                  <small>{employeeDetails?.Designation}</small>
                </span>
              </div>
            </div>
          </div>
        </Col>
        <Col
          className="d-flex justify-content-center justify-content-md-start"
          md={2}
        >
          <Box sx={{ height: 70, width: 70 }}>
            <CircularProgressbar
              value={(totalTakHome()/totalGrossSalary())*100}
              strokeWidth={50}
              styles={buildStyles({
                strokeLinecap: "butt",
                textColor: "red",
                pathColor: StandardConst.Colors.success,
                trailColor: StandardConst.Colors.warning,
              })}
            />
          </Box>
        </Col>
        <Col className="d-flex flex-row  justify-content-between" md={6}>
          <div className="d-flex align-items-center">
            <div className="me-2">
              <Box
                sx={{
                  backgroundColor: `${StandardConst.Colors.success}`,
                  width: 3,
                  height: 45,
                }}
              />
            </div>
            <div className="d-flex flex-column text-left">
              <div className="small">Take Home</div>
              <div className="h5">
                <strong>
                  <DisplayNumberFormatComponent Number={totalTakHome()} />
                </strong>
              </div>{" "}
            </div>
          </div>

          <div className="d-flex align-items-center">
            <div className="me-2">
              <Box
                sx={{
                  backgroundColor: `${StandardConst.Colors.warning}`,
                  width: 3,
                  height: 45,
                }}
              />
            </div>
            <div className="d-flex flex-column text-left">
              <div className="small">Deduction</div>
              <div className="h5">
                <strong>
                  <DisplayNumberFormatComponent Number={totalDeduction()} />
                </strong>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center d-none d-md-inline-flex">
            <div className="me-2">
              <Box
                sx={{
                  backgroundColor: `${StandardConst.Colors.grey}`,
                  width: 3,
                  height: 45,
                }}
              />
            </div>
            <div className="d-flex flex-column text-left">
              <div className="small">Gross Salary</div>
              <div className="h5">
                <strong>
                  <DisplayNumberFormatComponent Number={totalGrossSalary()} />
                </strong>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
  var reportInGridComponent = (
    <>
      <Row>
        <Col>
          <TableComponent
            data={reports}
            columns={[
              {
                Text: "Month",
                style: { "text-align": "center" },
                render: (dr) => (
                  <>
                    <span>{dr.Month}</span>
                  </>
                ),
              },
              {
                Text: "PaySlip Date",
                render: (dr) => <span>{dr.PaySlipDate}</span>,
              },
              {
                Text: "Take Home",
                render: (dr) => (
                  <>
                    <DisplayNumberFormatComponent Number={dr.TakeHome} />
                  </>
                ),
                cssClass: "text-center",
              },

              {
                Text: "Deduction",
                render: (dr) => (
                  <>
                    {" "}
                    <DisplayNumberFormatComponent Number={dr.Deduction} />
                  </>
                ),
                cssClass: "text-center",
              },
              // {
              //   Text: "Deduction",
              //   render: (dr) => (
              //     <>
              //       {" "}
              //       <DisplayNumberFormatComponent
              //         Number={dr.GrossSalary - (dr.Tax + dr.TakeHome)}
              //       />
              //     </>
              //   ),
              //   cssClass: "text-center",
              // },
              {
                Text: "",
                cssClass: "w-25",
                render: (dr) => (
                  <>
                    <span style={{ height: "26px", display: "block" }}>
                      <WSLinearProgressTriColor
                        FirstValue={(dr.TakeHome / dr.GrossSalary) * 100}
                        SecondValue={(dr.Deduction / dr.GrossSalary) * 100}
                        FirstValueColor={StandardConst.Colors.success}
                        SecondValueColor={StandardConst.Colors.warning}
                        Capacity={false}
                      />
                    </span>
                  </>
                ),
              },
              {
                Text: "Gross Salary",
                render: (dr) => (
                  <>
                    {" "}
                    <DisplayNumberFormatComponent Number={dr.GrossSalary} />
                  </>
                ),
                cssClass: "text-center",
              },
            ]}
            noRecordCss="p-0"
            headerCss="table-header-grey"
            noRecordFound={
              <NoRecordTemplate
                headerValue="No results found"
                subHeaderValue="Looks like you don't have any results for the filter applied!!!"
                imageUrl="uploads/Static/no-records-j.png"
              />
            }
            IsAddButtonVisible={false}
          ></TableComponent>
        </Col>
      </Row>
    </>
  );
  const [bData, setBData] = React.useState([
    {
      title: "Payroll Management",
      hrefLink: "#",
    },
    {
      title: "Salary Summary",
      hrefLink: "#",
    },
  ]);

  const MasterPageName = "Salary Summary";
  return (
    <>
      <Container fluid className="base-container">
        <TopbarComponent bData={bData} HeadingText={MasterPageName} />
        <div elevation={0} className="p-3 bg-primary"></div>
        {/* <div>{filterableViewComponent}</div> */}

        {reportSummaryComponent}
        <hr className="mx-2 p-0 m-0 mt-3 mb-1"></hr>
        <div className="mx-2">{reportInGridComponent}</div>
      </Container>
    </>
  );
};

export default SalarySummary;
