import Swal from "sweetalert2";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText, FormInputDropdown, FormAutoCompleteDropdownCard } from "../Form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
import { each, filter, map } from "underscore";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import { dataClone } from "../../Services/UtilityService";

const requiredMessage = StandardConst.requiredMessage;
const { forwardRef, useState, useImperativeHandle } = React;
const AddEditAssignTeam = (prop, ref) => {
  const [formats, setFormats] = React.useState([]);
  const [maxHour] = useState(8);
  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
  };
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const [selectedCurrencyData, setSelectedCurrencyData] = useState(null);

  useImperativeHandle(ref, () => ({
    openModal: async (id) => {
      setShow(true);
      if(id === 0){
          await fetchTeam(id);
      }else {
        setShow(false);
        await fetchTeam(id);
        setShow(true);
      }
    },
  }));

  const [EmployeesList, setEmployeesList] = useState([]);

  const fetchTeam = async(id) => {
    const data = await WebService({
      endPoint: `Team/${id || 0}`,
      dispatch,
    });
    const employeeData = await WebService({
      endPoint: `Visitor/fetchAllEmployees`,
      dispatch,
    });

    const list = employeeData?.filter((r) => r.FullName !== null)?.map((res) => {
      return {
          EmployeeId: res.EmployeeId,
          heading: res.FullName, 
          description: res.Designation, 
          avatar: `${StandardConst.apiBaseUrl}/uploads/${res.ProfileImage}`
        }
    });
    setEmployeesList(list);
    
    data.Details.EmployeeId = [list?.find(item => item?.EmployeeId === data?.Details?.EmployeeId)];

    setData(dataClone(data));

      //#region Default Monday to Friday select
    if (
      filter(data.WorkingDays, (f) => (f?.ProjectTeamId ?? 0) !== 0).length <
      1
    ) {
      each(data.WorkingDays, (e, i) => {
        if (i < 5) e.ProjectTeamId = -1;
      });
    }
    //#endregion
    setFormats(
      map(
        filter(data.WorkingDays, (f) => (f?.ProjectTeamId ?? 0) !== 0),
        (m) => m.Day
      )
    );
    
    setSelectedCurrencyData(data?.Projects.find(v => parseInt(v.ProjectId) === parseInt(data?.Details.ProjectId)));
  }
  const handleClose = () => {
    setShow(false)
    setData({});
    setSelectedCurrencyData(null);
  };

  const schema = yup
    .object()
    .shape({
      ProjectId: yup.string().trim().required(requiredMessage),
      EmployeeId: yup
        .array()
        .of(
          yup.object().shape({
            heading: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
          })
        )
        .typeError(StandardConst.ValidationMessages.RequiredFieldMsg)
        .min(1, StandardConst.ValidationMessages.RequiredFieldMsg),
      ProjectRoleId: yup.string().trim().required(requiredMessage),
      IsBillable: yup.string().trim().required(requiredMessage),
      Rate: yup.number().typeError(requiredMessage).required(requiredMessage),
      BillingMode: yup.string().trim().required(requiredMessage),
      TeamMemberStartDate: yup.string().trim().required(requiredMessage),
      // TeamMemberEndDate: yup.string().trim().required(requiredMessage),
      WorkingHour: yup
        .number()
        .label("Time in Hour")
        .typeError(requiredMessage)
        .required(requiredMessage)
        .min(0.01)
        .when([], (_, passSchema) => passSchema.max(maxHour)),
    })
    .required();

  const onSubmit = async (data) => {
    data.EmployeeId = data.EmployeeId[0].EmployeeId;
    if (formats.length < 1) {
      Swal.fire({
        title: "Error",
        text: "Please choose at least one working day",
        icon: "error",
        customClass: {
          confirmButton: "mx-1 btn btn-outline-danger",
        },
        buttonsStyling: false,
      });
      // alert("Please choose at least one working day");
      return;
    }
    data.WeekDays = map(formats, (m) => ({ Day: m }));
    var opt = {
      endPoint: "Team",
      dispatch,
      body: data,
    };
    if (data.ProjectTeamId === undefined) {
      await WebService(opt);
    } else {
      opt.method = "PUT";
      opt.endPoint += `/${data.ProjectTeamId}`;
      await WebService(opt);
      await WebService({
        endPoint: "Team",
        dispatch,
      });
    }

    handleClose();
    prop.callBackEvent();
  };
  const WsToggleButton = styled(ToggleButton)(({ selectedcolor }) => ({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: selectedcolor,
    },
  }));

  const formBody = (
    <>
      <div className="row">
        <div className="col-md-12">
          <FormInputDropdown
            name="ProjectId"
            ddOpt={map(data?.Projects ?? [], (m) => ({
              value: m.ProjectId,

              text: m.ProjectName,
            }))}
            label="Select Project"
            isRequired="true"
            setValue={(val) => setSelectedCurrencyData(data?.Projects.find(v => parseInt(v.ProjectId) === parseInt(val)))}
          ></FormInputDropdown>
          <FormAutoCompleteDropdownCard
            name="EmployeeId"
            data={EmployeesList}
            label="Select Employee"
            optionText="heading"
            value={EmployeesList?.find(item => item?.EmployeeId === data?.Details?.EmployeeId[0]?.EmployeeId)}
            isRequired="true"
          />
        </div>
        <div className="col-md-12">
          <FormInputDropdown
            name="ProjectRoleId"
            ddOpt={map(data?.Roles ?? [], (m) => ({
              value: m.ProjectRoleId,
              text: m.DisplayDescription,
            }))}
            label="Project Role"
            isRequired="true"
          ></FormInputDropdown>
        </div>
        <div className="col-md-12">
          <FormInputDropdown
            name="IsBillable"
            ddOpt={StandardConst.billTypes}
            label="Billable/Non-Billable"
            isRequired="true"
          ></FormInputDropdown>
        </div>
        <div className="col-md-12">
          <FormInputDropdown
            name="BillingMode"
            setValue={(v) =>
              setData((d) => ({
                ...d,
                Details: { ...d.Details, BillingMode: v },
              }))
            }
            ddOpt={data?.BilingMode ?? []}
            label="Fixed/Per Hour"
            isRequired="true"
          ></FormInputDropdown>
        </div>
        {/* <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 d-flex justify-content-start align-items-center">
              <label className="text-center">{`${
              data?.Details?.BillingMode ??
              ((data?.BilingMode ?? []).length > 0
                ? data.BilingMode[0].value
                : "")
            } Rate`}<span className="text-danger">＊</span></label>
            </div>
            <div className="col-md-1 d-flex justify-content-end align-items-center p-1">
              {projectSymbol}
            </div>
            <div className="col-md-7"  style={{paddingLeft: "0px"}}>
              <FormInputText
                name="Rate"
                type="text"
                isRequired="true"
              />
            </div>
          </div>
        </div> */}

        <div className="col-md-12">
          <div className="row">
            <div className="col-md-5 d-flex justify-content-start align-items-center">
              <label className="text-center">{`${
              data?.Details?.BillingMode ??
              ((data?.BilingMode ?? []).length > 0
                ? data.BilingMode[0].value
                : "")
            } Rate`}<span className="text-danger">＊</span></label>
            </div>
            <div className="col-md-7"  style={{paddingLeft: "0px"}}>

              <FormInputText
                locale={selectedCurrencyData?.LocaleCode}
                currencyCode={selectedCurrencyData?.CurrencyCode}
                name="Rate"
                type="currency"
                className="form-control"
                value={data?.Details?.Rate}
                predefaultValue={true}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <FormInputText
            label="Working Hours/Day"
            name="WorkingHour"
            type="text"
            isRequired="true"
          />
        </div>

        <div className="col-md-12">
          <FormInputText
            label="Start Date"
            name="TeamMemberStartDate"
            type="date"
            isRequired="true"
          />
        </div>
        <div className="col-md-12">
          <FormInputText
            label="End Date"
            name="TeamMemberEndDate"
            type="date"
            // isRequired="true"
          />
        </div>
        <div className="col-md-12">
          <FormInputDropdown
            label="Status"
            name="Status"
            ddOpt={StandardConst.StatusDropdown}
            isRequired="true"
          />
        </div>
        <div className="col-md-12 pt-2">
          <div className="float-start col-md-5 text-wrap">
            <label className="form-label">
              <div className="d-flex justify-content-start  ">
                <div style={{ fontFamily: "sans-serif", fontSize: "14px" }}>
                  Working Days
                </div>
                <div className="text-danger">＊</div>
              </div>
            </label>
          </div>
          <div className="float-end col-md-7">
            <ToggleButtonGroup
              color="primary"
              size="small"
              value={formats}
              onChange={handleFormat}
              aria-label="text formatting"
            >
              {data?.WorkingDays?.map((wd) => {
                return (
                  <WsToggleButton
                    selectedcolor={StandardConst.wsPrimaryColor}
                    value={wd.Day}
                    aria-label="bold"
                    style={{
                      marginLeft: "3px",
                      height: "35px",
                      width: "35px",
                    }}
                  >
                    {wd.DisplayName}
                  </WsToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </div>
        </div>
      </div>
    </>
  );
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="px-2">
          {(data?.Details?.ProjectTeamId || 0) === 0
            ? "Add Project Team Member"
            : "Edit Project Team Member"}
        </Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={data.Details}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        <Modal.Body className="p-4">{formBody}</Modal.Body>
        <Modal.Footer>
          <Button id="btnRoleSubmit" variant="outline-primary" type="submit">
            {" "}
            {(data?.Details?.ProjectTeamId || 0) === 0
              ? "Save Member"
              : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default forwardRef(AddEditAssignTeam);
