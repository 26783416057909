import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FormInputText, Form, FormInputDropdown } from "../Form";
import React, { useEffect } from "react";
import { WebService } from "../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { ReactangleShapeOfUploadPicture } from "../../Services/ImageUploader";
import { StandardConst } from "../../Services/StandardConst";
import { format } from "date-fns";
import { Autocomplete, TextField } from "@mui/material";

const { forwardRef, useState, useImperativeHandle } = React;

const CreateEvent = (prop, ref) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [data, setData] = useState({ EventMaster: {} });
    const [logoInSrc, setLogoInSrc] = useState("");
    const [showSpecificTimeInput, setShowSpecificTimeInput] = useState(false);
    const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const [imageName, setImageName] = useState("");
    const [eventVisitorTypeList, setEventVisitorTypeList] = useState([]);
    const [selectedVisitorTypes, setSelectedVisitorTypes] = useState([]);
    const [autoErrorShow, setAutoErrorShow] = useState(false);

    useEffect(() => {
        getEventVisitorTypeList();
    }, []);

    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            if(id > 0){
                await getThisEventVisitorTypeListForSelection(id, eventVisitorTypeList);
                const fetchData = await fetchEditValue(id);
                setLogoInSrc(fetchData.EventMaster.EventLogo)
            }
            setShow(true);
        },
    }));

    const getEventVisitorTypeList = async () => {
        const EventVisitorList = await WebService({
            endPoint: `Event/getEventVisitorTypeForManageEvent?IsEventVisitorType=${StandardConst.YesNo.Yes}&CompanyId=${StandardConst.YesNo.Yes}`,
            dispatch,
        });

        const EventVisitorListForDropdown = EventVisitorList.map(visitorType => ({
            text: visitorType.TypeName,
            value: visitorType.VisitorTypeId
          }));

        setEventVisitorTypeList(EventVisitorListForDropdown);
        return EventVisitorListForDropdown;
    }


    const getThisEventVisitorTypeListForSelection = async (EventId, selectedVisitorData) => {
        const selectedArray = [];
        const selectedEventVisitorList = await WebService({
            endPoint: `CommonUtility/Edit/VisitorTypeBadge?EventId=${EventId}`,
            dispatch,
        });

        selectedEventVisitorList.forEach(selectedEventVisitor => {
            const matchingObject = selectedVisitorData.find(item => item.value === selectedEventVisitor.VisitorTypeId);
            if (matchingObject) {
                selectedArray.push(matchingObject);
            }
        });

        setSelectedVisitorTypes(selectedArray);
    }


    const fetchEditValue = async(id) => {
        const data = {
            EventMaster: await WebService({
                endPoint: `CommonUtility/Edit/events?EventId=${id || 0}`,
                dispatch,
            }).then((c) => (c.length > 0 ? c[0] : {})),
        };
        if(data.EventMaster.EventTime === StandardConst.EventTime[1].value){
            setShowSpecificTimeInput(true);
        }else {
            setShowSpecificTimeInput(false);
        }
            setData(data);
            return data;
        }

    const requiredMessage = "This is a required field";
    const schema = yup
        .object()
        .shape({
            EventName: yup.string().trim().required(requiredMessage),
            EventPlace: yup.string().trim().required(requiredMessage),
            EventToDate: yup
                .date()
                .typeError(requiredMessage)
                .required(requiredMessage),
            EventFromDate: yup
                .date()
                .typeError(requiredMessage)
                .required(requiredMessage),
            EventTime: yup.number().required(requiredMessage),
            EventFromTime: showSpecificTimeInput ? yup
                .mixed()
                .test('is-time-valid', 'Time is not valid', (value, {parent}) => {
                    if(value){
                        return true;
                    }else {
                        return false;
                    }
                })
                .typeError(requiredMessage)
                .required(requiredMessage)
                : null,
            EventToTime: showSpecificTimeInput ? yup
                .mixed()
                .test('is-time-valid', 'To Time cannot be less than from time', (value, { parent }) => {
                    if (value) {
                        if(parent.EventFromTime === "00:00:00"){
                            parent.EventFromTime = "00:00"
                        }
                        const EventFromTime = yup.string().required().matches(/^([01]\d|2[0-3]):([0-5]\d)$/, 'Invalid time format').validateSync(parent.EventFromTime);
                        let isValidTimeFormat = yup.string().required().matches(/^([01]\d|2[0-3]):([0-5]\d)$/, 'Invalid time format').isValidSync(value);
                        if (isValidTimeFormat && value <= EventFromTime) {
                            const eventToDate = format(new Date(parent.EventToDate), "dd-MM-yyyy");
                            const eventFromDate = format(new Date(parent.EventFromDate), "dd-MM-yyyy");
                            if (eventToDate && eventFromDate && eventToDate === eventFromDate) {
                                return false;
                            }
                        }
                        return true;
                    } else {
                        return false;
                    }
                })
                .typeError(requiredMessage)
                .required(requiredMessage) 
                : null,
        })
        .required();

    const handleClose = () => {
        setShow(false);
        setLogoInSrc("");
        setSelectedVisitorTypes([]);
        setData({ EventMaster: {} });
        setAutoErrorShow(false);
    }

    const Roles = useSelector((s) => s.auth.AssignRole ?? {});
    const EmployeeId = useSelector((s) => s.auth.LoggedUser ?? {});
    const UserId = useSelector((s) => s.auth.LoggedCustomer ?? {});

    const onSubmit = async (data) => {
        if(selectedVisitorTypes.length > 0) {
            setAutoErrorShow(false);
            let EventId = data.EventId === undefined ? null : data.EventId

            data.EventLogo = imageName;
            data.EventToDate = format(new Date(data.EventToDate), "yyyy-MM-dd");
            data.EventFromDate = format(new Date(data.EventFromDate), "yyyy-MM-dd");
            data.CompanyId = CompanyInfo.CompanyId;
            if(data.EventTime === StandardConst.EventTime[0].value){
                data.EventFromTime = "00:00";
                data.EventToTime = "23:59";
            }else {}
            if (data.EventId === undefined) {
                const EventData = await WebService({
                    endPoint: "CommonUtility/events",
                    body: data,
                    dispatch,
                });

                EventId = EventData;

                if(data.Attribute || data.Description){
                    const propertiesData = {
                        EventId: EventData,
                        ...data
                    }
                    await WebService({
                        endPoint: "CommonUtility/eventattributes",
                        body: propertiesData,
                        dispatch,
                    });
                }

                if(Roles?.some(element => element === StandardConst.SystemRole.EventCoordinator)){
                    const propertiesDataForAddEventCoordinator = {
                        EventId: EventData,
                        EmployeeId: EmployeeId ? EmployeeId : null,
                        UserId: EmployeeId ? null : UserId ? UserId : null,
                    }
                    await WebService({
                        endPoint: "CommonUtility/eventcoordinators",
                        method: "POST",
                        body: propertiesDataForAddEventCoordinator,
                        dispatch,
                    });
                }
            } else {
                await WebService({
                    endPoint: `CommonUtility/events?EventId=${data.EventId}`,
                    method: "PUT",
                    body: data,
                    dispatch,
                });

                if(data.Attribute || data.Description){
                    const propertiesData = {
                        EventId: data.EventId,
                        ...data
                    }
                    
                    await WebService({
                        endPoint: `CommonUtility/eventattributes?EventId=${data.EventId}`,
                        body: propertiesData,
                        dispatch,
                    });
                }
            }

        // This is for association events in visitor types
            await WebService({
                endPoint: `Event/EventAssociateInVisitorType?EventId=${EventId}`,
                body: selectedVisitorTypes,
                method: "POST",
                dispatch,
            });
            
            handleClose();
            prop.callBackEvent();
        }else{
            setAutoErrorShow(true);
        }
    };

    const handleKnowValueOfEventTime = (currentValue) => {
        if(Number(currentValue) === StandardConst.EventTime[1].value){
            setShowSpecificTimeInput(true);
        }else {
            setShowSpecificTimeInput(false);
        }
    }

    const [eventFromDate, setEventFromDate] = useState(new Date().toISOString().split('T')[0]);

    // Function to handle change in selected visitor types
    const handleVisitorTypesChange = (event, selectedOptions) => {
        (selectedOptions.length === 0) ? setAutoErrorShow(true) : setAutoErrorShow(false);
        setSelectedVisitorTypes(selectedOptions);
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                {(data && data.EventMaster && data.EventMaster.EventId || 0) === 0
            ? "Add Event"
            : "Edit Event"
            }
                </Modal.Title>
            </Modal.Header>
            <Form
                defaultValues={data.EventMaster}
                onSubmit={onSubmit}
                validationSchema={schema}
            >
                <Modal.Body className="p-4">
                <div className="row">
                <div className="col-md-12 m-auto mb-4 d-flex justify-content-center">
                    <ReactangleShapeOfUploadPicture
                        UploadedFileName={setImageName}
                        ShowProfilePicture={logoInSrc ? logoInSrc : "placeholder-image-default.png"}
                        ShowSrcUrl={`${StandardConst.apiBaseUrl}/uploads`}
                        endPointUrl={"upload/File"}
                    >
                    </ReactangleShapeOfUploadPicture>
                </div>
                    <div className="col-md-12">
                    <FormInputText
                        label="Event Name"
                        name="EventName"
                        type="text"
                        isRequired="true"
                    />
                    </div>
                    <div className="col-md-12">
                    <FormInputText
                        label="Event Place"
                        name="EventPlace"
                        type="text"
                        isRequired="true"
                    />
                    </div>
                    <div className="col-md-12">
                    <FormInputText
                        label="Event From Date"
                        name="EventFromDate"
                        type="date"
                        isRequired="true"
                        min={new Date().toISOString().split('T')[0]}
                        max="2999-12-31"
                        setValue={(v) => setEventFromDate(v)}
                    />
                    </div>
                    <div className="col-md-12">
                    <FormInputText
                        label="Event To Date"
                        name="EventToDate"
                        type="date"
                        isRequired="true"
                        min={eventFromDate}
                        max="2999-12-31"
                    />
                    </div>
                    <div className="col-md-12">
                        <FormInputDropdown
                            name="EventTime"
                            ddOpt={StandardConst.EventTime}
                            label="Event Time"
                            isRequired="true"
                            setValue={(v) => handleKnowValueOfEventTime(v)}
                        />
                    </div>

                        {
                            showSpecificTimeInput && (
                                <>
                                    <div className="col-md-12">
                                        <FormInputText
                                            label="Event From Time"
                                            name="EventFromTime"
                                            type="time"
                                            isRequired="true"
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <FormInputText
                                            label="Event To Time"
                                            name="EventToTime"
                                            type="time"
                                            isRequired="true"
                                        />
                                    </div>
                                </>
                            )
                        }

                    <div className="col-md-12 mt-2">
                        <Autocomplete
                            multiple
                            id="tags-outlined"
                            options={eventVisitorTypeList}
                            getOptionLabel={(option) => option.text}
                            defaultValue={selectedVisitorTypes}
                            onChange={handleVisitorTypesChange}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Visitor Type"
                                    error={autoErrorShow} // Set error state of TextField
                                    helperText={(autoErrorShow) && "Atleast one visitor type you want to select."} // Display error message if error is true
                                />
                            )}
                        />
                    </div>
                </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnEventSubmit" variant="outline-primary" type="submit">
                        {(data && data.EventMaster && data.EventMaster.EventId || 0) === 0
            ? "Submit"
            : "Save Changes"
            }
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default forwardRef(CreateEvent);