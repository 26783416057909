import React, { useState, useEffect, useRef } from "react";
import { WebService } from "../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import { ActionPermission, PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import "../Static/Static.css";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import { sortBy } from "underscore";
import { NoRecordTemplate } from "../../Services/TableComponent";
import Button from "react-bootstrap/esm/Button";
import { StandardConst } from "../../Services/StandardConst";
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import AddEditAssignTeam from "./AddEditAssignTeam";
import SquareIcon from "@mui/icons-material/Square";
import { Avatar, AvatarGroup } from "@mui/material";
import { DisNumFormatWoCurrency } from "../../Services/DisplayNumberFormatComponent";
import { useSearchParams } from "react-router-dom";

const AssignTeam = () => {
  const [searchParams] = useSearchParams();
  const ref = useRef();
  const refSnackbar = useRef();
  const [appData, setAppData] = React.useState({
    searchPlaceHolder: "Search Team",
    addMenuTitle: "Add Team",
  });
  PageInfo({ pageTitle: "Assign Team" });
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);

  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
      ManageAdd: ActionPermission("Assign Team - Add"),
    ManageEdit: ActionPermission("Assign Team - Edit"),
    ManageDelete: ActionPermission("Assign Team - Delete"),
    });
  }, [permissionList]);

  const fetchAssignedTeam = async () => {
    await Promise.all([
      WebService({
        endPoint: "Team",
        dispatch,
      }).then((data) => setRecords(sortBy(data, (s) => s.ProjectName))),
      dispatch(ConfigurationflowStatusUpdate()),
    ]);
  };
  const onDelete = async (ProjectTeamId) => {
    await WebService({
      endPoint: `Team/${ProjectTeamId}`,
      method: "DELETE",
      dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    await fetchAssignedTeam();
  };
  const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchAssignedTeam();
    }
    renderAfterCalled.current = true;
  }, []);
  const columns = [
    {
      Text: "Project",
      Value: "ProjectName",
    },
    {
      Text: "Member",
      Value: "FullName",
    },
    {
      Text: "Role",
      Value: "RoleName",
    },
    {
      Text: "IsBillable",
      // Value: "IsBillable",
      render: (dr) => (
        <>
        <span> 
          <SquareIcon
            sx={{
              color: `${
                dr.IsBillable === StandardConst.YesNo.Yes
                  ? StandardConst.wsBillableColor
                  : StandardConst.wsNonBillableColor
              }`,
              fontSize: 10,
            }}
          />
          {dr.IsBillable === StandardConst.YesNo.Yes ? "Billable" : "Non-Billable"}
        </span>
        </>
      ),
    },
    {
      Text: "Rate",
      render: (dr) => (
        <>
          <span>
            <DisNumFormatWoCurrency Number={dr.Rate} />
          </span>
        </>
      ),
    },
    {
      Text: "Mode",
      Value: "BillingMode",
    },
    {
      Text: "Hour",
      Value: "WorkingHour",
      cssClass: "text-center",
    },
    {
      Text: "Working Days",
      cssClass: "text-center",
      render: (dr) => (
        <>
          <div className="text-center">
            {/* {dr.workingDays.map((days) => {
              return <>&nbsp;{days.Day}</>;
            })} */}
            <AvatarGroup max={7}>
              {dr.workingDays.map((days, key) => {
                return (
                    <Avatar
                      key={key}
                      variant="circle"
                      sx={{
                        bgcolor: StandardConst.wsPrimaryColor,
                        width: 25,
                        height: 25,
                      }}
                      alt={days.Day}
                    >
                      <small>{days.Day}</small>
                    </Avatar>
                );
              })}
            </AvatarGroup>
          </div>
        </>
      ),
    },
    {
      Text: "Action",
      cssClass: "text-center td-width-100",
      isVisiable: permission.ManageEdit || permission.ManageDelete,
      render: (dr) => (
        <>
          {dr.IsReserved != 1 && (
            <>
              <ActionButton
                onClick={() => fnEdit(dr.ProjectTeamId)}
                disabled={!permission.ManageEdit}
                IconName="Edit"
                id="btnRoleEdit"
              />

              <ActionButton
                onClick={(e) =>
                  ref.current.confirmAlert(
                    "Delete", //Confirm button text
                    "Are You Sure", // Text if Alert
                    "Do you want to remove " +
                      dr.FullName +
                      " from " +
                      dr.ProjectName +
                      "?", // Message of Alert
                    dr.ProjectTeamId // Endpoint to hit for delete
                  )
                }
                disabled={!permission.ManageDelete}
                IconName="Delete"
                id="btnRoleDelete"
              />
            </>
          )}
        </>
      ),
    },
  ];
  const [bData, setBData] = React.useState([
    {
      title: "Company",
      hrefLink: "#",
    },
    {
      title: "Assign Team",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = "Assign Team";
  const confirmMessage = MasterPageName + " Deleted successfully";

  const AddButtonText = "Team Member";

  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);


  
  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
      <StaticListComponent
        columns={columns}
        records={records}
        bData={bData}
        MasterPageName={MasterPageName}
        AddButtonText={AddButtonText}
        noRecordCss="p-0"
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.AssignTeamheaderValueNoResults}
            subHeaderValue={StandardConst.AssignTeamSubHeaderRole}
            imageUrl={StandardConst.imageNoRecordsFound}
            actionButton={
              <>
                {permission.ManageAdd && (
                  <Button variant="outline-primary" onClick={() => fnEdit(0)} id={`no_record_add_${AddButtonText}`}>
                    Add {AddButtonText}
                  </Button>
                )}
              </>
            }
          />
        }
        onAddEvent={() => fnEdit()}
        IsAddButtonVisible={permission?.ManageAdd}
        isSearchRequired={true}
        allowSerialNo={true}
        initialSearchContent={searchParams.get("search") ?? ""}
      ></StaticListComponent>
      <AddEditAssignTeam
        callBackEvent={() => fetchAssignedTeam()}
        ref={addEditModalRef}
      ></AddEditAssignTeam>
    </>
  );
};
export default AssignTeam;
