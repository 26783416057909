import React, { useEffect, useRef, useState } from 'react'
import SnackbarComponent from '../../Services/SnackbarComponent';
import DeleteConfirmAlert from "../../Services/AlertComponent";
import StaticListComponent from '../../Services/StaticListComponent';
import { NoRecordTemplate } from '../../Services/TableComponent';
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import ActionButton from '../../Services/ActionButton';
import { StandardConst } from '../../Services/StandardConst';
import { WebService } from '../../Services/WebService';
import { ActionPermission, PageInfo } from '../PageInfo';
import { useDispatch, useSelector} from "react-redux";
import Button from "react-bootstrap/esm/Button";
import AddEditAssignTicketCategory from './AddEditAssignTicketCategory';


const AssignTicketCategory = () => {

  const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    const [permission, SetPermission] = useState({});
    const [records, setRecords] = useState([]);
    PageInfo({ pageTitle: "Agent Ticket Category" });
    useEffect(() => {
        SetPermission({
            ManageAdd: true, //ActionPermission("Asset Type - Add"),
            ManageEdit: true, //ActionPermission("Asset Type - Edit"),
            ManageDelete: true, //ActionPermission("Asset Type - Delete"),
        });
    }, [permissionList]);

    const refSnackbar = useRef();
    const ref = useRef();
    const MasterPageName = "Agent Ticket Category";
    const confirmMessage = MasterPageName + " Deleted successfully";
    const addEditModalRef = useRef();

    const AddButtonText = "Assign Ticket Category";
    const dispatch = useDispatch();
    const [deletedName, setDeletedName] = useState(null);

    const onDelete = async (Details) => {
        const data = Details.split(',');
        const AgentId = data[0];
        setDeletedName(data[1]);
        await WebService({
            endPoint: `CommonUtility/ticketcategoryAgents?AgentId=${AgentId}`,
            method: "DELETE",
            dispatch,
        });
        refSnackbar.current.setOpenSnackBar();
        await fetchTicketCategoryAgents();
    };

    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});

    const fetchTicketCategoryAgents = async() => {
        const data = await WebService({
            endPoint: `AssetAssignment/ticketCategoryAgents?CompanyId=${companyInfo.CompanyId}`,
            dispatch,
        });
        setRecords(data);
        dispatch(ConfigurationflowStatusUpdate());
    }

    useEffect(() => {
      if(companyInfo.CompanyId){
        fetchTicketCategoryAgents();
      }
    }, [companyInfo.CompanyId]);

    const [bData,] = React.useState([
        {
            title: "Helpdesk",
            hrefLink: "#",
        },
        {
            title: "Agent Ticket Category",
            hrefLink: "#",
        },
    ]);

    const columns = [
      {
        Text: "Agent Name",
        Value: "FullName"
      },
      {
        Text: "Category Name",
        Value: "ticket_categories"
      },
      {
            Text: "Action",
            cssClass: "text-center td-width-100",
            isVisiable: permission.ManageEdit || permission.ManageDelete,
            render: (dr) => (
                <>
                    <ActionButton
                        onClick={(e) =>
                            ref.current.confirmAlert(
                                "Delete", //Confirm button text
                                "Are You Sure", // Text if Alert
                                "Do you want to delete " + dr.FullName, // Message of Alert
                                `${dr.AgentId},${dr.FullName}`  // Endpoint to hit for delete
                            )}
                        disabled={!permission.ManageDelete}
                        IconName="Delete"
                        IconTooltip={"Delete Asset Type"}
                        id={`btnAssetTypeDelete_${dr.AgentId}`}
                    />
                </>
            ),
        },
    ];

    const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);

  return (
    <>
        <SnackbarComponent ref={refSnackbar} confirmMessage={`Agent Ticket Category ${(deletedName!== null) ? deletedName : ""} Deleted successfully`} />
        <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
        <StaticListComponent
            columns={columns}
            records={records}
            bData={bData}
            MasterPageName={MasterPageName}
            AddButtonText={AddButtonText}
            noRecordCss="p-0"
            noRecordFound={
                <NoRecordTemplate
                    headerValue={StandardConst.AssignTicketCategoryheaderValueNoResults}
                    subHeaderValue={StandardConst.AssignTicketCategorySubHeader}
                    imageUrl={StandardConst.imageNoRecordsFound}
                    actionButton={
                        <>
                            {permission.ManageAdd && (
                                <Button variant="outline-primary" onClick={() => fnEdit(0)} id={`no_record_${AddButtonText}`}>
                                    Add {AddButtonText}
                                </Button>
                            )}
                        </>
                    }
                />
            }
            onAddEvent={() => fnEdit()}
            IsAddButtonVisible={permission?.ManageAdd}
            isSearchRequired={true}
            allowSerialNo={true}
        ></StaticListComponent>
        <AddEditAssignTicketCategory
            callBackEvent={async () => await fetchTicketCategoryAgents()}
            ref={addEditModalRef}
        ></AddEditAssignTicketCategory>
    </>
)
}

export default AssignTicketCategory