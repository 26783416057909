import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText } from "../Form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
const requiredMessage = StandardConst.requiredMessage;
const { forwardRef, useState, useImperativeHandle } = React;
const AddEditDesignation = (prop, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ Designations: {} });
  useImperativeHandle(ref, () => ({
    openModal: async (id) => {
      setShow(true);
      if(id){
        setShow(false);
        // const data = {
        //   Designations: await WebService({
        //     endPoint: `CommonUtility/staticdesignation?where=DesignationId eq ${
        //       id || 0
        //     }`,
        //     dispatch,
        //   }).then((c) => (c.length > 0 ? c[0] : {})),
        // };
        const data = {
          Designations : await WebService({
            endPoint: `CommonUtility/Edit/staticdesignation?DesignationId=${id || 0}`,
            dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {})),
        }
        setData(data);
        setShow(true);
      }
    },
  }));
  const handleClose = () => {
    setShow(false);
    setData({ Designations: {} })
  }
  const schema = yup
    .object()
    .shape({
      Designation: yup.string().trim().required(requiredMessage),
    })
    .required();

  const onSubmit = async (data) => {
    if (data.DesignationId === undefined) {
      await WebService({ dispatch, endPoint: "Designations", body: data });
    } else {
      await WebService({
        endPoint: `CommonUtility/staticdesignation?DesignationId=${data.DesignationId}`,
        method: "PUT",
        body: data,
        dispatch,
      });
    }

    handleClose();
    prop.callBackEvent();
  };

  const formBody = (
    <>
      <div className="row">
        <div className="col-md-12">
          <FormInputText
            label="Designation"
            name="Designation"
            type="text"
            isRequired="true"
          />
        </div>
      </div>
    </>
  );
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="px-2">
          {(data.Designations.DesignationId || 0) === 0
            ? "Add Designation"
            : "Edit Designation"}
        </Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={data.Designations}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        <Modal.Body className="p-4">{formBody}</Modal.Body>
        <Modal.Footer>
          <Button
            id="btnDesignationSubmit"
            variant="outline-primary"
            type="submit"
          >
            {" "}
            {(data.Designations.DesignationId || 0) === 0
              ? "Submit"
              : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default forwardRef(AddEditDesignation);
