import * as React from "react";
import { styled, alpha, ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import AddCircle from "@mui/icons-material/AddCircle";
import { Button } from "@mui/material";
import { grey } from "@mui/material/colors";
import theme from "./WsMuiTheme";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import _ from "underscore";
import { ExcelExport } from "./ImportExport";
import { useDispatch } from "react-redux";
import { StandardConst } from "./StandardConst";
import { InputDropdown, InputText } from "../Component/Form";
import { DateRange } from "../Component/Dashboard/Company/CompanyDashboard";
import { format } from "date-fns";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: grey,
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  height: "30px",
  minheight: "30px",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchDefault = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: grey,
  },
  backgroundColor: grey[300],

  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create("width"),
    fontSize: ".8rem",
    width: "100%",
    border: "none",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const AppbarComponent = ({
  title,
  isSearchRequired,
  isAddButtonRequired,
  setSearchContent,
  searchContent,
  onAddEvent,
  Exception,
  hideDefaultAddLabel,
  exportData,
  columns,
  excelExportFileName,
  isExcelExportButtonVisible,
  filterData,
  setFilterValues,
  filterValues
}) => {
  isExcelExportButtonVisible ??= false;
  filterData ??= [];
  filterValues ??= {};
  title ??= "";
  hideDefaultAddLabel ??= false;
  isSearchRequired ??= false;
  isAddButtonRequired ??= false;
  setSearchContent ??= (v) => {};
  searchContent ??= "";
  var inputBase = (
    <StyledInputBase
      placeholder={`Search ${title}`}
      id={`Search_${title}`}
      inputProps={{ "aria-label": "search" }}
      onChange={(e) => setSearchContent(e.target.value)}
      value={searchContent}
    />
  );

  const dispatch = useDispatch();

  const ExportToExcel = () => {
    var c1 = columns.filter(f => (f.NotUseInExport ?? false) === false && !StandardConst.ExcludeFieldsFromExcelExport.includes(f.Text));
    var c2 = _.map(exportData, (m) => {
      var object = {};
      _.each(c1, (e) => {
          object[e.Text] = e.render ? 
          (Array.isArray(e.render(m).props.children)) ? e.render(m).props.children.join('') : e.render(m).props.children
          : m[e.Value ?? e.Text]
      });
      return object;
    });
    excelExportFileName = excelExportFileName ? excelExportFileName + '-' + Date.now().toString() : Date.now().toString();
    ExcelExport(c2, excelExportFileName, dispatch);
  };

  const handleChangeFilters = (val, filedName) => {
    setFilterValues((prev) => ({
      ...prev,
      [filedName]: val
    }));
  };

  return (
    <>
      {(isSearchRequired || isAddButtonRequired || isExcelExportButtonVisible) && (
        <Box sx={{ p: 0 }}>
          <ThemeProvider theme={theme}>
            <AppBar
              style={{ minHeight: "40px", height: "40px" }}
              position="static"
              elevation={0}
              color={`${
                isAddButtonRequired || isExcelExportButtonVisible || (onAddEvent || null) !== null
                  ? "primary"
                  : ""
              }`}
            >
              <Toolbar
                className="ms-0 ps-0"
                style={{ minHeight: "40px", height: "40px" }}
              >
                {isSearchRequired &&
                  (isAddButtonRequired || isExcelExportButtonVisible || (onAddEvent || null) !== null) && (
                    <Search>
                      <SearchIconWrapper>
                        <SearchIcon sx={{ fontSize: 20 }} />
                      </SearchIconWrapper>
                      {inputBase}
                    </Search>
                  )}
                {isSearchRequired &&
                  !(isAddButtonRequired || isExcelExportButtonVisible || (onAddEvent || null) !== null) && (
                    <SearchDefault>
                      <SearchIconWrapper>
                        <SearchIcon sx={{ fontSize: 20 }} />
                      </SearchIconWrapper>
                      {inputBase}
                    </SearchDefault>
                  )}

                {(filterData.length === 0) && (
                  <Box sx={{ flexGrow: 1 }} />
                )}

                {(filterData.length > 0) && (
                  <Box sx={{ flexGrow: 1 }} >
                    <Row className="d-flex justify-content-center align-items-center">
                      
                        {filterData.map(item => {
                          if(item.FilterType === "INPUT") {
                            return <Col key={item.FieldName} className="d-flex justify-content-center align-items-center mt-1">
                              <InputText 
                                className="text-white border-0"
                                style={{backgroundColor: "rgba(255, 255, 255, 0.15)",
                                  height: "30px",
                                  borderRadius: "5px",
                                }}
                                placeholder={item.FilterPlaceholder}
                                name = {item.FieldName}
                                setValue={(val) => handleChangeFilters(val, item.FieldName)}
                                value={filterValues[item.FieldName] ?? ""}
                              />
                            </Col>
                          }else if(item.FilterType === "DATE"){
                            return <Col key={item.FieldName} className="d-flex justify-content-center align-items-center">
                              <DateRange 
                                ShowDefaultDate={false}
                                type="day"
                                ButtonColor="inherit"
                                setDateRange={({ StartDate, EndDate }) => {
                                  if(StartDate){
                                    handleChangeFilters(format(new Date(StartDate), "dd-MM-yyyy"), item.FieldName)
                                  }
                                }}
                              />
                            </Col>
                          }else{
                            return <Col key={item.FieldName} className="d-flex justify-content-center align-items-center">
                              <InputDropdown
                                className="border-0"
                                style={{backgroundColor: "rgba(255, 255, 255, 0.15)",
                                  height: "30px",
                                  borderRadius: "5px",
                                }}
                                name = {item.FieldName}
                                setValue={(val) => handleChangeFilters(val, item.FieldName)}
                                ddOpt={[{text: item.DefaultAllValuesText ?? "All", value: ""}].concat(item.FilterData)}
                                value={filterValues[item.FieldName] ?? ""}
                              />
                            </Col>
                          }
                        })}

                        <Col>
                          <Button
                            className="text-white"
                            onClick={() => setFilterValues({})}
                          >
                            Reset
                          </Button>
                        </Col>
                      
                    </Row>
                  </Box>
                )}

                {!Exception && (
                  <>
                    <Box sx={{ display: { xs: "none", md: "flex" } }}>
                      {
                        isExcelExportButtonVisible && (
                          <Row className="p-1 m-0">
                            <Col className="col-md-12">
                              <div className="d-flex justify-content-md-end justify-content-sm-end">
                                <Button
                                  variant="outline-success"
                                  onClick={() => ExportToExcel()}
                                >
                                  <i className="fa fa-file-excel-o me-2" aria-hidden="true"></i>{" "}
                                  Excel Export 
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        )
                      }
                    </Box>

                    {(isAddButtonRequired) && (
                      <>
                        <Box sx={{ display: { xs: "none", md: "flex" } }}>
                          <Button
                            color="inherit"
                            id={`Add_${title}`}
                            onClick={() => {
                              if ((onAddEvent || null) !== null) {
                                onAddEvent();
                              }
                            }}
                          >
                            {" "}
                            <div className=" d-flex align-items-center">
                              <i className="fa fa-plus-circle me-2"></i>{" "}
                              {`${(hideDefaultAddLabel) ? "" : "Add"} ${title}`}
                            </div>
                          </Button>
                        </Box>
                        <Box sx={{ display: { xs: "flex", md: "none" } }}>
                          <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-haspopup="true"
                            onClick={() => {
                              if ((onAddEvent || null) !== null) {
                                onAddEvent();
                              }
                            }}
                            color="inherit"
                          >
                            <AddCircle />
                          </IconButton>
                        </Box>
                      </>
                    )}
                  </>
                )}
              </Toolbar>
            </AppBar>
          </ThemeProvider>
        </Box>
      )}
    </>
  );
};
export default AppbarComponent;
