import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import { WebService } from "../../Services/WebService";
import { ActionPermission, PageInfo } from "../PageInfo";
import { useDispatch, useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import { Box, Grid, Chip, CardHeader, IconButton, Avatar, List, ListItem, ListItemButton, ListItemText, Tooltip } from "@mui/material";
import Topbar from "../../Services/TopbarComponent";
import { StandardConst } from '../../Services/StandardConst';
import { Form, FormInputText, InputDropdown } from '../Form';
import Button from "react-bootstrap/Button";
import { NoRecordTemplate } from '../../Services/TableComponent';
import ActionButton from '../../Services/ActionButton';
import { WSSuccessAlert } from '../../Services/WSAlert';
import BootstrapModal from "react-bootstrap/Modal";
import { format } from 'date-fns';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AppbarComponent from '../../Services/AppbarComponent';

const ApplicantsList = () => {
    PageInfo({ pageTitle: "Applicants List" });

    const refSendRejectionMailModal = useRef();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // ye original data ko store krti hai
    const [allApplicantsData, setAllApplicantsData] = useState([]);
    // ye state filter state hai jo ki JobApplicant ke time me filter se or first time data get krne ki liye set hoti hai
    const [filterapplicantsData, setFilterApplicantsData] = useState([]);

    const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    const [permission, SetPermission] = useState({});
    const [jobList, setJobList] = useState([]);
    const [filterJob, setFilterJob] = useState('All');
    const [filterStatus, setFilterStatus] = useState('All');
    const [showStatusMenu, setShowStatusMenu] = useState(null);

    useEffect(() => {
        SetPermission({
            ManageAdd: ActionPermission("Manage Job Posting - Add"),
            ManageEdit: ActionPermission("Manage Job Posting - Edit"),
            ManageDelete: ActionPermission("Manage Job Posting - Delete"),
        });
    }, [permissionList]);

    const [bData] = useState([
        {
            title: "Recruitment",
            hrefLink: "#",
        },
        {
            title: "Applicant List",
            hrefLink: "#",
        },
    ]);

    const fetchAllJobApplicants = async () => {
        await WebService({
            endPoint: `Recruitment/fetchAllJobApplicantsForLoggedInCompany?JobId=${filterJob}&Status=${filterStatus}`,
            method: "GET",
            dispatch
        }).then(response => {
            setAllApplicantsData(response);setFilterApplicantsData(response);});
    }

    const fetchAllJobs = async () => {
        await WebService({
            endPoint: `CommonUtility/jobs`,
            method: "GET",
            dispatch
        }).then(res => {
            if(res.length > 0) {
                const list = [{text: "All", value: 'All'}].concat(res.map((r) => ({ text: r.Title, value: r.JobId })));
                setJobList(list);
            }
        });
    }

    useEffect(() => {
        Promise.all([
            fetchAllJobApplicants(),
            fetchAllJobs()
        ])
    }, []);

    useEffect(() => {
        fetchAllJobApplicants();
    }, [filterJob, filterStatus]);

    const onSearchSubmit = async (data) => {
        const { Skills, Position, Location } = data;
    
        const searchSkills = Skills ? Skills.toLowerCase() : '';
        const searchPosition = Position ? Position.toLowerCase() : '';
        const searchLocation = Location ? Location.toLowerCase() : '';
    
        const filteredData = allApplicantsData.filter(item => {
            const itemSkills = item.ApplicantSkills ? item.ApplicantSkills.toLowerCase() : '';
            const itemPosition = item.Position ? item.Position.toLowerCase() : '';
            const itemLocation = item.Location ? item.Location.toLowerCase() : '';
    
            const matchesSkills = searchSkills ? itemSkills.includes(searchSkills) : true;
            const matchesPosition = searchPosition ? itemPosition.includes(searchPosition) : true;
            const matchesLocation = searchLocation ? itemLocation.includes(searchLocation) : true;
    
            return matchesSkills && matchesPosition && matchesLocation;
        });
    
        setFilterApplicantsData(filteredData);
    };

    const columns = [
        {
            Text: "FullName",
            Value: "FullName",
        },
        {
            Text: "Position",
            Value: "Position",
        },
        {
            Text: "Location",
            Value: "Location",
        },
        {
            Text: "Phone",
            Value: "Phone",
        },
        {
            Text: "Email",
            Value: "Email",
        },
        
        {
            Text: "ApplicantSkills",
            Value: "ApplicantSkills",
        },
        {
            Text: "YearOfExperience",
            Value: "YearOfExperience",
        },
        {
            Text: "ExpectedCTC",
            Value: "ExpectedCTC",
        },
        {
            Text: "Age",
            Value: "Age",
        },
        
        {
            Text: "Address",
            Value: "Address"
        },
        {
            Text: "Description",
            Value: "Description",
        }
    ];

    const handleToggleMenu = (JobApplicationId) => {
        if(showStatusMenu === JobApplicationId){
            setShowStatusMenu(null);
        }else{
            setShowStatusMenu(JobApplicationId);
        }
    };

    const updateApplicationStatus = async(JobApplicationId, updatedStatus) => {
        await WebService({
            endPoint: `CommonUtility/jobapplications?JobApplicationId=${JobApplicationId}`,
            body: {StatusId : updatedStatus},
            method: 'PUT',
            dispatch
        }).then((res) => {
            setShowStatusMenu(null);
            fetchAllJobApplicants();
            WSSuccessAlert("Success", "Status successfully Updated");
        });

        if(updatedStatus === StandardConst.Status.Reject){
            openModalSendRejectionMail(JobApplicationId);
        }
    };

    const openModalSendRejectionMail = async (JobApplicationId) => refSendRejectionMailModal.current.openModal(JobApplicationId);
    
    return (
        <>
            <ModalAfterApplicantReject ref={refSendRejectionMailModal} />
            <Container
                fluid
                className="base-container"
                style={{minHeight: '800px'}}
            >
                <Topbar bData={bData} HeadingText="Applicants List"></Topbar>
                <AppbarComponent
                    isSearchRequired={false}
                    isAddButtonRequired={false}
                    Exception={false}
                    isExcelExportButtonVisible={true}
                    exportData={filterapplicantsData}
                    columns={columns}
                />
                <Form
                    // defaultValues={{ Token: token }}
                    onSubmit={onSearchSubmit}
                    // validationSchema={schema}
                >
                    <div className="mx-4 py-2 d-flex justify-content-start text-align-center">
                        <div className="border card pt-2 pb-2 rounded m-0" style={{width: "840px"}}>
                            <div className="row m-0 d-flex justify-content-center text-align-center">
                                <div className="col-md-3">
                                    <FormInputText
                                        name="Skills"
                                        placeholder="Skills"
                                        type="text"
                                        isRequired="true"
                                        className="border-0 form-control"
                                    />
                                </div>
                                <div className="col-md-3">
                                    <FormInputText
                                        name="Position"
                                        placeholder="Position"
                                        type="text"
                                        isRequired="true"
                                        className="border-0"
                                    />
                                </div>
                                <div className="col-md-3">
                                    <FormInputText
                                        name="Location"
                                        placeholder="Location"
                                        type="text"
                                        isRequired="true"
                                        className="border-0"
                                    />
                                </div>
                                <div className="col-md-3 d-flex justify-content-center text-align-center">
                                    <Button type="submit">
                                        Find Jobs
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>

                <div className="container px-4">
                    <div className="row">
                        <div className="col-md-8"></div>
                        <div className="col-md-2 d-flex justify-content-end">
                            <InputDropdown
                                name="filter"
                                ddOpt={jobList}
                                setValue={(val) => setFilterJob(val)}
                                // setValue={(val) => fetchAllJobApplicants(val)}
                            />
                        </div>
                        <div className="col-md-2 d-flex justify-content-end">
                            <InputDropdown
                                name="filter"
                                ddOpt={[{text: "All", value: "All"}].concat(StandardConst.JobApplicationStatusDropdown)}
                                setValue={(val) => setFilterStatus(val)}
                                // setValue={(val) => fetchAllJobApplicants(val)}
                            />
                        </div>
                    </div>
                </div>

                <div className='m-4'>
                    <Box sx={{ flexGrow: 1 }}>
                        {(filterapplicantsData.length > 0) ? (
                            <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
                                {filterapplicantsData.map((Applicant, index) => (
                                    <Grid item xs={12} sm={4} md={3} key={index}>
                                        <Card sx={{ maxWidth: 345 }}>
                                            <CardHeader
                                                avatar={
                                                    <Avatar
                                                        alt={Applicant.FullName}
                                                        src={`${StandardConst.uploadImages}${
                                                            Applicant.ProfileImage ?? "user_placeholder.jpg"
                                                        }`}
                                                        sx={{ width: 40, height: 40 }}
                                                    />
                                                  }
                                                action={
                                                    <>
                                                        <IconButton aria-label="settings" onClick={() => handleToggleMenu(Applicant?.JobApplicationId)}>
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                        <div className={`position-relative ${showStatusMenu !== null && showStatusMenu === Applicant?.JobApplicationId ? '' : 'd-none'}`} id={`StatusMenu_${Applicant?.JobApplicationId}`}>
                                                            <div className="card position-absolute p-2" style={{right:0}}>
                                                                <nav aria-label="main mailbox folders">
                                                                    <List>
                                                                        <ListItem disablePadding>
                                                                            <ListItemButton onClick={() => updateApplicationStatus(Applicant?.JobApplicationId, StandardConst.Status.Hired)}>
                                                                                <ListItemText primary="Hired" />
                                                                            </ListItemButton>
                                                                        </ListItem>
                                                                        <ListItem disablePadding>
                                                                            <ListItemButton onClick={() => updateApplicationStatus(Applicant?.JobApplicationId, StandardConst.Status.Reject)}>
                                                                                <ListItemText primary="Reject" />
                                                                            </ListItemButton>
                                                                        </ListItem>
                                                                    </List>
                                                                </nav>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                title={Applicant?.FullName}
                                                subheader={
                                                    Applicant?.Position && (
                                                        <Chip 
                                                            label={Applicant?.Position}
                                                            sx={{ color: "#EE9626", padding: "0px", borderRadius: "5px", fontWeight: "bold", backgroundColor: "rgba(255, 255, 0, 0.2)", marginBottom: "8px"}}
                                                            size="small"
                                                        />
                                                    )
                                                }
                                                className="pb-0"
                                            />
                                            <CardContent className="pt-2 pb-3">
                                                <Typography variant="body2" color="text.secondary">
                                                    {Applicant?.Address}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {Applicant?.Phone}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {Applicant?.Email}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {Applicant?.Location}
                                                </Typography>
                                                <br />

                                                {(Applicant.ApplicantSkills) && (
                                                    <div>
                                                        <b>Skill : </b><br />
                                                        {Applicant.ApplicantSkills?.split(',').map((ApplicantSkill) => (
                                                            <>
                                                                <Chip 
                                                                    label={ApplicantSkill}
                                                                    sx={{ color: "green", padding: "0px", borderRadius: "5px", fontWeight: "bold", backgroundColor: "rgba(0,125,0,0.1)", marginBottom: "8px"}}
                                                                    size="small"
                                                                />
                                                                &nbsp;
                                                            </>
                                                        ))}
                                                    </div>
                                                )}

                                                <InterviewList JobApplicantId={Applicant?.JobApplicantId} JobId={Applicant?.JobId} />

                                                <Typography variant="body2" color="text.secondary">
                                                    <br />
                                                    <span className='text-secondary'>{Applicant?.JobTitle}</span>
                                                    <br />
                                                    <Chip 
                                                        label={StandardConst.JobApplicationStatusDropdown.find(item => item.value === Applicant?.ApplicationStatusId)?.text}
                                                        sx={{ color: "green", padding: "0px", borderRadius: "5px", fontWeight: "bold", backgroundColor: "rgba(0,125,0,0.1)", marginBottom: "8px"}}
                                                        size="small"
                                                    />
                                                </Typography>

                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <a href={`tel:${Applicant?.Phone}`}>
                                                            <ActionButton
                                                                // onClick={() => navigate('/JobApplicantProfile', { state: { JobApplicantId : Applicant.JobApplicantId } } )}
                                                                IconName="Phone"
                                                                id="btnIdJobView"
                                                                title="Call"
                                                            />
                                                        </a>
                                                        <a href={`mailto:${Applicant?.Email}`}>
                                                            <ActionButton
                                                                // onClick={() => navigate('/JobApplicantProfile', { state: { JobApplicantId : Applicant.JobApplicantId } } )}
                                                                IconName="Mail"
                                                                id="btnIdJobView"
                                                                title="Email"
                                                            />
                                                        </a>
                                                        <ActionButton
                                                            onClick={() => navigate('/JobApplicantProfile', { state: { JobApplicantId : Applicant.JobApplicantId, JobApplicationId : Applicant.JobApplicationId } } )}
                                                            IconName="View"
                                                            id="btnIdJobView"
                                                            title="View Profile"
                                                            IconColor="primary"
                                                        />
                                                        <ActionButton
                                                            onClick={() => navigate('/FixInterview')}
                                                            IconName="FixInterview"
                                                            id="btnIdFixInterview"
                                                            title="Schedule Interview"
                                                        />
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <NoRecordTemplate
                                headerValue={"No Jobs Posted"}
                                subHeaderValue={"There are no jobs opening"}
                                imageUrl={StandardConst.imageNoRecordsFound}
                            />
                        )}
                    </Box>
                </div>
            </Container>
        </>
    );
}

const InterviewList = ({JobId, JobApplicantId}) => {
    const dispatch = useDispatch();

    const [interviewList, setInterviewList] = useState([]);

    const fetchInterviewList = async() => {
        await WebService({
            endPoint : `Recruitment/fetchJobInterviewListByApplicant?JobApplicantId=${JobApplicantId}&JobId=${JobId}`,
            method : 'GET',
            dispatch
        }).then(res => res.length > 0 ? setInterviewList(res) : null);
    }
    useEffect(() => {
        fetchInterviewList();
    }, [JobId, JobApplicantId]);

    return (
        <>
            {interviewList.length > 0 && (
                <>
                    <b>Interviews : {interviewList.length}</b>
                    <ol className='px-2'>
                        {interviewList.map((interview, index) => (
                            <Tooltip title={interview?.InterviewerFeedback} >
                                <li title={interview?.InterviewerFeedback} style={{fontSize: "12px"}}>{format(new Date(interview?.InterviewDate), 'dd-MMM-yy')} {interview.InterviewType} {StandardConst.InterviewStatusDropdown.find(item => item.value === interview.Status).text}</li>
                            </Tooltip>
                        ))}
                    </ol>
                </>
            )}
        </>
    )
};

// this is for fetch job posting time
export const CalculatePostedDate = ({ dateTime }) => {
    const [difference, setDifference] = useState('');
  
    useEffect(() => {
      const interval = setInterval(() => {
        const now = new Date();
        const targetDate = new Date(dateTime);
  
        const diffInMs = now - targetDate;
        const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        
        let differenceString = '';
        if (days > 0) {
          differenceString = `${days} day${days > 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
          differenceString = `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else {
          differenceString = 'Just now';
        }
  
        setDifference(differenceString);
      }, 1000);
  
      return () => clearInterval(interval);
    }, [dateTime]);
  
    return (
      <div>
        <p>{difference}</p>
      </div>
    );
}

const ModalAfterApplicantReject = forwardRef(({}, ref) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const [show, setShow] = useState(false);
    const [applicantData, setApplicantData] = useState({});

    useImperativeHandle(ref, () => ({
        openModal: async (JobApplicationId) => {
            await WebService({
                endPoint: `Recruitment/fetchJobApplicantsDataInReject?JobApplicationId=${JobApplicationId}`,
                method: 'GET',
                dispatch
            }).then(res => {
                if(res.length > 0) {
                    res[0].CompanyName = companyInfo?.CompanyName;
                    setApplicantData(res[0]);
                }
            });
            setShow(true);
        }
    }));

    const handleClose = () => {
        setShow(false);
        setApplicantData({});
    };


    const SendRejectionMail = async () => {
        await WebService({
            endPoint: 'Recruitment/SendMailInApplicantRejection',
            body: applicantData,
            method: 'POST',
            dispatch
        }).then(res => WSSuccessAlert("Success", "Mail sent successfully"));
        handleClose();
    };
    
    return (
        <>
            <BootstrapModal show={show} onHide={handleClose}>
                <BootstrapModal.Header closeButton>
                <BootstrapModal.Title>Send rejection mail to applicant</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    <div className="d-flex justify-content-center align-itmes-center">
                        <Button variant="outline-primary" onClick={() => SendRejectionMail()}>
                            Send Rejection Email
                        </Button>
                    </div>
                </BootstrapModal.Body>
            </BootstrapModal>
        </>
    );
});


export default ApplicantsList;