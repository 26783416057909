/* #region Import */
import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  memo,
} from "react";
import "./EditProfile.css";
import { WebService } from "../../Services/WebService";
import { StandardConst } from "../../Services/StandardConst";
import { useDispatch, useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import ViewDocument from "./ViewDocument";
import Swal from "sweetalert2";
import _, { extend, filter, findWhere, map, omit, sortBy, where } from "underscore";
import format from "date-fns/format";
import { Alert, Box, IconButton, Modal, Paper, Tab, Tabs, AppBar, Avatar, Tooltip, ListItem, List, ListItemText, Autocomplete, TextField } from "@mui/material";
import ReactModal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import CheckBox from "@mui/icons-material/CheckCircle";
import Cancel from "@mui/icons-material/Cancel";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import * as yup from "yup";
import {
  FormInputText,
  Form,
  FormInputDropdown,
  InputDropdown,
  InputText,
  FormInputFile,
  FormAutoCompleteDropdown,
} from "../Form";
import TableComponent from "../../Services/TableComponent";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import ActionButton from "../../Services/ActionButton";
import AddEditFamilyDetails from "./AddEditFamilyDetails";
import { ActionPermission, PageInfo } from "../PageInfo";
import Snackbar from "@mui/material/Snackbar";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { DateTime } from "luxon";
import ViewIDCard from "../EmployeeIdCard/ViewIDCard";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { contentSearch, dataClone } from "../../Services/UtilityService";
import { WSErrorAlert, WSSuccessAlert, WSWarningAlert } from "../../Services/WSAlert";
import HtmlFileReader from "../../Services/HtmlFileReader";
import BootstrapModal from "react-bootstrap/Modal";
import { useTheme } from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import PropTypes from "prop-types";
import ArticleIcon from '@mui/icons-material/Article';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { FaceCapture } from "../../components/FaceCapture";
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import * as faceapi from 'face-api.js'
import QrReader from 'react-qr-scanner';
import { styled } from "@mui/material/styles";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import FileIcon from "@mui/icons-material/FilePresentOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import SnackbarComponent from "../../Services/SnackbarComponent";

/* #endregion */
const Profile = () => {
  const loggedUserAssignRoles = useSelector((s) => s.auth.AssignRole ?? []);
  const navigate = useNavigate();
  PageInfo({ pageTitle: "User Profile" });
  const viewIDCardRef = useRef();
  const GenerateBadgeRef = useRef();
  const reassignManagerRef = useRef();
  const reassignOfficeLocationRef = useRef();
  const [text, setText] = useState("");
  const frmUserProfileRef = useRef();

  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList ?? []);

  const [selectQualificationData, setSelectQualificationData] = useState([]);
  const [autoErrorShow, setAutoErrorShow] = useState(false);

  useEffect(() => {
    SetPermission({
      ManageReject: ActionPermission("EmployeeDocument - Reject"),
      ManageApprove: ActionPermission("EmployeeDocument - Approve"),
      ManageView: ActionPermission("EmployeeDocument - View"),
      ManageFaceBiometricView: ActionPermission("Employee - Face Biometric - View"),
      ManageFaceBiometricDelete: ActionPermission("Employee - Face Biometric - Delete"),
      ManageFaceBiometricAllView: ActionPermission("Employee - All - Face Biometric - View"),
      ManageFaceBiometricAllDelete: ActionPermission("Employee - All - Face Biometric - Delete"),
      ManageFaceBiometricRegistration: ActionPermission("Employee - Face Biometric - Registration"),
      ManageFaceBiometricAllRegistration: ActionPermission("Employee - All - Face Biometric - Registration"),
      ManageFaceBiometricAllApprove: ActionPermission("Employee-All-Face Biometric - Approve"),
      ManageFaceBiometricAllReject: ActionPermission("Employee-All-Face Biometric - Reject"),

      ManageDocumentTabView: ActionPermission("Employee - View Document"),
      ManageAllDocumentTabView: ActionPermission("Employee - ALL - View Document"),
      ManageFamiliesTabView: ActionPermission("Employee - View Families"),
      ManageAllFamiliesTabView: ActionPermission("Employee - ALL - View Families"),
      ManageFingerPrintBiometricTabView: ActionPermission("Employee - Finger Print Biometric"),
      ManageAllFingerPrintBiometricTabView: ActionPermission("Employee - All - Finger Print Biometric"),
      ManageFaceBiometricTabView: ActionPermission("Employee - Face Biometric"),
      ManageAllFaceBiometricTabView: ActionPermission("Employee - All - Face Biometric"),

      ManageFamilyAdd: ActionPermission("Employee - Family - Add"),
      ManageAllFamilyAdd: ActionPermission("Employee - All - Family - Add"),
      ManageFamilyEdit: ActionPermission("Employee - Family - Edit"),
      ManageAllFamilyEdit: ActionPermission("Employee - All - Family - Edit"),
      ManageFamilyDelete: ActionPermission("Employee - Family - Delete"),
      ManageAllFamilyDelete: ActionPermission("Employee - All - Family - Delete"),

      ManageAllManagerChange: ActionPermission("Employee - All - Manager - Edit"),
      ManageAllOfficeLocationChange: ActionPermission("Employee - All - Office Location - Edit"),
      ManageProfileSave: ActionPermission("Employee - Profile - Save"),
      ManageAllProfileSave: ActionPermission("Employee - All - Profile - Save"),
      ManageAllProfilePictureUpload: ActionPermission("Employee - All - Profile Upload"),
    });
  }, [permissionList]);

  const { state } = useLocation();
  const EmpId = state?.EmpId ?? 0;
  const loggedUserDetails = useSelector((s) => s.auth.LoggedUser ?? []);
  const dispatch = useDispatch();
  const ref = useRef();
  const refEmployeeBiometric = useRef();
  const refSnackbar = useRef();
  const [records, setRecords] = useState([]);
  const [loggedUser, setLoggedUser] = useState([]);
  const [info, setInfo] = useState([]);
  const [information, setInformation] = useState([]);
  const [dp, setDp] = useState([]);
  const [doc, setDoc] = useState([]);
  const [employeeBiometrics, setEmployeeBiometrics] = useState([]);
  const renderAfterCalled = useRef(false);
  const viewModalRef = useRef();
  const viewModalBiometricFaceRef = useRef();
  const [show, setShow] = useState(false);
  const [userDOB, setUserDOB] = useState();
  const [empDOJ, setDOJ] = useState();
  const [empStatus, setStatus] = useState();
  const [userLocation, setUserLocation] = useState();
  const [LogginInEmployeeId, setLoggedInEmployeeId] = useState();
  const [ApproveData, setData] = useState({
    FromDate: null,
    ToDate: null,
  });
  const [officelocationData, setlocatiinData] = useState({});
  const [qualificationMasterList, setQualificationMasterList] = useState([]);
  const fetchQulificationMaster = () => {
    WebService({
      dispatch,
      endPoint: "CommonUtility/static_qualification_master",
    }).then((c) => {
      setQualificationMasterList( map(c ?? [], (m) => ({
        value: m.QualificationId,
        text: m.DisplayQualification,
      })))
    });
  };

  const loadLibraries = async () => {
    await faceapi.nets.tinyFaceDetector.loadFromUri('facenet/models/tiny_face_detector')
    await faceapi.nets.ssdMobilenetv1.loadFromUri('facenet/models/ssd_mobilenetv1')
    await faceapi.nets.faceLandmark68Net.loadFromUri('facenet/models/face_landmark_68')
    await faceapi.nets.faceRecognitionNet.loadFromUri('facenet/models/face_recognition')
  }
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchDetails();
      const p1 = WebService({ dispatch, endPoint: "User/LoggedUser" }).then(
        (r) => {
          setLoggedUser(r.EmployeeId);
        }
      );
      Promise.all([p1]);
    }
    renderAfterCalled.current = true;
    loadLibraries();
  }, []);

  const fetchDetails = async () => {
    await Promise.all([
      fetchQulificationMaster(),
    ]);
    await Promise.all([
      fetchProfile(),
      fetchFamily(),
      GenerateToken(),
      fetchEmployeeDocuments(),
      fetchEmployeeBiometrics(),
      getManagerByRole(),
      getsetlocation(),
      getDesignation(),
      getJobFunction(),
      getDepartment()
    ]);
  };

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const fetchProfile = async () => {
    const data = await WebService({
      endPoint: `UserProfile/Fetch/${EmpId || 0}`,
      dispatch,
    });
    if ((data[0]?.Anniversary || "") !== "")
      data[0].Anniversary = format(new Date(data[0].Anniversary), "yyyy-MM-dd");
    if ((data[0]?.DOB || "") !== "")
      data[0].DOB = format(new Date(data[0].DOB), "yyyy-MM-dd");
    if ((data[0]?.DOJ || "") !== "")
      data[0].DOJ = format(new Date(data[0].DOJ), "yyyy-MM-dd");


    if(data[0]?.QualificationData.length > 0){
      setSelectQualificationData(data[0]?.QualificationData);
    }

    setUserDOB(data[0]?.DOB);
    setLoggedInEmployeeId(data[0].EmployeeId);
    setDp(data[0]?.ProfileImage ?? null);
    setStatus(data[0]?.StatusId ?? null);
    setUserLocation(data[0]?.Location ?? null);
    setRecords(data);
    setBasicProfile(data.length > 0 ? data[0] : {});

    setIsDataLoaded(true);
    frmUserProfileRef.current.fnReset(data[0]);
  };
  useEffect(() => { }, [empStatus]);
  const GenerateToken = async () => {
    const data = await WebService({
      endPoint: `User/Token`,
      method: "Get",
      dispatch,
    });
  };
  const fetchEmployeeDocuments = async () => {
    const data = await WebService({
      endPoint: `DocumentType/getLoggedUserDocuments/${EmpId || 0}`,
      dispatch,
    });
    setDoc(data);
  };

  const fetchEmployeeBiometrics = async () => {
    const employeeBiometricsData = await WebService({
      endPoint: `Biometrics/getEmployeeBiometrics?UserType=${StandardConst.UserType.Employee}&EmployeeId=${EmpId || 0}`,
      dispatch,
    });
    setEmployeeBiometrics(employeeBiometricsData);
  };

  const employeeBiometricsDelete = async (BiometricsFaceData) => {
    await WebService({
      endPoint: `Biometrics/BiometricsFaceDelete?BiometricsFaceId=${BiometricsFaceData.BiometricsFaceId}&ImageSrc=${BiometricsFaceData.ImageSrc}`,
      method: "DELETE",
      dispatch,
    });
    // refEmployeeBiometric.current.setOpenSnackBar();
    await fetchEmployeeBiometrics();
  }

  const BiometricRegistor = async (Msg) => {
    if (Msg === StandardConst.APIMessage.Success) {
      WSSuccessAlert("Face Registered", "Your face is registered successfully.")
      await fetchEmployeeBiometrics();
    } else if (Msg === StandardConst.APIMessage.Error) {
      WSErrorAlert("Face Not Registered", "Your face is not registered. Please try again after some time.")
    }
  }

  const fnViewBiometricFace = async (ImgPath) => {
    if ((permission.ManageFaceBiometricView && (loggedUserDetails === records[0]?.EmployeeId)) || permission.ManageFaceBiometricAllView) {
      await viewModalBiometricFaceRef.current.openModal(ImgPath || '')
    }
  };

  const employeeBiometricsListColumns = [
    {
      Text: "Face Registered Date",
      render: (dr) => {
        // This is for biometrics face image src
        const biometricImgFolderPath = `${StandardConst.apiBaseUrl}${StandardConst.UploadFolderPaths.Biometrics}/${dr.ImageSrc}`;

        const timestamp = dr.CreatedDate;
        const date = new Date(timestamp);

        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;

        const formattedDate = `${day} ${month} ${year} ${hours}:${minutes} ${ampm}`;

        return (
          <div className="row">
            <div className="col-4 col-md-2">
              <Avatar
                sx={{ width: 30, height: 30 }}
                alt={'Biometrics image'}
                src={biometricImgFolderPath}
                onClick={() => fnViewBiometricFace(biometricImgFolderPath)}
              />
            </div>
            <div className="col-8 col-md-10">
              {formattedDate}
            </div>
          </div>
        );
      }
    },
    {
      Text: "Approval Status",
      render: (dr) => (
        <span
          className={`${dr.StatusId == StandardConst.Status.Pending
              ? "text-primary"
              : dr.StatusId == StandardConst.Status.Approved
                ? "text-success"
                : "error"
            }`}
        >
          {Object.keys(StandardConst.Status).find(key => StandardConst.Status[key] === dr.StatusId)}
        </span>
      ),
    },
    {
      Text: "Action",
      key: "BiometricsFaceId",
      cssClass: "text-end",
      // isVisiable: permission.ManageFaceBiometricDelete,
      render: (dr) => (
        <>
          <button
            id="btnApproveBiometricFace"
            className={`btn btn-default mx-2 p-0 border-none ${!permission.ManageFaceBiometricAllApprove
                ? "d-none"
                : "d-inline"}
            }`}
            onClick={(e) => onApproveRejectBiometricFace(e, dr.BiometricsFaceId, StandardConst.Status.Approved, dr.ImageSrc)}
          >
            <i className="fa fa-check-circle text-primary"></i>
          </button>

          <button
            id="btnRejectBiometricFace"
            className={`btn btn-default mx-2 p-0 border-none ${!permission.ManageFaceBiometricAllReject
                ? "d-none"
                : "d-inline"}
            }`}
            onClick={(e) => onApproveRejectBiometricFace(e, dr.BiometricsFaceId, StandardConst.Status.Reject, dr.ImageSrc)}
          >
            <i className="fa fa-ban text-danger"></i>
          </button>

          <ActionButton
            disabled={((permission.ManageFaceBiometricDelete && (loggedUserDetails === records[0]?.EmployeeId)) || permission.ManageFaceBiometricAllDelete) ? false : true}
            onClick={() =>
              refEmployeeBiometric.current.confirmAlert(
                "Delete", //Confirm button text
                "Are You Sure", // Text if Alert
                "Do you want to delete ", // Message of Alert
                { "BiometricsFaceId": dr.BiometricsFaceId, "ImageSrc": dr.ImageSrc } // Endpoint to hit for delete
              )
            }
            IconName="Delete"
            id="EmployeeBiometricsDelete"
          />
        </>
      ),
    },
  ];

  // const [file, setFile] = useState();
  // const [fileName, setFileName] = useState("");

  const saveFile = async (e) => {
    //setFile(e.target.files[0]);
    //setFileName(e.target.files[0].name);

    const userphotodata = await WebService({
      // if you want to set file size to upload in folder so you want to pass call this api
      // upload/File?fileSize=8
      endPoint: "upload/File",
      body: e.target.files[0],
      dispatch,
      isFile: true,
    }).then((res) => ({
      ProfileimgName: res.filename,
      userId: records[0]?.EmployeeId,
    }));

    // const userphotodata = {
    //   ProfileimgName: imgfilename,
    //   userId: records[0]?.EmployeeId,
    // };
    await WebService({
      endPoint: "UserProfile/UpdateProfile",
      body: userphotodata,
      dispatch,
    });
    fetchDetails();
  };

  const fnView = async (id) => await viewModalRef.current.openModal(id || 0);
  const requiredMessage = "This is a required field";
  const schema = yup
    .object()
    .shape({
      Assignlocation: yup.string().trim().required(requiredMessage),
    })
    .required();
  const schemaApproveReject = yup
    .object()
    .shape({
      ApproveRejectReason: yup
        .string()
        .trim()
        .label("Remarks")
        .notRequired()
        .max(50),
    })
    .required();
  const [approvedData, setApprovedData] = React.useState({});

  const [approvelocationData, setaAprovelocationData] = React.useState({});

  const onApprovalSubmit = async (data) => {
    if (
      records[0].DOJ == null &&
      data.StatusId == StandardConst.Status.Approved
    ) {
      WSSuccessAlert("Pending", "Please Enter Employee's Date of Joining");

      // Swal.fire({
      //   title: "Pending",
      //   text: "Please Enter Employee's Date of Joining",
      //   icon: "error",
      // });
    } else {
      const result = await WebService({
        endPoint: `UserProfile/Approval`,
        body: {
          ManagerId: data.ManagerId,
          EmployeeId: records[0].EmployeeId,
          Status: data.StatusId,
          Reason: data.ApproveRejectReason,
          TimeZone: DateTime.now().zone.name,
        },
        dispatch,
      }).catch((e) => {
        if (contentSearch(e, "subscription")) {
          navigate("/Subscription");
        }
      });

      if (result.message == "Document Approval is pending") {
        Swal.fire({
          title: "Pending",
          text: result.message,
          icon: "error",
        });
      } else {
        Swal.fire({
          title: findWhere(
            map(StandardConst.Status, (value, key) => ({ value, key })),
            { value: data.StatusId }
          )?.key,
          text: result.message,
          icon: "info",
        });
        fetchDetails();
      }
    }
  };
  const approveRejectEmployee = async (status) => {
    var statusTest = null;
    if (status == 3) {
      statusTest = "Approved";
    } else {
      statusTest = "Rejected";
    }
    if (records[0].DOJ == null && statusTest == "Approved") {
      WSSuccessAlert("Pending", "Please Enter Employee's Date of Joining");

      // Swal.fire({
      //   title: "Pending",
      //   text: "Please Enter Employee's Date of Joining",
      //   icon: "error",
      // });
    } else {
      const result = await WebService({
        endPoint: `UserProfile/CreateEmployeeManager/${status}/${EmpId}/${status}`,
        body: {},
        dispatch,
      });

      if (result.message == "Document Approval is pending") {
        WSSuccessAlert("Pending", "Document Approval is pending");

        // Swal.fire({
        //   title: "Pending",
        //   text: result.message,
        //   icon: "error",
        // });
      } else {
        Swal.fire({
          title: statusTest,
          text: result.message,
          icon: "info",
        });
        fetchDetails();
      }
    }
  };

  const [departmentData, setDepartmentData] = useState([]);
  const getDepartment = () => {
    WebService({
      endPoint: `CommonUtility/static_department`,
      dispatch,
    }).then((data) => {
      setDepartmentData(data);
    });
  };
  const [ManagerData, setManagerData] = useState([]);
  const getManagerByRole = () => {
    WebService({
      endPoint: `UserProfile/FetchManager`,
      dispatch,
    }).then((data) => {
      setManagerData(
        data.map((v) => {
          return {
            value: v.EmployeeId,
            text: v.FullName,
          };
        })
      );
    });
  };
  const [locationData, setlocationData] = useState([]);
  const getsetlocation = () => {
    WebService({
      endPoint: `UserProfile/Fetchlocation`,
      dispatch,
    }).then((data) => {
      setlocationData(
        data.data.map((v) => {
          const words = v.Address.split(' ');
          const truncatedWords = words.slice(0, 3);
          const truncatedAddress = truncatedWords.join(' ');
          const finalAddress = truncatedAddress + ', ' + v.Location;
          return {
            value: v.OfficeLocationId,
            text: finalAddress,
          };
        })
      );
    });
  };
  const [designationData, setDesignationData] = useState([]);
  const [jobFunctionData, setJobFunctionData] = useState([]);
  const getDesignation = () => {
    WebService({
      endPoint: `CommonUtility/staticdesignation`,
      dispatch,
    }).then((data) => {
      setDesignationData(data);
    });
  };
  const getJobFunction = () => {
    WebService({
      endPoint: `CommonUtility/staticjobfunctions`,
      dispatch,
    }).then((data) => setJobFunctionData(data));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      FullName,
      Email,
      Phone,
      Designation,
      EmergencyPhone,
      PermanentAddress,
      CommunicationAddress,
      DOB,
      DOJ,
      Anniversary,
    } = e.target.elements;
    let details = {
      FullName: FullName.value,
      Email: Email.value,
      Phone: Phone.value,
      Designation: Designation.value,
      EmergencyPhone: EmergencyPhone.value,
      PermanentAddress: PermanentAddress.value,
      CommunicationAddress: CommunicationAddress.value,
      DOB: DOB.value,
      DOJ: DOJ.value,
      Anniversary: Anniversary?.value ?? null,
    };
    details.EmployeeId = records[0]?.EmployeeId;
    WebService({
      dispatch,
      endPoint: "UserProfile/Update",
      method: "POST",
      body: details,
    }).then((res) => {
      Swal.fire({
        title: "Profile",
        text: "Profile Updated",
        icon: "info",
      });
      fetchProfile();
      GenerateToken();
    });
  };
  const [open, setOpen] = React.useState(false);
  const [viewDoc, setViewDoc] = React.useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onView = (DocumentId, e) => {
    setViewDoc(
      doc.find((Documentid) => Documentid.DocumentId == DocumentId).FilePath
    );
    handleOpen();
  };

  const createNotification = async () => {
    var Notification = {};
    Notification.Title = "Document Approve Reject";
    Notification.Subject =
      "Your Document " +
      information.DocumentType +
      " Has been " +
      information.Status;
    Notification.Route = "RegistrationApproval";
    Notification.RoleId = 3;
    Notification.Status = 5;
    Notification.EmployeeId = information.EmployeeId;
    await WebService({
      endPoint: `Notification/Create`,
      body: Notification,
      dispatch,
    });
  };

  const onApproveReject = async (DocumentId, e, DocumentName) => {
    var confirmMessage = "You want to ";
    var Status = 0;
    if (e.currentTarget.id == "btnReject") {
      confirmMessage += `Reject the ${DocumentName} for ${records[0].FullName}`;
      Status = 4;
    } else {
      confirmMessage += `Approve the ${DocumentName} for  ${records[0].FullName}`;
      Status = 3;
    }
    Swal.fire({
      title: "Are you sure?",
      text: confirmMessage,
      icon: e.currentTarget.id == "btnReject" ? "warning" : "success",
      showCloseButton: true,

      confirmButtonColor:
        e.currentTarget.id == "btnReject" ? "#FF7300" : "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText:
        e.currentTarget.id == "btnReject" ? "<i class='fa fa-thumbs-down'></i> Reject" : "<i class='fa fa-thumbs-up'></i> Approve",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await WebService({
          endPoint: `UserProfile/UpdateEmployeeDocument/${DocumentId}/${Status}`,
          body: {},
          dispatch,
        }).then(() =>
          Promise.all([
            fetchEmployeeDocuments(),
            WebService({
              endPoint: `userProfile/FetchDocType/${DocumentId}`,
              dispatch,
            }).then((details) => {
              setInformation(details.data[0]);
            }),
          ])
        );
      }
    });
    fetchDetails();
    // set the id of confirm Button and close Button get the button and set the id
    const confirmButton = document.querySelector(".swal2-confirm");
    confirmButton.id = "confirmButton";
    const closeButton = document.querySelector(".swal2-close");
    closeButton.id = "closeButton";
  };

  const onApproveRejectBiometricFace = async (e, BiometricsFaceId, StatusId, ImageUrl) => {

    const biometricImgFolderPath = `${StandardConst.apiBaseUrl}${StandardConst.UploadFolderPaths.Biometrics}/${ImageUrl}`;


    Swal.fire({
      imageUrl: biometricImgFolderPath,
      imageWidth: 400,
      imageAlt: "Custom image",
      title: basicProfile?.FullName,
      // confirmButtonColor: e.currentTarget.id == "btnRejectBiometricFace" ? "#FF7300" : "#3085d6",
      confirmButtonText: e.currentTarget.id == "btnRejectBiometricFace" ? "Reject <i class='fa fa-thumbs-down'></i>" : "<i class='fa fa-thumbs-up'></i> Approve",
      confirmButtonAriaLabel: "Thumbs up, Approve",
      focusConfirm: true,
      showCloseButton: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        await WebService({
          endPoint: `CommonUtility/biometricsface?BiometricsFaceId=${BiometricsFaceId}`,
          body: { StatusId: StatusId },
          method: 'PUT',
          dispatch,
        }).then(() =>
          fetchEmployeeBiometrics()
        );
      }
    });
  };

  const fetchFamily = async () => {
    const data = await WebService({
      endPoint: `FamilyDetails/FetchFamilyDetails/${EmpId || 0}`,
      dispatch,
    });
    setInfo(data.FamilyDetails);
  };

  const onRemove = async (FamilyId) => {
    await WebService({
      endPoint: `CommonUtility/employeefamily?FamilyId=${FamilyId}`,
      method: "DELETE",
      dispatch,
    });
    await fetchFamily();
  };

  const addEditModalRef = useRef();
  const confirmMessage = " Deleted successfully";
  const fnEdit = async (id) =>
    await addEditModalRef.current.openModal(id || 0, EmpId);
  const columns = [
    {
      Text: "Document Name",
      // Value: "DocumentType",
      render: (dr) => (
        <span>
          {dr.DocumentType} &nbsp;
          {dr.Status == "Pending" ? (
            <HourglassEmptyIcon fontSize="10" color="primary" />
          ) : dr.Status == "Approved" ? (
            <CheckCircleIcon fontSize="10" color="success" />
          ) : (
            <CancelIcon fontSize="10" color="error" />
          )}
        </span>
      ),
    },
    {
      Text: "Document Number",
      Value: "Number",
    },
    {
      Text: "Status",
      render: (dr) => (
        <span
          className={`${dr.Status == "Pending"
              ? "text-primary"
              : dr.Status == "Approved"
                ? "text-success"
                : "error"
            }`}
        >
          {dr.Status}
        </span>
      ),
    },
    {
      Text: "Action",
      key: "DocumentId",
      cssClass: "text-end",
      isVisiable:
        permission.ManageReject ||
        permission.ManageApprove ||
        permission.ManageView,
      render: (dr) => (
        <>
          <ActionButton
            onClick={() =>
              window.open(
                `${StandardConst.apiBaseUrl}/uploads/${dr.FilePath}`,
                "_blank"
              )
            }
            // onClick={(e) => onView(dr.DocumentId, e)}
            IconName="View"
            id="btnView"
          />
          <button
            id="btnApprove"
            className={`btn btn-default mx-2 p-0 border-none ${!permission.ManageApprove
                ? "d-none"
                : `${dr.StatusId == StandardConst.Status.Approved ? "d-none" : "d-inline"}`
              }`}
            onClick={(e) => onApproveReject(dr.DocumentId, e, dr.DocumentType)}
          >
            <i className="fa fa-check-circle text-primary"></i>
          </button>

          <button
            id="btnReject"
            className={`btn btn-default mx-2 p-0 border-none ${!permission.ManageReject
                ? "d-none"
                : `${dr.StatusId == StandardConst.Status.Approved ? "d-none" : "d-inline"}`
              }`}
            onClick={(e) => onApproveReject(dr.DocumentId, e, dr.DocumentType)}
          >
            <i className="fa fa-ban text-danger"></i>
          </button>
        </>
      ),
    },
  ];
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const details = [
    {
      Text: "Name",
      Value: "Name",
    },
    {
      Text: "Relation",
      Value: "Relation",
    },
    {
      Text: "Age",
      Value: "Age",
    },
    {
      Text: "Gender",
      Value: "Gender",
    },
    {
      Text: "Blood Group",
      Value: "BloodGroup",
    },
    {
      Text: "Action",
      key: "FamilyId",
      cssClass: "text-center td-width-100",
      //isVisiable: permission.ManageEdit || permission.ManageDelete,
      Template: (
        <>
          <ActionButton
            onClick={(e) =>
              fnEdit(e.currentTarget.parentElement.parentElement.getAttribute("data-key"))
            }
            disabled={((permission.ManageFamilyEdit && (loggedUserDetails === records[0]?.EmployeeId)) || permission.ManageAllFamilyEdit) ? false : true}
            IconName="Edit"
            id="btnFamilyEdit"
          />

          <ActionButton
            onClick={(e) =>
              ref.current.confirmAlert(
                "Delete", //Confirm button text
                "Are You Sure", // Text if Alert
                "Do you want to delete ", // Message of Alert
                e.currentTarget.parentElement.parentElement.getAttribute("data-key") // Endpoint to hit for delete
              )
            }
            disabled={((permission.ManageFamilyDelete && (loggedUserDetails === records[0]?.EmployeeId)) || permission.ManageAllFamilyDelete) ? false : true}
            IconName="Delete"
            id="btnFamilyDelete"
          />
        </>
      ),
    },
  ];
  const GetTokenComponentRef = useRef();
  const scroll = (ref) => {
    const section = document.querySelector(ref);
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  //Added for Form Submit
  const onSubmitUserProfile = async (data) => {

    if(selectQualificationData.length > 0) {
      data.QualificationData = selectQualificationData;
    }else{
      setAutoErrorShow(false);
      WSErrorAlert("Error", "atleast one of the qualification you want to choose in dropdown");
      return;
    }

    if (data.DOB != null) data.DOB = DateTime.fromJSDate(data.DOB).toSQLDate();
    if (data.DOJ != null) data.DOJ = DateTime.fromJSDate(data.DOJ).toSQLDate();
    await WebService({
      endPoint: `UserProfile/SubmitUserProfile`,
      body: data,
      dispatch,
    });

    if(data.EmployeeId !== undefined) {
      customFieldsData.map(item => item.FieldValue = data[item.FieldName]);
      await saveCustomFieldData(data.EmployeeId);
    }
    WSSuccessAlert("Success", "Profile information updated successfully.");
    // Swal.fire({
    //   title: "Profile",
    //   text: "Profile Updated",
    //   icon: "info",
    // });
    fetchDetails();
  };

  const saveCustomFieldData = async (EmployeeId) => {
    if((customFieldsData.length > 0) && (EmployeeId !== null)){
        const AllCustomFieldsValueDelete = await WebService({
            endPoint: `CommonUtility/employeecustomfieldvalues?EmployeeId=${EmployeeId}`,
            method: "DELETE",
            // dispatch,
        }).then((res) => true);
        
        if(AllCustomFieldsValueDelete){
            customFieldsData.map(async (item) => {
                if(item.FieldType === StandardConst.dataTypes.Date){
                  item.FieldValue = format(new Date(item.FieldValue), "yyyy-MM-dd");
                }else if(item.FieldType === StandardConst.dataTypes.Currency){
                  item.FieldValue = parseInt(item.FieldValue.replace(/[^0-9]/g, ''), 10);
                }
                item['EmployeeId'] = EmployeeId;
                await WebService({
                    endPoint: `CommonUtility/employeecustomfieldvalues`,
                    body: item,
                    method: 'POST',
                    // dispatch,
                })
            })
        }
    }
  };

  //Added for Form submit
  //#region Basic Information
  const [basicProfile, setBasicProfile] = useState({});
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const [schemaState, setSchemaState] = useState({});
  const [customFieldsData, setCustomFieldsData] = useState([]);
  const staticEmployeeSchema = {
    FullName: yup.string().trim().label("Name").required(requiredMessage),
    Email: yup
      .string()
      .trim()
      .label("Email")
      .email()
      .required(requiredMessage),
    DesignationId: yup
      .string()
      .trim()
      .label("Designation")
      .required(requiredMessage),

    Phone: yup.string()
      .required(StandardConst.requiredMessage)
      .matches(StandardConst.PhoneNumberValidateRegex, StandardConst.ValidationMessages.PhoneNoIsNotValid),

    EmergencyPhone: yup.string()
      .required(StandardConst.requiredMessage)
      .matches(StandardConst.PhoneNumberValidateRegex, StandardConst.ValidationMessages.PhoneNoIsNotValid),
      
    PermanentAddress: yup
      .string()
      .trim()
      .label("Permanent Address")
      .required(requiredMessage)
      .max(100),
    CommunicationAddress: yup
      .string()
      .trim()
      .label("Communication Address")
      .required(requiredMessage)
      .max(100),
    DOB: yup
      .date()
      .label("Date of Birth")
      .typeError(requiredMessage)
      .required(requiredMessage),
    DOJ: yup
      .date()
      .label("Joining Date")
      .typeError(requiredMessage)
      .required(requiredMessage),
  };

  const submitSchema = yup
    .object()
    .shape(schemaState)
    .required();

  
    const fetchCustomfields = async () => {
      return await WebService({
        endPoint: `UserProfile/fetchEmployeeCustomFields?CountryId=${CompanyInfo?.CountryId}`,
        dispatch,
      }).then((res) => {
        res.map(item => {
            if(item.FieldIsRequired === StandardConst.YesNo.Yes) {
                if(item.FieldType === StandardConst.dataTypes.String){
                  staticEmployeeSchema[item.FieldName] = yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg);
                }else if(item.FieldType === StandardConst.dataTypes.Date){
                  staticEmployeeSchema[item.FieldName] = yup.date().typeError(StandardConst.ValidationMessages.RequiredFieldMsg).required(StandardConst.ValidationMessages.RequiredFieldMsg);
                }else if(item.FieldType === StandardConst.dataTypes.Number || item.FieldType === StandardConst.dataTypes.Number){
                  staticEmployeeSchema[item.FieldName] = yup.number().typeError(StandardConst.ValidationMessages.InvalidCharMsg).required(StandardConst.ValidationMessages.RequiredFieldMsg);
                }
            }
        })
        setSchemaState(staticEmployeeSchema);
        const sortedDataToAscendingOrder = res.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
        setCustomFieldsData(sortedDataToAscendingOrder);
        return res;
      });
    };

    const [selectedIdCard, setSelectedIdCard] = useState([]);
    const fetchEmployeeDetails = async(employeeId) => {
      const data = await WebService({
        endPoint: `EmployeeIdCard/FetchActiveIdCard/${employeeId}`,
        dispatch,
      });
  
      const Details = data.IdCardDetails[0];
      setSelectedIdCard([{text: Details.badge_template_name, value: Details.badge_templatesId}]);
      const companyString = Details.CompanyName.split(' ');
        const companyFormattedText = companyString.length >= 5 ? companyString.slice(0, 5).join(' ') : null;
  
        const nameString = Details.FullName.toUpperCase().split(' ');
        const nameFormattedText =nameString.length >= 3 ?nameString.slice(0, 3).join(' ') : null;
  
      const paramData = {
        CompanyName: companyFormattedText || Details.CompanyName,
        Logo: CompanyInfo.Logo,
        ProfileImage: Details?.ProfileImage,
        FullName: nameFormattedText || Details.FullName.toUpperCase(),
        EmployeeCode: Details?.EmployeeCode?.toUpperCase(),
        PermanentAddress: Details.PermanentAddress,
        Email: Details.EmployeeEmail,
        Designation: Details.Designation,
        Phone: Details.Phone,
        Address: Details.Address,
        CommunicationAddress: Details.CommunicationAddress,
        CompanyPhone: Details.CompanyPhone,
        EmployeeGuid: Details.Guid,
        QRData: 
            "Name: " + Details.FullName + 
            "Company: " + Details.CompanyName + 
            "Email: " + Details.Address + 
            "Phone: " + Details.Phone + 
            "Code: " + Details.EmployeeCode
      }
      await GenerateBadgeRef.current.openModal(paramData);
    }

    const handleQualificationChange = (event, selectedOptions) => {
        (selectedOptions.length === 0) ? setAutoErrorShow(true) : setAutoErrorShow(false);
        setSelectQualificationData(selectedOptions);
    };

  const personalInfoForm = (
    <Form
      defaultValues={basicProfile}
      onSubmit={onSubmitUserProfile}
      validationSchema={submitSchema}
      ref={frmUserProfileRef}
    >
      <div className="card shadow-none border-0  border-start border-bottom footer-widget">
        <Alert severity="info">Personal Information</Alert>
        <div className="card-body">
          <div className="row mt-1">
            <div className="col-md-12">
              <FormInputText
                label="Name"
                name="FullName"
                type="text"
                isRequired="true"
              />
            </div>
          </div>
          <div
            className={`row ${basicProfile.StatusId === StandardConst.Status.Reject
                ? "d-none"
                : ""
              }`}
          >
            <div className="col-md-12">
              <FormInputText
                label="Employee Code"
                name="EmployeeCode"
                type="text"
                disabled={
                  basicProfile.StatusId !== StandardConst.Status.Pending
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-1">
              <FormInputText
                label="Email"
                name="Email"
                type="text"
                isRequired="true"
                disabled
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <FormInputDropdown
                name="DesignationId"
                ddOpt={[{}].concat(
                  designationData.map((m) => ({
                    value: m.DesignationId,
                    text: m.Designation,
                  }))
                )}
                label="Select Designation"
                isRequired="true"
              ></FormInputDropdown>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <FormInputDropdown
                name="Department"
                ddOpt={[{}].concat(
                  departmentData.map((m) => ({
                    value: m.DepartmentId,
                    text: m.DepartmentName,
                  }))
                )}
                label="Select Department"
              // isRequired="true"
              ></FormInputDropdown>
            </div>
          </div>

          {/* <div className="row">
            <div className="col-md-12">
              <FormInputDropdown
                name="JobFunctionId"
                ddOpt={jobFunctionData.map((m) => ({
                  value: m.JobFunctionId,
                  text: m.JobFunction,
                }))}
                label="Job Function"
                isRequired="true"
              ></FormInputDropdown>
            </div>
          </div> */}
          <div className="row">
            <div className="col-md-12  mt-1">
              <FormInputText
                label="Date of Birth"
                name="DOB"
                type="date"
                max={DateTime.now().minus({ year: 18 }).toSQLDate()}
                setValue={(v) => setBasicProfile((dt) => ({ ...dt, DOB: v }))}
                isRequired="true"
                portalId="root"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-1">
              <FormInputDropdown
                name="RelationShip"
                ddOpt={[{}].concat(StandardConst.Relationship)}
                label="Relationship"
                setValue={async (v) => {
                  setBasicProfile((dt) => ({ ...dt, RelationShip: v }));
                }}
              ></FormInputDropdown>
            </div>
          </div>
          {/* {show == "Married" || */}
          {/* records[0]?.Anniversary > "01-01-1970" ? ( */}
          {(basicProfile?.RelationShip ?? "") === "Married" && (
            <div className="mt-1">
              <FormInputText
                label="Anniversary Date"
                name="Anniversary"
                max={DateTime.now().toSQLDate()}
                min={(basicProfile?.DOB ?? "") === "" ? null : basicProfile.DOB}
                type="date"
                portalId="root"
              />
            </div>
          )}

          {/* // ) : ( // "" // )} */}
          <div className="mx-n4 my-2">
            {" "}
            <Alert severity="info">Other Information</Alert>
          </div>
          <div className="row">
            <div className="col-md-12">
              <FormInputText
                label="Phone"
                name="Phone"
                type="text"
                isRequired="true"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <FormInputText
                label="Emergency Phone"
                name="EmergencyPhone"
                type="text"
                isRequired="true"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <FormInputText
                label="Permanent Address"
                name="PermanentAddress"
                as="textarea"
                isRequired="true"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-1">
              <FormInputText
                label="Communication Address"
                name="CommunicationAddress"
                as="textarea"
                isRequired="true"
              />
            </div>
          </div>

          <div className="col-md-12 mt-1">
              <div className="row">
                  <div className="col-md-5 d-flex align-items-center justify-content-between" style={{fontSize: "14px"}}>
                      <label className="d-flex">Qualification <div className="text-danger">＊</div></label>
                  </div>
                  <div className="col-md-7" style={{paddingLeft: "0px"}}>
                      <Autocomplete
                          multiple
                          id="grouped-demo"
                          options={qualificationMasterList}
                          getOptionLabel={(option) => option?.text}

                          defaultValue={
                            selectQualificationData?.map(selectedData => qualificationMasterList[qualificationMasterList?.findIndex(pitem => pitem.value === selectedData.value)])
                          }
                          
                          onChange={handleQualificationChange}
                          filterSelectedOptions
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  placeholder="Qualification"
                                  error={autoErrorShow} // Set error state of TextField
                                  helperText={(autoErrorShow) && "Atleast one qualification you want to select."} // Display error message if error is true
                              />
                          )}
                      />
                  </div>
              </div>
          </div>


          <div className="col-md-12">
            <div className="col-md-12">
              
            </div>{" "}
          </div>
          <div className="col-md-12">
            <div>
              <FormInputText
                label="Joining Date"
                name="DOJ"
                min={basicProfile.DOB}
                max={DateTime.now().toSQLDate()}
                type="date"
                isRequired="true"
                portalId="root"
              />
            </div>
          </div>
          {(customFieldsData.length > 0) && (
            customFieldsData.map(CustomField => {
              const FieldType = CustomField.FieldType;
              // const FieldValue = (CustomField.FieldValue !== undefined) ? CustomField.FieldValue : "";
              if(FieldType === StandardConst.dataTypes.Date){
                  return <div className="col-md-12">
                      <FormInputText
                          label={CustomField.FieldLabel}
                          name={CustomField.FieldName}
                          type="date"
                          portalId="root"
                          isRequired={CustomField.FieldIsRequired === StandardConst.YesNo.Yes ? "true" : "false"}
                          setValue={(val) => CustomField['FieldValue'] = val}
                      />
                  </div>
              }else if(FieldType === StandardConst.dataTypes.Currency){
                return <div className="col-md-12">
                    <FormInputText
                        label={CustomField.FieldLabel}
                        name={CustomField.FieldName}
                        type="currency"
                        locale={CompanyInfo?.LocaleCode}
                        currencyCode={CompanyInfo?.CurrencyCode}
                        isRequired={CustomField.FieldIsRequired === StandardConst.YesNo.Yes ? "true" : "false"}
                        setValue={(val) => CustomField['FieldValue'] = val}
                    />
                </div>
              }else{
                  return <div className="col-md-12">
                      <FormInputText
                          label={CustomField.FieldLabel}
                          name={CustomField.FieldName}
                          type={FieldType}
                          isRequired={CustomField.FieldIsRequired === StandardConst.YesNo.Yes ? "true" : "false"}
                          setValue={(val) => CustomField['FieldValue'] = val}
                      />
                  </div>
              }
            })
          )}
        </div>

        {((permission.ManageProfileSave && (loggedUserDetails === records[0]?.EmployeeId)) || permission.ManageAllProfileSave) && (
          <div className="card-footer mt-2">
            <Button
              variant="outline-primary"
              type="submit"
              id="btnSubmit"
              className="px-4 float-end"
            >
              Save Changes
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
  //#endregion


  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // function TabPanel(props) {
  //   const { children, value, index, ...other } = props;

  //   return (
  //     <div
  //       role="tabpanel"
  //       hidden={value !== index}
  //       id={`full-width-tabpanel-${index}`}
  //       aria-labelledby={`full-width-tab-${index}`}
  //       {...other}
  //     >
  //       {value === index && (
  //         <Box sx={{ p: 0, mt: 0 }}>
  //           <span>{children}</span>
  //         </Box>
  //       )}
  //     </div>
  //   );
  // }

  // TabPanel.propTypes = {
  //   children: PropTypes.node,
  //   index: PropTypes.number.isRequired,
  //   value: PropTypes.number.isRequired,
  // };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  //Tab End

  const [registerDocumentData, setRegisterDocumentData] = useState({
    File: null,
  });
  const [employeeDocuments, setEmployeeDocuments] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const EmployeeId = useSelector((s) => s.auth.LoggedUser ?? 0);
  const [dense, setDense] = React.useState(false);
  const Demo = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));
  
  const onSubmitDocument = async (data) => {
    data.FilePath = await WebService({
      endPoint: "upload/File",
      dispatch,
      body: data.File,
      isFile: true,
    }).then((res) => res.filename);
    data.EmployeeId = EmpId ? EmpId : EmployeeId;
    await WebService({
      dispatch,
      endPoint: "User/Document",
      body: extend(omit(data, ["File"])),
    });
    await fetchEmployeeDocuments();
    resetDocumentForm.current.fnReset({ File: null });
  };

  const schemaDocumentUpload = yup
  .object()
  .shape({
    DocumentTypeId: yup.string().trim().required(requiredMessage),
    Number: yup.string().trim().required(requiredMessage),
    File: yup.mixed().required(),
  })
  .required();

  const resetDocumentForm = useRef();

  const fnRemaningDocType = () => {
    return filter(
      dataClone(documentData ?? []),
      (f) =>
        where(dataClone(employeeDocuments ?? []), { DocumentTypeId: f.value })
          .length < 1
    );
  };

  const Grid = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const getDocumentType = () => {
    WebService({
      endPoint: `DocumentType/Fetch?CompanyId=${(CompanyInfo ?? {}).CompanyId}`,
      dispatch,
    }).then((data) => {
      setDocumentData(
        data.map((v) => {
          return {
            value: v.DocumentTypeId,
            text: v.DocumentType,
          };
        })
      );
    });
  };

  useEffect(() => {
    if (((CompanyInfo ?? {}).CompanyId ?? 0) != 0) getDocumentType();
    if (((CompanyInfo ?? {}).CountryId ?? 0) != 0) fetchCustomfields();
  }, [CompanyInfo]);

  // if(!isDataLoaded){
  //   return (<></>);
  // }
  if (!isDataLoaded) {
    return <div></div>;
  }

  return (
    <>

      <Container
        fluid
        className="base-container"
        // style={{
        //   padding: "10px",
        //   // "background-color": "#FFF",
        //   borderRadius: "5px ",
        //   // margin: "10px",
        //   backgroundColor: "#fff",
        // }}
      >
        <Box>
          <AppBar
            position="static"
            color="info"
            elevation={0}
            className="Maintab_Custome"
            style={{ maxHeight: "40px", height: "40px" }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              // indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
              style={{
                maxHeight: "40px",
                height: "40px",
              }}
              id="tabs"
            >
              <Tab
                title="Profile"
                value={0}
                sx={{ "min-width": "40px !important" }}
                icon={<AccountBoxIcon />}
                aria-label="person"
                label={window.innerWidth <= 600 ? '' : 'Profile'}
                iconPosition="start"
                className="tab_Custome pt-0"
                id="profile"
              />

              {((permission.ManageDocumentTabView && (loggedUserDetails === records[0]?.EmployeeId)) || permission.ManageAllDocumentTabView) && (
                <Tab
                  value={1}
                  sx={{ "min-width": "40px !important" }}
                  icon={<ArticleIcon />}
                  aria-label="Documents"
                  label={window.innerWidth <= 600 ? '' : 'Documents'}
                  iconPosition="start"
                  className="tab_Custome pt-0"
                  id="documents"
                />
              )}

              {((permission.ManageFamiliesTabView && (loggedUserDetails === records[0]?.EmployeeId)) || permission.ManageAllFamiliesTabView) && (
                <Tab
                  value={2}
                  sx={{ "min-width": "40px !important" }}
                  icon={<Diversity3Icon />}
                  aria-label="Families"
                  label={window.innerWidth <= 600 ? '' : 'Families'}
                  iconPosition="start"
                  className="tab_Custome pt-0"
                  id="families"
                />
              )}

              {((permission.ManageFingerPrintBiometricTabView && (loggedUserDetails === records[0]?.EmployeeId)) || permission.ManageAllFingerPrintBiometricTabView) && (
                <Tab
                  value={3}
                  sx={{ "min-width": "40px !important" }}
                  icon={<FingerprintIcon />}
                  aria-label="phone"
                  label={window.innerWidth <= 600 ? '' : 'Biometric'}
                  iconPosition="start"
                  className="tab_Custome pt-0"
                  id="biometric"
                />
              )}

              {((permission.ManageFaceBiometricTabView && (loggedUserDetails === records[0]?.EmployeeId)) || permission.ManageAllFaceBiometricTabView) && (
                <Tab
                  value={4}
                  sx={{ "min-width": "40px !important" }}
                  icon={<EmojiEmotionsIcon />}
                  aria-label="favorite"
                  label={window.innerWidth <= 600 ? '' : 'Face'}
                  iconPosition="start"
                  className="tab_Custome pt-0"
                  id="face"
                />
              )}
              <Tab
                value={5}
                sx={{ "min-width": "40px !important" }}
                icon={<ArticleIcon />}
                aria-label="Assets"
                label={window.innerWidth <= 600 ? '' : 'Assets'}
                iconPosition="start"
                className="tab_Custome pt-0"
                id="Assets"
              />

            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >

            <div id="0">
              <Container className="base-container" fluid>
                <div>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <img src={` ${StandardConst.uploadImages}${viewDoc}`} alt="" />
                    </Box>
                  </Modal>
                </div>

                <div className="row p-0 mb-2 d-flex flex-row">
                  <div className="col-md-4">
                    <form onSubmit={handleSubmit}>
                      {records.map((user) => (
                        <div className="card shadow-none border-0 border-end border-bottom bg-light footer-widget">
                          <div className="card-body">
                            <div className="d-flex flex-column align-items-center text-center">
                              <div className="pic-holder">
                                <img
                                  className="pic rounded-circle"
                                  src={
                                    dp != ""
                                      ? `${StandardConst.apiBaseUrl}/uploads/${dp ?? "user_placeholder.jpg"
                                      }`
                                      : `${StandardConst.apiBaseUrl}/uploads/user_placeholder.jpg`
                                  }
                                  alt=""
                                />
                                <input
                                  className="uploadProfileInput"
                                  type="file"
                                  name="File"
                                  id="newProfilePhoto"
                                  accept="image/*"
                                  style={{ opacity: "0", display: "contents" }}
                                  onChange={saveFile}
                                />

                                {(loggedUserDetails === records[0]?.EmployeeId || permission.ManageAllProfilePictureUpload) && (
                                  <label
                                    htmlFor="newProfilePhoto"
                                    className="upload-file-block"
                                  >
                                    <div className="text-center">
                                      <div className="mb-1">
                                        <i className="fa fa-camera fa-2x"></i>
                                      </div>
                                      <div className="text-uppercase">
                                        Update <br /> Profile Photo
                                      </div>
                                    </div>
                                  </label>
                                )}
                              </div>
                              {/* <button
                        style={{ marginTop: "-50px", left: "30px" }}
                        id="btnProfileUpload"
                        onClick={onSubmitDocument}
                        className="btn btn-success btn-circle btn-xl"
                      >
                        <i className="fa fa-upload"></i>
                      </button> */}

                              <div className="d-flex flex-column mt-1">
                                <div>
                                  <h4 className="m-0 d-flex flex-column align-items-center">
                                    {user.FullName}
                                  </h4>
                                  <div>{user.Designation}</div>
                                  <div>
                                    {user.Status === "Approved" ? (
                                      <span className="badge text-bg-success">
                                        {user.Status}
                                      </span>
                                    ) : (
                                      <span className="badge text-bg-danger rounded-pill">
                                        {user.Status}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="d-flex flex-column align-items-start mt-2">
                              <div className="text-secondary">
                                <i className="fa fa-user mx-1" aria-hidden="true"></i>
                                {user.EmployeeCode}
                              </div>
                              <div className="text-secondary">
                                <i className="fa fa-envelope mx-1" aria-hidden="true"></i>
                                {user.Email}
                              </div>
                              <div className="text-secondary">
                                <i className="fa fa-phone mx-1" aria-hidden="true"></i>
                                {user.Phone}
                              </div>
                            </div>
                            <hr></hr>
                            <div className="d-flex flex-column align-items-start mt-2">
                              <div className="text-secondary">
                                <i className="fa fa-calendar mx-1" aria-hidden="true"></i>
                                Hire Date:{" "}
                                {user.DOJ ?? (
                                  <span className="text-danger">
                                    <small>Not Assigned</small>
                                  </span>
                                )}
                              </div>
                              {/* <div className="text-secondary"> */}
                              {/* {user.RelationShip} */}
                              {/* {user.Anniversary == null
                          ? "Unmarried"
                          : `Anniversary: ${user.Anniversary}`} */}
                              {/* </div> */}
                              {/* <div className="text-secondary">
                                <i className="fa fa-map-marker mx-1" aria-hidden="true"></i>
                                {user.Location ?? (
                                  <span className="text-danger">
                                    <small>Not Assigned</small>
                                  </span>
                                )}
                              </div>
                              <div className="text-secondary">
                                <i className="fa fa-map mx-1" aria-hidden="true"></i>
                                {user.Address ?? (
                                  <span className="text-danger">
                                    <small>Not Assigned</small>
                                  </span>
                                )}
                              </div> */}
                            </div>
                            <hr></hr>
                            <div>
                              <div className="d-flex justify-content-between">
                                <span className="text-success h6">Manager</span>
                                {basicProfile.StatusId ==
                                  StandardConst.Status.Approved &&
                                  basicProfile.EmployeeId != loggedUser && permission.ManageAllManagerChange && (
                                    <IconButton>
                                      <ModeEditOutlineOutlinedIcon
                                        sx={{ fontSize: 20 }}
                                        color="primary"
                                        onClick={async () =>
                                          await reassignManagerRef.current.openModal(
                                            basicProfile.EmployeeId
                                          )
                                        }
                                      />
                                    </IconButton>
                                  )}
                              </div>

                              <div className="d-flex flex-rows align-items-center">
                                <div>
                                  <img
                                    src={
                                      user.ManagerProfileImage != ""
                                        ? `${StandardConst.apiBaseUrl}/uploads/${user.ManagerProfileImage ??
                                        "user_placeholder.jpg"
                                        }`
                                        : `${StandardConst.apiBaseUrl}/uploads/user_placeholder.jpg`
                                    }
                                    alt=""
                                    className="avatar-md rounded-circle img-thumbnail mt-1"
                                  />
                                </div>
                                <div
                                  className="flex-1 ms-2"
                                  onClick={() => scroll("#contact-us")}
                                >
                                  <h6 className="mb-1">
                                    {user.EmployeeManager ?? (
                                      <span className="text-danger">
                                        <small>Not Assigned</small>
                                      </span>
                                    )}
                                  </h6>
                                  <span className="badge badge-soft-success mb-0">
                                    {user.ManagerDesignation}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <hr></hr>

                            <div>
                              {(basicProfile.StatusId === StandardConst.Status.Pending ||
                                (basicProfile.StatusId === StandardConst.Status.Approved &&
                                  basicProfile.EmployeeId !== loggedUser) ||
                                (basicProfile.EmployeeId === loggedUser &&
                                  loggedUserAssignRoles.filter(
                                    (f) => f === StandardConst.SystemRole.Company
                                  ).length > 0)) && (
                                  <>
                                    <div className="d-flex justify-content-between">
                                      <span className="text-success h6">Office location</span>

                                      {(permission.ManageAllOfficeLocationChange) && (
                                        <IconButton>
                                          <ModeEditOutlineOutlinedIcon
                                            sx={{ fontSize: 20 }}
                                            color="primary"
                                            onClick={async () =>
                                              await reassignOfficeLocationRef.current.openModal(
                                                basicProfile.EmployeeId
                                              )
                                            }
                                          />
                                        </IconButton>
                                      )}
                                    </div>
                                    <div className="d-flex flex-column align-items-start mt-2">
                                      {/* <div className="text-secondary">
                                            Office Location:{" "}
                                                  {userLocation ?? (
                                                    <span className="text-danger">
                                                      Not Assigned
                                                    </span>
                                                  )}
                                          </div> */}

                                      <div className="text-secondary">
                                        <i className="fa fa-map-marker mx-1" aria-hidden="true"></i>
                                        {user.Location ?? (
                                          <span className="text-danger">
                                            <small>Not Assigned</small>
                                          </span>
                                        )}
                                      </div>
                                      <div className="text-secondary">
                                        <i className="fa fa-map mx-1" aria-hidden="true"></i>
                                        {user.Address ?? (
                                          <span className="text-danger">
                                            <small>Not Assigned</small>
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <hr></hr>
                                  </>
                                )}
                            </div>
                            {/* ===================== */}
                          </div>
                          <div className="card-footer mt-2">
                            <div className="d-flex  justify-content-around">
                              {/* <button
                        onClick={GetTokenComponentRef.current.getToken}
                        className="btn btn-success mx-2 d-none"
                        type="button"
                      >
                        {" "}
                        <i className="fa fa-key" aria-hidden="true"></i> Token
                      </button> */}
                              <button
                                type="button"
                                id="btn_Show_ID_Card"
                                className="btn btn-outline-primary mx-2"
                                disabled={
                                  (basicProfile?.CardStatusId ?? 2) === 2
                                  // (basicProfile?.IsIdCardActive ?? "No") === "No"
                                }
                                // onClick={async () =>await viewIDCardRef.current.openModal(basicProfile.EmployeeId)}
                                onClick={async () => await fetchEmployeeDetails(basicProfile.EmployeeId)}
                              >
                                <i className="fa fa-cloud-download" aria-hidden="true"></i>{" "}
                                ID Card
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </form>
                  </div>

                  <div className="col-md-8">{personalInfoForm}</div>
                </div>

                <div>
                  {records.map((user) => (
                    <div key={user.EmpId}>
                      <div>
                        {/* For this document section we create new tab for this */}
                        {/* <Paper elevation={1} className="mb-3">
                          <Alert severity="info">
                            <strong>Documents</strong>
                          </Alert>
                          <TableComponent
                            columns={columns}
                            data={doc}
                            IsAddButtonVisible={false}
                            isSearchRequired={false}
                          />
                        </Paper> */}
                        <DeleteConfirmAlert
                          ref={ref}
                          confirmEvent={(v) => onRemove(v)}
                        />
                        {/* This Family Details commented out becouse This section have new tab */}
                        {/* <Paper elevation={2} className="mb-2">
                          <Alert severity="info">
                            <strong>Family Details</strong>
                          </Alert>

                          <TableComponent
                            columns={details}
                            data={info}
                            onAddEvent={() => fnEdit()}
                            isSearchRequired={false}
                          />
                          <AddEditFamilyDetails
                            callBackEvent={() => fetchFamily()}
                            ref={addEditModalRef}
                          ></AddEditFamilyDetails>
                        </Paper> */}

                        {/* This office location section commented out because this section sift on profile section */}
                        {/* <div className="row mb-2">
                          {(basicProfile.StatusId === StandardConst.Status.Pending ||
                            (basicProfile.StatusId === StandardConst.Status.Approved &&
                              basicProfile.EmployeeId !== loggedUser) ||
                            (basicProfile.EmployeeId === loggedUser &&
                              loggedUserAssignRoles.filter(
                                (f) => f === StandardConst.SystemRole.Company
                              ).length > 0)) && (
                              <div className="col-sm-12">
                                <Form
                                  defaultValues={approvelocationData}
                                  validationSchema={schema}
                                >
                                  <Paper elevation={1}>
                                    <Alert severity="info">
                                      <strong>Assign Employee Office location</strong>
                                    </Alert>
                                    <div className="row d-flex justify-content-start pl-10 pr-10">
                                      <div className="col-md-2 my-3 ">
                                        <label className="ws-font-14">
                                          Office Location:
                                        </label>
                                      </div>
                                      <div className="col-md-2 my-3">
                                        <label className="ws-font-14">
                                          {" "}
                                          {userLocation ?? (
                                            <span className="text-danger">
                                              Not Assigned
                                            </span>
                                          )}
                                        </label>
                                      </div>
                                      <div className="col-md-2 my-3">
                                        <label className="ws-font-14">
                                          Select Location:
                                        </label>
                                      </div>
                                      <div className="col-md-3 my-3">
                                        <FormInputDropdown
                                          name="OfficeLocationId"
                                          id="OfficeLocationId"
                                          ddOpt={locationData}
                                          setValue={(v) =>
                                            setlocatiinData({
                                              ...officelocationData,
                                              OfficeLocationId: v,
                                            })
                                          }
                                        ></FormInputDropdown>
                                      </div>

                                      <div className="col-md-2 my-3">
                                        <ButtonGroup
                                          disableElevation
                                          aria-label="Disabled elevation buttons"
                                        >
                                          <Button
                                            variant="outline-primary"
                                            type="button"
                                            id="btnAssignOfficelocation"
                                            onClick={() => assignOfficelocation()}
                                          >
                                            Assign Location
                                          </Button>
                                        </ButtonGroup>
                                      </div>
                                    </div>
                                  </Paper>
                                </Form>
                              </div>
                            )}
                        </div> */}
                        <div className="row mb-2" id="contact-us">
                          <div
                            className={`col-sm-12 ${records[0].StatusId == 3 ? "d-none" : ""
                              }`}
                          >
                            <Paper elevation={1} className="my-2">
                              <Alert severity="info">
                                <strong> Assign Manager</strong>
                              </Alert>
                              <div className="row d-flex justify-content-start pl-10 pr-10">
                                <div className="col-md-2 my-3">
                                  <label className="ws-font-14">Select Manager</label>
                                </div>
                                <div className="col-md-4 my-3">
                                  <InputDropdown
                                    ddOpt={ManagerData}
                                    value={ApproveData.ManagerId}
                                    setValue={(v) =>
                                      setData({ ...ApproveData, ManagerId: v })
                                    }
                                  />
                                </div>
                              </div>
                            </Paper>
                            <Paper elevation={1} className="my-2">
                              <div className="row d-flex justify-content-between  pl-10 pr-10">
                                <div className="col-md-4 mt-3 mb-2 ">
                                  <InputText
                                    label="Remarks"
                                    name="ApproveRejectReason"
                                    as="textarea"
                                    value={ApproveData.ApproveRejectReason}
                                    setValue={(v) =>
                                      setData({
                                        ...ApproveData,
                                        ApproveRejectReason: v,
                                      })
                                    }
                                  />
                                </div>
                                <div className="col-md-4 mt-3">
                                  <ButtonGroup
                                    disableElevation
                                    aria-label="Disabled elevation buttons"
                                  >
                                    <Button
                                      variant="outline-primary"
                                      id="btnApproveRejectEmployee"
                                      onClick={() =>
                                        onApprovalSubmit(
                                          extend(ApproveData, {
                                            StatusId: StandardConst.Status.Approved,
                                          })
                                        )
                                      }
                                    >
                                      Approve Employee
                                    </Button>
                                    <Button
                                      variant="outline-danger"
                                      id="btnProfileReject"
                                      onClick={() =>
                                        onApprovalSubmit(
                                          extend(ApproveData, {
                                            StatusId: StandardConst.Status.Reject,
                                          })
                                        )
                                      }
                                    >
                                      Reject Employee
                                    </Button>
                                  </ButtonGroup>
                                </div>
                              </div>
                            </Paper>
                            {/* </Form> */}

                            <ViewDocument ref={viewModalRef} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Container>
              <GetTokenComponent ref={GetTokenComponentRef} />
              {/* <ViewIDCard ref={viewIDCardRef} /> */}
              <GenerateBadge ref={GenerateBadgeRef} SelectedTemplate={selectedIdCard} />
              <ReassignManager
                ref={reassignManagerRef}
                onSubmited={() => fetchDetails()}
              />
              <ReassignOfficeLocation
                ref={reassignOfficeLocationRef}
                onSubmited={() => fetchDetails()}
              />
            </div>

            {((permission.ManageDocumentTabView && (loggedUserDetails === records[0]?.EmployeeId)) || permission.ManageAllDocumentTabView) && (
              <div id="1">
                <Paper elevation={1} className="mb-3">
                  <Alert severity="info">
                    <strong>Documents</strong>
                  </Alert>

                  {/* ankit documents start */}
                  <div className="upload-documents">
                    <Form
                      defaultValues={registerDocumentData}
                      onSubmit={onSubmitDocument}
                      validationSchema={schemaDocumentUpload}
                      ref={resetDocumentForm}
                    >
                      <div>
                        {fnRemaningDocType().length >= 0 && (
                          <>
                            <Grid item xs={12} md={12} sx={{ mt: 0, mb: 2 }}>
                              <div className="row">
                                <div className="col-md-6">
                                  <FormInputDropdown
                                    name="DocumentTypeId"
                                    ddOpt={fnRemaningDocType()}
                                    label="Select Document"
                                  ></FormInputDropdown>
                                </div>
                                <div className="col-md-6">
                                  <FormInputFile
                                    lablel="dsdfsdfsdf"
                                    name="File"
                                    type="file"
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <FormInputText
                                    label="Document Number"
                                    name="Number"
                                    type="text"
                                  />
                                </div>
                                <div className="col-md-6 text-end">
                                  <Button
                                    id="button-addon2"
                                    type="submit"
                                    variant="btn btn-outline-primary"
                                  >
                                    Upload
                                  </Button>
                                </div>
                              </div>
                            </Grid>
                          </>
                        )}
                      </div>
                    </Form>
                  </div>


                  <TableComponent
                    columns={columns}
                    data={doc}
                    IsAddButtonVisible={false}
                    isSearchRequired={false}
                  />
                </Paper>
                <Paper elevation={1} className={`my-2 ${records[0]?.StatusId == 3 ? "d-none" : ""}`}>
                  <div className="row d-flex justify-content-between  pl-10 pr-10">
                    <div className="col-md-4 mt-3 mb-2 ">
                      <InputText
                        label="Remarks"
                        name="ApproveRejectReason"
                        as="textarea"
                        value={ApproveData.ApproveRejectReason}
                        setValue={(v) =>
                          setData({
                            ...ApproveData,
                            ApproveRejectReason: v,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <ButtonGroup
                        disableElevation
                        aria-label="Disabled elevation buttons"
                      >
                        <Button
                          variant="outline-primary"
                          id="btnApproveRejectEmployee"
                          onClick={() =>
                            onApprovalSubmit(
                              extend(ApproveData, {
                                StatusId: StandardConst.Status.Approved,
                              })
                            )
                          }
                        >
                          Approve Employee
                        </Button>
                        <Button
                          variant="outline-danger"
                          id="btnProfileReject"
                          onClick={() =>
                            onApprovalSubmit(
                              extend(ApproveData, {
                                StatusId: StandardConst.Status.Reject,
                              })
                            )
                          }
                        >
                          Reject Employee
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                </Paper>
              </div>
            )}

            {((permission.ManageFamiliesTabView && (loggedUserDetails === records[0]?.EmployeeId)) || permission.ManageAllFamiliesTabView) && (
              <div id="2">
                <Paper elevation={2} className="mb-2">
                  <Alert severity="info">
                    <strong>Family Details</strong>
                  </Alert>

                  <TableComponent
                    columns={details}
                    data={info}
                    onAddEvent={() => fnEdit()}
                    IsAddButtonVisible={((permission.ManageFamilyAdd && (loggedUserDetails === records[0]?.EmployeeId)) || permission.ManageAllFamilyAdd) ? true : false}
                    isSearchRequired={false}
                  />
                  <AddEditFamilyDetails
                    callBackEvent={() => fetchFamily()}
                    ref={addEditModalRef}
                  ></AddEditFamilyDetails>
                </Paper>

                <Paper elevation={1} className={`my-2 ${records[0]?.StatusId == 3 ? "d-none" : ""}`}>
                  <div className="row d-flex justify-content-between  pl-10 pr-10">
                    <div className="col-md-4 mt-3 mb-2 ">
                      <InputText
                        label="Remarks"
                        name="ApproveRejectReason"
                        as="textarea"
                        value={ApproveData.ApproveRejectReason}
                        setValue={(v) =>
                          setData({
                            ...ApproveData,
                            ApproveRejectReason: v,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <ButtonGroup
                        disableElevation
                        aria-label="Disabled elevation buttons"
                      >
                        <Button
                          variant="outline-primary"
                          id="btnApproveRejectEmployee"
                          onClick={() =>
                            onApprovalSubmit(
                              extend(ApproveData, {
                                StatusId: StandardConst.Status.Approved,
                              })
                            )
                          }
                        >
                          Approve Employee
                        </Button>
                        <Button
                          variant="outline-danger"
                          id="btnProfileReject"
                          onClick={() =>
                            onApprovalSubmit(
                              extend(ApproveData, {
                                StatusId: StandardConst.Status.Reject,
                              })
                            )
                          }
                        >
                          Reject Employee
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                </Paper>
              </div>
            )}

            {((permission.ManageFingerPrintBiometricTabView && (loggedUserDetails === records[0]?.EmployeeId)) || permission.ManageAllFingerPrintBiometricTabView) && (
              <div id="3">
                {RenderBiometricComponent()}
              </div>
            )}

            {((permission.ManageFaceBiometricTabView && (loggedUserDetails === records[0]?.EmployeeId)) || permission.ManageAllFaceBiometricTabView) && (
              <div id="4">
                <div className="mt-2">
                  {(employeeBiometrics.length > 0) && (
                    <>
                      <DeleteConfirmAlert ref={refEmployeeBiometric} confirmEvent={(v) => employeeBiometricsDelete(v)} />
                      <TableComponent
                        columns={employeeBiometricsListColumns}
                        data={employeeBiometrics}
                        isExcelExportButtonVisible={false}
                        IsAddButtonVisible={false}
                        isSearchRequired={false}
                      />
                      <ViewBiometricFace ref={viewModalBiometricFaceRef} />
                    </>
                  )}

                  {((permission.ManageFaceBiometricRegistration && (loggedUserDetails === records[0]?.EmployeeId)) || permission.ManageFaceBiometricAllRegistration) && (
                    <>
                      <br />
                      <FaceCapture EmployeeId={EmpId} onUpdateFunction={BiometricRegistor} />
                    </>
                  )}
                </div>
              </div>
            )}
            <div id="5">
              <AssetsComponent EmployeeId={EmpId}/>
            </div>
          </SwipeableViews>
        </Box>
      </Container>
    </>
  );
};

const RenderBiometricComponent = () => {

  return (
    <div className="m-3"></div>
  )
};

const ViewBiometricFace = forwardRef(({ }, ref) => {
  const [show, setShow] = useState(false);
  const [biometricFaceImgPath, setBiometricFaceImgPath] = useState('');

  useImperativeHandle(ref, () => ({
    openModal: async (imagePath) => {
      setBiometricFaceImgPath(imagePath);
      setShow(true);
    },
  }));

  const handleClose = () => {
    setBiometricFaceImgPath('');
    setShow(false);
  };

  return (
    <>
      <BootstrapModal show={show} onHide={handleClose}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>View Biometric Face</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          {
            (biometricFaceImgPath !== '') ?
              (<img src={biometricFaceImgPath} />) :
              (<span>Image Not found here</span>)
          }
        </BootstrapModal.Body>
      </BootstrapModal>
    </>
  );
});

const GenerateBadge = forwardRef(({ SelectedTemplate }, ref) => {
  const [show, setShow] = useState(false);
  const [paramData, setParamData] = useState(false);
  const handleClose = () => setShow(false);
  useImperativeHandle(ref, () => ({
    openModal: async (paramData) => {
      setShow(true);
      setParamData(paramData);
    },
  }));

  return (
    <BootstrapModal show={show} onHide={handleClose}>
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title>Employee Id Card</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <HtmlFileReader
          jsonData={paramData}
          defaultTemplate={SelectedTemplate[0]}
          ShowDropdown={0}
          ShowPrintBadgeBtn={1}
          ShowDownloadBadgeBtn={1}
          SelectedTemplateId={SelectedTemplate}
          showDropDownList={StandardConst.BadgeType[1].text}
        />
      </BootstrapModal.Body>
    </BootstrapModal>
  );
});

const GetTokenComponent = forwardRef(({ }, ref) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const dispatch = useDispatch();
  const [tokenValue, setTokenValue] = useState("");
  const copy = () => {
    navigator.clipboard.writeText(tokenValue);
    setOpen(true);
  };
  const getToken = () => {
    WebService({ dispatch, endPoint: "User/Token" }).then((c) => {
      setTokenValue(c);
      handleShow();
    });
  };
  const [show, setShow] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useImperativeHandle(
    ref,
    () => ({
      getToken,
    }),
    []
  );
  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={1000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Token Copied!!!
        </Alert>
      </Snackbar>
      <ReactModal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ReactModal.Header closeButton>
          <ReactModal.Title>Token Number</ReactModal.Title>
        </ReactModal.Header>
        <ReactModal.Body>
          <FormInputText
            as="textarea"
            value={tokenValue}
            multiline={true}
            rows={8}
            readOnly={true}
          />
        </ReactModal.Body>
        <ReactModal.Footer>
          <button onClick={copy} className="btn btn-success">
            <i className="fa fa-copy" aria-hidden="true"></i> Copy Generate Token
          </button>
        </ReactModal.Footer>
      </ReactModal>
    </div>
  );
});
const ReassignManager = forwardRef(({ onSubmited }, ref) => {
  onSubmited ??= () => { };
  const dispatch = useDispatch();
  const [employeeList, setEmployeeList] = useState([]);
  const [assign, setAssign] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useImperativeHandle(ref, () => ({
    openModal: async (empId) => {
      const assignList = await WebService({
        dispatch,
        endPoint: `CommonUtility/employeemanager?where=EmployeeId eq ${empId}`,
      });
      const empList = await WebService({
        dispatch,
        endPoint: `CommonUtility/employees?where=StatusId eq ${StandardConst.Status.Approved}&select=EmployeeId,FullName`,
      });
      setEmployeeList(
        sortBy(empList, "FullName").filter((f) => f.EmployeeId !== empId)
      );
      setAssign(assignList.length > 0 ? assignList[0] : { EmployeeId: empId });
      handleOpen();
    },
  }));
  const onSubmit = async (data) => {
    data.ValidFrom = DateTime.now().toSQLDate();
    await WebService({ dispatch, endPoint: "User/AssignManager", body: data });
    onSubmited();
    handleClose();
  };
  return (
    <>
      <ReactModal
        show={open}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ReactModal.Header closeButton>
          <ReactModal.Title>Manager Reassign</ReactModal.Title>
        </ReactModal.Header>
        <ReactModal.Body>
          <InputDropdown
            ddOpt={employeeList.map((m) => ({
              value: m.EmployeeId,
              text: m.FullName,
            }))}
            value={assign?.ManagerID}
            setValue={(v) =>
              setAssign((assign) => ({ ...assign, ManagerID: parseInt(v) }))
            }
          />
        </ReactModal.Body>
        <ReactModal.Footer>
          <Button onClick={() => onSubmit(assign)} variant="outline-primary">
            Submit
          </Button>
        </ReactModal.Footer>
      </ReactModal>
    </>
  );
});

// This is modal for office location reassign (and this is called in edit of office location)
const ReassignOfficeLocation = forwardRef(({ onSubmited }, ref) => {
  onSubmited ??= () => { };
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const loggedUserDetails = useSelector((s) => s.auth.LoggedUser ?? []);
  const [employeeId, setEmployeeId] = useState({});
  const [changedLocationId, setChangedLocationId] = useState({});
  const [locationData, setlocationData] = useState([]);

  useImperativeHandle(ref, () => ({
    openModal: async (empId) => {
      setEmployeeId(empId);
      getsetlocation();
      handleOpen();
    },
  }));

  const getsetlocation = () => {
    WebService({
      endPoint: `UserProfile/Fetchlocation`,
      dispatch,
    }).then((data) => {
      setlocationData(
        data.data.map((v) => {
          const words = v.Address.split(' ');
          const truncatedWords = words.slice(0, 3);
          const truncatedAddress = truncatedWords.join(' ');
          const finalAddress = truncatedAddress + ', ' + v.Location;
          return {
            value: v.OfficeLocationId,
            text: finalAddress,
          };
        })
      );
    });
  };

  const onSubmit = async () => {
    let data = {
      OfficeLocationId: changedLocationId.OfficeLocationId,
      EmployeeId: employeeId || loggedUserDetails,
    };
    const result = await WebService({
      endPoint: `UserProfile/CreateEmployeeOfficelocation`,
      body: data,
      dispatch,
    });

    Swal.fire({
      title: "Assigned",
      text: result.message,
      icon: "info",
      showCancelButton: false,
      customClass: {
        confirmButton: "mx-1 btn btn-outline-primary",
      },
      buttonsStyling: false,
    });
    onSubmited();
    handleClose();
  };

  return (
    <>
      <ReactModal
        show={open}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ReactModal.Header closeButton>
          <ReactModal.Title>Office Location</ReactModal.Title>
        </ReactModal.Header>
        <ReactModal.Body>
          <InputDropdown
            name="OfficeLocationId"
            id="OfficeLocationId"
            ddOpt={locationData}
            setValue={(v) =>
              setChangedLocationId({
                ...changedLocationId,
                OfficeLocationId: parseInt(v),
              })
            }
          ></InputDropdown>
        </ReactModal.Body>
        <ReactModal.Footer>
          <Button onClick={() => onSubmit()} variant="outline-primary">
            Assign Office
          </Button>
        </ReactModal.Footer>
      </ReactModal>
    </>
  );
});

export const AssetsComponent = (props) => {

  const [records, setRecords ] = useState([]);
  const EmployeeId = useSelector((s) => s.auth.LoggedUser ?? {});
  const UserId = useSelector((s) => s.auth.LoggedCustomer ?? {});
  const dispatch = useDispatch();
  const AssignAssetModelRef = useRef();

  const fetchAllAssignedAssets = async () => {
    let whereQuery = "";
    if(props.EmployeeId){
        whereQuery = `EmployeeId=${props.EmployeeId}`
    }else if(EmployeeId){
      whereQuery = `EmployeeId=${EmployeeId}`
    }
    if(UserId){
      whereQuery = `UserId=${UserId}`
    }
    const data = await WebService({
      endPoint: `AssetAssignment/fetchAssignedAssets?${whereQuery}`,
      dispatch,
    })
    await Promise.all(data.map(async(item) => {
      const imageArray = await WebService({
          endPoint: `CommonUtility/assetimages?AssetId=${item.AssetId || 0}`,
          dispatch,
      });
      imageArray.map((img) => {
          if(img.AssetId === item.AssetId) {
              _.extend(item, { image: img.AssetImageName });
          }
      });
      return  item;
  }));
    setRecords(data);
  }

  useEffect(() => {
    if(EmployeeId || UserId || props.EmployeeId){
      fetchAllAssignedAssets();
    }
  }, [EmployeeId, UserId, props.EmployeeId]);

  const columns = [
      {
        Text: "Asset Name",
        Value: "AssetName",
        render: (dr) => {
          return (
            <>
                <img
                    src={dr.image ? `${StandardConst.apiBaseUrl}/uploads/assetImages/${dr.image}` :
                        `${StandardConst.apiBaseUrl}/uploads/3720094.png`
                    }
                    style={{ width: "100px", height: "100px", objectFit: "contain" }}
                    loading="lazy"
                />
                <span className='px-3'>{dr.AssetName}</span>
            </>
        )
        } 
    },
    {
      Text: "Issue Date",
      Value: "IssueDate",
      DateFormat: "dd-MM-yyyy",
    },
    {
      Text: "Return Date",
      Value: "ReturnDate",
      DateFormat: "dd-MM-yyyy",
    },
    {
      Text: "Status",
      Value: "Status",
      render: (dr) => {
        const foundStatus = StandardConst?.AssetStatusDropdown?.find(item => item.value === dr?.Status);  
        return (
          <>
            {foundStatus?.text}
          </>
        )
      }
    },
  ];

  const buttonStyle = {
    padding: "5px",
    marginRight: "11px",
  };
  
  const buttonMobileStyle = {
    ...buttonStyle,
    marginRight: "64px",
  };

  return (
    <>
      <Paper elevation={1} className="mb-3">
        <div className="d-flex align-items-center justify-content-between" style={{background : "#e5f6fd"}}>
              <div>
                <Alert severity="info" >
                  <strong>Assets</strong>
                </Alert>
              </div>

              {(props.EmployeeId > 0) && (
                <div>
                  <Button
                    variant="outline-primary"
                    style={window.innerWidth <= 600 ? buttonMobileStyle : buttonStyle}
                    onClick={async () => await AssignAssetModelRef.current.openModal(props.EmployeeId, 0)}
                  >
                    Assign Asset Via QR Code
                  </Button>
                </div>
              )}
        </div>

        <TableComponent
          columns={columns}
          data={records}
          IsAddButtonVisible={false}
          isSearchRequired={true}
        />
        <AssignAssetComponentModal ref={AssignAssetModelRef} callBackEvent={async () => await fetchAllAssignedAssets()}/>
      </Paper>
    </>
  )
};

export const AssignAssetComponentModal = forwardRef((props, ref) => {

  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [EmployeeId, setEmployeeId] = useState(null);
  const [UserId, setUserId] = useState(null);
  const handleClose = () => setShow(false);
  useImperativeHandle(ref, () => ({
    openModal: async (EmployeeId, UserId) => {
      if(EmployeeId) {
        setEmployeeId(EmployeeId);
      }else {
        setEmployeeId(null);
      }
      if(UserId) {
        setUserId(UserId);
      }else {
        setUserId(null);
      }
      setShow(true);
    },
  }));

  const [key, setKey] = useState(0);
  const [openFrontOrBackCamera , setOpenFrontOrBackCamera] = useState(StandardConst.Camera.BackCamera);

  const handleScan = async(data) => {
    if (data) {
      setKey(key+1);
      if(UserId || EmployeeId){
        await AssignAssetViaQrScannedData(data.text);
      }else {
        WSWarningAlert("Mandatory", "Please fill person name");
        handleClose();
      }
    }
  }

  const AssignAssetViaQrScannedData = async (scannedQRData) => {
    const arrQRCodeData = scannedQRData.split(',');
    let assetIdNumber;
    arrQRCodeData.map((res) => {
      var inputString = res;
      var AssetIdFind = inputString.match(/AssetId:(\d+)/);
      if (AssetIdFind) {
        assetIdNumber = AssetIdFind[1];
      }
    });
    if (assetIdNumber) {
      let CompanyId = null;
      if (EmployeeId) {
        const EmployeeDetails = await WebService({
          endPoint: `CommonUtility/Edit/employees?EmployeeId=${EmployeeId}`,
          dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}));
        CompanyId = EmployeeDetails.CompanyId;
      }
      if (UserId) {
        const UserDetails = await WebService({
          endPoint: `CommonUtility/Edit/users?UserId=${UserId}`,
          dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}));
        CompanyId = UserDetails.CompanyId;
      }
      const data = {
        AssetId: Number(assetIdNumber),
        Status: 1,
        EmployeeId: EmployeeId,
        UserId: UserId,
        IssueDate: format(new Date(), "yyyy-MM-dd"),
      }
      let whereCondition = ``;
      if (data.EmployeeId) {
        whereCondition = `EmployeeId=${data.EmployeeId}`
      } else if (data.UserId) {
        whereCondition = `UserId=${data.UserId}`
      }
      const checkAssetAssignment = await WebService({
        endPoint: `CommonUtility/Edit/assetassignment?AssetId=${data.AssetId}&${whereCondition}`,
        dispatch,
      }).then((c) => (c.length > 0 ? c : []));
      const filteredStatus = checkAssetAssignment?.filter((obj) => obj.Status === StandardConst.AssetStatusDropdown[1].value);
      
      if (filteredStatus.length > 0) {
        filteredStatus.map(async (element) => {
          if (element.Status === StandardConst.AssetStatusDropdown[1].value) {
            data.ReturnDate = format(new Date(), "yyyy-MM-dd");
            data.Status = StandardConst.AssetStatusDropdown[4].value;
            await WebService({
              endPoint: `CommonUtility/assetassignment?AssetAssignmentId=${element.AssetAssignmentId}`,
              method: "PUT",
              body: data,
              dispatch,
            });
            if (data.Status === StandardConst.AssetStatusDropdown[4].value) data.Status = StandardConst.AssetStatusDropdown[0].value;
            await WebService({
              endPoint: `CommonUtility/assets?AssetId=${data.AssetId}`,
              method: "PUT",
              body: data,
              dispatch,
            });
            WSSuccessAlert("Success", "You successfully returned this asset");
          } else {
            WSWarningAlert("Warning", "This Asset is not valid");
          }
          props.callBackEvent();
          handleClose();
        });
      } else {
        const AssetStatus = await WebService({
          endPoint: `CommonUtility/Edit/assets?AssetId=${data.AssetId}`,
          dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}));

        if (AssetStatus.Status === StandardConst.AssetStatusDropdown[0].value && AssetStatus.CompanyId === CompanyId) {
          await WebService({
            endPoint: `CommonUtility/assets?AssetId=${data.AssetId}`,
            method: "PUT",
            body: data,
            dispatch,
          });
          await WebService({
            endPoint: "CommonUtility/assetassignment",
            body: data,
            dispatch,
          });
          WSSuccessAlert("Success", `You have successfully assigned ${AssetStatus.AssetName}`)
        }
        if (AssetStatus.Status === StandardConst.AssetStatusDropdown[2].value && AssetStatus.CompanyId === CompanyId) {
          WSWarningAlert("Warning", `This asset is ${StandardConst.AssetStatusDropdown[2].text.toLowerCase()}`)
        }
        if (AssetStatus.Status === StandardConst.AssetStatusDropdown[3].value && AssetStatus.CompanyId === CompanyId) {
          WSWarningAlert("Warning", `This asset is ${StandardConst.AssetStatusDropdown[3].text.toLowerCase()}`)
        }
        if (AssetStatus.Status === StandardConst.AssetStatusDropdown[5].value && AssetStatus.CompanyId === CompanyId) {
          WSWarningAlert("Warning", `This asset is ${StandardConst.AssetStatusDropdown[5].text.toLowerCase()}`)
        }
        if (AssetStatus.Status === StandardConst.AssetStatusDropdown[6].value && AssetStatus.CompanyId === CompanyId) {
          WSWarningAlert("Warning", `This asset is ${StandardConst.AssetStatusDropdown[6].text.toLowerCase()}`)
        }
        if (AssetStatus.Status === StandardConst.AssetStatusDropdown[1].value && AssetStatus.CompanyId === CompanyId) {
          WSWarningAlert("Warning", `This asset is already assigned`);
        }
        if (!AssetStatus || AssetStatus.CompanyId !== CompanyId) {
          WSWarningAlert("Warning", `This Asset is not valid`)
        }
        props.callBackEvent();
        handleClose();
      }
    }
  }

  const handleError = (err) => {
    console.log(err);
  }

  const toggleCamera = () => {
    if(openFrontOrBackCamera === StandardConst.Camera.BackCamera){
      setOpenFrontOrBackCamera(StandardConst.Camera.FrontCamera);
    }else{
      setOpenFrontOrBackCamera(StandardConst.Camera.BackCamera);
    }
  }

  return (
    <>
      <BootstrapModal
        size="xs"
        centered
        show={show}
        onHide={handleClose}
        className="container-fluid"
      >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Assign Asset Via Scan QR Code</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body className="p-0 m-0">
          <div className="p-3 m-0">
            <div className="text-center">
              <QrReader
                key={key}
                delay={300}
                onError={handleError}
                onScan={handleScan}
                constraints={{
                  video: { facingMode: openFrontOrBackCamera }
                }}
                style={{
                  width: '100%'
                }}
              />
              <span>
                Scan the QR CODE of the asset
                <ActionButton
                  onClick={() => toggleCamera()}
                  IconName="SwitchCameraLarge"
                  IconTooltip="Switch Camera"
                  id="SwitchCamera"
                />
                <ActionButton
                  IconName="FullScreenLarge"
                  IconTooltip="Full Screen"
                  id="FullScreen"
                />
                <ActionButton
                  IconName="FullScreenExitLarge"
                  IconTooltip="Exit Full Screen"
                  id="FullScreenExit"
                />
              </span>
            </div>
          </div>
        </BootstrapModal.Body>
      </BootstrapModal>
    </>
  )
});

export default memo(Profile);
