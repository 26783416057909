import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, FormAutoCompleteDropdown, FormInputDropdown, FormInputText } from '../Form';
import { WebService } from '../../Services/WebService';
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { StandardConst } from '../../Services/StandardConst';
import { chain } from 'underscore';
import { Autocomplete, TextField } from "@mui/material";


const AddEditAssignTicketCategory = (props, ref) => {
    const [show, setShow] = useState(false);
    const [data, setData] = useState({ AssignTicketCategory: {} });
    const dispatch = useDispatch();
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const [autoErrorShow, setAutoErrorShow] = useState(false);
    const [selectedTicketCategory, setSelectedTicketCategory] = useState([]);

    useEffect(() => {
        Promise.all([
            fetchAllAgents(),
            fetchAllTicketCategories()
        ])
    }, []);

    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            setShow(true);
            if (id === 0) {
                const data = {
                    AssignTicketCategory: {},
                };
                setData(data);
            } else {
                setShow(false);
                await fetchEditValue(id);
                setShow(true);
            }
        },
    }));

    const fetchEditValue = async (id) => {
        const data = {
            AssignTicketCategory: await WebService({
                endPoint: `CommonUtility/Edit/ticketcategoryAgents?TicketCategoryAgentId=${id || 0}`,
                dispatch,
            }).then((c) => (c.length > 0 ? c[0] : {})),
        };
        setData(data);
    }

    const handleClose = () => {
        setShow(false)
        setData({AssignTicketCategory : {}});
        setSelectedTicketCategory([]);
        setAutoErrorShow(false);
    };

    const schema = yup
        .object()
        .shape({
            AgentId: yup
                .array()
                .of(
                    yup.object().shape({}),
                )
                .min(1, StandardConst.ValidationMessages.RequiredFieldMsg)
                .typeError(StandardConst.ValidationMessages.RequiredFieldMsg)
                .required(StandardConst.ValidationMessages.RequiredFieldMsg),
        })
        .required();

    const onSubmit = async (data) => {
        data.CompanyId = companyInfo.CompanyId;
        data.AgentId = data.AgentId[0].AgentId;
        if(selectedTicketCategory.length > 0){
            setAutoErrorShow(false);

            selectedTicketCategory.map(async (item) => {
                data.TicketCategoryId = item.value;
                if (data.AssignTicketCategoryId === undefined) {
                    await WebService({
                        endPoint: "CommonUtility/ticketcategoryAgents",
                        body: data,
                        dispatch,
                    });
                } else {
                    await WebService({
                        endPoint: `CommonUtility/ticketcategoryAgents?TicketCategoryAgentId=${data.AssignTicketCategoryId}`,
                        method: "PUT",
                        body: data,
                        dispatch,
                });
            }
            });
            props.callBackEvent();
            handleClose();
        }else {
            setAutoErrorShow(true);
        }
    }

    const [AgentsList, setAgentsList] = useState([]);

    const fetchAllAgents = async() => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/agents?CompanyId=${StandardConst.YesNo.Yes}`,
            dispatch,
            requiredLoader: false
        });
        const modifiedData = data.filter((r) => r.FullName !== "");
        setAgentsList(modifiedData);
    }

    const [ticketCategoriesList, setTicketCategoriesList] = useState([]);

    const fetchAllTicketCategories = async () => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/ticketcategories?CompanyId=${StandardConst.YesNo.Yes}`,
            dispatch,
            requiredLoader: false
        });
        const list = data?.map((item) => {
            return { text: item.CategoryName, value: item.TicketCategoryId, ParentId: item.ParentId }
        });
        setTicketCategoriesList(list);
    }

    // Function to handle change in selected ticket categories
    const handleCategoryChange = (event, selectedOptions) => {
        (selectedOptions.length === 0) ? setAutoErrorShow(true) : setAutoErrorShow(false);
        setSelectedTicketCategory(selectedOptions);
    };

    
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {(data && data.AssignTicketCategory && data.AssignTicketCategory.TicketCategoryAgentId || 0) === 0
                        ? "Assign Ticket Category"
                        : "Assign Ticket Category"
                    }
                </Modal.Title>
            </Modal.Header>
            <Form
                defaultValues={data.AssignTicketCategory}
                onSubmit={onSubmit}
                validationSchema={schema}
            >
                <Modal.Body className="p-4">
                    <div className="row">
                        <div className="col-md-12">
                        <FormAutoCompleteDropdown
                                name="AgentId"
                                data={chain(AgentsList ?? [])
                                    .sortBy((s) => s?.FullName)
                                    .value()}
                                label="Agent Name"
                                optionText="FullName"
                                isRequired="true"
                            />
                        </div>

                        <div className="col-md-12 mt-2">
                            <Autocomplete
                                multiple
                                id="grouped-demo"
                                options={ticketCategoriesList}
                                groupBy={(option) => option.ParentId === null ? option.text : ""}
                                getOptionLabel={(option) => option?.text}
                                defaultValue={selectedTicketCategory}
                                onChange={handleCategoryChange}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Ticket Categories"
                                        placeholder="Ticket Categories"
                                        error={autoErrorShow} // Set error state of TextField
                                        helperText={(autoErrorShow) && "Select atleast one ticket category"} // Display error message if error is true
                                    />
                                )}
                            />
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnAccessLevelSubmit" variant="outline-primary" type="submit">
                        {" "}
                        {(data && data.AssignTicketCategory && data.AssignTicketCategory.TicketCategoryAgentId || 0) === 0
                            ? "Submit"
                            : "Save Changes"}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default forwardRef(AddEditAssignTicketCategory);