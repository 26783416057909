import React, { useEffect, useRef, useState } from 'react'
import TableComponent, { NoRecordTemplate } from '../../Services/TableComponent'
import { StandardConst } from '../../Services/StandardConst'
import { Form, FormAutoCompleteDropdown, FormAutoCompleteDropdownCard, FormCheckRadioInput, FormInputDropdown } from '../Form'
import SnackbarComponent from '../../Services/SnackbarComponent'
import { Box, Container } from "@mui/material";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import { PageInfo } from '../PageInfo'
import { useDispatch, useSelector } from "react-redux";
import { WebService } from '../../Services/WebService'
import { Stack } from "@mui/material";
import { chain } from 'underscore'
import ActionButton from '../../Services/ActionButton'
import ModalChildGroupAndLevel from './ModalChildGroupAndLevel'
import { WSErrorAlert } from '../../Services/WSAlert'

const AssignAccessToUsers = () => {

    const refSnackbar = useRef();
    const refModalChildGroupAndLevel = useRef();
    const [confirmMessage, setConfirMessage] = useState("");
    const [data, setData] = useState({ AssignAccessGroup: {} });
    PageInfo({ pageTitle: "Assign Access to User" });
    const [currentUserId, setCurrentUserId] = useState(null);
    const [currentUserType, setCurrentUserType] = useState(null);
    const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const dispatch = useDispatch();
    const [usersList, setUsersList] = useState([]);

    const dictUserType = {
        [StandardConst.UserType.Employee]: "Employee",
        [StandardConst.UserType.Visitor]: "Visitor",
        [StandardConst.UserType.EventVisitor]: "Event Visitor",
        [StandardConst.UserType.User]: "User",
    };

    const fetchUsers = async () => {
        const allTypeUsersData = await WebService({
            endPoint: `CommonUtility/getAllTypeOfUsers/visitor`,
            method: 'GET',
            dispatch,
        });

        const updatedUsersData = allTypeUsersData.map(user => ({
            text: `${user.Name} (${dictUserType[user.UserType]})`,
            value: user.Id,
            heading: `${user.Name} (${dictUserType[user.UserType]})`,
            description: user.Description,
            avatar: `${StandardConst.apiBaseUrl}/uploads/visitorPhotos/${user.Picture}`,
            UserType: user.UserType
        }));

        setUsersList(updatedUsersData);
    }

    useEffect(() => {
        fetchUsers();
    }, []);

    const [bData, setBData] = React.useState([
        {
            title: "Access Management",
            hrefLink: "#",
        },
        {
            title: "Assign Access to User",
            hrefLink: "#",
        },
    ]);
    const MasterPageName = "Assign Access to User";

    const [AccessLevelList, setAccessLevelList] = useState([]);
    const fetchAccessLabelsList = async() => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/accesslevel?CompanyId=${CompanyInfo.CompanyId}`,
            dispatch,
        });
        const filteredData = data.filter((r) => r.Status === StandardConst.StatusDropdown[0].value);
        setAccessLevelList(filteredData);
    }

    const [AccessGroupList, setAccessGroupList] = useState([]);
    const fetchAccessGroupList = async() => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/accessgroup?CompanyId=${CompanyInfo.CompanyId}`,
            dispatch,
        });
        const filteredData = data.filter((r) => r.Status === StandardConst.StatusDropdown[0].value);
        setAccessGroupList(filteredData);
    }

    useEffect(() => {
        fetchAccessLabelsList();
        fetchAccessGroupList();
    }, []);

    const handleAssignAccessLevels = async (currentValue,AccessLevelId) => {
        if(currentUserId !== null && currentUserType !== null){
            if(currentValue){
                const data = {
                    UserId: currentUserId ? currentUserId : null,
                    UserType: currentUserType ? currentUserType : null,
                    AccessLevelId: AccessLevelId,
                }
                await WebService({
                    endPoint: "CommonUtility/useraccessassignment",
                    body: data,
                    dispatch,
                });
                setConfirMessage("Access Level successfully assigned to the Access Group.");
                refSnackbar.current.setOpenSnackBar();
            }else {
                await WebService({
                    endPoint: `CommonUtility/useraccessassignment?AccessLevelId=${AccessLevelId}`,
                    method: "DELETE",
                    dispatch,
                });
                setConfirMessage("Access Level successfully removed from the Access Group.");
                refSnackbar.current.setOpenSnackBar();
            }
        }else{
            WSErrorAlert("No user selected", "Please select a user to assign the access.");
        }
    }

    const AccessLevelColumns = [
        {
            Text: "Select",
            style: {width: "5px"},
            render: (dr) => (
                <>
                <Stack>
                    <FormCheckRadioInput
                        name={String(dr.AccessLevelId)+"ALID"}
                        isRadio={false}
                        value={checkedAccessLevelList.find((AccessLevelId) => AccessLevelId === dr.AccessLevelId ? true : false)}
                        setValue={(currentValue) => { handleAssignAccessLevels(Number(currentValue), dr.AccessLevelId)}}
                    />
                </Stack>
                </>
            )
        },
        {
            Text: "Access Level Name",
            Value: "AccessLevelName",
        },{
            Text: "Status",
            Value: "Status",
            render: (dr) => (
                <>
                    <span>
                    { dr.Status === StandardConst.StatusDropdown[0].value ? StandardConst.StatusDropdown[0].text : StandardConst.StatusDropdown[1].text}
                    </span>
                </>
            )
        },
    ]


    const handleAssignAccessGroup = async(currentValue, AccessGroupId) => {
        if(currentUserId !== null && currentUserType !== null){
            if(currentValue){
                const data = {
                    UserId: currentUserId  ? currentUserId : null,
                    UserType: currentUserType  ? currentUserType : null,
                    AccessGroupId: AccessGroupId,
                }
                await WebService({
                    endPoint: "CommonUtility/useraccessassignment",
                    body: data,
                    dispatch,
                });
            }else {
                await WebService({
                    endPoint: `CommonUtility/useraccessassignment?AccessGroupId=${AccessGroupId}`,
                    method: "DELETE",
                    dispatch,
                });
            }
        }else{
            WSErrorAlert("No user selected", "Please select a user to assign the access.");
        }
    }

    const [checkedAccessGroupList, setCheckedAccessGroupList] = useState([]);
    const checkedAssignedAccessGroup = async (Id) => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/useraccessassignment?UserId=${Id}`,
            dispatch,
        });
        const checkedAccessGroupIds= data.map((obj) => obj.AccessGroupId);
        if(checkedAccessGroupIds.length > 0) setCheckedAccessGroupList(checkedAccessGroupIds);
    }

    const AccessGroupcolumns = [
        {
            Text: "Select",
            style: {width: "5px"},
            render: (dr) => (
                <>
                <Stack>
                    <FormCheckRadioInput
                        name={String(dr.AccessGroupId)+"AGID"}
                        isRadio={false}
                        value={checkedAccessGroupList?.find((AccessGroupId) => AccessGroupId === dr.AccessGroupId ? true : false)}
                        setValue={(currentValue) => { handleAssignAccessGroup(Number(currentValue), dr.AccessGroupId)}}
                    />
                </Stack>
                </>
            )
        },
        {
            Text: "Access Group Name",
            Value: "AccessGroupName",
            style: {lineHeight: 0}
        },{
            Text: "Status",
            Value: "Status",
            style: {lineHeight: 0},
            render: (dr) => (
                <>
                    <span>
                    { dr.Status === StandardConst.StatusDropdown[0].value ? StandardConst.StatusDropdown[0].text : StandardConst.StatusDropdown[1].text}
                    </span>
                </>
            )
        },
        {
            Text: "Action",
            key: "AccessGroupId",
            cssClass: "text-end",
            render: (dr) => (
                <>
                    <ActionButton
                        className="text-align-end"
                        onClick={async () => await refModalChildGroupAndLevel.current.openModal(dr.AccessGroupId, dr.AccessGroupName)}
                        IconName="Information"
                        IconTooltip="Child Access Group and Levels"
                        id="modal-access-group"
                    />
                </>
            )
        }
    ]

    const [checkedAccessLevelList, setCheckedAccessLevelList] = useState([]);
    const handleCurrentValue = async(dropdownSelectedObj) => {
        if(dropdownSelectedObj[0].value){
            setCurrentUserId(dropdownSelectedObj[0].value);
            setCurrentUserType(dropdownSelectedObj[0].UserType)
            await checkedAssignedAccessLevels(dropdownSelectedObj[0].value);
            await checkedAssignedAccessGroup(dropdownSelectedObj[0].value);
        }else {
            setCheckedAccessLevelList([]);
            setCheckedAccessGroupList([]);
            setCurrentUserId(null);
            setCurrentUserType(null);
        }
    }

    const checkedAssignedAccessLevels = async (UserId) => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/useraccessassignment?UserId=${UserId}`,
            dispatch,
        });
        const checkedAccessLevelIds= data.map((obj) => obj.AccessLevelId );
        if(checkedAccessLevelIds.length > 0) setCheckedAccessLevelList(checkedAccessLevelIds);
    }

    return (
        <>
            <ModalChildGroupAndLevel ref={refModalChildGroupAndLevel} />
            <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
            <Container fluid className="base-container">
                <Form
                    defaultValues={data.AssignAccessGroup}
                // validationSchema={schema}
                >
                    <Box
                        sx={{
                            width: 1,
                        }}
                    >
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="">
                                <h3 className="ms-4 mt-2">{MasterPageName}</h3>
                                <div className="ms-4">
                                    <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
                                </div>
                            </div>
                        </div>
                    </Box>
                    <div className="row primary-bg-color m-0 p-0" style={{height : "71px"}}>
                        <div className="col-md-5 col-sm-12" style={{ margin: "0.5rem 0 0.5rem 1.5rem" }}>
                            <div className="row" >
                                <div className="col-2 p-0 text-white d-flex justify-content-start align-items-center" style={{ "font-size": "19px" }}>
                                    User <span className='text-danger'>＊</span>
                                </div>

                                <div className="col-10">
                                    <FormAutoCompleteDropdownCard
                                        name="UserId"
                                        data={chain(usersList ?? [])
                                            .sortBy((s) => s.text)}
                                        optionText="heading"
                                        isRequired="true"
                                        placeholder="Choose a Employee"
                                        setValue={(currentValue) => { handleCurrentValue(currentValue) }}
                                        sx={{
                                            "& fieldset": {
                                                border: "none",
                                            },
                                            '& .MuiAutocomplete-inputRoot': {
                                                backgroundColor: 'white',
                                                height: "41px",
                                                margin: "7px",
                                                marginTop: "0px",
                                            },
                                            '& .MuiAutocomplete-input:hover, .MuiAutocomplete-input:focus': {
                                                borderColor: 'white',
                                            },
                                            "& .MuiInputBase-input": {},
                                            '& .MuiInputLabel-root.Mui-focused': {
                                                backgroundColor: 'white', // Background color of the label when focused
                                                color: "black",
                                            },
                                            '& .MuiInputLabel-root': {
                                                backgroundColor: 'white', // Background color of the label
                                                color: "black",
                                            },
                                            "& .MuiInputBase-root": {
                                                paddingTop: "0px",
                                            },
                                            width: 300
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                    <TableComponent
                        columns={AccessGroupcolumns}
                        noRecordCss="p-0"
                        noRecordFound={
                            <NoRecordTemplate
                                headerValue={StandardConst.headerValueNoResults}
                                subHeaderValue={StandardConst.subHeaderValueStandard}
                                imageUrl={StandardConst.imageNoRecordsFound}
                            />
                        }
                        data={AccessGroupList}
                        isSearchRequired={true}
                        IsAddButtonVisible={false}
                    />
                    <TableComponent
                        columns={AccessLevelColumns}
                        noRecordCss="p-0"
                        noRecordFound={
                            <NoRecordTemplate
                                headerValue={StandardConst.headerValueNoResults}
                                subHeaderValue={StandardConst.subHeaderValueStandard}
                                imageUrl={StandardConst.imageNoRecordsFound}
                            />
                        }
                        data={AccessLevelList}
                        isSearchRequired={true}
                    />
                </Form>
            </Container>
        </>
    )
}

export default AssignAccessToUsers