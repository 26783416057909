import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import { WebService } from "../../Services/WebService";
import { Form, FormInputDropdown, FormInputText } from "../Form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
import Container from "react-bootstrap/Container";
import { Tab, Tabs, Box, AppBar, Alert } from "@mui/material";
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import Topbar from "../../Services/TopbarComponent";
import { PageInfo } from "../PageInfo";
import { useLocation } from "react-router-dom";
import TableComponent, { NoRecordTemplate } from "../../Services/TableComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import SnackbarComponent from '../../Services/SnackbarComponent';
import ActionButton from '../../Services/ActionButton';
import { DateTime } from "luxon";
import AddEditSubscriptionFeature from "./AddEditSubscriptionFeature";
import AddEditSubscriptionDetail from "./AddEditSubscriptionDetail";
import { WSWarningAlert } from "../../Services/WSAlert";


const apiUrl = "CommonUtility/subscription";

const AddEditManageSubscription = (prop, ref) => {

    const dispatch = useDispatch();
    const { state } = useLocation();

    const [SubscriptionId, setSubscriptionId] = useState(0);

    useEffect(() => {
        if (state.SubscriptionId) {
            setSubscriptionId(state.SubscriptionId);
        }
    }, []);

    PageInfo({ pageTitle: SubscriptionId ? "Edit Subscription" : "Add Subscription" });

    //Tabs start

    const [bData] = React.useState([
        {
            title: "Master",
            hrefLink: "#",
        },
        {
            title: "Manage Subscription",
            hrefLink: "#",
        },
    ]);
    const [value, setValue] = React.useState(0);
    const theme = useTheme();
    const handleChange = (event, newValue) => {
        if(SubscriptionId){
            setValue(newValue);
        }else {
            WSWarningAlert("Warning", "Please Fill General Information page first.");
            setValue(0);
        }
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 0, mt: 0 }}>
                        <span>{children}</span>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    //Tab End

    return (
        <>
            <Container
                fluid
                style={{
                    padding: "0px",
                    borderRadius: "5px ",
                    margin: "10px",
                    backgroundColor: "#fff",
                    width: "90%"
                }}
            >
                <Topbar bData={bData} HeadingText={SubscriptionId ? "Edit Subscription" : "Add Subscription"}></Topbar>
                <Box>
                    <AppBar
                        position="static"
                        color="info"
                        elevation={0}
                        className="Maintab_Custome mb-2"
                        style={{ maxHeight: "40px", height: "40px" }}
                    >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="secondary"
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                            style={{
                                maxHeight: "40px",
                                height: "40px",
                            }}
                            id="tabs"
                        >
                            <Tab
                                icon={<WebAssetIcon />}
                                sx={{ "min-width": "40px !important" }}
                                aria-label="General"
                                label={window.innerWidth <= 600 ? '' : "General"}
                                iconPosition="start"
                                className="tab_Custome pt-0"
                                id="General"
                            />
                            <Tab
                                icon={<ShoppingCartIcon />}
                                sx={{ "min-width": "40px !important" }}
                                aria-label="Subscription Detail"
                                label={window.innerWidth <= 600 ? '' : "Subscription Details"}
                                iconPosition="start"
                                className="tab_Custome pt-0"
                                id="SubscriptionD"
                            />
                            <Tab
                                icon={<RemoveShoppingCartIcon />}
                                sx={{ "min-width": "40px !important" }}
                                aria-label="Subscription Feature"
                                label={window.innerWidth <= 600 ? '' : "Subscription Feature"}
                                iconPosition="start"
                                className="tab_Custome pt-0"
                                id="SubscriptionF"
                            />
                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={value} index={0} dir={theme.direction}>
                            <GeneralComponent
                                SubscriptionId={SubscriptionId}
                                GetSubscriptionIdAfterSave={((id) => setSubscriptionId(id))}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                            <SubscriptionDetails
                                SubscriptionId={SubscriptionId}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={2} dir={theme.direction}>
                            <SubscriptionFeatures
                                SubscriptionId={SubscriptionId}
                            />
                        </TabPanel>
                    </SwipeableViews>
                </Box>
            </Container>
        </>
    );
};

const GeneralComponent = (props) => {

    const [data, setData] = useState({ Subscription: {} });
    const dispatch = useDispatch();


    useEffect(() => {
        const fetchEditValue = async (id) => {
            const data = {
                General: await WebService({
                    endPoint: `CommonUtility/Edit/subscription?SubscriptionId=${id || 0}`,
                    dispatch,
                }).then((c) => (c.length > 0 ? c[0] : {})),
            };
            const combinedDetails = { ...data.General };
            const mergeObject = {
                Subscription: combinedDetails
            }
            setData(mergeObject);
        };

        const fetchData = async () => {
            if (props.SubscriptionId) {
                await fetchEditValue(props.SubscriptionId);
            } else {
                const data = {
                    Subscription: {},
                };
                setData(data);
            }
        };

        fetchData();
    }, [props.SubscriptionId]);

    useEffect(() => {
        fetchAllCountries();
        fetchAllCurrency();
        fetchAllSubscriptionType();
    }, []);

    const schema = yup
        .object()
        .shape({
            Name: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            // CountryId: yup.string().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            CurrencyId: yup.string().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            IsDefault: yup.string().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            Status: yup.string().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            SubscriptionTypeId: yup.string().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            ValidityFromDate: yup.date().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            ValidityToDate: yup.date().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        })
        .required();

    const onSubmit = async (data) => {
        if (data.SubscriptionId === undefined) {
            const SubscriptionId = await WebService({
                endPoint: apiUrl,
                body: data,
                dispatch,
            });
            props.GetSubscriptionIdAfterSave(SubscriptionId);
        } else {
            await WebService({
                endPoint: `${apiUrl}?SubscriptionId=${data.SubscriptionId}`,
                method: "PUT",
                body: data,
                dispatch,
            });
        }
    };

    const [countries, setCountries] = useState([]);

    const fetchAllCountries = async () => {
        const data = await WebService({
            endPoint: `CommonUtility/Edit/countries`,
            dispatch,
        });
        data.sort((a, b) => {
            const AText = a.Country.toLowerCase();
            const BText = b.Country.toLowerCase();
            if (AText < BText) {
                return -1;
            }
            if (AText > BText) {
                return 1;
            }
            return 0;
        });
        const list = [{text: "All", value: null}].concat(data.map((r) => {
            return { text: r.Country, value: r.CountryId }
        }));
        setCountries(list);
    }

    const [currency, setCurrency] = useState([]);

    const fetchAllCurrency = async () => {
        const data = await WebService({
            endPoint: `CommonUtility/Edit/staticcurrency`,
            dispatch,
        });

        const list = data.map((r) => {
            return { text: r.Symbol, value: r.CurrencyId }
        });
        setCurrency(list);
    }

    const [SubscriptionType, setSubscriptionType] = useState([]);

    const fetchAllSubscriptionType = async () => {
        const data = await WebService({
            endPoint: `CommonUtility/Edit/subscription_type`,
            dispatch,
        });

        const list = data.map((r) => {
            return { text: r.Name, value: r.SubscriptionTypeId }
        });
        setSubscriptionType(list);
    };

    const [ValidityFromDate, setValidityFromDate] = useState(null)

    return (
        <>
            <div>
                <Container className="base-container" fluid style={{ width: "98%" }}>
                    <div className="row p-0 mb-2 d-flex flex-row">
                        <div className="col-md-12">
                            {Object.keys(data?.Subscription).length > 0 || props.SubscriptionId === 0 ? (
                                <Form
                                    defaultValues={data.Subscription}
                                    onSubmit={onSubmit}
                                    validationSchema={schema}
                                >
                                    <div className="card shadow-none border-0  border-start footer-widget">
                                        <Alert severity="info">General Information</Alert>
                                        <div className="card-body">
                                            <div className="row mt-1">
                                                <div className="row">
                                                <div className="col-md-12 pb-1">
                                                        <FormInputDropdown
                                                            name="CountryId"
                                                            ddOpt={countries}
                                                            label="Country"
                                                        />
                                                    </div>
                                                    <div className="col-md-12 pb-1">
                                                        <FormInputDropdown
                                                            name="CurrencyId"
                                                            ddOpt={currency}
                                                            label="Currency"
                                                            isRequired="true"
                                                        />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <FormInputText
                                                            label="Name"
                                                            name="Name"
                                                            type="text"
                                                            isRequired="true"
                                                        />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-5 d-flex align-items-center" style={{ color: "#5a5a5a" }}>
                                                                Validity Date
                                                                <span className="text-danger">＊</span>
                                                            </div>
                                                            <div className="col-md-7 p-0">
                                                                <div className="d-inline-block">
                                                                    <FormInputText
                                                                        name="ValidityFromDate"
                                                                        type="date"
                                                                        min={DateTime.local().toISODate()}
                                                                        max="2999-12-31"
                                                                        isRequired="true"
                                                                        portalId="root"
                                                                        setValue={(val) => setValidityFromDate(val)}
                                                                    />
                                                                </div>
                                                                <div className="d-inline-block ms-3">
                                                                    <FormInputText
                                                                        name="ValidityToDate"
                                                                        type="date"
                                                                        min={ValidityFromDate}
                                                                        max="2999-12-31"
                                                                        isRequired="true"
                                                                        portalId="root"
                                                                        // disabled={ValidityFromDate ? false : true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 pb-1">
                                                        <FormInputDropdown
                                                            name="SubscriptionTypeId"
                                                            ddOpt={SubscriptionType}
                                                            label="Subscription Type"
                                                            isRequired="true"
                                                        />
                                                    </div>
                                                    <div className="col-md-12 pb-1">
                                                        <FormInputDropdown
                                                            name="IsDefault"
                                                            label="Default"
                                                            ddOpt={StandardConst.YesOrNo}
                                                            isRequired="true"
                                                        />
                                                    </div>
                                                    <div className="col-md-12 pb-1">
                                                        <FormInputDropdown
                                                            name="Status"
                                                            label="Status"
                                                            ddOpt={StandardConst.StatusDropdown}
                                                            isRequired="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <Button
                                                        id="btnSubmit"
                                                        variant="outline-primary"
                                                        type="submit"
                                                        className="px-4 float-end mt-4 mb-1"
                                                        style={{ marginRight: "27px" }}
                                                    >
                                                        {" "}
                                                        {(data?.Subscription?.SubscriptionId || 0) === 0
                                                            ? "Submit"
                                                            : "Save Changes"}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )
                                :
                                ""}
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

const SubscriptionDetails = (props) => {

    const dispatch = useDispatch();
    const ref = useRef();
    const refSnackbar = useRef();
    const addEditModalRef = useRef();

    const [records, setRecords] = useState([]);
    const [subscriptionCurrencyData, setSubscriptionCurrencyData] = useState({});

    const fetchAllSubscriptionFeatures = async () => {

        if (props.SubscriptionId) {
            await WebService({
                endPoint: `Subscription/fetchSubscriptionCurrencyDetail?SubscriptionId=${props.SubscriptionId}`,
                dispatch,
            }).then(res => res.length > 0 ? setSubscriptionCurrencyData(res[0]) : null );

            const data = await WebService({
                endPoint: `CommonUtility/Edit/subscription_details?SubscriptionId=${props.SubscriptionId}`,
                dispatch,
            });
            setRecords(data);
        }
    }

    useEffect(() => {
        fetchAllSubscriptionFeatures();
    }, [props.SubscriptionId]);

    const [companyCurrencyData, setCompanyCurrencyData] = useState({});
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});

    const fetchCurrencyData = async () => {
        const companyInformation = await WebService({
            endPoint: `CommonUtility/Edit/companyinformation?CompanyId=${companyInfo.CompanyId || 0}`,
            dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}));
        const currencyInformation = await WebService({
            endPoint: `CommonUtility/Edit/staticcurrency?CurrencyId=${companyInformation.CurrencyId || 0}`,
            dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}));

        const currencyLocaleCode = await WebService({
            endPoint: `CommonUtility/Edit/countries?CountryId=${currencyInformation.CountryId || 0}`,
            dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}));

        currencyInformation.LocaleCode = currencyLocaleCode.LocaleCode;

        setCompanyCurrencyData(currencyInformation);
    };

    useEffect(() => {
        if (companyInfo?.CurrencyId) {
            fetchCurrencyData();
        }
    }, [companyInfo]);

    const columns = [
        {
            Text: "	SubType",
            Value: "SubType"
        },
        {
            Text: "Duration",
            Value: "Duration"
        },
        {
            Text: "User Limit From",
            Value: "UserLimitFrom"
        },
        {
            Text: "User Limit To",
            Value: "UserLimitTo"
        },
        {
            Text: "Price Per User",
            Value: "PricePerUser",
            render: (dr) => {
                return (
                    <>
                        <span>
                            {subscriptionCurrencyData.Symbol}{" "}{dr.PricePerUser}
                        </span>
                    </>
                )
            }
        },
        {
            Text: "Discount",
            Value: "Discount",
            render: (dr) => {
                return (
                    <>
                        <span>
                            {subscriptionCurrencyData.Symbol}{" "}{dr.Discount}
                        </span>
                    </>
                )
            }
        },
        {
            Text: "Final Price",
            Value: "FinalPrice",
            render: (dr) => {
                return (
                    <>
                        <span>
                            {subscriptionCurrencyData.Symbol}{" "}{dr.FinalPrice}
                        </span>
                    </>
                )
            }
        },
        {
            Text: "Discount In Percentage",
            Value: "DiscountInPercentage"
        },
        {
            Text: "Action",
            cssClass: "text-center td-width-100",
            isVisiable: true,
            render: (dr) => (
                <>
                    <ActionButton
                        onClick={() => fnEdit(dr.SubscriptionDetailId)}
                        IconName="Edit"
                        IconTooltip={"Edit Subcription Feature"}
                        id={`btnSubcriptionEdit_${dr.SubscriptionDetailId}`}
                    />
                    <ActionButton
                        onClick={(e) =>
                            ref.current.confirmAlert(
                                "Delete", //Confirm button text
                                "Are You Sure", // Text if Alert
                                "Do you want to delete " + dr.SubType, // Message of Alert
                                dr.SubscriptionDetailId // Endpoint to hit for delete
                            )
                        }
                        IconName="Delete"
                        id={`btnDelete${dr.SubscriptionDetailId}`}
                    />
                </>
            ),
        },
    ];

    const onDelete = async (SubscriptionDetailId) => {
        await WebService({
            endPoint: `CommonUtility/subscription_details?SubscriptionDetailId=${SubscriptionDetailId}`,
            method: "DELETE",
            dispatch,
        });
        refSnackbar.current.setOpenSnackBar();
        await fetchAllSubscriptionFeatures();
    };

    const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);

    const AddButtonText = "Subscription Detail";

    return (
        <>
            <div>
                <Container className="base-container" fluid style={{ width: "98%" }}>
                    <div className="row p-0 mb-2 d-flex flex-row">
                        <div className="col-md-12">
                            <div className="table-subscription-details">
                                <SnackbarComponent ref={refSnackbar} confirmMessage={"Delete"} />
                                <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
                                <TableComponent
                                    columns={columns}
                                    data={records}
                                    title={AddButtonText}
                                    noRecordCss="p-0"
                                    noRecordFound={
                                        <NoRecordTemplate
                                            headerValue={StandardConst.SubscriptionDetailsheaderValueNoResults}
                                            subHeaderValue={StandardConst.SubscriptionDetailsSubHeaderRole}
                                            imageUrl={StandardConst.imageNoRecordsFound}
                                            actionButton={
                                                <>
                                                    {true && (
                                                        <Button variant="outline-primary" onClick={() => fnEdit(0)} id={`no_record_${AddButtonText}`}>
                                                            Add {AddButtonText}
                                                        </Button>
                                                    )}
                                                </>
                                            }
                                        />
                                    }
                                    isSearchRequired={true}
                                    allowSerialNo={true}
                                    onAddEvent={() => fnEdit()}
                                    isExcelExportButtonVisible={true}
                                />
                                {(Object.keys(subscriptionCurrencyData).length > 0) && (
                                    <AddEditSubscriptionDetail
                                        callBackEvent={async () => await fetchAllSubscriptionFeatures()}
                                        ref={addEditModalRef}
                                        SubscriptionId={props.SubscriptionId}
                                        SubscriptionCurrencyData={subscriptionCurrencyData}
                                    >
                                    </AddEditSubscriptionDetail>
                                )}
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

const SubscriptionFeatures = (props) => {

    const dispatch = useDispatch();
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const ref = useRef();
    const refSnackbar = useRef();
    const addEditModalRef = useRef();

    const [records, setRecords] = useState([]);

    const fetchAllSubscriptionFeatures = async () => {

        if (props.SubscriptionId) {
            const data = await WebService({
                endPoint: `CommonUtility/Edit/subscription_features?SubscriptionId=${props.SubscriptionId}`,
                dispatch,
            });
            setRecords(data);
        }
    }

    useEffect(() => {
        fetchAllSubscriptionFeatures();
    }, [props.SubscriptionId]);

    const columns = [
        {
            Text: "	Feature",
            Value: "Feature"
        },
        {
            Text: "Description",
            Value: "Description"
        },
        {
            Text: "Action",
            cssClass: "text-center td-width-100",
            isVisiable: true,
            render: (dr) => (
                <>
                    <ActionButton
                        onClick={() => fnEdit(dr.SubscriptionFeatureId)}
                        IconName="Edit"
                        IconTooltip={"Edit Subcription Feature"}
                        id={`btnSubcriptionEdit_${dr.SubscriptionFeatureId}`}
                    />
                    <ActionButton
                        onClick={(e) =>
                            ref.current.confirmAlert(
                                "Delete", //Confirm button text
                                "Are You Sure", // Text if Alert
                                "Do you want to delete " + dr.Feature, // Message of Alert
                                dr.SubscriptionFeatureId // Endpoint to hit for delete
                            )
                        }
                        IconName="Delete"
                        id={`btnDelete${dr.SubscriptionFeatureId}`}
                    />
                </>
            ),
        },
    ];

    const onDelete = async (SubscriptionFeatureId) => {
        await WebService({
            endPoint: `CommonUtility/subscription_features?SubscriptionFeatureId=${SubscriptionFeatureId}`,
            method: "DELETE",
            dispatch,
        });
        refSnackbar.current.setOpenSnackBar();
        await fetchAllSubscriptionFeatures();
    };

    const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);

    const AddButtonText = "Subscription Feature";


    return (
        <>
            <div>
                <Container className="base-container" fluid style={{ width: "98%" }}>
                    <div className="row p-0 mb-2 d-flex flex-row">
                        <div className="col-md-12">
                            <div className="table-subscription-features">
                                <SnackbarComponent ref={refSnackbar} confirmMessage={"Delete successfully"} />
                                <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
                                <TableComponent
                                    columns={columns}
                                    data={records}
                                    title={AddButtonText}
                                    noRecordCss="p-0"
                                    noRecordFound={
                                        <NoRecordTemplate
                                            headerValue={StandardConst.SubscriptionDetailsheaderValueNoResults}
                                            subHeaderValue={StandardConst.SubscriptionDetailsSubHeaderRole}
                                            imageUrl={StandardConst.imageNoRecordsFound}
                                            actionButton={
                                                <>
                                                    {true && (
                                                        <Button variant="outline-primary" onClick={() => fnEdit(0)} id={`no_record_${AddButtonText}`}>
                                                            Add {AddButtonText}
                                                        </Button>
                                                    )}
                                                </>
                                            }
                                        />
                                    }
                                    onAddEvent={() => fnEdit()}
                                    isSearchRequired={true}
                                    allowSerialNo={true}
                                    isExcelExportButtonVisible={true}
                                />
                                <AddEditSubscriptionFeature
                                    callBackEvent={async () => await fetchAllSubscriptionFeatures()}
                                    ref={addEditModalRef}
                                    SubscriptionId={props.SubscriptionId}
                                ></AddEditSubscriptionFeature>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default AddEditManageSubscription;
