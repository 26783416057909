import FilePresentIcon from "@mui/icons-material/FilePresent";
import ApprovalIcon from "@mui/icons-material/Approval";
import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Button from "react-bootstrap/Button";
import { Form, FormAutoCompleteDropdownCard, FormInputDropdown, FormInputText } from "../Form";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import * as yup from "yup";
import { WebService } from "../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import _, { findWhere, groupBy, map, pluck, reduce, sortBy, union, where } from "underscore";
import TableComponent, {
  NoRecordTemplate,
} from "../../Services/TableComponent";
import { ActionPermission, PageInfo } from "../PageInfo";
import { Box, IconButton, Toolbar, Tooltip } from "@mui/material";
import { StandardConst } from "../../Services/StandardConst";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import { dataClone, generateQueryStringByObject } from "../../Services/UtilityService";
import { WSErrorAlert } from "../../Services/WSAlert";

const TimesheetReport = () => {
  PageInfo({ pageTitle: "Time Sheet Report" });
  const roundOf = (num, position) => {
    return (
      Math.round((num + Number.EPSILON) * Math.pow(10, position)) /
      Math.pow(10, 2)
    );
  };
  const [filter, setFilter] = useState({
    FromDate: DateTime.local().toFormat("yyyy-MM-dd"),
    ToDate: DateTime.local().toFormat("yyyy-MM-dd"),
  });
  const [data, setData] = useState(null);
  const [fromdate, setFromdate] = useState(null);
  const [todate, setTodate] = useState(null);
  const [projects, setProjects] = useState([{ value: 0, text: "All" }]);
  const [tasks, setTasks] = useState();
  const [persons, setPersons] = useState([{ value: 0, text: "All" }]);
  const [userProfile, setUserProfile] = useState({});
  const [companyTaskList, setCompanyTaskList] = useState([]);
  // const renderAfterCalled = useRef(false);
  const dispatch = useDispatch();
  const fetchProjectList = async () => {
    var opt = { endPoint: "Project/Team", dispatch };
    if (permission.ManageSearchAllUsers)
      opt.endPoint = "CommonUtility/projectmaster?select=ProjectId,ProjectName";
    const res = await WebService(opt).then((c) =>
      sortBy(c ?? [], (s) => s.ProjectName)
    );
    setProjects((projects) =>
      [...projects].concat(
        (res ?? []).map((m) => {
          return {
            value: m.ProjectId,
            text: m.ProjectName,
          };
        })
      )
    );
  };
  const fetchCompanyTaskList = () =>
    WebService({
      endPoint: `CommonUtility/project_tasks?${generateQueryStringByObject({
        select: "TaskCategoryId,TaskCategoryName,ProjectId",
      })}`,
      dispatch,
    }).then(setCompanyTaskList);
  useEffect(() => {
    if ((projects ?? []).length > 1) {
      if (companyTaskList.length < 1) {
        setTasks([]);
      } else {
        var res = dataClone(companyTaskList);
        if (!permission.ManageSearchAllUsers) {
          res = (res ?? []).filter(
            (f) => where(projects ?? [], { value: f.ProjectId }).length > 0
          );
        }
        res = map(groupBy(res ?? [], "TaskCategoryName"), (v, k) => ({
          value: k,
          text: k,
          details: v,
        }));
        res = sortBy(res ?? [], (s) => s.value);
        setTasks([{ value: 0, text: "All" }].concat(res));
      }
    }
  }, [projects, companyTaskList]);
  const fetchPersonList = async () => {
    let opt = { dispatch };
    setPersons([{value: 0, text: "All"}]);
    const manageSearchAllUsers = ActionPermission("TimeSheet Report - Search All Users");
    if (manageSearchAllUsers){
        opt.endPoint = `Visitor/fetchAllEmployees`;
    }else {
      opt.endPoint = "Project/Team/Member/Under";
    }
    const res = await WebService(opt).then((r) =>
      sortBy(r ?? [], (f) => f.FullName)
    );

    const emplist = res?.filter((r) => r.FullName !== null)?.map((res) => {
      return {
          ...res,
          EmployeeId: res.EmployeeId,
          heading: res.FullName,
          description: res.Designation,
          avatar: `${StandardConst.apiBaseUrl}/uploads/${res.ProfileImage}`,
      }
  });
      setPersons(emplist);
  };
  const fetchUserProfile = async () => {
    var opt = { endPoint: "UserProfile/Fetch/0", dispatch };
    const res = await WebService(opt).then((r) =>
      sortBy(r ?? [], (f) => f.FullName)
    ).then(r=>r.length>0?r[0]:{});
    setUserProfile(res);
  };

  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  const LoggedEmployee = useSelector((s) => s.auth.LoggedUser ?? 0);
  useEffect(() => {
    SetPermission({
      ManageSearchAllUsers: ActionPermission("TimeSheet Report - Search All Users"),
    });
    fetchPersonList();
  }, [permissionList]);


  const init = async () => {
    await Promise.all([
      fetchProjectList(),
      // fetchUserProfile(),
      fetchCompanyTaskList(),
    ]);
  };
  useEffect(() => {
    // if (!renderAfterCalled.current) {
      Promise.all([init()]);
    // }
    // renderAfterCalled.current = true;
  }, [permission]);

  const onSubmit = async (rec) => {
    if(rec?.TaskId?.toString()==="0"){
      if(tasks.length>1){
        rec.tasks = reduce(
          map(tasks, (m) => pluck(m?.details ?? [], "TaskCategoryId")),
          (m, v) => m.concat(v),
          []
        );
      }
      else{
        rec.tasks=[0];
      }
    }else{
      rec.tasks = pluck(
        findWhere(tasks, { value: rec.TaskId })?.details ?? [],
        "TaskCategoryId"
      );
    }
    rec.ToDate = DateTime.fromJSDate(rec.ToDate).toSQLDate();
    rec.FromDate = DateTime.fromJSDate(rec.FromDate).toSQLDate();

    var EmployeeId = LoggedEmployee ?? 0;

    if(rec.EmployeeId !== undefined) {
      if(Array.isArray(rec.EmployeeId)){
        if(rec.EmployeeId[0] !== null){
          EmployeeId = rec.EmployeeId[0].EmployeeId;
          rec.EmployeeId = rec.EmployeeId[0];
        }else{
          WSErrorAlert("Error", "Please select employee");
          return;
        }
      }else{
        if(rec.EmployeeId !== null) {
          EmployeeId = rec.EmployeeId.EmployeeId;
          rec.EmployeeId = rec.EmployeeId;
        }else{
          WSErrorAlert("Error", "Please select employee");
          return;
        }
      }
    }
    
    setFilter(rec);
    // if(Object.keys(rec.EmployeeId).length > 0){
    //   rec.EmployeeId.EmployeeId ??= 0;
    // }
    var condition = `(Date between '${rec.FromDate}' and '${
      rec.ToDate
    }') and EmployeeId in (${
      EmployeeId == 0
        ? persons
            .filter((f) => f?.EmployeeId != 0)
            .reduce((m, v) => `${m},${v?.heading}`, "0")
        : EmployeeId
    }) and ProjectId in (${
      rec.ProjectId == 0
        ? projects
            .filter((f) => f.value != 0)
            .reduce((m, v) => `${m},${v.value}`, "0")
        : rec.ProjectId
    }) and TaskCategoryId in (${
      rec.tasks.reduce((m, v) => `${m},${v}`, "0")
    })`;
    const endPoint=`TimeSheet/Report?where=${condition}`;
    const res = await WebService({
      endPoint,
      dispatch,
    });
    setData(res);
    setFetchReportEndPoint(endPoint);
  };
  const [fetchReportEndPoint,setFetchReportEndPoint]=useState("");
  const requiredMessage = "This is a required field";
  const schema = yup
    .object()
    .shape({
      FromDate: yup
        .date()
        .label("From Date")
        .typeError(requiredMessage)
        .required()
        .when([], (_, passSchema) => {
          return todate ?? "" != ""
            ? passSchema.max(DateTime.fromSQL(todate).toJSDate())
            : passSchema;
        }),

      ToDate: yup
        .date()
        .label("To Date")
        .typeError(requiredMessage)
        .required()
        .when([], (_, passSchema) => {
          return fromdate ?? "" != ""
            ? passSchema.min(DateTime.fromSQL(fromdate).toJSDate())
            : passSchema;
        }),
    })
    .required();
  const filterChange = () => {
    setData(null);
  };
  const columns = [
    {
      Text: "Date",
      DateFormat: "yyyy-MM-dd",
      NotUseInExport: false,
      IsGroupByFeature: true,
    },
    {
      Text: "Project",
      Value: "ProjectName",
      IsGroupByFeature: true,
    },
    {
      Text: "Task",
      Value: "TaskCategoryName",
      IsGroupByFeature: true,
    },
    {
      Text: "Person",
      Value: "FullName",
      IsGroupByFeature: true,
    },
    {
      Text: "Details",
    },
    {
      Text: "Hours",
      style: { width: "100px", "text-align": "right" },
      Value: "TimeInHour",
      GroupByResult: "Summation",
    },
    {
      Text: "",
      NotUseInExport: true,
      style: { width: "60px", padding: "0px" },
      render: (dr) => (
        <>
          {attachmentAction(dr)}
          {/* {approvedAction(dr)} */}
        </>
      ),
    },
  ];
  const attachmentAction = (dr) => (
    <Tooltip title="Attachment">
      <IconButton
        disabled={(dr?.Attachment ?? "") == ""}
        size="small"
        color="primary"
        aria-label="attachment"
        component="label"
        onClick={() => {
          window.open(
            `${StandardConst.apiBaseUrl}/uploads/${dr.Attachment}`,
            "_blank"
          );
        }}
      >
        <FilePresentIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
  
  useEffect(() => {
    setFromdate(filter.FromDate);
  }, [filter.FromDate]);
  useEffect(() => {
    setTodate(filter.ToDate);
  }, [filter.ToDate]);
  const MasterPageName = "Time Sheet Report";
  const [bData, setBData] = React.useState([
    {
      title: "Time Sheet",
      hrefLink: "#",
    },
    {
      title: "Time Sheet Report",
      hrefLink: "#",
    },
  ]);

  const filterComponent = (
    <>
      {data === null && (
        <div className="primary-bg-color pt-2 pb-2 px-4">
          <Form
            defaultValues={filter}
            onSubmit={onSubmit}
            validationSchema={schema}
          >
            <Row>
              <Col className="col-md-4">
                <FormInputText
                  label="From Date"
                  name="FromDate"
                  type="date"
                  labelCss="text-light"
                  setValue={setFromdate}
                  max={(todate ?? "") == "" ? undefined : todate}
                />
              </Col>
              <Col className="col-md-4">
                <FormInputText
                  label="To Date"
                  name="ToDate"
                  labelCss="text-light"
                  type="date"
                  setValue={setTodate}
                  min={(fromdate ?? "") == "" ? undefined : fromdate}
                />
              </Col>
              {persons.length > 2 && (
                <Col md={4}>
                  <FormAutoCompleteDropdownCard
                    name="EmployeeId"
                    labelCss="text-light"
                    data={persons}
                    label="Person"
                    optionText="heading"
                    sx={{
                      "& fieldset": {
                        border: "none",
                      },
                      '& .MuiAutocomplete-inputRoot': {
                        backgroundColor: 'white',
                        height: "41px",
                        margin: "7px",
                        marginTop: "0px",
                      },
                      '& .MuiAutocomplete-input:hover, .MuiAutocomplete-input:focus': {
                        borderColor: 'white', 
                      },
                      "& .MuiInputBase-input":{},
                      '& .MuiInputLabel-root.Mui-focused': {
                        backgroundColor: 'white', // Background color of the label when focused
                        color: "black",
                      },
                      '& .MuiInputLabel-root': {
                        backgroundColor: 'white', // Background color of the label
                        color: "black",
                      },
                      "& .MuiInputBase-root": {
                        paddingTop: "0px",
                      },
                    }}
                  />
                </Col>
              )}
            </Row>
            <div className="pt-1 pb-1"></div>
            <Row>
              <Col md={4} className="py-0">
                <FormInputDropdown
                  name="ProjectId"
                  labelCss="text-light"
                  ddOpt={projects ?? []}
                  label="Project"
                />
              </Col>
              <Col md={4} className="py-0">
                <FormInputDropdown
                  name="TaskId"
                  labelCss="text-light"
                  ddOpt={tasks ?? []}
                  label="Task"
                />
              </Col>
              <Col md={4} className="py-0">
                <Button
                  id="btnRunReport"
                  variant="btn btn-outline-primary "
                  type="submit"
                  className="float-end text-light css-1y942vo-MuiButtonBase-root-MuiButton-root"
                >
                  Run Report
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </>
  );
  const reportResultComponent = (
    <>
      {data !== null && (
        <div>
          <div className="primary-bg-color p-4">
            <Row>
              <Col>
                <div className="float-start">
                  <span className="h4 me-2 text-light">
                    Detailed Time Report:
                  </span>
                  <span className="h5 text-light">
                    {filter.FromDate}
                    {" — "}
                    {filter.ToDate}
                  </span>
                </div>
                <div className="float-end">
                  <Button
                    id="btnTimesheetFilter"
                    className="text-light"
                    variant="outline-primary"
                    onClick={filterChange}
                  >
                    Change Filters
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          <div className="px-4 py-2">
            <Row>
              <Col>
                <div>
                  <span className="h6">Total hours: </span>{" "}
                  <span className="font-bold">
                    {data.reduce((m, c) => roundOf(m + c.TimeInHour, 2), 0)}
                  </span>
                </div>
              </Col>
              <Col>
                <div>
                  <span className="col-md-3 font-bold me-2">Project:</span>
                  <span className="col-md-9 font-bold">
                    {filter.ProjectId == 0
                      ? "All projects"
                      : projects.filter((f) => f.value == filter.ProjectId)[0]
                          .text}
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <span className="col-md-3 font-bold me-2">Task:</span>
                  <span className="col-md-9 font-bold">
                    {filter?.TaskId == 0
                      ? "All tasks"
                      : tasks?.filter((f) => f.value == filter?.TaskId)[0]?.text}
                  </span>
                </div>
              </Col>
              {persons.length > 2 && (
                <Col>
                  <div>
                    {" "}
                    <span className="col-md-3 font-bold me-2">Team:</span>
                    <span className="col-md-9 font-bold">
                      {filter.EmployeeId.EmployeeId == 0
                        ? "Everyone"
                        : persons.filter((f) => f.EmployeeId == filter.EmployeeId.EmployeeId)[0]
                            .heading}
                    </span>
                  </div>
                </Col>
              )}
            </Row>
          </div>
          <TableComponent
            columns={columns}
            data={data}
            IsAddButtonVisible={false}
            excelExportFileName={"TimeSheet"}
            noRecordCss="p-0"
            noRecordFound={
              <NoRecordTemplate
                headerValue="Timesheets are not submitted. "
                subHeaderValue="Once the team has submitted the timesheets, you will be able to see the timesheet summary report."
                imageUrl="uploads/Static/no-records-j.png"
                actionButton={
                  <>
                    <Button variant="outline-primary" onClick={filterChange}>
                      Change filter
                    </Button>
                  </>
                }
              />
            }
            allowSerialNo={true}
          />
        </div>
      )}
    </>
  );
  return (
    <>
      <Container fluid className="base-container">
        <Box
          sx={{
            width: 1,
          }}
        >
          <h3 className="ms-4 mt-2">{MasterPageName}</h3>
          <div className="ms-4">
            <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
          </div>
        </Box>
        {filterComponent}
        {reportResultComponent}
      </Container>
    </>
  );
};

export default TimesheetReport;
