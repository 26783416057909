import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import { Form, FormAutoCompleteDropdownCard, InputDatePicker, InputDropdown } from "../Form";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { WebService } from "../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import _, { extend, map, omit, sortBy } from "underscore";
import TableComponent from "../../Services/TableComponent";
import { ActionPermission, PageInfo } from "../PageInfo";
import TopbarComponent from "../../Services/TopbarComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";
import { StandardConst } from "../../Services/StandardConst";
import WSCircularProgress from "../../Services/WSCircularProgress";
import SquareIcon from "@mui/icons-material/Square";
import { Button, ButtonGroup } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import WSLinearProgressColor from "../../Services/WSLinearProgressColor";

const LeaveReport = () => {
  const [permission, SetPermission] = useState({
    AllUser: ActionPermission("Leave - Search All User"),
  });

  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
      AllUser: ActionPermission("Leave - Search All User"),
    });
    fetchEmployee();
  }, [permissionList]);

  const loggedUser = useSelector((s) => s.auth.LoggedUser ?? 0);
  const childUser = useSelector((s) => s.auth.ChildUsers ?? []);
  PageInfo({ pageTitle: "Leave Summary " });
  var dispatch = useDispatch();
  const roundOf = (num, position) => {
    return (
      Math.round((num + Number.EPSILON) * Math.pow(10, position)) /
      Math.pow(10, 2)
    );
  };
  const [filter, setFilter] = useState({
    FromDate: DateTime.local().toFormat("yyyy-MM-dd"),
    ToDate: DateTime.local().toFormat("yyyy-MM-dd"),
    leaveType: 0,
    employee: []
  });
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [reports, setReports] = useState([]);
  const [basicDataIsLoaded, setBasicDataIsLoaded] = useState(false);
  const [leaveTotalBalance, setLeaveTotalBalance] = useState({
    Consumed:0,
    Allocated:0
  });
  const afterRender = useRef(false);
  useEffect(() => {
    if (!afterRender.current) {
      Promise.all([fetchLeaveType()]);
    }
    afterRender.current = true;
  }, []);
  const fetchLeaveType = async () => {
    const v1 = await WebService({
      dispatch,
      endPoint: "CommonUtility/staticleavetypes",
    });
    if (v1.length > 0){
      setLeaveTypes(
        [{ value: 0, text: "All Leave Types" }].concat(
          map(sortBy(v1,"LeaveName"), (m) => ({
            value: m.LeaveId,
            text: m.LeaveName,
          }))
        )
      );
      setLeaveType(sortBy(v1,"LeaveName").map(m=>({LeaveType:m.LeaveName})));
    }
  };
  const fetchEmployee = async () => {
    let v1 = await WebService({
      dispatch,
      endPoint: `Visitor/fetchAllEmployees`
    });

    const childUsers = await WebService({ dispatch, endPoint: "User/LoggedUser" }).then((r) => {
      let resp;
      if((r.EmployeeId??0)!==0){
        resp = WebService({ dispatch, endPoint: `User/ChildUsers/${r.EmployeeId}` }).then((cu) => {
          return cu;
        });
      }
      return resp;
    });

    const AllUser = ActionPermission("Leave - Search All User");

    if (v1.length > 0) {
      // if(!permission.AllUser){
      if(AllUser){
        const listOfUser = childUsers.concat([loggedUser]);
        v1 = v1.filter(
          (f) => listOfUser.filter((u) => u === f.EmployeeId).length > 0
        );
      }
      setEmployees(
        [{ EmployeeId: 0, heading: "All Employees" }].concat(
            map(sortBy(v1,"FullName"), (m) => ({
              EmployeeId: m.EmployeeId,
              heading: m.FullName, 
              description: m.Designation, 
              avatar: `${StandardConst.apiBaseUrl}/uploads/${m.ProfileImage}`
          }))
        )
      );
    }
  };

  const fetchReport = async (search = filter) => {
    const leaveType =
      search.leaveType != 0
        ? `'${search.leaveType}'`
        : leaveTypes
            .filter((f) => f.value != 0)
            .reduce((m, v) => `${m},${v.value}`, "")
            .substring(1);
    
    const employee = search.employee.length > 0
      ? search.employee[0].EmployeeId
      : employees[0].EmployeeId;

    if (leaveTypes.length > 0 && employees.length>0) {
      const endPoint = `Report/Leave/Summary?Types=${leaveType}&Employees=${employee}&FromDate=${search.FromDate}&ToDate=${search.ToDate}`;
      let results = await WebService({ dispatch, endPoint });
      
      results = map(results, (m1) =>
        extend(omit(m1,"Leave"), {
          Leave: m1.Leave.filter(f=>(f.Consumed===0 && f.Allocated===0)===false).map(l=>{
            l.Consumed=parseFloat(l.Consumed);
            l.Allocated=parseFloat(l.Allocated);
            if(l.Consumed>l.Allocated){
              l.Allocated=l.Consumed;
            }
            return l;
          }),
        })
      ).filter((f) => f.Leave.length > 0);
      setReports(results);
      CalculateLeaveBalance(results);
    }

    setBasicDataIsLoaded(true);
  };
  const CalculateLeaveBalance = (data) => {
        const s1 = data.map((m) => m.Leave).reduce((m, r) => m.concat(r), []);
        setLeaveTotalBalance({
      Consumed: s1.reduce((m, r) => m + r.Consumed, 0),
      Allocated: s1.reduce((m, r) => m + r.Allocated, 0),
    });
      };
  const [LeaveType, setLeaveType] = useState([
    { LeaveType: "Casual" },
    { LeaveType: "Unpaid" },
    { LeaveType: "Sick" },
    { LeaveType: "Leave x" },
    { LeaveType: "Leave Y" },
  ]);
  const [BarColors, setBarColors] = useState([
    {
      Item: 0,
      PrimaryColor: "#0AA2C0",
      SecondaryColor: "#9EEAF9",
    },
    {
      Item: 1,
      PrimaryColor: "#D63384",
      SecondaryColor: "#EFADCE",
    },
    {
      Item: 2,
      PrimaryColor: "#3D8BFD",
      SecondaryColor: "#9EC5FE",
    },
    {
      Item: 3,
      PrimaryColor: "#FD7E14",
      SecondaryColor: "#FECBA1",
    },
    {
      Item: 4,
      PrimaryColor: "#4DD4AC",
      SecondaryColor: "#A6E9D5",
    },
  ]);
  useEffect(() => {
    if (
      (filter.FromDate ?? "").toString().length > 0 &&
      (filter.ToDate ?? "").toString().length > 0 &&
      leaveTypes.length > 0 &&
      employees.length > 0
    ) {
      fetchReport();
    }
  }, [filter, leaveTypes, employees]);

  //#region Time Frame
  const [timeFrame, setTimeFrame] = useState(StandardConst.timeFrameForLeaves.Month);
  const setDateByTimeFrame = (fromDate) => {
    switch (timeFrame) {
      //dropdown option for week semimonth and costum are removed because consume and allocated leave calculation is deficult for a day week or semimonth -- (2023-09-29)
      //#region Week
      // case StandardConst.timeFrameForLeaves.Week: {
      //   setFilter((filter) => ({
      //     ...filter,
      //     FromDate: fromDate.toSQLDate(),
      //     ToDate: fromDate.plus({ day: 6 }).toSQLDate(),
      //   }));
      //   break;
      // }
      //#endregion
      //#region Semimonth
      // case StandardConst.timeFrameForLeaves.Semimonth: {
      //   setFilter((filter) => ({
      //     ...filter,
      //     FromDate: fromDate.toSQLDate(),
      //     ToDate: (fromDate.day > 15
      //       ? fromDate.endOf("month")
      //       : fromDate.plus({ day: 14 })
      //     ).toSQLDate(),
      //   }));
      //   break;
      // }
      //#endregion
      //#region Month
      case StandardConst.timeFrameForLeaves.Month: {
        setFilter((filter) => ({
          ...filter,
          FromDate: fromDate.toSQLDate(),
          ToDate: fromDate.endOf("month").toSQLDate(),
        }));
        break;
      }
      //#endregion
      //#region Quarter
      case StandardConst.timeFrameForLeaves.Quarter: {
        setFilter((filter) => ({
          ...filter,
          FromDate: fromDate.toSQLDate(),
          ToDate: fromDate.endOf("quarter").toSQLDate(),
        }));
        break;
      }
      //#endregion
      //#region Year
      case StandardConst.timeFrameForLeaves.Year: {
        setFilter((filter) => ({
          ...filter,
          FromDate: fromDate.toSQLDate(),
          ToDate: fromDate.endOf("year").toSQLDate(),
        }));
        break;
      }
      //#endregion
    }
  };
  useEffect(() => {
    switch (timeFrame) {

      //dropdown option for week semimonth and costum are removed because consume and allocated leave calculation is deficult for a day week or semimonth -- (2023-09-29)
      //#region Week
      // case StandardConst.timeFrameForLeaves.Week: {
      //   const fromDate = DateTime.now().minus({
      //     day: DateTime.now().weekday - 1,
      //   });
      //   setDateByTimeFrame(fromDate);
      //   break;
      // }
      //#endregion
      //#region Semimonth
      // case StandardConst.timeFrameForLeaves.Semimonth: {
      //   const currentDay = DateTime.now().day;
      //   let fromDate = DateTime.now().startOf("month");
      //   if (currentDay > 15) fromDate = fromDate.plus({ day: 15 });
      //   setDateByTimeFrame(fromDate);
      //   break;
      // }
      //#endregion
      //#region Month
      case StandardConst.timeFrameForLeaves.Month: {
        setDateByTimeFrame(DateTime.now().startOf("month"));
        break;
      }
      //#endregion
      //#region Quarter
      case StandardConst.timeFrameForLeaves.Quarter: {
        setDateByTimeFrame(DateTime.local().startOf("quarter"));
        break;
      }
      //#endregion
      //#region Year
      case StandardConst.timeFrameForLeaves.Year: {
        setDateByTimeFrame(DateTime.now().startOf("year"));
        break;
      }
      //#endregion
    }
  }, [timeFrame]);
  const fnPrevious = () => {
    switch (timeFrame) {

      //dropdown option for week semimonth and costum are removed because consume and allocated leave calculation is deficult for a day week or semimonth -- (2023-09-29)
      //#region Week
      // case StandardConst.timeFrameForLeaves.Week: {
      //   const fromDate = DateTime.fromSQL(filter.FromDate).minus({ day: 7 });
      //   setDateByTimeFrame(fromDate);
      //   break;
      // }
      //#endregion
      //#region Semimonth
      // case StandardConst.timeFrameForLeaves.Semimonth: {
      //   let fromDate = DateTime.fromSQL(filter.FromDate);
      //   fromDate =
      //     fromDate.day > 15
      //       ? fromDate.startOf("month")
      //       : fromDate.minus({ month: 1 }).plus({ day: 15 });
      //   setDateByTimeFrame(fromDate);
      //   break;
      // }
      //#endregion
      //#region Month
      case StandardConst.timeFrameForLeaves.Month: {
        setDateByTimeFrame(
          DateTime.fromSQL(filter.FromDate).minus({ month: 1 })
        );
        break;
      }
      //#endregion
      //#region Quarter
      case StandardConst.timeFrameForLeaves.Quarter: {
        setDateByTimeFrame(
          DateTime.fromSQL(filter.FromDate).minus({ month: 3 })
        );
        break;
      }
      //#endregion
      //#region Year
      case StandardConst.timeFrameForLeaves.Year: {
        setDateByTimeFrame(
          DateTime.fromSQL(filter.FromDate).minus({ year: 1 })
        );
        break;
      }
      //#endregion
    }
  };
  const fnNext = () => {
    switch (timeFrame) {

      //dropdown option for week semimonth and costum are removed because consume and allocated leave calculation is deficult for a day week or semimonth -- (2023-09-29)
      //#region Week
      // case StandardConst.timeFrameForLeaves.Week: {
      //   const fromDate = DateTime.fromSQL(filter.FromDate).plus({ day: 7 });
      //   setDateByTimeFrame(fromDate);
      //   break;
      // }
      //#endregion
      //#region Semimonth
      // case StandardConst.timeFrameForLeaves.Semimonth: {
      //   let fromDate = DateTime.fromSQL(filter.FromDate);
      //   fromDate =
      //     fromDate.day > 15
      //       ? fromDate.plus({ month: 1 }).startOf("month")
      //       : fromDate.plus({ day: 15 });
      //   setDateByTimeFrame(fromDate);
      //   break;
      // }
      //#endregion
      //#region Month
      case StandardConst.timeFrameForLeaves.Month: {
        setDateByTimeFrame(
          DateTime.fromSQL(filter.FromDate).plus({ month: 1 })
        );
        break;
      }
      //#endregion
      //#region Quarter
      case StandardConst.timeFrameForLeaves.Quarter: {
        setDateByTimeFrame(
          DateTime.fromSQL(filter.FromDate).plus({ month: 3 })
        );
        break;
      }
      //#endregion
      //#region Year
      case StandardConst.timeFrameForLeaves.Year: {
        setDateByTimeFrame(DateTime.fromSQL(filter.FromDate).plus({ year: 1 }));
        break;
      }
      //#endregion
    }
  };
  //#endregion
  var filterableViewComponent = (
    <>
      <Row className="mt-3 mx-2">
        <Col className="d-flex flex-row align-items-center p-0" md={9}>
          <ButtonGroup
            size="small"
            variant="outlined"
            aria-label="small button group"
          >
            <Button
              className={
                timeFrame === StandardConst.timeFrameForLeaves.Custom ? "hide" : ""
              }
              onClick={fnPrevious}
            >
              <ArrowBackIcon />
            </Button>
            <Button
              className={
                timeFrame === StandardConst.timeFrameForLeaves.Custom ? "hide" : ""
              }
              onClick={fnNext}
            >
              <ArrowForwardIcon />
            </Button>
          </ButtonGroup>
          <span className="mx-2 h4">
            <strong>
              This&nbsp;
              {`${timeFrame}: `}
            </strong>
            {`${DateTime.fromSQL(filter.FromDate).toFormat(
              "dd MMM"
            )} - ${DateTime.fromSQL(filter.ToDate).toFormat("dd MMM yyyy")}`}
          </span>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between mx-4 pt-2">
        <Col md={8} className="p-0">
          <Row>
            <Col md={4} className="p-0">
              <InputDropdown
                setValue={setTimeFrame}
                ddOpt={map(StandardConst.timeFrameForLeaves, (text, _) => ({
                  value: text,
                  text,
                }))}
              />
            </Col>
            <Col
              md={2}
              className={
                timeFrame === StandardConst.timeFrameForLeaves.Custom ? "p-0 " : "d-none"
              }
            >
              <InputDatePicker
                labelCss="text-center"
                value={filter.FromDate}
                setValue={(v) => setFilter({ ...filter, FromDate: v })}
                max={(filter.ToDate ?? "") == "" ? undefined : filter.ToDate}
              />
            </Col>
            <Col
              md={2}
              className={
                timeFrame === StandardConst.timeFrameForLeaves.Custom ? "" : "d-none"
              }
            >
              <InputDatePicker
                labelCss="text-center"
                value={filter.ToDate}
                setValue={(v) => setFilter({ ...filter, ToDate: v })}
                min={
                  (filter.FromDate ?? "") == "" ? undefined : filter.FromDate
                }
              />
            </Col>
          </Row>
        </Col>
        <Col md={4} className="p-0">
          <Row>
            <Col
              md={6}
              className={
                timeFrame === StandardConst.timeFrameForLeaves.Custom ? "p-0" : "p-0"
              }
            >
              <InputDropdown
                labelCss="text-center"
                className="form-control"
                ddOpt={leaveTypes || []}
                value={filter.leaveType}
                setValue={(v) => setFilter({ ...filter, leaveType: v })}
              />
            </Col>
            <Col md={6} className="ps-1">
                <Form>
                  <FormAutoCompleteDropdownCard
                      data={employees}
                      name="EmployeeId"
                      optionText="heading"
                      setValue={(v) =>
                        setFilter({ ...filter, employee: v })
                      } 
                      value={filter.employee.length > 0 ? filter.employee[0] : employees[0]}
                      placeholder="Choose a Employee"
                      disableClearable={true}
                    />
                </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
  var reportSummaryComponent = (
    <>
      <hr className="mx-2"></hr>
      <Row className="px-4 p-1">
        <Col md={1}>
          <div className="text-left mt-n1">
            <WSCircularProgress Billable={(leaveTotalBalance.Consumed/leaveTotalBalance.Allocated)*100} />
          </div>
        </Col>
        <Col md={2}>
          <div className="ms-2 small text-start">
            <SquareIcon
              sx={{
                color: `${StandardConst.wsBillableColor}`,
                fontSize: 20,
              }}
            />
            <span>&nbsp;Consumed</span>
            <br></br>
            <SquareIcon
              sx={{
                color: `${StandardConst.wsNonBillableColor}`,
                fontSize: 20,
              }}
            />
            <span>&nbsp;Allocated</span>
          </div>{" "}
        </Col>
        <Col className="d-flex flex-row  justify-content-between" md={3}>
          <div className="d-flex flex-column text-left">
            <div className="small">Consumed/Allocated Leaves</div>
            <div className="h3">
              <strong>
              {leaveTotalBalance?.Consumed ?? 0} / {leaveTotalBalance?.Allocated??0}
              </strong>
            </div>{" "}
          </div>
          {/* <div className="d-flex flex-column text-left">
            <div className="small">Expired Leaves</div>
            <div className="h3">
              <strong>
                {" "}
                <DisNumFormatWoCurrency
                  Number={roundOf(
                    reduce(reports, (m, v) => m + v.NonBillable, 0),
                    2
                  )}
                />
              </strong>
            </div>{" "}
          </div> */}
        </Col>
        <Col md={6}>
          <div className="ms-2 small mt-2">
            {LeaveType.map((p, i) => {
              return (
                <React.Fragment key={i}>
                  <SquareIcon
                    sx={{
                      color: `${BarColors[i]?.PrimaryColor}`,
                      fontSize: 20,
                    }}
                  />
                  <span>&nbsp;{p.LeaveType} &nbsp;</span>
                </React.Fragment>
              );
            })}
          </div>{" "}
        </Col>
      </Row>
    </>
  );
  var reportInGridComponent = (
    <>
      <Row>
        <Col>
          <TableComponent
            data={reports}
            columns={[
              {
                Text: "Employee",
                render: (dr) => <span>{dr.EmployeeName}</span>,
                cssClass: "w-25",
              },
              {
                Text: "Type",
                render: (dr) => (
                  <>
                    {dr.Leave.map((p, i) => {
                      return (
                        <React.Fragment key={i}>
                          {/* <div className={`d-flex flex-column my-1`}>
                            <span
                              class="badge"
                              style={{
                                backgroundColor: BarColors[i].PrimaryColor,
                              }}
                            >
                              {p.Type}
                            </span>
                          </div> */}
                          {p.Type}
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </>
                ),
                cssClass: "td-width-1 text-end",
              },

              {
                Text: "",
                cssClass: "w-50",
                render: (dr) => (
                  <>
                    {dr.Leave.map((p, i) => {
                      return (
                        <React.Fragment key={i}>
                          <WSLinearProgressColor
                            Billable={(p.Consumed / p.Allocated) * 100}
                            PrimaryColor={BarColors[i].PrimaryColor}
                            SecondaryColor={BarColors[i].SecondaryColor}
                          />
                        </React.Fragment>
                      );
                    })}
                  </>
                ),
              },
              {
                Text: "Consumed / Allocated",
                cssClass: "w-25",
                render: (dr) => (
                  <>
                    {dr.Leave.map((p, i) => {
                      return (
                        <React.Fragment key={i}>
                          <div className="d-flex flex-column text-start">
                            {p.Consumed} / {p.Allocated}
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </>
                ),
                cssClass: "td-width-1 text-start",
              },
            ]}
            noRecordCss="p-0"
            headerCss="table-header-grey"
            noRecordFound={
              <NoRecordTemplate
                headerValue="No leave records found"
                subHeaderValue="Looks like you don't have any results for the filter applied!!!"
                imageUrl="uploads/Static/no-records-j.png"
              />
            }
            IsAddButtonVisible={false}
          ></TableComponent>
        </Col>
      </Row>
    </>
  );
  const [bData, setBData] = React.useState([
    {
      title: "Leave Management",
      hrefLink: "#",
    },
    {
      title: "Leave Summary",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = "Leave Summary ";

  if(!basicDataIsLoaded){
    return <></>;
  }

  return (
    <>
      <Container fluid className="base-container">
        <TopbarComponent bData={bData} HeadingText={MasterPageName} />
        <div elevation={0} className="p-3 primary-bg-color"></div>
        <div>{filterableViewComponent}</div>

        {reportSummaryComponent}
        <hr className="mx-2 p-0 m-1"></hr>
        <div className="mx-2">{reportInGridComponent}</div>
      </Container>
    </>
  );
};

export default LeaveReport;
