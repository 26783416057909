import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { DateRange } from "../Dashboard/Company/CompanyDashboard";
import { useDispatch, useSelector } from "react-redux";
import { WebService } from "../../Services/WebService";
import { generateQueryStringByObject } from "../../Services/UtilityService";
import TableComponent, {
  NoRecordTemplate,
} from "../../Services/TableComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import { Avatar, Box, Button, Container, Stack } from "@mui/material";
import ActionButton from "../../Services/ActionButton";
import HtmlFileReader from "../../Services/HtmlFileReader";
import Modal from "react-bootstrap/Modal";
import { ActionPermission, PageInfo } from "../PageInfo";
import { StandardConst } from "../../Services/StandardConst";
import SnackbarComponent from "../../Services/SnackbarComponent";
import { DateTime } from "luxon";
import EditVisitorDetails from "./EditVisitorDetails";
import { WSSuccessAlert } from "../../Services/WSAlert";
import { Form, FormInputDropdown, FormInputText } from "../Form";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import _, { map } from "underscore";
import { useNavigate } from "react-router-dom";
import { formatCurrentDate } from "../../utils/CommonUtils";
import { useLocation } from 'react-router-dom';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const VisitorCheckInOutComponent = () => {
  const dispatch = useDispatch();
  PageInfo({ pageTitle: "CheckIn - CheckOut" });

  const [condition, setCondition] = useState({});
  const [records, setRecords] = useState([]);
  const confirmMessage = "Invitation deleted successfully";
  const refSnackbar = useRef();
  const ref = useRef();
  const EditVisitorModalRef = useRef();
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const CompanyId = useSelector((s) => s.auth.CompanyInfo?.CompanyId ?? 0);
  const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
  const { state } = useLocation();

  const [permission, setPermission] = useState({});
  useEffect(() => {
    setPermission({
      ManageAdd: ActionPermission("Visitor - Add"),
      ManageEdit: ActionPermission("Visitor - Edit"),
      ManageCheckout: ActionPermission("Visitor - Checkout"),
      ManageCheckIn: ActionPermission("Visitor - CheckIn"),
      ManagePrintBadge: ActionPermission("Visitor - PrintBadge"),
      ManageDownloadBadge: ActionPermission("Visitor - DownloadBadge"),
      ManageDelete: ActionPermission("Visitor - Delete"),
    });
  }, [permissionList]);

  const Roles = useSelector((s) => s.auth.AssignRole ?? {});

  const EmployeeId = useSelector((s) => s.auth.LoggedUser ?? {});
  const UserId = useSelector((s) => s.auth.LoggedCustomer ?? {});

  var userType = "";
  var ShowAllEventRecord = false;
  var EmployeeOrUserId;

  if(EmployeeId){
      userType = StandardConst.UserType.Employee;
      EmployeeOrUserId = EmployeeId
  }else if(UserId){
      userType = StandardConst.UserType.User;
      EmployeeOrUserId = UserId;
  }

  if(Roles?.some(element => element === StandardConst.SystemRole.Company) || Roles?.some(element => element === StandardConst.SystemRole.Receptionist) || Roles?.some(element => element === StandardConst.SystemRole.SuperAdmin)){
      ShowAllEventRecord = true;
      if(Roles?.some(element => element === StandardConst.SystemRole.SuperAdmin)) {
          userType = StandardConst.UserType.SuperAdmin;
      }
  }else if(Roles?.some(element => element === StandardConst.SystemRole.EventCoordinator)){
      ShowAllEventRecord = false;
  }


  const [EventList, setEventList] = useState([]);
  const fetchEventList = async () => {
    const data = await WebService({
      endPoint : `Event/EventList?CompanyId=${StandardConst.YesNo.Yes}&ShowAllEventRecord=${ShowAllEventRecord}&userType=${userType}&EmployeeOrUserId=${EmployeeOrUserId}`,
      dispatch,
    });


    const list = data.map((r) => {
      return { text: r.EventName, value: r.EventId }
    })
    setEventList(list);
  }

  const handleCurrentEventId = async (currentEventId) => {
      state.EventId = currentEventId;
      await fetchVisitorList(currentEventId);
  }

  useEffect(() => {
    fetchEventList();
  }, [userType, ShowAllEventRecord]);


  const [visitorTypeSelectedTemplate, setVisitorTypeSelectedTemplate] = useState([]);

  const userRole = useSelector((s) => s.auth.AssignRole);
  const loggedUser = useSelector((s) => s.auth.LoggedUser);

  const [InvitedBy, setInvitedBy] = useState(false);
  useEffect(() => {
    if (loggedUser && userRole.length > 0) {
      var doNotSetInvitedByCondition = false;
      userRole.map((element) => {
        // agar user role company hai ya receptionist hai to sare user visitor dikhane hai
        if (Number(element) === StandardConst.SystemRole.Company || Number(element) === StandardConst.SystemRole.Receptionist) {
          doNotSetInvitedByCondition = true;
        }
        if (Number(element) === StandardConst.SystemRole.Employee) {
          setInvitedBy(true);
        } else {
          setInvitedBy(false);
        }
      });
      if(doNotSetInvitedByCondition){
        setInvitedBy(false);
      }
    }
  }, [loggedUser, userRole]);


  const fetchVisitorList = useCallback(async (eventId, filterTimeData=null) => {

    const SelectedDate = condition.StartDate.endOf("day").setZone("utc").toSQL().substring(0, 10);

    const CheckInFromTime = (filterTimeData !== null) ? ((filterTimeData.CheckInFromTime !== null) ? filterTimeData.CheckInFromTime : null) : null;
    const CheckInToTime = (filterTimeData !== null) ? ((filterTimeData.CheckInToTime !== null) ? filterTimeData.CheckInToTime : null) : null;

    let endPoint = ``;
    if(eventId){
      endPoint = `Visitor/VisitList/${CompanyId}/${InvitedBy ? loggedUser : null}?SelectedDate=${SelectedDate}&CheckInFromTime=${CheckInFromTime}&CheckInToTime=${CheckInToTime}&EventId=${eventId}`;
    }else {
      endPoint = `Visitor/VisitList/${CompanyId}/${InvitedBy ? loggedUser : null}?SelectedDate=${SelectedDate}&CheckInFromTime=${CheckInFromTime}&CheckInToTime=${CheckInToTime}`;
    }

    await WebService({
      dispatch,
      endPoint: endPoint,
    }).then((r) => {
      setRecords(r);
    });
  }, [condition.StartDate, InvitedBy]);

  const autoCheckout = async() => {
    await WebService({
      dispatch,
      endPoint: `Event/EventVisitorAutoCheckOut`,
      method: "PUT",
    })
  };

  useEffect(() => {
    autoCheckout();
    if (CompanyId !== 0 && condition.StartDate !== undefined) {
      if(state?.EventId){
        Promise.all([fetchVisitorList(state?.EventId)]);
      }else {
        Promise.all([fetchVisitorList()]);
      }
    }
  }, [CompanyId, condition.StartDate, fetchVisitorList]);
  const GenerateBadgeRef = useRef();
  const [filter, setFilter] = useState({
    FromDate: DateTime.local().toFormat("yyyy-MM-dd"),
    ToDate: DateTime.local().toFormat("yyyy-MM-dd"),
  });

  const CheckOutAction = (dr) => {

    const formattedDate = formatCurrentDate();

    WebService({
      dispatch,
      endPoint: `Visitor/CheckOut`,
      body: { VisitorChekInChekOutId: dr.VisitorChekInChekOutId, CheckOut: formattedDate },
      method: "PATCH",
    }).then((_) => {
      fetchVisitorList(state?.EventId);
      WSSuccessAlert("Success", `${dr.VisitorName} Successfully Checked Out.`);
    });
  }
  const MasterPageName = "CheckIn - CheckOut";
  const [bData, setBData] = React.useState([
    {
      title: state?.EventId ? "Manage Event" : "Visitor Management",
      hrefLink: state?.EventId ? "/ManageEvents" : "#",
    },
    {
      title: "CheckIn - CheckOut",
      hrefLink: "#",
    },
  ]);

  const onfilter = async (data) => {
    fetchVisitorList(state?.EventId, data);
  };

  const filterComponent = (
    <>
      <Form onSubmit={(filterTime) => onfilter(filterTime)} >
        <div className="row primary-bg-color m-0 p-0 p-md-2">
            <div className="col-12 col-md-7">
              <div className="row">
                <div className="col-5">
                  <FormInputText
                    label="CheckIn From"
                    name="CheckInFromTime"
                    type="time"
                    labelCss="text-light"
                    // setValue={setFromdate}
                    // max={(todate ?? "") == "" ? undefined : todate}
                  />
                </div>
                <div className="col-5">
                  <div className="row p-0 m-0">
                    <div className="col-12 col-md-2 text-white p-0 mt-2 mt-md-0 d-flex justify-content-start align-items-center">To</div>
                    <div className="col-12 col-md-10 p-0">
                      <FormInputText
                        // label="To"
                        name="CheckInToTime"
                        labelCss="text-light"
                        type="time"
                        // setValue={setTodate}
                        // min={(fromdate ?? "") == "" ? undefined : fromdate}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-2 d-flex justify-content-center align-items-center mt-4 mt-md-0">
                  <Button
                    id="btnRunReport"
                    variant="outline-primary"
                    type="submit"
                    className="text-light float-end"
                  >
                    <FilterAltIcon />
                  </Button>
                </div>
              </div>
            </div>
            {/* <div className="col-12 col-md-1"></div> */}
            <div className="col-12 col-md-2">
            {
                state?.EventId ?
                  // <div className="d-flex justify-content-center">
                    <FormInputDropdown
                      name="EventId"
                      ddOpt={EventList}
                      value={state.EventId}
                      setValue={(currentEventId) => { handleCurrentEventId(Number(currentEventId)) }}
                      isRequired="true"
                    // label="Event"
                    ></FormInputDropdown>
                  // </div>
                : null
              }
            </div>
            <div className="col-12 col-md-3">
                <div elevation={0} className="bg-primary text-white">
                  <div className="d-flex justify-content-end align-items-center">
                    <DateRange
                      ButtonColor={"white"}
                      type="day"
                      setDateRange={({ StartDate, EndDate }) =>
                        setCondition((dt) => ({ ...dt, StartDate, EndDate }))
                      }
                    />
                  </div>
                </div>
            </div>
        </div>
      </Form>
    </>
  );
  const [visitorDetails, setVisitorDetails] = useState();
  const fnEdit = async (id, CheckInAction, VisitorTypeId, EventId) => {
    await EditVisitorModalRef.current.openModal(id || 0, CheckInAction, VisitorTypeId, EventId);
  }

  const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});

  const FetchVisitorDetails = async (visitorId) => {
    await WebService({
      dispatch,
      endPoint: `Visitor/GetVisitorDetails/${CompanyId}/${visitorId}${state?.EventId ? `?EventId=${state?.EventId}` : ``}`,
    }).then(async (d) => {
      setVisitorDetails(d[0]);
      setVisitorTypeSelectedTemplate([{
        text: d[0].badge_template_name, value: d[0].badge_templatesId,
        file: d[0].html_file_path
      }]);

      const nameString = d[0]?.VisitorName.toUpperCase();
      const lines = nameString.split(' ');
      const nameFormattedText = lines.length >= 5 ? lines.slice(0, 4).join(' ') : null;


      const companyString = d[0]?.VisitorCompany?.split(' ');
      const companyFormattedText = companyString?.length >= 5 ? companyString?.slice(0, 5).join(' ') : null;

      const paramData = {
        Name: nameFormattedText || d[0]?.VisitorName.toUpperCase(),
        Photo: d[0].ProfilePicture,
        VisitorType: d[0]?.TypeName,
        Company: companyFormattedText || d[0]?.VisitorCompany,
        Email: d[0]?.Email,
        Phone: d[0]?.ContactNo,
        Logo: CompanyInfo.Logo,
        Address: d[0]?.Address,
        Designation: d[0]?.Designation,
        VisitorId: d[0]?.VisitorId,
        CheckInTime: d[0]?.CheckIn
          ? DateTime.fromISO(d[0]?.CheckIn, { zone: 'utc' }).toFormat('dd-MMM-yyyy hh:mm a')
          : "Not CheckedIn",
        QRData:
          "Name: " + d[0]?.VisitorName +
          "Company: " + d[0]?.VisitorCompany +
          "Email: " + d[0]?.Email +
          "Phone: " + d[0]?.ContactNo
        ,
        VisitorGuid: d[0]?.Guid
      };
      await GenerateBadgeRef.current.openModal(paramData, state?.EventId);
    });
  };

  const [badgeList, setBadgeList] = useState([]);
  //  here fetch the visitor type list of the company from the database;
  const fetchVisitorBadgeList = async () => {
    var EndPoint = `Visitor/Type?CompanyId=${CompanyId}`;
    if(state?.EventId){
      EndPoint = `Event/getEventVisitorDataForDropdown?EventId=${state?.EventId}`;
    }
    const badgeList = await WebService({
      dispatch,
      endPoint: EndPoint
    });
    setBadgeList(badgeList);
  }

  useEffect(() => {
    fetchVisitorBadgeList();
  }, [CompanyId > 0])

  const onDelete = async (VisitorId) => {
    await WebService({
      endPoint: `Visitor/Remove/${VisitorId}`,
      method: "DELETE",
      dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    await fetchVisitorList(state?.EventId);
  };

  const columns = [
    ...(!state?.EventId ? [
      {
        Text: "Visit Time",
        render: (dr) => (
          <>
            <span>
              {dr.VisiteTime != null
                ? DateTime.fromISO(dr.VisiteTime).toFormat("hh:mm a")
                : ""}
            </span>
          </>
        ),
      }
    ] : []),
    {
      Text: "Visitor",
      render: (dr) => (
        <>
          <div className="d-flex flex-row align-items-center">
            <div>
              <Avatar
                variant="circle"
                alt={dr.VisitorName}
                src={
                  dr?.ProfilePicture != ""
                    ? `${StandardConst.apiBaseUrl}/uploads/visitorPhotos/${dr?.ProfilePicture ?? "user_placeholder.jpg"
                    }`
                    : `${StandardConst.apiBaseUrl}/uploads/user_placeholder.jpg`
                }
                sx={{ width: 40, height: 40 }}
              />
            </div>
            <div className="d-flex flex-column ms-2">
              <span>
                <strong>{dr.VisitorName}</strong>
              </span>
              <span>{dr.TypeName}</span>
            </div>
          </div>
        </>
      ),
    },

    ...(state?.EventId ? [{ Text: "Event Name", Value: "EventName" }] : []),

    { Text: "Company", Value: "VisitorCompany" },
    { Text: "Contact No", Value: "ContactNo" },
    { Text: "Email", Value: "Email" },
    { Text: "Designation", Value: "Designation" },

    ...(!state?.EventId ? [{ Text: "Location", Value: "Location" }] : []),
    ...(!state?.EventId ? [{ Text: "Contact Person", Value: "ContactPersonName" }] : []),
    ...(!state?.EventId ? [{ Text: "Invited By", Value: "InvitedUserName" }] : []),
    
    { Text: "Purpose", Value: "Purpose" },
    {
      Text: "Check-In/Out",

      render: (dr) => (
        <>
          <div className="d-flex flex-column">
            <span>
              {dr.CheckIn != null
                ? DateTime.fromISO(dr.CheckIn, { zone: 'utc' }).toFormat('dd-MMM hh:mm a')
                : "Not CheckedIn"}
            </span>
            <span>
              {/* {dr.CheckOut != null ? " - " : ""} */}
              {dr.CheckOut != null
                ? DateTime.fromISO(dr.CheckOut, { zone: 'utc' }).toFormat('dd-MMM hh:mm a')
                : ""}
            </span>
          </div>
        </>
      ),
    },
    { Text: "Duration", Value: "duration" },
    {
      Text: "Action",
      cssClass: "text-end",
      // render: tableComponectActions,
      isVisiable: permission.ManageEdit || permission.ManageDelete || permission.ManageCheckIn || permission.CheckOut || permission.ManageDownloadBadge || permission.ManagePrintBadge || permission.ManageAdd,
      render: (dr) => (
        <div>
          {(dr.CheckIn == null || state?.EventId ) && (
            <>
              {((dr.CheckIn !== null && dr.CheckOut !== null ) || (dr.CheckIn === null && dr.CheckOut === null)) && (
                <ActionButton
                  IconTooltip={"Check-In"}
                  onClick={() => fnEdit(dr.VisitorId, "CheckInAction", dr.VisitorTypeId, state?.EventId)}
                  IconName="CheckIn"
                  id="btnCheckIn"
                  disabled={!permission.ManageCheckIn}
                />
              )}
            </>
          )}
           <ActionButton
                IconTooltip={"Edit"}
                onClick={() => fnEdit(dr.VisitorId, "Edit", dr.VisitorTypeId, state?.EventId)}
                IconName="Edit"
                id="Editbtn"
                disabled={!permission.ManageEdit}
              />
          {dr?.CheckIn != null && dr?.CheckOut == null && (
            <>
              <ActionButton
                IconTooltip={"Check-Out"}
                onClick={async () => await CheckOutAction(dr)}
                IconName="CheckOut"
                id="btnCheckOut"
                disabled={!permission.ManageCheckout}
              />
              {
                badgeList.map((obj) => {
                  if (obj.VisitorTypeId === dr.VisitorTypeId && obj.VisitorBadgeNeeded !== null) {
                    return (
                      <ActionButton
                        key={obj.id}
                        IconTooltip={"Print Badge"}
                        onClick={async () => await FetchVisitorDetails(dr.VisitorId)}
                        IconName="Print"
                        id="btnBadge"
                        disabled={!permission.ManagePrintBadge}
                      />
                    );
                  }
                  return null;
                })
              }
            </>
          )}

          {dr.InvitedBy != null && (
            <ActionButton
              onClick={(e) =>
                ref.current.confirmAlert(
                  "Delete",
                  "Are You Sure",
                  "Do you want to delete " + dr.VisitorName,
                  dr.VisitorId
                )
              }
              IconName="Delete"
              id="btnStaticPageDelete"
              disabled={!permission.ManageDelete}
            />
          )}
        </div>
      ),
    },
  ];

  if (state?.EventId){
    const movedObject = columns.splice(4, 1)[0];
    columns.splice(0, 0, movedObject);
  }


  return (
    <Container fluid className="base-container">
      <Box
        sx={{
          width: 1,
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="">
            <h3 className="ms-4 mt-2">{MasterPageName}</h3>
            <div className="ms-4">
              <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
            </div>
          </div>
        </div>
      </Box>
      <div></div>
      {filterComponent}
      {/* <div elevation={0} className="p-0 bg-primary mt-2 text-white">
        <div className="d-flex justify-content-end align-items-center">
          <DateRange
            ButtonColor={"white"}
            type="day"
            setDateRange={({ StartDate, EndDate }) =>
              setCondition((dt) => ({ ...dt, StartDate, EndDate }))
            }
          />
        </div>
      </div> */}
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
      <TableComponent
        columns={columns}
        noRecordCss="p-0"
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.headerValueNoResults}
            subHeaderValue={StandardConst.subHeaderValueStandard}
            imageUrl={StandardConst.imageNoRecordsFound}
          />
        }
        data={records}
        isSearchRequired={true}
        allowSerialNo={true}
      />
      <GenerateBadge ref={GenerateBadgeRef} visitorTypeSelectedTemplate={visitorTypeSelectedTemplate}/>
      <EditVisitorDetails
        callBackEvent={() => fetchVisitorList(state?.EventId)}
        ref={EditVisitorModalRef}
        state={state}
      ></EditVisitorDetails>
    </Container>
  );
};
const GenerateBadge = forwardRef(({visitorTypeSelectedTemplate}, ref) => {
  const [show, setShow] = useState(false);
  const [paramData, setParamData] = useState(false);
  const [eventId, setEventId] = useState(null);
  const handleClose = () => setShow(false);
  useImperativeHandle(ref, () => ({
    openModal: async (paramData, EventId) => {
      if(EventId){
        setEventId(EventId);
      }
      setParamData(paramData);
      setShow(true);
    },
  }));


  const role = useSelector((s) => s.auth.AssignRole);
  let hideDropdown = 1;
  role.map((element) => {
    if(element === StandardConst.SystemRole.Employee){
      hideDropdown = 0;
    }
  });
  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      className="container-fluid"
    >
      <Modal.Header closeButton>
        <Modal.Title>Print Badge</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <HtmlFileReader 
        jsonData={paramData} 
        defaultTemplate={visitorTypeSelectedTemplate[0]} 
        ShowDropdown={hideDropdown} 
        ShowPrintBadgeBtn={1} 
        ShowDownloadBadgeBtn = {1}
        SelectedTemplateId={visitorTypeSelectedTemplate}
        selfVisitorBadge={StandardConst.ShowOrHide.Hide}
        showDropDownList={(eventId) ? StandardConst.BadgeType[2].text : StandardConst.BadgeType[0].text}
        />
      </Modal.Body>
    </Modal>
  );
});
export default VisitorCheckInOutComponent;
