import { PictureControls } from "./PictureControls"
import { useDispatch, useSelector } from "react-redux"
import { getActiveTab, getAuthError, getRequest, getScreenshot, registerUser, setAuthError, setRegisterEmail, setRegisterName, setRegisterPassword, setRegisterRepeatPassword, getCameraStatus, setCameraStatus, setScreenshot } from "../features/auth/authSlice"
import { Navigate } from "react-router-dom";
import { getFaces, setFacenetMessage, setFacesToBlank, setOutline } from "../features/auth/facenetSlice";
import { WebService } from "../Services/WebService";
import { StandardConst } from "../Services/StandardConst";
import Button from "react-bootstrap/esm/Button";

export const Register = ({EmployeeId, updateFunction}) => {
    updateFunction ??= null;
    EmployeeId ??= null;
    const isFirefox = typeof InstallTrigger !== 'undefined';

    const activeTab = useSelector(getActiveTab)
    const dispatch = useDispatch()
    const request = useSelector(getRequest)
    const name = request.register.name
    const email = request.register.email
    const password = request.register.password
    const repeatPassword = request.register.repeatPassword
    const screenshot = useSelector(getScreenshot)
    const error = useSelector(getAuthError)
    const faces = useSelector(getFaces)
    const cameraStatus = useSelector(getCameraStatus)

    const manageForm = (e) => {
        e.preventDefault()
        validateInputs()
        // validatePassword()
        if(
            // name != null &&
            // error.register.email == null &&
            // error.register.password == null &&
            screenshot != null &&
            // repeatPassword === password &&
            faces.length !== 0
        ){
            const user = {screenshot, descriptor: Object.values(faces[0].descriptor)}
            
            WebService({ 
                endPoint: `Biometrics/FaceCapture/${StandardConst.UserType.Employee}?UserTypeId=${parseInt(EmployeeId)}`,
                method: "POST",
                body: user,
                dispatch
            }).then((result) => {
                if(updateFunction !== null){
                    updateFunction(StandardConst.APIMessage.Success);
                }
                dispatch(setFacesToBlank([]))
            }).catch((error) => {
                if(updateFunction !== null){
                    updateFunction(StandardConst.APIMessage.Error);
                }
                dispatch(setFacesToBlank([]))
            });
              
            // dispatch(registerUser(user)).then(payload => {
            //     if (payload.meta.requestStatus === 'fulfilled') {
            //         <Navigate to="/dashboard" />
            //     }
            // })
        }
    }

    const validateInputs = () => {
        // if(name == null){
        //     dispatch(setAuthError({register:{name: 'The NAME field is required.'}}))
        // } else {
        //     dispatch(setAuthError({register:{name:null}}))
        // }
        // if(email == null){
        //     dispatch(setAuthError({register:{email: 'The EMAIL field is required.'}}))
        // } else if (!email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        //     dispatch(setAuthError({register:{email: 'The EMAIL field is not in the standard form.'}}))
        // } else {
        //     dispatch(setAuthError({register:{email:null}}))
        // }
        if(screenshot == null){
            dispatch(setAuthError({register:{screenshot: 'A face IMAGE is required.'}}))
        } else {
            dispatch(setAuthError({register:{screenshot:null}}))
        }
    }

    const handleCameraClosing = () => {
        dispatch(setCameraStatus('closed'))
        dispatch(setScreenshot(null))
        dispatch(setOutline('#ddd'))
        dispatch(setFacenetMessage('Place the face in the oval.'))
    }

    const handleCameraOpening = () => {
        scrollToCamera()
        dispatch(setCameraStatus('opened'))
        dispatch(setScreenshot(null))
        dispatch(setOutline('#ddd'))
        dispatch(setFacenetMessage('Place the face in the oval.'))
    }

    const scrollToCamera = () => {
        const container = document.getElementById('camera');
        if (container) {
            container.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return(
        <form >
            {/* <PictureControls /> */}
            

            <div className="container-login100-form-btn">
                <div className="row p-2">
                    <div className="col-6">
                        <button
                            type="button"
                            className="my-submit-btn zoom-out"
                            onClick={() => cameraStatus === 'closed' ? handleCameraOpening() : handleCameraClosing()}
                            // variant="outline-primary"
                            // style={{ borderColor: 'white', color: 'white' }}
                        >
                            {screenshot != null ? 'Open ' : (cameraStatus === 'opened' ? 'Close ' : 'Open ')}
                            <i class="fa fa-camera" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div className="col-6">
                        <button
                        type="button"
                        className="my-submit-btn zoom-in"
                        onClick={(e) => manageForm(e)}
                        >Register</button>
                    </div>
                </div>
            </div>
        </form>
    )
}