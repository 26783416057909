import { LazyLoadImage } from "react-lazy-load-image-component";
import React, { PureComponent, useEffect, useState } from "react";
import "./EmployeeDashboard.css";
import Button from "react-bootstrap/esm/Button";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
} from "recharts";
import { StandardConst } from "../../../Services/StandardConst";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { WebService } from "../../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import Carousel from "react-bootstrap/Carousel";
import CelebrationIcon from "@mui/icons-material/Celebration";
import {
  Alert,
  AlertTitle,
  Avatar,
  AvatarGroup,
  Box,
  IconButton,
  TablePagination,
  alpha,
  styled,
} from "@mui/material";
import {
  chunk,
  extend,
  filter,
  findWhere,
  first,
  map,
  max,
  min,
  range,
  reduce,
  sortBy,
  where,
} from "underscore";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  generateQueryStringByObject,
  roundOff,
} from "../../../Services/UtilityService";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { useCallback } from "react";
import DisplayNumberFormatComponent from "../../../Services/DisplayNumberFormatComponent";
import {
  AttendanceSummary,
  CompanyProjects,
  ContactUs,
  EmployeeLeaveChart,
  EmployeeSalaryChart,
  NotificationIcon,
  ProfileSummary,
  DateRange,
  SystemMaintenanceShowYellowBar
} from "../Company/CompanyDashboard";
import { WSSuccessAlert } from "../../../Services/WSAlert";
import { InputText } from "../../Form";
import CakeIcon from "@mui/icons-material/Cake";
import { PageInfo } from "../../PageInfo";
import { ConfigurationActions } from "../DashboardComponent";
import { MyTeam } from "../../../Services/MyTeam";
const EmployeeDashboard = () => {
  PageInfo({ pageTitle: "Dashboard" });

  return (
    <>
      <div className="container">
        <SystemMaintenanceShowYellowBar/>
        <div className="card card-cd mb-3 mt-3">
          <div className="card-body p-0 p-2">
            <div className="d-flex flex-column flex-lg-row flex-sm-row">
              <ProfileSummary className="col-md-5 col-sm-9" />
              <div className="vr my-2 col-md-1 col-sm-1 d-flex justify-content-end d-none d-md-inline"></div>
              <AttendanceSummary className="col-md-4 col-sm-3 d-flex justify-content-center align-items-center text-center" />
              <div className="vr my-2 col-md-1 col-sm-1 d-flex justify-content-end d-none d-md-inline"></div>
              <NotificationIcon className="col-md-3 col-sm-3 d-flex justify-content-end pe-3" />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div
              className="row"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div className="col-md-8 col-sm-12 col-xs-12">
                <EmployeeLeaveStatus />
              </div>
              <div className="col-md-4 col-sm-12 col-xs-12">
                <EmployeeAttendanceDayWise />
              </div>
            </div>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="row">
              <div className="col-md-4 col-sm-12 col-xs-12">
                <EmployeeLeaveChart Self={true} />
              </div>
              <div className="col-md-8 col-sm-12 col-xs-12">
                <EmployeeSalaryChart Self={true} />
              </div>
            </div>
          </div>
          <div className="col-md-9 col-sm-12 col-xs-12">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <MyTeam />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <CompanyProjects Self={true} />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <EmployeePayslipStatus />
              </div>

              <div className="col-md-12 col-sm-12 col-xs-12">
                <ConfigurationActions />
              </div>
            </div>
          </div>

          <div className="col-md-3 col-sm-12 col-xs-12">
            <div className="row"></div>

            <div className="col-md-12 col-sm-12 col-xs-12">
              <EmployeeTimesheetStatus />
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              {/* <EmployeeBirthday /> */}
              <EmployeeBirthdayCarousel />
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <EmployeeAnniversaries />
            </div>

            <div className="col-md-12 col-sm-12 col-xs-12">
              <EmployeeOnLeave />
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <ContactUs />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const EmployeeBirthday = () => {
  const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const fetchData = async () => {
    await WebService({
      dispatch,
      endPoint: `Report/EmployeeBirthday?CompanyId=${
        companyInfo.CompanyId
      }&Date=${DateTime.now().toSQLDate()}`,
    }).then((r) => {
      setData(
        map(r, (m) => {
          if ((m.ProfileImage ?? "") !== "")
            m.ProfileImage = `${StandardConst.uploadImages}${m.ProfileImage}`;
          else
            m.ProfileImage =
              "https://bootdey.com/img/Content/avatar/avatar1.png";
          return m;
        })
      );
    });
  };
  useEffect(() => {
    if ((companyInfo.CompanyId ?? 0) !== 0) Promise.all([fetchData()]);
  }, [companyInfo]);

  return (
    <>
      {data.length == 0 && (
        <div className="card radius-15 border border-primary birthday-image">
          <div className="card-body text-center">
            <h5 className="mb-0 mb-3 text-primary">Birthday</h5>
            <div className="p-1 radius-15">
              <h6 className="mb-0 mt-2 text-primary ">
                Today there is no Birthday found
              </h6>
              <SentimentVeryDissatisfiedIcon
                color="primary"
                sx={{ fontSize: 40, marginTop: 3 }}
              />
            </div>
          </div>
        </div>
      )}
      {map(data, (m, i) => (
        <div className="card radius-15 bg-primary" key={i}>
          <div className="card-body text-center">
            <h5 className="mb-0 mb-3 text-white">Happy Birthday</h5>
            <div className="p-1 radius-15">
              <LazyLoadImage
                src={m.ProfileImage ?? ""}
                width="80"
                height="80"
                className="rounded shadow p-1 bg-white"
                alt=""
              />
              <h5 className="mb-0 mt-2 text-white">{m.FullName ?? ""}</h5>
              <p className="mb-3 text-white">{m.Designation ?? ""}</p>

              <div className="d-grid">
                <Button size="sm" className="mx-1" variant="outline-warning">
                  Wish
                </Button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export const EmployeeBirthdayCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [stateChunk, setStateChunk] = useState([]);
  const [sliderCount, setSliderCount] = useState([]);
  const handleSelect = (selectedIndex) => {
        setActiveIndex(selectedIndex);
  };

  //#region Employee Birthday

  const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const fetchData = async () => {
    await WebService({
      dispatch,
      endPoint: `Report/EmployeeBirthday?CompanyId=${
        companyInfo.CompanyId
      }&Date=${DateTime.now().toSQLDate()}`,
    }).then((r) => {
      setData(
        map(r, (m, index) => {
          m.id = index + 1;
          m.alt = m.FullName;
          if ((m.ProfileImage ?? "") !== "")
            m.ProfileImage = `${StandardConst.uploadImages}${m.ProfileImage}`;

          return m;
        })
      );
      setStateChunk(chunk(r, 3));
      if (parseInt(r.length) % 3 === 0) {
        setSliderCount(range(0, parseInt(r.length) / 3, 1));
      } else {
        var cnt = Math.floor(parseInt(r.length) / 3) + 1;
        setSliderCount(range(0, cnt, 1));
      }
    });
  };
  useEffect(() => {
    if ((companyInfo.CompanyId ?? 0) !== 0) {
      Promise.all([fetchData()]);
    }
  }, [companyInfo]);
  //#endregion
  return (
    <div className="card card-cd mb-3 birthday-image">
      {data.length > 0 && (
        <>
          <div className="row" style={{ height: "160px" }}>
            <Carousel activeIndex={activeIndex} onSelect={handleSelect}>
              {data.map((image) => (
                <Carousel.Item key={image.id}>
                  <div className="d-flex align-items-center justify-content-center mt-2">
                    <Avatar
                      alt={image?.alt}
                      src={image?.ProfileImage}
                      sx={{ width: 120, height: 120 }}
                    />
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
          <div className="BirsthayName  d-flex justify-content-center align-items-center">
            <CakeIcon />{" "}
            <span className="ms-2 text-wrap">
              {" "}
              {data[activeIndex]?.FullName}
            </span>
          </div>
        </>
      )}

      {data.length <= 0 && (
        <>
          <div className="row" style={{ height: "150px" }}>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <Avatar
                alt=""
                src={`${StandardConst.apiBaseUrl}/uploads/Static/sad-boy.jpg`}
                sx={{ width: 120, height: 120 }}
              />
            </div>
          </div>
          <div className="BirsthayName  d-flex justify-content-center align-items-center">
            <CakeIcon />{" "}
            <span className="ms-2">No Birthday Today to Celebrate</span>
          </div>
        </>
      )}

      <div style={{ margin: "8px" }}>
        {data.length < 0 && (
          <div className="d-flex justify-content-center align-items-center text-white">
            <CelebrationIcon />{" "}
            <span className="mx-2">No Celebration Today!!!</span>{" "}
            <CelebrationIcon />
          </div>
        )}

        {data.length > 0 && (
          <Carousel
            interval={null}
            nextIcon={<ChevronRightIcon />}
            prevIcon={<KeyboardArrowLeftIcon />}
          >
            {sliderCount?.map((image, index) => (
              <Carousel.Item>
                <div className="row">
                  {stateChunk[index]?.map((image, i) => (
                    <div
                      className="col-md-4 mblImg text-center"
                      style={{ lineHeight: "10px", padding: "0px -1px" }}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <Avatar
                          alt={image?.alt}
                          src={image?.ProfileImage}
                          sx={{ width: 30, height: 30 }}
                          onClick={() => handleSelect(image?.id - 1)}
                        />
                      </div>
                      <div>
                        <span className="text-white font-size-10 text-truncate text-wrap">
                          {image?.FullName}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};
export const EmployeeAnniversaries = () => {
  const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const [activeIndex, setActiveIndex] = useState(0);
  const [stateChunk, setStateChunk] = useState([]);
  const [sliderCount, setSliderCount] = useState([]);
  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  const fetchData = async () => {
    await WebService({
      dispatch,
      endPoint: `Report/EmployeeAnniversaries?CompanyId=${
        companyInfo.CompanyId
      }&Date=${DateTime.now().toSQLDate()}`,
    }).then((r) => {
      setData(
        map(r, (m, index) => {
          m.id = index + 1;
          m.alt = m.FullName;
          if ((m.ProfileImage ?? "") !== "")
            m.ProfileImage = `${StandardConst.uploadImages}${m.ProfileImage}`;
          else
            m.ProfileImage =
              "https://bootdey.com/img/Content/avatar/avatar1.png";
          return m;
        })
      );
      setStateChunk(chunk(r, 3));
      if (parseInt(r.length) % 3 === 0) {
        setSliderCount(range(0, parseInt(r.length) / 3, 1));
      } else {
        var cnt = Math.floor(parseInt(r.length) / 3) + 1;
        setSliderCount(range(0, cnt, 1));
      }
    });
  };
  useEffect(() => {
    if ((companyInfo.CompanyId ?? 0) !== 0) Promise.all([fetchData()]);
  }, [companyInfo]);

  //#region Employee Birthday

  return (
    <>
      <div className="card card-cd mb-3  anniversaries-image">
        {data.length > 0 && (
          <>
            <div className="row" style={{ height: "150px" }}>
              <Carousel activeIndex={activeIndex} onSelect={handleSelect}>
                {data.map((image) => (
                  <Carousel.Item key={image.id}>
                    <div className="d-flex align-items-center justify-content-center mt-2">
                      <Avatar
                        alt={image?.alt}
                        src={image?.ProfileImage}
                        sx={{ width: 120, height: 120 }}
                      />
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
            <div className="AnnyvName  d-flex justify-content-center align-items-center">
              <CakeIcon />{" "}
              <span className="ms-2"> {data[activeIndex]?.FullName}</span>
            </div>
          </>
        )}

        {data.length <= 0 && (
          <>
            <div className="row" style={{ height: "150px" }}>
              <div className="d-flex align-items-center justify-content-center mt-2">
                <Avatar
                  alt=""
                  src={`${StandardConst.apiBaseUrl}/uploads/Static/sad-boy.jpg`}
                  sx={{ width: 120, height: 120 }}
                />
              </div>
            </div>
            <div className="AnnyvName  d-flex justify-content-center align-items-center">
              <CakeIcon />{" "}
              <span className="ms-2">No Anniversary Today to Celebrate</span>
            </div>
          </>
        )}

        <div style={{ margin: "8px" }}>
          {data.length < 0 && (
            <div className="d-flex justify-content-center align-items-center text-white">
              <CelebrationIcon />{" "}
              <span className="mx-2">No Anniversary Today!!!</span>{" "}
              <CelebrationIcon />
            </div>
          )}

          {data.length > 0 && (
            <Carousel
              interval={null}
              nextIcon={<ChevronRightIcon />}
              prevIcon={<KeyboardArrowLeftIcon />}
            >
              {sliderCount?.map((image, index) => (
                <Carousel.Item>
                  <div className="row">
                    {stateChunk[index]?.map((image, i) => (
                      <div
                        className="col-md-4 mblImg text-center"
                        style={{ lineHeight: "10px", padding: "0px -1px" }}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <Avatar
                            alt={image?.alt}
                            src={image?.ProfileImage}
                            sx={{ width: 30, height: 30 }}
                            onClick={() => handleSelect(image?.id - 1)}
                          />
                        </div>
                        <div>
                          <span className="text-white font-size-10  text-truncate text-wrap">
                            {image?.FullName}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          )}
        </div>
      </div>
    </>
  );
};
export const EmployeeOnLeave = () => {
  const loggedUser = useSelector((s) => s.auth.LoggedUser ?? 0);
  const dispatch = useDispatch();
  const [EmployeeHoliday, setEmployeeHoliday] = useState([]);
  const fetchData = async () => {
    await WebService({
      dispatch,
      endPoint: `Report/CurrentLeave?timeZone=${DateTime.now().zone.name}`,
    }).then((r) => {
      r = map(r, (m) => {
        if (m.LeaveFromDate === m.LeaveToDate) m.Leaves = "Today";
        else
          m.Leaves = `${DateTime.fromISO(m.LeaveFromDate).toFormat(
            "MMM, dd"
          )} - ${DateTime.fromISO(m.LeaveToDate).toFormat("MMM, dd")}`;
        m.ProfileImage = `${StandardConst.uploadImages}${m.ProfileImage}`;
        return m;
      });
      setEmployeeHoliday(r);
    });
  };
  useEffect(() => {
    if (loggedUser !== 0) Promise.all([fetchData()]);
  }, [loggedUser]);

  return (
    <div className="card card-cd mb-3">
      <div className="d-flex flex-row mx-3 mt-3">
        <h5 className="card-title h6">Employee(s) on Leave</h5>
      </div>

      <div className="card-body card-body-cd card-employee-holyday">
        {EmployeeHoliday.length <= 0 && (
          <>
            <div className="d-flex justify-content-center justify-items-center">
              <span className="text-muted text-center">
                <b>None of Our Employee on Leave</b>
              </span>
            </div>
          </>
        )}
        {EmployeeHoliday.map((row) => (
          <div className="d-flex flex-column flex-lg-row flex-sm-row mb-1">
            <div className="col-md-12 col-sm-12  d-flex align-items-center">
              <div className="d-flex flex-column flex-lg-row flex-sm-row d-flex  align-items-center">
                <Avatar
                  alt={row?.EmployeeName}
                  src={row?.ProfileImage}
                  sx={{ width: 40, height: 40 }}
                />
                <div className="row flex-fill ms-1">
                  <div className="col-sm-12">
                    <span className="h6">{row?.EmployeeName}</span>
                  </div>
                  <div className="col-sm-12 mt-n2">
                    <span className="text-muted font-size-13">
                      {row?.Leaves}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// export const ProfileSummary = ({ ...rest }) => {
//   const loggedUser = useSelector((s) => s.auth.LoggedUser ?? 0);
//   const dispatch = useDispatch();
//   const [state, setState] = useState({});
//   const fetchProfile = async () => {
//     await WebService({
//       dispatch,
//       endPoint: `CommonUtility/employees?select=FullName, ProfileImage, fn_Designation(DesignationId) Designation&where=EmployeeId eq ${loggedUser}`,
//     }).then((r) => setState(r.length > 0 ? r[0] : {}));
//   };
//   useEffect(() => {
//     if (loggedUser !== 0) Promise.all([fetchProfile()]);
//   }, [loggedUser]);
//   return (
//     <div {...rest}>
//       <div className="d-flex flex-column flex-lg-row flex-sm-row">
//         <LazyLoadImage
//           className="img-md rounded me-2"
//           src={`${StandardConst.uploadImages}${
//             state.ProfileImage ?? "user_placeholder.jpg"
//           }`}
//           alt=""
//         />
//         <div className="row flex-fill">
//           <div className="col-sm-12">
//             <h4 className="h5">
//               <span className="d-none d-md-inline">Welcome, </span>
//               {state.FullName}
//             </h4>
//             <span className="badge bg-success">{state.Designation}</span>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
// export const AttendanceSummary = ({ ...rest }) => {
//   const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
//   const dispatch = useDispatch();
//   const [state, setState] = useState({});
//   const fetchData = async () => {
//     await WebService({
//       dispatch,
//       endPoint: `Attendance/DayWiseSummary?${generateQueryStringByObject({
//         startTime: DateTime.now()
//           .startOf("day")
//           .setZone("utc")
//           .toSQL()
//           .substring(0, 23),
//         endTime: DateTime.now()
//           .endOf("day")
//           .setZone("utc")
//           .toSQL()
//           .substring(0, 23),
//         CompanyId: companyInfo.CompanyId,
//       })}`,
//     }).then(setState);
//   };
//   useEffect(() => {
//     if ((companyInfo.CompanyId ?? 0) !== 0) Promise.all([fetchData()]);
//   }, [companyInfo.CompanyId]);
//   return (
//     <>
//       <div {...rest}>
//         <div className="row flex-fill mx-1">
//           <span className="badge bg-success">Attendance</span>
//           <h4 className="h5">{state.Attend ?? 0}</h4>
//         </div>
//         <div className="row flex-fill mx-1">
//           <span className="badge bg-warning">Leave</span>
//           <h4 className="h5">{state.Leaves ?? 0}</h4>
//         </div>
//         <div className="row flex-fill mx-1">
//           <span className="badge bg-danger">Absent</span>
//           <h4 className="h5">{state.Absent ?? 0}</h4>
//         </div>
//       </div>
//     </>
//   );
// };

export const EmployeeAttendanceDayWise = ({ ...rest }) => {

  const loggedUser = useSelector((s) => s.auth.LoggedUser ?? 0);
  const dispatch = useDispatch();
  const [Info, setInfo] = useState([]);
  const [state, setState] = useState({});
  const fetchData = async () => {
    setState({ isPlus: false });
    await WebService({
      dispatch,
      endPoint: `Attendance/Report?${generateQueryStringByObject({
        select: "CheckInDate, CheckInTime, CheckOutTime",
        where: `EmployeeId eq ${loggedUser} and (CheckInDate between '${DateTime.now()
          .startOf("month")
          .toUTC()
          .toSQL()
          .substring(0, 23)}' and '${DateTime.now()
          .endOf("month")
          .toUTC()
          .toSQL()
          .substring(0, 23)}')`,
      })}`,
    })
      .then((r) =>
        map(r, (m) => {
          m.CheckInDate = DateTime.fromISO(m.CheckInDate)
            .setZone("local")
            .toISO();
          if (m.CheckOutTime === null) {
            if (
              DateTime.fromISO(m.CheckInDate).toSQLDate() ===
              DateTime.now().toSQLDate()
            ) {
              m.CheckOutTime = DateTime.now().toUTC().toISO().replace("Z", "");
            }
          }

          m.TotalMinutes = 0;
          if (m.CheckInTime !== null && m.CheckOutTime !== null) {
            m.TotalMinutes = DateTime.fromISO(`${m.CheckOutTime}Z`)
              .diff(DateTime.fromISO(`${m.CheckInTime}Z`), "minutes")
              .toObject().minutes;
            m.TotalMinutes = roundOff(m.TotalMinutes, 2);
          }
          if (m.CheckInTime !== null)
            m.CheckInTime = DateTime.fromISO(`${m.CheckInTime}Z`)
              .setZone("local")
              .toISO();
          if (m.CheckOutTime !== null)
            m.CheckOutTime = DateTime.fromISO(`${m.CheckOutTime}Z`)
              .setZone("local")
              .toISO();

          return m;
        })
      )
      .then((r) => {
        const thisMonth = (r ?? []).reduce((m, v) => m + v.TotalMinutes, 0);
        const thisWeek = filter(
          r ?? [],
          (f) =>
            DateTime.fromISO(f.CheckInDate) >= DateTime.now().startOf("week") &&
            DateTime.fromISO(f.CheckInDate) <= DateTime.now().endOf("week")
        ).reduce((m, v) => m + v.TotalMinutes, 0);
        const toDay = filter(
          r ?? [],
          (f) =>
            DateTime.fromISO(f.CheckInDate) >= DateTime.now().startOf("day") &&
            DateTime.fromISO(f.CheckInDate) <= DateTime.now().endOf("day")
        ).reduce((m, v) => m + v.TotalMinutes, 0);

        setState((st) => ({ ...st, thisMonth, thisWeek, toDay }));
      });
  };
  useEffect(() => {
    if (loggedUser !== 0) Promise.all([fetchData(), fetchCheckIn()]);
  }, [loggedUser]);
  const fnHourFormat = (minutes) => {
    const hour = parseInt(minutes / 60);
    const minute = parseInt(minutes % 60);
    return `${hour}:${minute}`;
  };

  const fetchCheckIn = async () => {
    var info = await WebService({
      endPoint: "Dashboard/fetchCheckIn",
      method: "GET",
      //dispatch,
    });
    setInfo(info?.slice(-1) ?? []);
  };

  const checkIn = async () => {
    const res = await WebService({
      endPoint: "Dashboard/EmployeeCheckInCheckOut",
      method: "POST",
      dispatch,
    });

    if (res.Message == "CheckInSuccess") {
      WSSuccessAlert("CheckIn Success", "Attendance Successfully logged");
    }

    if (res.Message == "CheckOutSuccess") {
      WSSuccessAlert("CheckOut Success", "Attendance Successfully logged");
    }
    fetchCheckIn();
  };

  var Checked =
    Info?.length == 0 ? (
      <Button variant="outline-success" onClick={checkIn} className="mx-1">
        <span className=" mx-1">
          <i className="fa fa-clock-o"></i>
        </span>
        Check In
      </Button>
    ) : Info[0]?.CheckOutTime == null && Info?.length > 0 ? (
      <Button variant="outline-success" className="mx-1" onClick={checkIn}>
        <span className=" mx-1">
          <i className="fa fa-clock-o"></i>
        </span>
        Check Out
      </Button>
    ) : (
      <Button variant="outline-primary" className="mx-1" onClick={checkIn}>
        <span className=" mx-1">
          <i className="fa fa-clock-o"></i>
        </span>
        Check In
      </Button>
    );
  return (
    <>
      <div className="card card-cd mb-3">
        <div className="d-flex flex-row mx-2 mt-2">
          <div className="col-md-12 col-sm-12 d-flex justify-content-start">
            <h6 className="card-title text-muted">How long did you work today?</h6>
          </div>
        </div>

        <div className="card-body card-body-cd py-1">
          <div className="row">
            <div className="col-md-5 col-sm-6 d-flex justify-content-center align-items-center border border-primary flex-column bg-primary text-white p-2">
              <span>
                <strong>{DateTime.now().weekdayLong}</strong>
              </span>
              <span>{DateTime.now().toFormat("MMM | dd")}</span>
            </div>
            <div className="col-md-7 col-sm-6 d-flex justify-content-center align-items-center border border-primary flex-column text-primary p-2">
              <span>
                <strong>{fnHourFormat(state.toDay ?? 0)} H</strong>
              </span>
            </div>
          </div>
        </div>
        <div className="card-footer mt-3">
          <div className="row text-muted">
            <div className="col-4 d-flex justify-content-center align-items-start flex-column  py-2 ">
              <span>
                <strong>This Week</strong>
              </span>
              <span>{fnHourFormat(state.thisWeek ?? 0)} H</span>
            </div>

            <div className="col-4 d-flex justify-content-center align-items-start flex-column p-2  py-2">
              <span>
                <strong>This Month</strong>
              </span>
              <span>{fnHourFormat(state.thisMonth ?? 0)} H</span>
            </div>
            <div className="col-4 d-flex justify-content-center align-items-start flex-column p-0">
              {Info?.length == 0 ? (
                <>
                  <Button
                    variant="outline-success"
                    onClick={checkIn}
                    size="sm"
                    className="mx-1"
                  >
                    <span className=" mx-1">
                      <i className="fa fa-clock-o"></i>
                    </span>
                    Check In
                  </Button>
                </>
              ) : Info[0]?.CheckOutTime == null && Info?.length > 0 ? (
                <>
                  <Button
                    variant="outline-success"
                    className="mx-1"
                    size="sm"
                    onClick={checkIn}
                  >
                    <span className=" mx-1">
                      <i className="fa fa-clock-o"></i>
                    </span>
                    Check Out
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    className="mx-1"
                    onClick={checkIn}
                  >
                    <span className=" mx-1">
                      <i className="fa fa-clock-o"></i>
                    </span>
                    Check In
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const EmployeeLeaveStatus = ({ ...rest }) => {
  const loggedUser = useSelector((s) => s.auth.LoggedUser ?? 0);
  const dispatch = useDispatch();
  const [state, setState] = useState([]);
  const [stateChunk, setStateChunk] = useState([]);
  const [sliderCount, setSliderCount] = useState([]);
  const [leaveType, setLeaveType] = useState({});
  const [leaveAndTimeoffMonth, setLeaveAndTimeoffMonth] = useState({});

  const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});

  
  const fetchData = async () => {
    const LeaveDate = new Date(leaveAndTimeoffMonth.StartDate);
    const formattedDate = `${LeaveDate.getFullYear()}-${(LeaveDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${LeaveDate.getDate().toString().padStart(2, '0')}`;

    await WebService({
      dispatch,
      endPoint: `Report/GetAllLeaveType?${generateQueryStringByObject({
        CompanyId: companyInfo.CompanyId
      })}`,
    }).then((r) => {
      const leaveTypeMapping = {};
      
      r.forEach(leave => {
        leaveTypeMapping[leave.LeaveId] = leave.LeaveName;
      });
      setLeaveType(leaveTypeMapping);
    });
    
    await WebService({
      dispatch,
      endPoint: `Report/EmployeeLeaveStatus?${generateQueryStringByObject({
        EmployeeId: loggedUser,
        Date: formattedDate,
      })}`,
    }).then((r) => {
      setState(r);

      if (parseInt(r.length) % 4 === 0) {
        setSliderCount(range(0, parseInt(r.length) / 4, 1));
      } else {
        var cnt = Math.floor(parseInt(r.length) / 4) + 1;
        setSliderCount(range(0, cnt, 1));
      }
      setStateChunk(chunk(r, 4));
    });
  };
  useEffect(() => {
    if (loggedUser !== 0) Promise.all([fetchData()]);
  }, [loggedUser, leaveAndTimeoffMonth]);
  const navigate = useNavigate();
  const [limit, setLimit] = useState(0);
  const leaveIcons = [
    { Icon: <AirplanemodeActiveIcon /> },
    { Icon: <TimeToLeaveIcon /> },
    { Icon: <AccountBalanceIcon /> },
    { Icon: <TransferWithinAStationIcon /> },
    { Icon: <DirectionsBusIcon /> },
    { Icon: <HomeIcon /> },
    { Icon: <AirplanemodeActiveIcon /> },
  ];
  return (
    <>
      <div className="card card-cd mb-3">
        <div className="d-flex flex-row mx-4 mt-4">
          <div className="col-4 d-flex justify-content-start">
            <h5 className="card-title">Leaves & Timeoff</h5>
          </div>

          <div className="col-3 d-flex justify-content-end">
            <DateRange 
            setDateRange={({ StartDate, EndDate }) =>
            setLeaveAndTimeoffMonth((dt) => ({ ...dt, StartDate, EndDate }))
            }
            />
          </div>

          <div className="col-5 d-flex justify-content-end">
            {state.length > 0 && (
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => navigate(`/AllApplyleavelist`)}
              >
                Request Timeoff
              </Button>
            )}
          </div>
        </div>

        <div className="card-body card-body-cd py-1">
          {state.length > 0 && (
            <Carousel
              interval={null}
              // onSlide={(e, direction) => {
              //   if (direction === "start") {
              //     setLimit((l) => l + 1);
              //     alert(limit);
              //   }
              // }}
              data-bs-theme="dark"
              nextIcon={<ChevronRightIcon />}
              prevIcon={<KeyboardArrowLeftIcon />}
            >
              {sliderCount?.map((m, index) => (
                <Carousel.Item>
                  <div className="row">
                    {stateChunk[index]?.map((m, i) => (
                      <div
                        key={i}
                        className="col-md-3 col-sm-3 d-flex justify-content-center align-items-center  flex-column p-3"
                      >
                        <span className="text-muted">
                          <strong>{
                            leaveType[m.LeaveTypeId]}</strong>
                        </span>
                        <div
                          className={`d-flex justify-content-center align-items-center flex-row ${
                            m.Available !== 0 ? "text-success" : "text-danger"
                          }`}
                        >
                          <span className="me-1"> {leaveIcons[i].Icon} </span>

                          <span className="font-size-20">{m.Available}</span>
                        </div>

                        <span
                          className={`font-size-16 ${
                            m.Available !== 0 ? "text-success" : "text-danger"
                          }`}
                        >
                          Days Avaliable
                        </span>
                        <span className="font-size-13 text-muted">
                          {m.Consumed} Days Availed
                        </span>
                      </div>
                    ))}
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          )}
          {state.length <= 0 && (
            <div className="d-flex text-center flex-column mb-4">
              <div>No applied leaves found</div>
              <div>
                <Button
                  size="sm"
                  className="mx-1"
                  variant="outline-primary"
                  onClick={() => navigate(`/AllApplyleavelist`)}
                >
                  Request Timeoff
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export const EmployeePayslipStatus = ({ ...rest }) => {
  const loggedUser = useSelector((s) => s.auth.LoggedUser ?? 0);
  const dispatch = useDispatch();
  const [data, setData] = useState([
    { name: "Take Home", value: 0 },
    { name: "Deductions", value: 0 },
  ]);
  const [nodata, setNodata] = useState([{ name: "No Salary", value: 100 }]);
  const [condition, setCondition] = useState({
    StartDate: DateTime.now().startOf("month"),
    EndDate: DateTime.now().endOf("month"),
    CurrentMonth: DateTime.now().toFormat("MMM yyyy"),
  });
  const fetchData = async () => {
    await WebService({
      dispatch,
      endPoint: `Report/EmployeePayslipsSummary?${generateQueryStringByObject({
        EmployeeId: loggedUser,
        StartDate: condition.StartDate.setZone("utc").toSQL().substring(0, 23),
        EndDate: condition.EndDate.setZone("utc").toSQL().substring(0, 23),
      })}`,
    }).then((r) => {
      if (r.length > 0) {
        setData([
          {
            name: "Take Home",
            value:
              parseFloat(r[0].NetSalary) > 0 ? parseFloat(r[0].NetSalary) : 0,
          },
          {
            name: "Deductions",
            value:
              parseFloat(r[0].TotalDeductions) > 0
                ? parseFloat(r[0].TotalDeductions)
                : 0,
          },
        ]);
      } else {
        setData([
          { name: "Take Home", value: 0 },
          { name: "Deductions", value: 0 },
        ]);
      }
    });
  };
  useEffect(() => {
    if (loggedUser !== 0 && condition.StartDate !== undefined)
      Promise.all([fetchData()]);
  }, [loggedUser, condition]);
  const [dateRange, setDateRange] = useState([]);
  const DateRangeMinus = (date) => {
    var range = [];
    for (var i = 0; i < 4; i++) {
      range.push({
        StartDate: date.minus({ month: i }).startOf("month"),
        EndDate: date.minus({ month: i }).endOf("month"),
        CurrentMonth: date.minus({ month: i }).toFormat("MMM yy"),
      });
    }
    setDateRange(range);
  };
  const DateRangePlus = (date) => {
    var range = [];
    for (var i = 0; i < 4; i++) {
      range.push({
        StartDate: date.plus({ month: i }).startOf("month"),
        EndDate: date.plus({ month: i }).endOf("month"),
        CurrentMonth: date.plus({ month: i }).toFormat("MMM yy"),
      });
    }
    setDateRange(range);
  };
  useEffect(() => {
    DateRangeMinus(DateTime.now());
  }, []);
  const COLORS = [
    StandardConst.GraphColors.blue,
    StandardConst.GraphColors.green,
    StandardConst.GraphColors.yellow,
    StandardConst.GraphColors.info,
    StandardConst.GraphColors.red,
    StandardConst.GraphColors.grey,
  ];
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );
  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
      name,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
          fontSize="13px"
        >{`  ${value}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
          fontSize="13px"
        >
          {`(${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  const renderActiveShapeNoData = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
      name,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />

        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      </g>
    );
  };
  return (
    <>
      <div className="card card-cd mb-3">
        <div className="d-flex flex-row mx-4 mt-4">
          <div className="col-md-2 col-sm-3 d-flex justify-content-start">
            <h5 className="card-title">Payslips</h5>
          </div>
          <div className="col-md-10 col-sm-9 d-flex justify-content-end align-items-center ">
            <IconButton
              color="primary"
              onClick={() => {
                var v1 = min(dateRange, "StartDate")?.StartDate.minus({
                  month: 1,
                });
                DateRangeMinus(v1);
              }}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>

            <Nav
              activeKey={condition.CurrentMonth}
              onSelect={(selectedKey) => {
                const v1 = findWhere(dateRange, {
                  CurrentMonth: selectedKey,
                });
                setCondition(v1);
              }}
            >
              {sortBy(dateRange, "StartDate").map((m, i) => (
                <Nav.Item key={i} className="ws-nav-button p-0 m-0">
                  <Nav.Link eventKey={m.CurrentMonth} className=" p-0 mx-2">
                    {m.CurrentMonth}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
            <IconButton
              color="primary"
              onClick={() => {
                var v1 = max(dateRange, "StartDate")?.StartDate.plus({
                  month: 1,
                });
                DateRangePlus(v1);
              }}
            >
              <ChevronRightIcon />
            </IconButton>
          </div>
        </div>
        <div className="card-body card-body-cd py-1">
          <div className="row d-flex flex-row">
            <div className="col-md-8 col-sm-6 d-flex flex-column align-items-center justify-content-center">
              {data[0].value > 0 && (
                <ResponsiveContainer width="115%" height={300}>
                  <PieChart width={150} height={300}>
                    <Pie
                      activeIndex={activeIndex}
                      activeShape={renderActiveShape}
                      data={data}
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                      onMouseEnter={onPieEnter}
                    >
                      {data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              )}
              {data[0].value <= 0 && (
                <ResponsiveContainer width="100%" height={300}>
                  <PieChart width={150} height={300}>
                    <Pie
                      activeIndex={activeIndex}
                      activeShape={renderActiveShapeNoData}
                      data={nodata}
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {nodata.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={StandardConst.GraphColors.lightGrey}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              )}
            </div>
            <div className="col-md-4 col-sm-6 d-flex flex-column align-items-start justify-content-center">
              <div className="d-flex flex-row my-2">
                <div>
                  <Box
                    sx={{
                      width: 5,
                      height: 50,
                      backgroundColor: COLORS[2],
                    }}
                  />
                </div>

                <div className="d-flex flex-column ms-2">
                  <span>Gross Salary</span>
                  <span>
                    <strong>
                      {" "}
                      <DisplayNumberFormatComponent
                        Number={
                          (findWhere(data, { name: "Take Home" })?.value ?? 0) +
                          (findWhere(data, { name: "Deductions" })?.value ?? 0)
                        }
                      />
                    </strong>
                  </span>
                </div>
              </div>

              <div className="d-flex flex-row my-2">
                <div>
                  <Box
                    sx={{
                      width: 5,
                      height: 50,
                      backgroundColor: COLORS[0],
                    }}
                  />
                </div>

                <div className="d-flex flex-column ms-2">
                  <span>Take Home</span>
                  <span>
                    <strong>
                      {" "}
                      <DisplayNumberFormatComponent
                        Number={
                          findWhere(data, { name: "Take Home" })?.value ?? 0
                        }
                      />
                    </strong>
                  </span>
                </div>
              </div>

              <div className="d-flex flex-row my-2">
                <div>
                  <Box
                    sx={{
                      width: 5,
                      height: 50,
                      backgroundColor: COLORS[1],
                    }}
                  />
                </div>

                <div className="d-flex flex-column ms-2">
                  <span>Deductions</span>
                  <span>
                    <strong>
                      {" "}
                      <DisplayNumberFormatComponent
                        Number={
                          findWhere(data, { name: "Deductions" })?.value ?? 0
                        }
                      />
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const EmployeeTimesheetStatus = ({ ...rest }) => {
  const navigate = useNavigate();
  const [timeSheet, setTimeSheet] = useState({});
  const loggedUser = useSelector((s) => s.auth.LoggedUser ?? 0);
  useEffect(() => {
    if (loggedUser !== 0) Promise.all([fetchData()]);
  }, [loggedUser]);

  var fetchData = async () => {
    var res = await WebService({
      endPoint: "Dashboard/FetchTimeSheet" /* dispatch*/,
    });
    let pendingFor = 0;
    if (
      res.timeSheet.lastDay.length > 0 &&
      DateTime.fromISO(res.timeSheet.lastDay[0].LastDate).diffNow("day").values
        .days < -1
    ) {
      pendingFor =
        parseInt(
          DateTime.fromISO(res.timeSheet.lastDay[0].LastDate).diffNow("day")
            .values.days
        ) * -1;
    }
    let lastEntryTime = 0;
    let lastEntryProject = "N/A";
    if (res.timeSheet.lastEntry.length > 0) {
      const lastEntry = res.timeSheet.lastEntry[0];
      lastEntryProject = lastEntry.ProjectName;
      lastEntryTime = parseInt(
        DateTime.fromISO(lastEntry.UpdatedTime).diffNow("day").values.days * -1
      );
    }
    setTimeSheet({
      pendingFor: `${pendingFor} Days`,
      lastEntryTime: `${lastEntryTime} Days`,
      lastEntryProject,
    });
  };
  return (
    <>
      <div className="card card-cd mb-3">
        <div className="d-flex flex-row mx-4 mt-2 d-flex justify-content-start align-items-center">
          <div className="col-md-6 col-sm-6 d-flex justify-content-start">
            <h5 className="card-title h6">Timesheet</h5>
          </div>
          <div className="col-md-6 col-sm-6 d-flex justify-content-end">
            <IconButton
              color="primary"
              onClick={() => navigate(`/TimeSheet/Entry`)}
            >
              <PostAddIcon />
            </IconButton>
          </div>
        </div>
        <div className="card-body card-body-cd py-1">
          <ul className="list-unstyled text-dark-l1 text-90 text-left my-md-0">
            <li>
              <i className="fa fa-check text-danger text-110 mr-2 mt-1"></i>
              <span className="text-110">
                &nbsp;Pending for : {timeSheet.pendingFor}
              </span>
            </li>
            <li>
              <i className="fa fa-check text-success text-110 mr-2 mt-1"></i>
              <span className="text-110">
                &nbsp;Last Entry : {timeSheet.lastEntryTime} ago
              </span>
            </li>
            <li>
              <i className="fa fa-check text-success text-110 mr-2 mt-1"></i>
              <span className="text-110">
                &nbsp;Last Project : {timeSheet.lastEntryProject}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default EmployeeDashboard;
