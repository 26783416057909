import React from "react";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputDropdown } from "../Form";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/esm/Button";
import { StandardConst } from "../../Services/StandardConst";
import HtmlFileReader from "../../Services/HtmlFileReader";
import { NoRecordTemplate } from "../../Services/TableComponent";

const { forwardRef, useState, useImperativeHandle } = React;
const ModalManageVisitorType = (prop, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [eventVisitorTypeBadgesData, setEventVisitorTypeBadgesData] = useState([]);
  const [visitorDropdownList, setVisitorDropdownList] = useState([]);
  const [selectedBadgeFromDropdown, setSelectedBadgeFromDropdown] = useState([]);
  const [visitorTypeChange, setVisitorTypeChange] = useState(false);
  const [selectedBadgeTemplateId, setSelectedBadgeTemplateId] = useState(null);
  const [eventId, setEventId] = useState(null);

  const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});

  useImperativeHandle(ref, () => ({
    openModal: async (EventId) => {
        setEventId(EventId);
        const eventVisitorTypeBadges = await WebService({
            endPoint: `Event/getEventVisitorTypeBadges?EventId=${EventId}`,
            dispatch,
        });

        if(eventVisitorTypeBadges.length > 0) {
            const visitorTypeDropdown = eventVisitorTypeBadges.map((item) => ({text : item.TypeName, value : item.VisitorTypeId}))

        
            setSelectedBadgeFromDropdown([{text: eventVisitorTypeBadges[0].badge_template_name, value: eventVisitorTypeBadges[0].BadgeTemplatesId}]);
            setVisitorDropdownList(visitorTypeDropdown);
            setEventVisitorTypeBadgesData(eventVisitorTypeBadges);
        }
        setShow(true);
    },
  }));

  const handleClose = () => {
    setEventVisitorTypeBadgesData([]);
    setVisitorDropdownList([]);
    setSelectedBadgeFromDropdown([]);
    setVisitorTypeChange(false);
    setSelectedBadgeTemplateId(null);
    setEventId(null);
    setShow(false);
  };

  const handleSelectFile = (badgeTemplateId) => {
    setSelectedBadgeTemplateId(badgeTemplateId);
  }

  const handleChangeVisitorType = (visitorType) => {
    setSelectedBadgeFromDropdown(eventVisitorTypeBadgesData.filter(item => item.VisitorTypeId === parseInt(visitorType)).map(visitorTypeItem => ({text: visitorTypeItem.badge_template_name, value: visitorTypeItem.BadgeTemplatesId})));

    setVisitorTypeChange(previous => !previous);
  };

  const onSubmit = async (data) => {
    if(selectedBadgeTemplateId !== null && eventId !== null){
        const result = await WebService({
            endPoint : `CommonUtility/VisitorTypeBadge?EventId=${eventId}&VisitorTypeId=${data.VisitorTypeId}`,
            body : {BadgeTemplatesId : selectedBadgeTemplateId},
            method : 'PUT',
            dispatch,
        });
    }
    handleClose();
  };


  return (
    <Modal
      show={show}
      onHide={handleClose}
    >
        <Modal.Header closeButton>
            <Modal.Title>Manage Visitor Type</Modal.Title>
        </Modal.Header>
        {(eventVisitorTypeBadgesData.length> 0) ? (
            <Form
                // defaultValues={data.TypeName}
                onSubmit={onSubmit}
                // validationSchema={schema}
            >
                <Modal.Body className="p-4">
                    <div className="row">
                        <div className="col-md-12">
                            <FormInputDropdown
                            name="VisitorTypeId"
                            ddOpt={visitorDropdownList}
                            label="Visitor Type"
                            setValue={(val) => handleChangeVisitorType(val)}
                            >
                            </FormInputDropdown>
                        </div>
                        
                        <div className="col-md-12 mt-2">
                            <span style={{color: "#5a5a5a", position: "relative", top: "6px", fontSize: "14px"}}>
                                Visitor Badge Type{" "}
                            </span>
                            {(visitorTypeChange) && (
                                <HtmlFileReader 
                                    jsonData={{
                                        Name: "VisitorName",
                                        Photo: `null`,
                                        VisitorType: "Visitor TypeName",
                                        Company: "VisitorCompany",
                                        Email: "Email",
                                        Phone: "ContactNo",
                                        VisitorId: "VisitorId",
                                        CheckInTime:"CheckIn",
                                        TemplateName: "/sample.html",
                                        QRData: {
                                        Name: "VisitorName",
                                        Company: "VisitorCompany",
                                        Email: "Email",
                                        Phone: "ContactNo",
                                        },
                                        Logo: CompanyInfo.Logo,
                                        VisitorGuid: "Guid"
                                    }} 
                                    ShowDropdown={StandardConst.YesNo.Yes} 
                                    selectPosition={true}
                                    selectFile={handleSelectFile}
                                    SelectedTemplateId={selectedBadgeFromDropdown}
                                    showDropDownList={StandardConst.BadgeType[2].text}
                                />
                            )}
            
                            {(!visitorTypeChange) && (
                                <HtmlFileReader 
                                    jsonData={{
                                        Name: "VisitorName",
                                        Photo: `null`,
                                        VisitorType: "Visitor TypeName",
                                        Company: "VisitorCompany",
                                        Email: "Email",
                                        Phone: "ContactNo",
                                        VisitorId: "VisitorId",
                                        CheckInTime:"CheckIn",
                                        TemplateName: "/sample.html",
                                        QRData: {
                                        Name: "VisitorName",
                                        Company: "VisitorCompany",
                                        Email: "Email",
                                        Phone: "ContactNo",
                                        },
                                        Logo: CompanyInfo.Logo,
                                        VisitorGuid: "Guid"
                                    }} 
                                    ShowDropdown={StandardConst.YesNo.Yes} 
                                    selectPosition={true}
                                    selectFile={handleSelectFile}
                                    SelectedTemplateId={selectedBadgeFromDropdown}
                                    showDropDownList={StandardConst.BadgeType[2].text}
                                />
                            )}
                        </div>
                    </div>
                    </Modal.Body>
                <Modal.Footer>
                <Button
                    id="btnEventManageVisitorBadges"
                    variant="outline-primary"
                    type="submit"
                >
                    Save Changes
                </Button>
                </Modal.Footer>
            </Form>
        ) : (
            <NoRecordTemplate
                headerValue="No Record Found"
                subHeaderValue="Please add visitor type fist for this event."
                imageUrl="uploads/Static/no-records-j.png"
            />
        )}
    </Modal>
  );
};

export default forwardRef(ModalManageVisitorType);
