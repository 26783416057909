import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText } from "../Form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
const requiredMessage = StandardConst.requiredMessage;
const { forwardRef, useState, useImperativeHandle } = React;
const AddEditQualification = (prop, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ Qualification: {} });
  useImperativeHandle(ref, () => ({
    openModal: async (id) => {
      setShow(true);
      if(id){
        setShow(false);
        const data = {
          Qualification : await WebService({
            endPoint: `CommonUtility/Edit/static_qualification_master?QualificationId=${id || 0}`,
            dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}))
        }
        setData(data);
        setShow(true);
      }
    },
  }));
  const handleClose = () =>{ 
    setShow(false);
    setData({Qualification: {}});
  }
  const schema = yup
    .object()
    .shape({
      DisplayQualification: yup.string().trim().required(requiredMessage),
    })
    .required();
  const onSubmit = async (data) => {
    console.log("Qualication",data);
    if (data.QualificationId === undefined) {
      await WebService({ endPoint: "CommonUtility/static_qualification_master", body: data, dispatch });
    } else {
      await WebService({
        endPoint: `CommonUtility/static_qualification_master?QualificationId=${data.QualificationId}`,
        method: "PUT",
        body: data,
        dispatch,
      });
    }

    handleClose();
    prop.callBackEvent();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="px-2">
          {(data.Qualification.QualificationId || 0) === 0 ? "Add Qualification" : "Edit Qualification"}
        </Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={data.Qualification}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        <Modal.Body className="p-4">
          <div className="row">
            <div className="col-md-12">
              <FormInputText
                label="Qualification"
                name="DisplayQualification"
                type="text"
                isRequired="true"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>          
          <Button id="btnQualificationSubmit"  variant="outline-primary" type="submit">  {(data.Qualification.QualificationId || 0) === 0 ? "Submit" : "Save Changes"}</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default forwardRef(AddEditQualification);
