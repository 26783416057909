import * as yup from "yup";
import React, { memo, useEffect, useRef, useState } from "react";
import {
  Form,
  FormAutoCompleteDropdown,
  FormAutoCompleteDropdownCard,
  FormInputDropdown,
  FormInputText,
} from "../Form";
import { WebService } from "../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import { generateQueryStringByObject } from "../../Services/UtilityService";
import { StandardConst } from "../../Services/StandardConst";
import { chain, extendOwn } from "underscore";
import Button from "react-bootstrap/esm/Button";
import { DateTime } from "luxon";
import { Box, Container } from "@mui/material";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Swal from "sweetalert2";
import { PageInfo } from "../PageInfo";
import { UploadOrClickProfilePicture } from "../../Services/ImageUploader";
import { WSSuccessAlert } from "../../Services/WSAlert";
const ScheduleVisitorRegistrationComponent = memo(() => {
  const loggedUser = useSelector((s) => s.auth.LoggedUser ?? 0);

  var frmRef = useRef();
  var dispatch = useDispatch();
  var [state, setState] = useState({});
  var CompanyId = useSelector((s) => s.auth.CompanyInfo?.CompanyId ?? 0);
  PageInfo({ pageTitle: "Invite" });
  const [imageName, setImageName] = useState("");
  const [settings, setSettings] = useState({phone: false, email: false, whomTOMeet: false});

  const FetchVisitorTypeList = () => {
    WebService({
      endPoint: `Visitor/Type?CompanyId=${CompanyId}`,
      dispatch,
    }).then((visitorTypes) => {
      setState((s) => ({ ...s, visitorTypes }))
    });
  }

  const [EmployeesList, setEmployeesList] = useState([]);

  const FetchEmployeeList = async() => {
    const data = await WebService({
      dispatch,
      endPoint: `Visitor/fetchAllEmployees`,
    });
    const list = data?.filter((r) => r.FullName !== null)?.map((res) => {
      return {
          uniqueId: res.EmployeeId,
          heading: res.FullName, 
          description: res.Designation, 
          avatar: `${StandardConst.apiBaseUrl}/uploads/${res.ProfileImage}`
        }
    });
    setEmployeesList(list);
  }

    const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmit = async (dt) => {
    dt.ScheduleCheckInTime  = DateTime.fromJSDate(dt.ScheduleCheckInTime).toSQLDate()
    extendOwn(dt, {
      CompanyId,
      ContactPerson:
        chain(dt.ContactPerson ?? [])
          .first()
          .value()?.uniqueId ?? null,
      InvitedBy: loggedUser,
      CreatedBy: loggedUser,
    });
    dt.ProfilePicture = imageName;
    await WebService({
      dispatch,
      endPoint: "Visitor/RegisterVisitorAfterLogin",
      body: dt,
    });

    const formatedDate = async (ScheduleCheckInTime) => {
      const currentDate = DateTime.now().startOf("day");
      const checkInDate = DateTime.fromJSDate(
        new Date(ScheduleCheckInTime)
      ).startOf("day");
      if (checkInDate.equals(currentDate)) {
        return "today";
      } else if (checkInDate.equals(currentDate.plus({ days: 1 }))) {
        return "tomorrow";
      } else {
        return checkInDate.toFormat("dd-MMMM-yyyy");
      }
    };

    await frmRef.current.fnReset({});
    setSettings({phone: false, email: false, whomTOMeet: false});
    setIsSubmitted(!isSubmitted);
    setuploadedFile(null);
    WSSuccessAlert("Success", "Successfully invited visitor " +
      dt.VisitorName +
      " on " +
      (await formatedDate(dt.ScheduleCheckInTime)) +
      " at " +
      dt.VisiteTime);
  };
  const [locationData, setlocationData] = useState([]);
  const getsetlocation = () => {
    WebService({
      endPoint: `UserProfile/Fetchlocation`,
      dispatch,
    }).then((data) => {
      setlocationData(
        data.data.map((v) => {
          const words = v.Address.split(' ');
          const truncatedWords = words.slice(0, 3);
          const truncatedAddress = truncatedWords.join(' ');
          const finalAddress = truncatedAddress + ', ' + v.Location;
          return {
            value: v.OfficeLocationId,
            text: finalAddress,
          };
        })
      );
    });
  };
  useEffect(() => {
    if (CompanyId > 0)
      Promise.all([
        FetchEmployeeList(),
        FetchVisitorTypeList(),
        getsetlocation(),
      ]);
  }, [CompanyId]);

  const validationSchema = yup
    .object()
    .shape({
      VisitorName: yup
        .string()
        .trim()
        .label("Name")
        .required(StandardConst.requiredMessage)
        .max(100),
      VisitorCompany: yup.string().trim().label("Company").max(100),
      Address: yup.string().trim().label("Address").max(300),
      Email: settings.email ? yup.string().trim().label("Email").max(200).email() : null,
      ContactPerson: !settings.whomTOMeet ? 
                    yup
                    .array()
                    .of(
                      yup.object().shape({
                        value: yup.string(),
                        label: yup.string(),
                      })
                    )
                    .typeError(StandardConst.requiredMessage)
                    .min(1, StandardConst.requiredMessage)
                    :null,
      Purpose: yup.string().required(StandardConst.requiredMessage),
      // InvitedBy: yup
      //   .string()
      //   .trim()
      //   .label("Invited By")
      //   .required(StandardConst.requiredMessage),
      VisitorTypeId: yup
        .string()
        .trim()
        .label("Visitor Type")
        .required(StandardConst.requiredMessage),

      ContactNo: settings.phone ? yup.string()
        .required(StandardConst.requiredMessage)
        .matches(StandardConst.PhoneNumberValidateRegex, StandardConst.ValidationMessages.PhoneNoIsNotValid) :
        null,
        
      ScheduleCheckInTime: yup
        .date()
        .typeError(StandardConst.requiredMessage)
        .required(StandardConst.requiredMessage),
      VisiteTime: yup
                  .mixed()
                  .test('is-time-valid', 'Time is not valid', (value) => {
                    if (!value) return false; // don't empty values
                    return true;
                  })
                  .typeError(StandardConst.requiredMessage)
                  .required(StandardConst.requiredMessage),
                  })
    .required();
  const MasterPageName = "Invite";
  const [bData, setBData] = React.useState([
    {
      title: "Visitor Management",
      hrefLink: "#",
    },
    {
      title: "Invite",
      hrefLink: "#",
    },
  ]);
  const [uploadedFile, setuploadedFile] = useState();

  const handleSelectedValue = async(visitorTypeId) => {

    const visitorTypeData = await WebService({
      dispatch, 
      endPoint: `CommonUtility/fetchAll/static_visitor_type?VisitorTypeId=${visitorTypeId}`,
    }).then((c) => (c.length > 0 ? c[0] : {}));
    
    setSettings((prevSettings) => ({
      ...prevSettings,
      phone: prevSettings.phone || visitorTypeData.WillProvidePhoneNo ? visitorTypeData.WillProvidePhoneNo : false,
      email: prevSettings.email || visitorTypeData.WillProvideEmail ? visitorTypeData.WillProvideEmail : false,
      whomTOMeet: prevSettings.whomTOMeet || visitorTypeData.IsServiceProvider ? visitorTypeData.IsServiceProvider : false,
    }));
  }

  return (
    <>
      <Container fluid className="base-container">
        <Box
          sx={{
            width: 1,
          }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <h3 className="ms-4 mt-2">{MasterPageName}</h3>
              <div className="ms-4">
                <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
              </div>
            </div>
          </div>
        </Box>
        <div elevation={0} className="p-3 bg-primary mt-1"></div>
        <div className="row">
          <div className="col-md-6 col-12 p-5">
            <div className="mx-4 mt-2">
              <Form
                defaultValues={{}}
                onSubmit={onSubmit}
                ref={frmRef}
                validationSchema={validationSchema}
              >
                <Row>
                  <Col md={12} sm={12}>
                    <Col className="d-flex justify-content-center  pb-2">
                      <UploadOrClickProfilePicture
                        UploadedFileName={setImageName}
                        onSubmittedClicked={isSubmitted}
                      >
                      </UploadOrClickProfilePicture>
                    </Col>
                  </Col>
                  <Col md={12} sm={12}>
                    <FormInputDropdown
                      label="Visitor Type"
                      name="VisitorTypeId"
                      ddOpt={[{}].concat(
                        chain(state.visitorTypes ?? [])
                          .map((m) => ({
                            value: m.VisitorTypeId,
                            text: m.TypeName,
                          }))
                          .value()
                      )}
                      isRequired="true"
                      setValue={(currentValue) => {handleSelectedValue(Number(currentValue))}}
                    />
                  </Col>
                  <Col>
                    <Col>
                      <FormInputText
                        label="Name"
                        name="VisitorName"
                        isRequired="true"
                      />
                    </Col>
                    <Col>
                      <FormInputText label="Company" name="VisitorCompany" />
                    </Col>
                    <Col>
                      {" "}
                      { settings.email ? 
                      <FormInputText label="Email" name="Email" />
                      : 
                      null}
                    </Col>
                  </Col>
                  <Col md={12} sm={12}>
                    {" "}
                    { settings.phone ? 
                    <FormInputText
                      label="Contact No"
                      name="ContactNo"
                      isRequired="true"
                    />
                    :
                    null}
                  </Col>
                  <Col md={12} sm={12}>
                    <FormInputText
                      label="Designation"
                      name="Designation"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} sm={12}>
                    <FormInputText
                      label="Visitor Address"
                      name="Address"
                      as="textarea"
                    />
                  </Col>
                  <Col md={12} sm={12}>
                    <FormInputText
                      label="Purpose of Visit"
                      name="Purpose"
                      as="textarea"
                      isRequired="true"
                    />
                  </Col>

                  <Col md={12} sm={12}>
                    <FormInputDropdown
                      label="Office Location"
                      name="OfficeLocationId"
                      ddOpt={locationData}
                      isRequired="true"
                    />
                  </Col>
                  <Col md={12} sm={12}>
                    {
                      !settings.whomTOMeet ?
                        <FormAutoCompleteDropdownCard
                          name="ContactPerson"
                          data={chain(EmployeesList ?? [])}
                          label="Whom To Meet"
                          optionText="heading"
                          isRequired="true"
                          placeholder="Choose a Person"
                        />
                        : null
                    }
                  </Col>
                  <Col md={12} sm={12} className="mt-1">
                    <FormInputText
                      label="Check In Date"
                      name="ScheduleCheckInTime"
                      type="date"
                      min={DateTime.now().toJSDate()}
                      isRequired="true"
                    />
                  </Col>
                  <Col md={12} sm={12}>
                    <FormInputText
                      label="Check In Time"
                      name="VisiteTime"
                      type="time"
                      isRequired="true"
                    />
                  </Col>
                </Row>
                <Row></Row>
                {/* <FormInputDropdown
          label="Invited By"
          name="InvitedBy"
          ddOpt={[{}].concat(
            chain(state.employees ?? [])
              .map((m) => ({
                value: m.EmployeeId,
                text: m.FullName,
              }))
              .value()
          )}
        /> */}
                <Row className="mt-2 mb-5">
                  <Col className="d-flex justify-content-end">
                    <Button
                      className="mx-2"
                      type="reset"
                      onClick={() => frmRef.current.fnReset()}
                      variant="outline-danger"
                    >
                      Reset
                    </Button>
                    <Button type="Submit" variant="outline-primary">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
          <div className="col-md-6 p-5 d-none d-sm-block">
            <div className="card1">
              <div className="row px-3 justify-content-center  mt-5 mb-5 border-line pt-5">
                <img
                  src="../vistorSelfCheckin.png"
                  className="bg-image "
                  alt="image "
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
});

export default ScheduleVisitorRegistrationComponent;
