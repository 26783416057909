import React, { useEffect, memo, useState, useRef } from "react";
import { WebService } from "../../Services/WebService";
import { PageInfo } from "../PageInfo";
import { useDispatch } from "react-redux";
import Container from "react-bootstrap/Container";
import TableComponent from "../../Services/TableComponent";
import { useNavigate } from "react-router-dom";
import CheckBox from "@mui/icons-material/CheckCircle";
import Cancel from "@mui/icons-material/Cancel";
import Pending from "@mui/icons-material/Pending";
import { Tab, Tabs, Box, AppBar, Toolbar } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { useTheme, styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import PropTypes from "prop-types";
import Topbar from "../../Services/TopbarComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";
import { StandardConst } from "../../Services/StandardConst";

const EmployeeListComponent = () => {
  PageInfo({ pageTitle: "Employee List" });
    const dispatch=useDispatch();
  const navigate = useNavigate();
  const [state,setState]=useState();
  useEffect(()=>{
    var p1=WebService({dispatch,endPoint:`User/EmployeeList?where=StatusId eq ${StandardConst.Status.Approved}`})
    .then(r=>setState(r));
    Promise.all([p1]);
  },[]);
  const columns = [
    {
      Text: "Full Name",
      Value: "FullName",
    },
    {
      Text: "Code",
      Value: "EmployeeCode",
    },
    {
      Text: "Email",
      Value: "Email",
    },
    {
      Text: "Phone",
      Value: "Phone",
    },
    {
      Text: "Designation",
      Value: "Designation",
    },
    {
      Text: "Manager",
      Value: "Manager",
    },
    {
      Text: "Action",
      //isVisiable: permission.ManageEdit || permission.ManageDelete,
      render: (dr) => (
        <>
          <button
            className="btn btn-default text-primary"
            id="btnRegistrationApprovalView"
            onClick={() => fnEdit(dr.EmployeeId)}
            //disabled={!permission.ManageEdit}
          >
            <i className="fa fa-eye"></i>
          </button>
        </>
      ),
    },
  ];

  const fnEdit = async (EmpId) => {
    navigate("/Common/Profile", { state: { EmpId } });
  };
  const topbar = (
    <Topbar
      bData={[
        {
          title: "Employee",
          hrefLink: "#",
        },
        {
          title: "Employee List",
          hrefLink: "#",
        },
      ]}
      HeadingText="Employee List"
    ></Topbar>
  );
  return (
    <>
      <Container
        fluid
        style={{
          padding: "0px",
          // "background-color": "#FFF",
          borderRadius: "5px ",
          margin: "10px",
          backgroundColor: "#fff",
        }}
      >
        {topbar}
        <Box>
          <TableComponent
            columns={columns}
            noRecordCss="p-0"
            noRecordFound={
              <NoRecordTemplate
                headerValue="No results found"
                subHeaderValue="Looks like you don't have any approved results !!!"
                imageUrl="uploads/Static/no-records-j.png"
              />
            }
            data={state}
            onAddEvent={() => fnEdit()}
            IsAddButtonVisible={false}
            isSearchRequired={true}
            allowSerialNo={true}
          />
        </Box>
      </Container>
    </>
  );
};

export default memo(EmployeeListComponent);
